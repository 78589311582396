import React, {
  FocusEvent, useEffect, useState,
  useMemo,
} from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper, Spacer,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { H3, Toast } from '@scaut-sro/meepo';
import { ScreeningDefinitionsEditableProps } from '../CataloguePackages.model';
import { ScreeningDefinitionCountDto } from '../../../../build/generated-sources/dto/ScreeningDefinitionCountDto';
import { useGetUser } from '../../../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../../../core/localization/LocaleUtils';
import CataloguePackagesLocale from '../CataloguePackages.locale';
import Translate from '../../../../components/Translate/Translate';
import {
  useGetActiveScreeningDefinitions,
} from '../../../../build/generated-sources/service/QueryService';
import { ScreeningDefinition } from '../../../../build/generated-sources/dto/ScreeningDefinition';

const ScreeningDefinitionsEditable: React.FunctionComponent<ScreeningDefinitionsEditableProps> = (props) => {
  const { language } = useGetUser();
  const { screeningDefinitions, onScreeningDefinitionsChange } = props;
  const [screeningDefinitionsList, setScreeningDefinitionsList] = useState<ScreeningDefinition[]>([]);

  const [rows, setRows] = useState<ScreeningDefinitionCountDto[]>(screeningDefinitions);

  useEffect(() => {
    setRows([...screeningDefinitions] || []);
  }, [screeningDefinitions, setRows]);

  useGetActiveScreeningDefinitions(
    {
      id: true,
      internalName: true,
      countable: true,
      countLimit: true,
      country: {
        id: true,
        translation: {
          localizationStrings: true,
        },
      },
    }, {
      onError: () => {
        Toast({
          title: translate(CataloguePackagesLocale.ERROR_GETTING_SCREENING_DEFINITIONS, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setScreeningDefinitionsList(response.activeScreeningDefinitions);
      },
    },
  );

  const handleCountChange = (event: FocusEvent<HTMLInputElement>) => {
    const newScreeningDefinitions = [...rows];
    const index: number = parseInt(event.target.id.split('-')[0], 10) - 1;
    const attribute: string = event.target.name;
    const { value } = event.target;

    newScreeningDefinitions[index] = { ...screeningDefinitions[index] };
    if (attribute === 'count') {
      newScreeningDefinitions[index].count = +value;
    }

    onScreeningDefinitionsChange(newScreeningDefinitions);
  };

  const internalNameOptions: { value: number; label: string; }[] = useMemo(() => screeningDefinitionsList.map((sd) => ({
    value: sd.id || -1,
    label: `${sd.internalName} (${sd.country?.translation?.localizationStrings?.[language]})` || '',
  })), [screeningDefinitionsList, language]);

  const updateScreeningDefinitionName = (selected: { value: number; label: string; }, index: number) => {
    const newScreeningDefinitions = [...rows];
    const definition = screeningDefinitionsList.find((element) => element.id === selected.value);
    newScreeningDefinitions[index].id = definition?.id;
    newScreeningDefinitions[index].internalName = definition?.internalName;
    newScreeningDefinitions[index].countable = definition?.countable;
    newScreeningDefinitions[index].countLimit = definition?.countLimit;
    newScreeningDefinitions[index].count = 1;

    onScreeningDefinitionsChange(newScreeningDefinitions);
  };

  const addScreeningDefinitionRow = () => {
    const newRows = [...screeningDefinitions] || [];
    newRows.push({});
    setRows(newRows);
  };

  const removeScreeningDefinitionRow = (event: any) => {
    const index: number = parseInt(event.target.id.split('-')[0], 10) - 1;
    const newScreeningDefinitions = [...rows];
    newScreeningDefinitions.splice(index, 1);

    onScreeningDefinitionsChange(newScreeningDefinitions);
  };

  return (
    <>
      <Flex mt={20}>
        <Box maxWidth={500}>
          <H3><Translate label={CataloguePackagesLocale.SCREENING_DEFINITIONS} /></H3>
        </Box>
        <Spacer />
        <Button onClick={addScreeningDefinitionRow} variant="outline">+</Button>
      </Flex>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th />
            <Th>{translate(CataloguePackagesLocale.NAME, language)}</Th>
            <Th>{translate(CataloguePackagesLocale.COUNT_LIMIT, language)}</Th>
            <Th>{translate(CataloguePackagesLocale.COUNT, language)}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {rows.map((screeningDefinition, index) => (
            <Tr key={screeningDefinition.id}>
              <Td w="40px">{index + 1}</Td>
              <Td>
                <Select
                  id={`${index + 1}-internalName`}
                  name="internalName"
                  onChange={(selected: { value: number; label: string; }) => {
                    updateScreeningDefinitionName(selected, index);
                  }}
                  value={{
                    value: screeningDefinition.id,
                    label: `${screeningDefinition.internalName} (${
                      // TODO: add country to ScreeningDefinitionCountDto and stop cycling here
                      // screeningDefinition.country?.translation?.localizationStrings?.[language]
                      screeningDefinitionsList.find(
                        (sdl) => sdl.id === screeningDefinition.id,
                      )?.country?.translation?.localizationStrings?.[language]
                    })`,
                  }}
                  options={internalNameOptions}
                />
              </Td>
              <Td w="100px">
                <Input
                  id={`${index + 1}-countLimit`}
                  name="countLimit"
                  disabled
                  variant="unstyled"
                  placeholder={translate(CataloguePackagesLocale.COUNT_LIMIT, language)}
                  defaultValue={screeningDefinition.countLimit}
                />
              </Td>
              <Td w="100px">
                { screeningDefinition.countable ? (
                  <NumberInput
                    id={`${index + 1}-count`}
                    name="count"
                    min={1}
                    max={screeningDefinition.countLimit}
                    defaultValue={screeningDefinition.count}
                    onBlur={handleCountChange}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                ) : (
                  <Input
                    id={`${index + 1}-count`}
                    ml={3}
                    name="count"
                    disabled
                    variant="unstyled"
                    defaultValue={screeningDefinition.count}
                  />
                )}
              </Td>
              <Td width="35px">
                <Button
                  id={`${index + 1}-count`}
                  name="count"
                  color="red"
                  variant="outline"
                  onClick={(event) => removeScreeningDefinitionRow(event)}
                >
                  -
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};

export default ScreeningDefinitionsEditable;
