import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  CentralizedContainer,
  H1, H3, PasswordInput,
} from '@scaut-sro/meepo';
import {
  Box,
  Button,
  Divider, Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  SimpleGrid, Spacer,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { RestLink } from 'apollo-link-rest';
import { translate } from '../../core/localization/LocaleUtils';
import RegistrationLocale from '../../core/localization/translations/RegistrationLocale';
import { Language } from '../../build/generated-sources/enum/Language';
import FormLocale from '../../core/localization/translations/Form.locale';
import { ClientRegistrationDto } from '../../core/service/dto/ClientRegistrationDto';
import * as surveyQuery from '../../core/service/queries/SurveyQuery';
import * as countryQuery from '../../core/service/queries/CountryQuery';
import * as registrationQuery from '../../core/service/queries/RegistrationQuery';
import ScautLogo from '../../components/Logo/Logo';

const Registration: React.FunctionComponent = () => {
  const [language, setMylanguage] = useState<Language>(Language.EN);
  useEffect(() => {
    if (navigator.language === 'cs') {
      setMylanguage(Language.CZ);
    } else {
      setMylanguage(Language.EN);
    }
  }, []);
  const ico = '25596641';
  const history = useHistory();
  const {
    register, handleSubmit, getValues, formState: { errors },
  } = useForm();

  const client = useMemo(() => {
    const restLink = new RestLink({
      uri: '/public',
    });

    return new ApolloClient({
      link: restLink,
      cache: new InMemoryCache(),
    });
  }, []);

  const [enumerations, setEnumerations] = useState([]);
  const [survey, setSurvey] = useState([]);

  useEffect(() => {
    client.query({ query: countryQuery.getCountry }).then((response) => setEnumerations(response.data.enumerations));
    client.query({ query: surveyQuery.getSurvey }).then((response) => setSurvey(response.data.enumerations));
  }, [client]);

  const countryOptions = () => {
    if (enumerations !== undefined && enumerations !== null) {
      return enumerations.map((element: any) => (
        <option
          key={element.id}
          value={element.id}
          label={element.translation.localizationStrings[language].value}
        />
      ));
    }
    return <option label=" " />;
  };

  const surveyOptions = () => {
    if (survey !== undefined && survey !== null) {
      return survey.map((element: any) => (
        <option
          key={element.id}
          value={element.id}
          label={element.translation.localizationStrings[language].value}
        />
      ));
    }
    return <option label=" " />;
  };

  const handleRegistrationSubmit = (values: ClientRegistrationDto) => {
    client.mutate({
      mutation: registrationQuery.postClient,
      variables: {
        clientRegistrationDto:
        {
          email: values.email,
          password: values.password,
          firstName: values.firstName,
          lastName: values.lastName,
          clientName: values.clientName,
          jobPosition: values.jobPosition,
          phone: values.phone,
          survey: values.survey,
          ico: values.ico,
          dic: values.dic,
          street: values.street,
          city: values.city,
          zip: values.zip,
          country: values.country,
          language,
        },
      },
    }).then((response) => {
      if (!response.errors) {
        history.push('/');
      }
    });
  };

  return (
    <Box bg="gray.200">
      <CentralizedContainer
        header={(<Box mt={-1}><ScautLogo /></Box>)}
      >
        <form name="regInfo" onSubmit={handleSubmit(handleRegistrationSubmit)}>
          <Divider mb={['20px', '20px', '20px', '30px', '30px']} />
          <H1>{translate(RegistrationLocale.REGISTRATION, language)}</H1>
          <H3>{translate(RegistrationLocale.LOGIN_INFORMATION, language)}</H3>
          <SimpleGrid columns={[1, 1, 2]} spacing={6} mb={6}>
            <FormControl isInvalid={errors.email} isRequired>
              <FormLabel htmlFor="email">{translate(RegistrationLocale.EMAIL, language)}</FormLabel>
              <Input
                id="email"
                placeholder={translate(RegistrationLocale.EMAIL_PLACEHOLDER, language)}
                {...register('email', {
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                  pattern: {
                    // eslint-disable-next-line max-len
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: translate(RegistrationLocale.EMAIL_ERROR_INVALID, language),
                  },
                })}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.password} isRequired>
              <FormLabel htmlFor="password">{translate(RegistrationLocale.PASSWORD, language)}</FormLabel>
              <PasswordInput
                id="password"
                placeholder={
                    translate(RegistrationLocale.PASSWORD, language)
                  }
                {...register('password', {
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                })}
              />
              <FormErrorMessage>
                {errors.password && errors.password.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.passwordConfirmation} isRequired>
              <FormLabel htmlFor="passwordConfirmation">
                {translate(RegistrationLocale.PASSWORD_CONFIRMATION, language)}
              </FormLabel>
              <PasswordInput
                id="passwordConfirmation"
                placeholder={
                    translate(RegistrationLocale.PASSWORD_CONFIRMATION_PLACEHOLDER, language)
                  }
                {...register('passwordConfirmation', {
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                  validate: {
                    matchesPreviousPassword: (value) => {
                      const { password } = getValues();
                      return password === value || translate(
                        RegistrationLocale.PASSWORD_CONFIRMATION_ERROR, language,
                      );
                    },
                  },
                })}
              />
              <FormErrorMessage>
                {errors.passwordConfirmation && errors.passwordConfirmation.message}
              </FormErrorMessage>
            </FormControl>
          </SimpleGrid>
          <H3>{translate(RegistrationLocale.PERSONAL_INFORMATION, language)}</H3>
          <SimpleGrid columns={[1, 1, 2]} spacing={6} mb={6}>
            <FormControl isInvalid={errors.firstName} isRequired>
              <FormLabel htmlFor="firstName">{translate(RegistrationLocale.FIRST_NAME, language)}</FormLabel>
              <Input
                id="firstName"
                placeholder={translate(RegistrationLocale.FIRST_NAME_PLACEHOLDER, language)}
                {...register('firstName', {
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                })}
              />
              <FormErrorMessage>
                {errors.firstName && errors.firstName.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.lastName} isRequired>
              <FormLabel htmlFor="lastName">{translate(RegistrationLocale.LAST_NAME, language)}</FormLabel>
              <Input
                id="lastName"
                placeholder={translate(RegistrationLocale.LAST_NAME_PLACEHOLDER, language)}
                {...register('lastName', {
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                })}
              />
              <FormErrorMessage>
                {errors.lastName && errors.lastName.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.clientName}>
              <FormLabel htmlFor="clientName">{translate(RegistrationLocale.CLIENT_NAME, language)}</FormLabel>
              <Input
                id="clientName"
                placeholder={translate(RegistrationLocale.CLIENT_NAME_PLACEHOLDER, language)}
                {...register('clientName', {
                })}
              />
            </FormControl>

            <FormControl isInvalid={errors.position}>
              <FormLabel htmlFor="jobPosition">{translate(RegistrationLocale.POSITION, language)}</FormLabel>
              <Input
                id="jobPosition"
                placeholder={translate(RegistrationLocale.POSITION_PLACEHOLDER, language)}
                {...register('jobPosition')}
              />
            </FormControl>
            <FormControl isInvalid={errors.phone}>
              <FormLabel htmlFor="phone">{translate(RegistrationLocale.PHONE, language)}</FormLabel>
              <Input
                id="phone"
                type="tel"
                placeholder={translate(RegistrationLocale.PHONE_PLACEHOLDER, language)}
                {...register('phone', {
                  maxLength: {
                    value: 15,
                    message: translate(RegistrationLocale.PHONE_ERROR_MAX_LENGTH, language),
                  },
                  minLength: {
                    value: 8,
                    message: translate(RegistrationLocale.PHONE_ERROR_MIN_LENGTH, language),
                  },
                  pattern: {
                    value: /^\+?[\d\s-]*$/,
                    message: translate(FormLocale.INVALID_CHARACTERS, language),
                  },
                })}
              />
              <FormErrorMessage>
                {errors.phone && errors.phone.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="survey">{translate(RegistrationLocale.SURVEY, language)}</FormLabel>
              <Select
                id="survey"
                {...register('survey')}
                placeholder={`--${translate(RegistrationLocale.SELECT_VALUE, language)}--`}
              >
                { surveyOptions() }
              </Select>
            </FormControl>
          </SimpleGrid>
          <H3>{translate(RegistrationLocale.COMPANY_INFORMATION, language)}</H3>
          <SimpleGrid columns={[1, 1, 2]} spacing={6} mb={10}>
            <FormControl>
              <FormLabel htmlFor="ico">{translate(RegistrationLocale.COMPANY_NUMBER, language)}</FormLabel>
              <Input
                id="ico"
                placeholder={ico}
                {...register('ico')}
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="dic">{translate(RegistrationLocale.COMPANY_VAT, language)}</FormLabel>
              <Input
                id="dic"
                placeholder={translate(RegistrationLocale.DIC_PLACEHOLDER, language)}
                {...register('dic')}
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="companyName">{translate(RegistrationLocale.COMPANY_NAME, language)}</FormLabel>
              <Input
                id="companyName"
                placeholder={translate(RegistrationLocale.COMPANY_NAME_PLACEHOLDER, language)}
                {...register('companyName')}
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="street">{translate(RegistrationLocale.COMPANY_STREET, language)}</FormLabel>
              <Input
                id="street"
                placeholder={translate(RegistrationLocale.COMPANY_STREET_PLACEHOLDER, language)}
                {...register('street')}
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="city">{translate(RegistrationLocale.COMPANY_CITY, language)}</FormLabel>
              <Input
                id="city"
                placeholder={translate(RegistrationLocale.COMPANY_CITY_PLACEHOLDER, language)}
                {...register('city')}
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="zip">{translate(RegistrationLocale.COMPANY_ZIP, language)}</FormLabel>
              <Input
                id="zip"
                placeholder={translate(RegistrationLocale.COMPANY_ZIP_PLACEHOLDER, language)}
                {...register('zip')}
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="country">{translate(RegistrationLocale.COMPANY_Country, language)}</FormLabel>
              <Select
                id="country"
                placeholder={`--${translate(RegistrationLocale.SELECT_VALUE, language)}--`}
                {...register('country')}
              >
                {countryOptions()}
              </Select>
            </FormControl>
          </SimpleGrid>
          <Flex>
            <Button
              leftIcon={<ArrowBackIcon />}
              variant="ghost"
              onClick={() => history.push('./')}
            >
              {translate(RegistrationLocale.BACK_TO_LOGIN, language)}
            </Button>
            <Spacer />
            <Button
              type="submit"
            >
              {translate(RegistrationLocale.SUBMIT_REG, language)}
            </Button>
          </Flex>
        </form>
      </CentralizedContainer>
    </Box>
  );
};

export default Registration;
