import React, { useRef, useState } from 'react';
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import Translate from '../../../../components/Translate/Translate';
import FormLocale from '../../../../core/localization/translations/Form.locale';
import ClientDetailForm from './ClientDetailForm';
import ClientsLocale from '../../Clients.locale';
import { PaymentMethod } from '../../../../build/generated-sources/enum/PaymentMethod';

const ClientDetailModal: React.FunctionComponent = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);

  return (
    <>
      <Button onClick={onOpen} leftIcon={<AddIcon />}>
        <Translate label={FormLocale.NEW} />
      </Button>
      <Modal closeOnOverlayClick={false} size="6xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text><Translate label={ClientsLocale.MODAL_CREATE_CLIENT_TITLE} /></Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ClientDetailForm
              client={{}}
              allowedPaymentMethods={[PaymentMethod.CARD_PAYMENT, PaymentMethod.PREPAID]}
              loadingSetter={(isLoading) => { setLoading(isLoading); }}
              formRef={formRef}
            />
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button
                onClick={() => {
                  if (formRef) {
                    formRef.current?.dispatchEvent(new Event('submit', {
                      cancelable: true,
                      bubbles: true,
                    }));
                  }
                }}
                isLoading={loading}
              >
                <Translate label={FormLocale.CONFIRM} />
              </Button>
              <Button variant="ghost" colorScheme="red" onClick={onClose}>
                <Translate label={FormLocale.CANCEL} />
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ClientDetailModal;
