import { ApolloClient, ApolloQueryResult } from '@apollo/client';
import { GraphqlBuilder, GraphQLType } from '@scaut-sro/npm-core-generator';
import {
  UseGetComponentDefinitionScreeningDefinitionsVariables,
  UseGetComponentDefinitionScreeningDefinitionsData,
  UseGetScreeningDefinitionScreeningPackagesVariables, UseGetScreeningDefinitionScreeningPackagesData,
} from '../../../build/generated-sources/service/QueryServiceModel';
import { ScreeningDefinitionMapper } from '../../../build/generated-sources/dto/ScreeningDefinition';
import { ScreeningPackageMapper } from '../../../build/generated-sources/dto/ScreeningPackage';

export function getComponentDefScreeningDefs(
  client: ApolloClient<any>,
  mapper: ScreeningDefinitionMapper,
  variables: UseGetComponentDefinitionScreeningDefinitionsVariables,
): Promise<ApolloQueryResult<UseGetComponentDefinitionScreeningDefinitionsData>> {
  const queryBuilder: GraphqlBuilder<ScreeningDefinitionMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  const query = queryBuilder
    .setName('componentDefinitionScreeningDefinitions')
    .setReturnProperties(mapper)
    .declareVariables({
      componentDefinitionId: 'Long!',
    })
    .setProperties('componentDefinitionId: $componentDefinitionId')
    .build();

  return client.query<UseGetComponentDefinitionScreeningDefinitionsData, UseGetComponentDefinitionScreeningDefinitionsVariables>({
    query,
    variables,
  });
}

export function getScreeningDefScreeningPacks(
  client: ApolloClient<any>,
  mapper: ScreeningPackageMapper,
  variables: UseGetScreeningDefinitionScreeningPackagesVariables,
): Promise<ApolloQueryResult<UseGetScreeningDefinitionScreeningPackagesData>> {
  const queryBuilder: GraphqlBuilder<ScreeningPackageMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  const query = queryBuilder
    .setName('screeningDefinitionScreeningPackages')
    .setReturnProperties(mapper)
    .declareVariables({
      screeningDefinitionId: 'Long!',
    })
    .setProperties('screeningDefinitionId: $screeningDefinitionId')
    .build();

  return client.query<UseGetScreeningDefinitionScreeningPackagesData, UseGetScreeningDefinitionScreeningPackagesVariables>({
    query,
    variables,
  });
}
