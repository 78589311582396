import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, FormControl, FormErrorMessage, FormLabel, GridItem, Input, SimpleGrid, Switch,
} from '@chakra-ui/react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Toast } from '@scaut-sro/meepo';
import { Select } from 'chakra-react-select';
import { CatalogueScreeningDetailProps, ScreeningDefinitionFormAttr } from '../CatalogueScreenings.model';
import Translate from '../../../../components/Translate/Translate';
import { translate } from '../../../../core/localization/LocaleUtils';
import { useGetUser } from '../../../../core/store/reducers/UserSettingsReducer';
import { useScreeningDefinition } from '../../../../build/generated-sources/service/MutationService';
import FormLocale from '../../../../core/localization/translations/Form.locale';
import CatalogueScreeningsLocale from '../CatalogueScreenings.locale';
import { Country } from '../../../../build/generated-sources/dto/Country';
import {
  useGetActiveComponentDefinitions,
  useGetCountries,
} from '../../../../build/generated-sources/service/QueryService';
import { Translation } from '../../../../build/generated-sources/dto/Translation';
import { ComponentDefinition } from '../../../../build/generated-sources/dto/ComponentDefinition';
import TranslationChooseModal from '../../../Translations/TranslationChooseModal/TranslationChooseModal';
import {
  UseScreeningDefinitionVariables,
} from '../../../../build/generated-sources/service/MutationServiceModel';

const createScreeningDefinitionMapper = {
  id: true,
  internalName: true,
  active: true,
  countable: true,
  countLimit: true,
  country: {
    id: true,
    translation: {
      id: true,
      localizationStrings: true,
    },
  },
  name: {
    id: true,
    localizationStrings: true,
  },
  description: {
    id: true,
    localizationStrings: true,
  },
  componentDefinitions: {
    id: true,
    internalName: true,
    processDefinition: true,
    averageRuntime: true,
    internalExpenses: true,
    externalExpenses: true,
    fees: true,
    requiresPowerOfAttorney: true,
    performableAsGroup: true,
    country: {
      id: true,
      translation: {
        id: true,
        localizationStrings: true,
      },
    },
  },
};

const CatalogueScreeningDetailForm: React.FunctionComponent<CatalogueScreeningDetailProps> = (props) => {
  const {
    screeningDefinitionDetail, loadingSetter, formRef, cancelChangesTrigger, refreshData,
  } = props;
  const { language } = useGetUser();
  const [countryList, setCountryList] = useState<Country[]>([]);
  const [isCountLimitDisabled, setIsCountLimitDisabled] = useState(false);
  const [componentDefinitionList, setComponentDefinitionList] = useState<ComponentDefinition[]>([]);
  const [activeButtonDisabled, setActiveButtonDisabled] = useState<boolean>(
    screeningDefinitionDetail?.screeningDefinition?.componentDefinitions?.length === 0,
  );

  const defaultValues: Partial<ScreeningDefinitionFormAttr> = useMemo(() => ({
    id: screeningDefinitionDetail?.screeningDefinition?.id,
    internalName: screeningDefinitionDetail?.screeningDefinition?.internalName,
    price: screeningDefinitionDetail?.price,
    active: screeningDefinitionDetail?.screeningDefinition?.active,
    countable: screeningDefinitionDetail?.screeningDefinition?.countable,
    countLimit: screeningDefinitionDetail?.screeningDefinition?.countLimit || 1,
    translationNameId: screeningDefinitionDetail?.screeningDefinition?.name?.id || undefined,
    translationDescriptionId: screeningDefinitionDetail?.screeningDefinition?.description?.id || undefined,
    countryId: screeningDefinitionDetail?.screeningDefinition?.country?.id ? {
      value: screeningDefinitionDetail?.screeningDefinition?.country?.id || -1,
      label: screeningDefinitionDetail?.screeningDefinition?.country?.translation?.localizationStrings?.[language] || '',
    } : undefined,
    componentDefinitions: screeningDefinitionDetail?.screeningDefinition?.componentDefinitions?.map((componentDefinition) => ({
      value: componentDefinition.id || -1,
      label: componentDefinition.internalName || '',
    })),
  }
  ), [screeningDefinitionDetail, language]);

  const {
    handleSubmit, register, setValue, formState: { errors }, reset, control, getValues,
  } = useForm<Partial<ScreeningDefinitionFormAttr>>({
    defaultValues,
  });

  useGetCountries(
    {
      id: true,
      translation: {
        id: true,
        localizationStrings: true,
      },
    }, {
      onError: () => {
        Toast({
          title: translate(CatalogueScreeningsLocale.ERROR_GETTING_COUNTRIES, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setCountryList(response.countries);
      },
    },
  );

  useGetActiveComponentDefinitions(
    {
      id: true,
      internalName: true,
    }, {
      onError: () => {
        Toast({
          title: translate(CatalogueScreeningsLocale.ERROR_GETTING_TRANSLATIONS, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setComponentDefinitionList(response.activeComponentDefinitions);
      },
    },
  );

  const countryOptions = useMemo(() => (countryList.map((c) => ({
    value: c.id,
    label: c?.translation?.localizationStrings?.[language],
  }))), [countryList, language]);

  const componentDefinitionOptions = useMemo(() => componentDefinitionList.map((c) => ({
    value: c.id,
    label: c?.internalName,
  })), [componentDefinitionList]);

  const componentDefinitions = useMemo(() => (componentDefinitionOptions.filter(
    (componentDefinition) => {
      const isSelected = screeningDefinitionDetail?.screeningDefinition?.componentDefinitions?.find(
        (cd) => cd.id === componentDefinition.value,
      );
      return !!isSelected;
    },
  )), [componentDefinitionOptions, screeningDefinitionDetail]);

  useEffect(() => {
    reset(defaultValues);
    setIsCountLimitDisabled(getValues().countable === false);
    setValue('componentDefinitions', componentDefinitions as { value: number, label: string }[]);
  }, [
    cancelChangesTrigger, reset, setValue, defaultValues,
    componentDefinitions, getValues,
  ]);

  const [mutateCreateScreeningDefinition,
    optionsMutationScreeningDefinition] = useScreeningDefinition(createScreeningDefinitionMapper, {
    onCompleted: () => {
      Toast({
        title: translate(FormLocale.SUCCESSFULLY_SAVED, language),
        status: 'success',
      });
      if (refreshData) {
        refreshData();
      }
    },
    onError: () => {
      Toast({
        title: translate(CatalogueScreeningsLocale.ERROR_GETTING_DATA, language),
        status: 'error',
      });
    },
  });

  useEffect(() => {
    if (loadingSetter) loadingSetter(optionsMutationScreeningDefinition.loading);
  }, [loadingSetter, optionsMutationScreeningDefinition]);

  const handleScreeningDefinitionFormSubmit: SubmitHandler<ScreeningDefinitionFormAttr> = (values) => {
    const componentDefs = values.componentDefinitions.map((cd) => (cd.value));

    const variables: UseScreeningDefinitionVariables = {
      screeningDefinition: {
        id: screeningDefinitionDetail?.screeningDefinition?.id,
        internalName: values.internalName,
        price: values.price,
        countryId: values.countryId.value,
        active: componentDefs.length === 0 ? false : values.active,
        countable: values.countable,
        countLimit: values.countLimit,
        componentDefinitions: componentDefs,
        translationNameId: values.translationNameId,
        translationDescriptionId: values.translationDescriptionId,
      },
    };
    mutateCreateScreeningDefinition({ variables });
  };

  const setTranslationName = (translation: Translation) => {
    setValue('translationNameId', translation.id);
  };

  const setTranslationDescription = (translation: Translation) => {
    setValue('translationDescriptionId', translation.id);
  };

  const handleFormChange = () => {
    setIsCountLimitDisabled(getValues().countable === false);
  };

  return (
    <>
      <form
        name="screeningDefinitionForm"
        onSubmit={handleSubmit(handleScreeningDefinitionFormSubmit)}
        onChange={handleFormChange}
        ref={formRef}
      >
        <SimpleGrid columns={[1, 1, 1, 3, 3, 3]} spacing={6}>

          <GridItem colSpan={[1, 1, 1, 2, 2, 2]}>
            <FormControl isRequired isInvalid={!!errors.internalName}>
              <FormLabel htmlFor="internalName">
                <Translate label={CatalogueScreeningsLocale.INTERNAL_NAME} />
              </FormLabel>
              <Input
                placeholder={translate(CatalogueScreeningsLocale.INTERNAL_NAME, language)}
                {...register('internalName', {
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                })}
              />
              <FormErrorMessage>
                {errors.internalName && errors.internalName.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <Box>
            <FormControl
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderRadius="md"
              backgroundColor="gray.100"
              p="6px"
              pl={3}
              mt="30px"
            >
              <label htmlFor="active">{translate(CatalogueScreeningsLocale.ACTIVE, language)}</label>
              <Controller
                control={control}
                name="active"
                render={({ field }) => (
                  <Switch
                    onChange={(e) => { field.onChange(e.target.checked); }}
                    isChecked={field.value}
                    size="lg"
                    colorScheme="green"
                    isDisabled={activeButtonDisabled}
                  />
                )}
              />
            </FormControl>
          </Box>

          <FormControl isRequired isInvalid={!!errors.price}>
            <FormLabel htmlFor="price">
              <Translate label={CatalogueScreeningsLocale.PRICE} />
            </FormLabel>
            <Input
              placeholder={translate(CatalogueScreeningsLocale.PRICE, language)}
              type="number"
              {...register('price', {
                required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
              })}
            />
            <FormErrorMessage>
              {errors.price && errors.price.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={!!errors.translationNameId}>
            <FormLabel htmlFor="translationName">
              <Translate label={CatalogueScreeningsLocale.TRANSLATION_NAME} />
            </FormLabel>
            <TranslationChooseModal
              translationId={getValues('translationNameId')}
              setValue={setTranslationName}
              type="string"
              {...register('translationNameId', {
                required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
              })}
            />
            <FormErrorMessage>
              {errors.translationNameId && errors.translationNameId.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={!!errors.translationDescriptionId}>
            <FormLabel htmlFor="translationDescriptionId">
              <Translate label={CatalogueScreeningsLocale.TRANSLATION_DESCRIPTION} />
            </FormLabel>
            <TranslationChooseModal
              translationId={getValues('translationDescriptionId')}
              setValue={setTranslationDescription}
              type="string"
              {...register('translationDescriptionId', {
                required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
              })}
            />
            <FormErrorMessage>
              {errors.translationDescriptionId && errors.translationDescriptionId.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={!!errors.countryId}>
            <FormLabel htmlFor="countryId">
              <Translate label={CatalogueScreeningsLocale.COUNTRY} />
            </FormLabel>
            <Controller
              name="countryId"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={countryOptions}
                  hideSelectedOptions={false}
                  selectedOptionStyle="check"
                />
              )}
              rules={{
                required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
              }}
            />
            <FormErrorMessage>
              {errors.countryId && errors.countryId.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.componentDefinitions}>
            <FormLabel htmlFor="componentDefinitions">
              <Translate label={CatalogueScreeningsLocale.COMPONENT_DEFINITIONS} />
            </FormLabel>
            <Controller
              name="componentDefinitions"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={componentDefinitionOptions}
                  hideSelectedOptions={false}
                  selectedOptionStyle="check"
                  isMulti
                  closeMenuOnSelect={false}
                  onChange={(event: any) => {
                    setValue('componentDefinitions', event);
                    setActiveButtonDisabled(getValues().componentDefinitions?.length === 0);
                    if (getValues().componentDefinitions?.length === 0) {
                      setValue('active', false);
                    }
                  }}
                />
              )}
            />
            <FormErrorMessage>
              {errors.componentDefinitions && errors.componentDefinitions.message}
            </FormErrorMessage>
          </FormControl>

          <Box />

          <Box>
            <FormControl
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderRadius="md"
              backgroundColor="gray.100"
              p="6px"
              pl={3}
              mt="30px"
            >
              <label htmlFor="countable">{translate(CatalogueScreeningsLocale.COUNTABLE, language)}</label>
              <Controller
                control={control}
                name="countable"
                render={({ field }) => (
                  <Switch
                    onChange={(e) => { field.onChange(e.target.checked); }}
                    isChecked={field.value}
                    size="lg"
                    colorScheme="green"
                  />
                )}
              />
            </FormControl>
          </Box>
          <FormControl isInvalid={!!errors.countLimit}>
            <FormLabel htmlFor="countLimit">
              <Translate label={CatalogueScreeningsLocale.COUNT_LIMIT} />
            </FormLabel>
            <Input
              placeholder={translate(CatalogueScreeningsLocale.COUNT_LIMIT, language)}
              type="number"
              disabled={isCountLimitDisabled}
              {...register('countLimit')}
            />
            <FormErrorMessage>
              {
                    errors.countLimit && errors.countLimit.message
                }
            </FormErrorMessage>
          </FormControl>

        </SimpleGrid>
      </form>
    </>
  );
};

export default CatalogueScreeningDetailForm;
