import React, { useRef, useState } from 'react';
import { Box, Button, Divider } from '@chakra-ui/react';
import { TranslationsDetailProps } from '../Translations.model';
import TranslationDetailForm from './TranslationDetailForm';
import Translate from '../../../components/Translate/Translate';
import FormLocale from '../../../core/localization/translations/Form.locale';
import { Translation } from '../../../build/generated-sources/dto/Translation';

const TranslationDetail: React.FunctionComponent<TranslationsDetailProps> = (props) => {
  const {
    translation,
    isModal,
    onTranslationChosen,
    chooseModalOnClose,
  } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);

  return (
    <>
      <Divider mb={6} />
      <Box p={3} pt={0}>
        <TranslationDetailForm
          loadingSetter={(isLoading) => {
            setLoading(isLoading);
          }}
          formRef={formRef}
          translation={translation}
        />
        {
                    isModal && onTranslationChosen ? (
                      <Button
                        textAlign="right"
                        onClick={() => {
                          onTranslationChosen(translation as Translation);
                          if (chooseModalOnClose) chooseModalOnClose();
                        }}
                        isLoading={loading}
                      >
                        <Translate label={FormLocale.CHOOSE_TRANSLATION} />
                      </Button>
                    ) : (
                      <Button
                        textAlign="right"
                        onClick={() => {
                          if (formRef) {
                            formRef.current?.dispatchEvent(new Event('submit', {
                              cancelable: true,
                              bubbles: true,
                            }));
                          }
                        }}
                        isLoading={loading}
                      >
                        <Translate label={FormLocale.CONFIRM} />
                      </Button>
                    )
                }
      </Box>
    </>
  );
};

export default TranslationDetail;
