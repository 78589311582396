import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Divider, FormControl, FormErrorMessage, FormLabel, GridItem, Input, SimpleGrid, Switch,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { H3, Toast } from '@scaut-sro/meepo';
import { useHistory } from 'react-router-dom';
import FormLocale from '../../../../core/localization/translations/Form.locale';
import UserSettingsLocale from '../../../UserSettings/UserSettings.locale';
import Translate from '../../../../components/Translate/Translate';
import { translate } from '../../../../core/localization/LocaleUtils';
import ClientsLocale from '../../Clients.locale';
import { useGetUser } from '../../../../core/store/reducers/UserSettingsReducer';
import {
  useGetAdminUsers,
  useGetCountries,
  useGetEnumerations,
  useGetScreeningPackages,
} from '../../../../build/generated-sources/service/QueryService';
import { Country } from '../../../../build/generated-sources/dto/Country';
import { Enumeration } from '../../../../build/generated-sources/dto/Enumeration';
import { EnumerationType } from '../../../../build/generated-sources/enum/EnumerationType';
import { useClient, useClientRegistration } from '../../../../build/generated-sources/service/MutationService';
import { AnonymizeAfter } from '../../../../build/generated-sources/enum/AnonymizeAfter';
import { InvoicePart } from '../../../../build/generated-sources/enum/InvoicePart';
import UsersLocale from '../../../Users/Users.locale';
import { PaymentMethod } from '../../../../build/generated-sources/enum/PaymentMethod';
import { Language } from '../../../../build/generated-sources/enum/Language';
import { ClientDetailProps, ClientFormAttr } from './ClientDetail.model';
import { handleApolloErrors } from '../../../../core/error/ScautErrorHandler';
import CataloguePackagesLocale from '../../../Catalogue/CataloguePackages/CataloguePackages.locale';
import { ScreeningPackage } from '../../../../build/generated-sources/dto/ScreeningPackage';
import { Direction } from '../../../../build/generated-sources/enum/Direction';

const createClientMapper = {
  id: true,
};

const ClientDetailForm: React.FunctionComponent<ClientDetailProps> = (props) => {
  const {
    loadingSetter, formRef, cancelChangesTrigger, client, allowedPaymentMethods,
  } = props;
  const { language } = useGetUser();
  const [countryList, setCountryList] = useState<Country[]>([]);
  const [countryEnumList, setCountryEnumList] = useState<Enumeration[]>([]);
  const [screeningPackageList, setScreeningPackageList] = useState<ScreeningPackage[] | undefined>([]);
  // const [teamList, setTeamList] = useState<TeamDto[]>([]);
  const history = useHistory();

  const defaultValues: Partial<ClientFormAttr> = useMemo(() => ({
    clientId: client?.id,
    name: client?.name,
    ico: client?.ico,
    dic: client?.dic,
    clientEmail: client?.email,
    clientPhone: client?.phone,
    street: client?.street,
    zipcode: client?.zipcode,
    city: client?.city,
    communicationLanguage: {
      value: client?.communicationLanguage || 'EN',
      label: translate(ClientsLocale[client?.communicationLanguage || Language.EN], language),
    },
    countryId: client?.country?.id ? {
      value: client?.country?.id,
      label: client?.country?.translation?.localizationStrings?.[language] || '',
    } : undefined,
    allowedPaymentMethods: allowedPaymentMethods?.map((method) => ({
      value: method || '',
      label: translate(ClientsLocale[method], language) || '',
    }) || []),
    allowPrepayment: client?.allowPrepayment || false,
    enterprise: client?.enterprise || false,
    screeningApprovals: client?.screeningApprovals || false,
    enabled2fa: client?.enabled2fa || false,
    anonymizeAfter: {
      value: client?.anonymizeAfter || 'NINETY_DAYS',
      label: translate(ClientsLocale[client?.anonymizeAfter || AnonymizeAfter.NINETY_DAYS], language),
    },
    invoiceParts: client?.invoiceParts?.map((part) => ({
      value: part || '',
      label: translate(ClientsLocale[part], language) || '',
    }) || []),
    supervisorId: {
      value: client?.supervisor?.id || undefined,
      label: (client?.supervisor?.id && `${client?.supervisor?.firstName} ${client?.supervisor?.lastName}`) || '- Select value -',
    },
    allowedCountries: client?.allowedCountries?.map((country) => ({
      value: country.id || -1,
      label: country.translation?.localizationStrings?.[language] || '',
    }) || []),
    defaultCountries: client?.defaultCountries?.map((country) => ({
      value: country.id || -1,
      label: country.translation?.localizationStrings?.[language] || '',
    }) || []),
    screeningPackages: client?.screeningPackages?.map((screeningPackage) => ({
      value: screeningPackage.id || -1,
      label: screeningPackage.name?.localizationStrings?.[language] || '',
    }) || []),
  }), [client, language, allowedPaymentMethods]);
  const {
    handleSubmit, register, setValue, reset, formState: { errors }, control, watch, getValues,
  } = useForm<Partial<ClientFormAttr>>({
    defaultValues,
  });

  const supervisors = useGetAdminUsers({
    id: true,
    firstName: true,
    lastName: true,
  });

  useGetEnumerations(
    {
      id: true,
      value: true,
      translation: {
        id: true,
        localizationStrings: true,
      },
    }, {
      variables: {
        enumerationType: EnumerationType.COUNTRY,
      },
      onError: () => {
        Toast({
          title: translate(ClientsLocale.ERROR_GETTING_CLIENT_DATA, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setCountryEnumList(response.enumerations);
      },
    },
  );

  useGetCountries(
    {
      id: true,
      translation: {
        id: true,
        localizationStrings: true,
      },
    }, {
      onError: () => {
        Toast({
          title: translate(ClientsLocale.ERROR_GETTING_COUNTRIES, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setCountryList(response.countries);
      },
    },
  );

  useGetScreeningPackages({
    pageInfo: {
      pageSize: true,
      pageNumber: true,
    },
    content: {
      id: true,
      internalName: true,
      active: true,
      name: {
        id: true,
        localizationStrings: true,
      },
      country: {
        translation: { localizationStrings: true },
      },
    },
  }, {
    onError: () => {
      Toast({
        title: translate(CataloguePackagesLocale.ERROR_GETTING_DATA, language),
        status: 'error',
      });
    },
    variables: {
      paging: {
        page: 0,
        size: 1000,
        sortDirection: Direction.DESC,
      },
      filter: {
        active: true,
      },
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      setScreeningPackageList(response.screeningPackages.content);
    },
  });

  /*  useGetTeams(
    {
      id: true,
      name: true,
    }, {
      onError: () => {
        Toast({
          title: translate(ClientsLocale.ERROR_GETTING_TEAMS, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setTeamList(response.teams);
      },
    },
  ); */

  const allowedCountriesOptions = useMemo(() => (countryList.map((c) => ({
    value: c.id,
    label: c?.translation?.localizationStrings?.[language],
  }))), [countryList, language]);

  const allowedCountries = watch('allowedCountries');

  const defaultCountriesOptions = useMemo(
    () => (
      allowedCountriesOptions.filter(
        (c) => allowedCountries?.find((cnt) => cnt.value === c.value),
      )),
    [allowedCountriesOptions, allowedCountries],
  );

  useEffect(() => {
    const defaultCountries = getValues('defaultCountries') || [] as { value: number, label: string }[];
    const newDefaultCountries: { value: number, label: string }[] = defaultCountries.filter(
      (defaultCountry) => (allowedCountries?.find((dco) => (defaultCountry.value === dco.value))),
    );
    setValue('defaultCountries', newDefaultCountries);
  }, [allowedCountries, getValues, setValue]);

  const screeningPackagesOptions = useMemo(() => (screeningPackageList?.map((c) => ({
    value: c.id,
    label: c?.name?.localizationStrings?.[language],
  }))), [screeningPackageList, language]);

  const countryEnumOptions = useMemo(() => countryEnumList.map((c) => ({
    value: c.id,
    label: c.translation?.localizationStrings?.[language],
  })), [countryEnumList, language]);

  const supervisorsOptions = useMemo(() => supervisors.data?.adminUsers.map((supervisor) => ({
    value: supervisor.id,
    label: `${supervisor.firstName} ${supervisor.lastName}`,
  })), [supervisors]);

  useEffect(() => {
    supervisorsOptions?.unshift({ value: undefined, label: '- Select value -' });
  }, [supervisorsOptions]);

  const anonymizeAfterOptionsList = useMemo(() => ([
    { value: 'TEN_DAYS', label: translate(ClientsLocale.TEN_DAYS, language) },
    { value: 'THIRTY_DAYS', label: translate(ClientsLocale.THIRTY_DAYS, language) },
    { value: 'SIXTY_DAYS', label: translate(ClientsLocale.SIXTY_DAYS, language) },
    { value: 'NINETY_DAYS', label: translate(ClientsLocale.NINETY_DAYS, language) },
    { value: 'YEAR', label: translate(ClientsLocale.YEAR, language) },
  ]), [language]);

  const paymentMethodOptionsList = useMemo(() => ([
    { value: 'CARD_PAYMENT', label: translate(ClientsLocale.CARD_PAYMENT, language) },
    { value: 'INVOICE_AFTER_ORDER', label: translate(ClientsLocale.INVOICE_AFTER_ORDER, language) },
    { value: 'INVOICE_MONTHLY', label: translate(ClientsLocale.INVOICE_MONTHLY, language) },
    { value: 'PREPAID', label: translate(ClientsLocale.PREPAID, language) },
  ]), [language]);

  const invoicePartsOptions = useMemo(() => ([
    { value: 'INCLUDE_INDIVIDUAL_CHECKS', label: translate(ClientsLocale.INCLUDE_INDIVIDUAL_CHECKS, language) },
    { value: 'LIST_CANDIDATES', label: translate(ClientsLocale.LIST_CANDIDATES, language) },
  ]), [language]);

  const languageOptions = useMemo(() => ([
    { value: 'EN', label: translate(ClientsLocale.ENGLISH, language) },
    { value: 'CZ', label: translate(ClientsLocale.CZECH, language) },
  ]), [language]);

  /*  const teamOptions = useMemo(() => teamList.map((team) => ({
    value: team.id,
    label: team.name,
  })), [teamList]); */

  useEffect(() => {
    reset(defaultValues);
  }, [
    cancelChangesTrigger, reset, setValue, defaultValues,
  ]);

  const [mutateClient,
    mutateClientOptions] = useClient(createClientMapper, {
    onCompleted: () => {
      Toast({
        title: translate(FormLocale.SUCCESSFULLY_SAVED, language),
        status: 'success',
      });
    },
    onError: (error) => {
      handleApolloErrors(error, language);
    },
  });

  const [mutateClientRegistration,
    mutateClientRegistrationOptions] = useClientRegistration({
    id: true,
  }, {
    onCompleted: (response) => {
      Toast({
        title: translate(FormLocale.SUCCESSFULLY_SAVED, language),
        status: 'success',
      });
      history.push(`/client/${response.clientRegistration.id}`);
    },
    onError: (error) => {
      handleApolloErrors(error, language);
    },
  });

  useEffect(() => {
    if (loadingSetter) {
      if (client?.id) {
        loadingSetter(mutateClientOptions.loading);
      } else {
        loadingSetter(mutateClientRegistrationOptions.loading);
      }
    }
  }, [loadingSetter, mutateClientOptions, mutateClientRegistrationOptions, client]);

  useEffect(() => {
    register('countryId');
    register('supervisorId');
    register('invoiceParts');
    register('anonymizeAfter');
    register('allowedCountries');
    register('screeningApprovals');
    register('enterprise');
    register('enabled2fa');
  }, [register]);

  const handleClientFormSubmit: SubmitHandler<ClientFormAttr> = (values) => {
    if (client?.id) {
      mutateClient({
        variables: {
          clientId: client?.id || -1,
          client: {
            name: values.name,
            ico: values.ico,
            dic: values.dic,
            clientEmail: values.clientEmail,
            clientPhone: values.clientPhone,
            street: values.street,
            zipcode: values.zipcode,
            city: values.city,
            countryId: values.countryId?.value,
            allowedPaymentMethods: values.allowedPaymentMethods?.map((method) => method.value as PaymentMethod),
            allowPrepayment: values.allowPrepayment,
            enterprise: values.enterprise,
            enabled2fa: values.enabled2fa,
            screeningApprovals: values.screeningApprovals,
            anonymizeAfter: values.anonymizeAfter?.value as AnonymizeAfter,
            invoiceParts: values.invoiceParts?.map((ip) => ip.value as InvoicePart),
            supervisorId: values.supervisorId?.value,
            allowedCountryIds: values.allowedCountries?.map((ac) => ac.value),
            defaultCountryIds: values.defaultCountries?.map((dc) => dc.value),
            screeningPackageIds: values.screeningPackages?.map((sp) => sp.value),
            communicationLanguage: values.communicationLanguage?.value as Language,
          },
        },
      });
    } else {
      mutateClientRegistration({
        variables: {
          client: {
            name: values.name,
            ico: values.ico,
            dic: values.dic,
            clientEmail: values.clientEmail,
            clientPhone: values.clientPhone,
            street: values.street,
            zipcode: values.zipcode,
            city: values.city,
            countryId: values.countryId?.value,
            allowedPaymentMethods: values.allowedPaymentMethods?.map((method) => method.value as PaymentMethod),
            allowPrepayment: values.allowPrepayment,
            enterprise: values.enterprise,
            enabled2fa: values.enabled2fa,
            screeningApprovals: values.screeningApprovals,
            anonymizeAfter: values.anonymizeAfter?.value as AnonymizeAfter,
            invoiceParts: values.invoiceParts?.map((ip) => ip.value as InvoicePart),
            supervisorId: values.supervisorId?.value,
            allowedCountryIds: values.allowedCountries?.map((ac) => ac.value),
            defaultCountryIds: values.defaultCountries?.map((dc) => dc.value),
            communicationLanguage: values.communicationLanguage?.value as Language,
            screeningPackageIds: values.screeningPackages?.map((sp) => sp.value),
            email: values.email,
            phone: values.phone,
            firstName: values.firstName,
            lastName: values.lastName,
            language: values.language?.value as Language,
            // teamIds: values.teamIds?.map((id) => id.value as number),
          },
        },
      });
    }
  };

  return (
    <>
      <form
        name="userDetailForm"
        ref={formRef}
        onSubmit={handleSubmit(handleClientFormSubmit)}
      >
        <SimpleGrid columns={[1, 1, 1, 3, 3, 3]} spacing={6} mb={7}>
          <FormControl isRequired isInvalid={!!errors.name}>
            <FormLabel htmlFor="name">
              <Translate label={ClientsLocale.NAME} />
            </FormLabel>
            <Input
              placeholder={translate(ClientsLocale.NAME, language)}
              {...register('name', {
                required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
              })}
            />
            <FormErrorMessage>
              {errors.name && errors.name.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={!!errors.clientEmail}>
            <FormLabel htmlFor="clientEmail">
              <Translate label={ClientsLocale.CLIENT_EMAIL} />
            </FormLabel>
            <Input
              type="clientEmail"
              id="clientEmail"
              placeholder={translate(ClientsLocale.EMAIL_PLACEHOLDER, language)}
              {...register('clientEmail', {
                required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                pattern: {
                  // eslint-disable-next-line max-len
                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: translate(UserSettingsLocale.EMAIL_ERROR_INVALID, language),
                },
              })}
            />
            <FormErrorMessage>
              {errors.clientEmail && errors.clientEmail.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="clientPhone">
              <Translate label={ClientsLocale.CLIENT_PHONE} />
            </FormLabel>
            <Input
              type="tel"
              id="clientPhone"
              placeholder={translate(ClientsLocale.PHONE_PLACEHOLDER, language)}
              {...register('clientPhone', {
                maxLength: {
                  value: 15,
                  message: translate(ClientsLocale.PHONE_ERROR_MAX_LENGTH, language),
                },
                minLength: {
                  value: 8,
                  message: translate(ClientsLocale.PHONE_ERROR_MIN_LENGTH, language),
                },
                pattern: {
                  value: /^\+?[\d\s-]*$/,
                  message: translate(FormLocale.INVALID_CHARACTERS, language),
                },
              })}
            />
            <FormErrorMessage>
              {errors.clientPhone && errors.clientPhone.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="ico">{translate(ClientsLocale.ID, language)}</FormLabel>
            <Input
              id="ico"
              placeholder={translate(ClientsLocale.ID, language)}
              {...register('ico')}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="dic">{translate(ClientsLocale.VAT, language)}</FormLabel>
            <Input
              id="dic"
              placeholder={translate(ClientsLocale.VAT, language)}
              {...register('dic')}
            />
          </FormControl>
          <FormControl isRequired isInvalid={!!errors.countryId}>
            <FormLabel><Translate label={ClientsLocale.COUNTRY} /></FormLabel>
            <Controller
              name="countryId"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={countryEnumOptions}
                  hideSelectedOptions={false}
                  selectedOptionStyle="check"
                />
              )}
              rules={{
                required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="street">{translate(ClientsLocale.STREET, language)}</FormLabel>
            <Input
              id="street"
              placeholder={translate(ClientsLocale.STREET, language)}
              {...register('street')}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="city">{translate(ClientsLocale.CITY, language)}</FormLabel>
            <Input
              id="city"
              placeholder={translate(ClientsLocale.CITY, language)}
              {...register('city')}
            />
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="zipcode">{translate(ClientsLocale.ZIP, language)}</FormLabel>
            <Input
              id="zipcode"
              placeholder={translate(ClientsLocale.ZIP, language)}
              {...register('zipcode')}
            />
          </FormControl>
          <FormControl>
            <FormLabel><Translate label={ClientsLocale.SUPERVISOR} /></FormLabel>
            <Controller
              name="supervisorId"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={supervisorsOptions}
                  hideSelectedOptions={false}
                  selectedOptionStyle="check"
                />
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel><Translate label={ClientsLocale.ANONYMIZE_AFTER} /></FormLabel>
            <Controller
              name="anonymizeAfter"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={anonymizeAfterOptionsList}
                  hideSelectedOptions={false}
                  selectedOptionStyle="check"
                />
              )}
            />
          </FormControl>
          <Box>
            <FormControl
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderRadius="md"
              backgroundColor="gray.100"
              p="6px"
              pl={3}
              mt={['15px', '15px', '15px', '30px', '30px', '30px']}
              mb={['10px', '10px', '10px', 0, 0, 0]}
            >
              <label htmlFor="enterprise">{translate(ClientsLocale.ENTERPRISE, language)}</label>
              <Controller
                control={control}
                name="enterprise"
                render={({ field }) => (
                  <Switch
                    onChange={(e) => { field.onChange(e.target.checked); }}
                    isChecked={field.value}
                    size="lg"
                    colorScheme="green"
                  />
                )}
              />
            </FormControl>
          </Box>
          <GridItem colSpan={[1, 1, 1, 2, 2, 2]}>
            <FormControl mb={7}>
              <FormLabel htmlFor="screeningPackages">
                <Translate label={ClientsLocale.SCREENING_PACKAGES} />
              </FormLabel>
              <Controller
                name="screeningPackages"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={screeningPackagesOptions}
                    hideSelectedOptions={false}
                    selectedOptionStyle="check"
                    isMulti
                    closeMenuOnSelect={false}
                  />
                )}
              />
            </FormControl>
            <FormControl mb={7}>
              <FormLabel htmlFor="allowedCountries">
                <Translate label={ClientsLocale.ALLOWED_COUNTRIES} />
              </FormLabel>
              <Controller
                name="allowedCountries"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={allowedCountriesOptions}
                    hideSelectedOptions={false}
                    selectedOptionStyle="check"
                    isMulti
                    closeMenuOnSelect={false}
                  />
                )}
              />
            </FormControl>
            <FormControl mb={7}>
              <FormLabel htmlFor="defaultCountries">
                <Translate label={ClientsLocale.DEFAULT_COUNTRIES} />
              </FormLabel>
              <Controller
                name="defaultCountries"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={defaultCountriesOptions}
                    hideSelectedOptions={false}
                    selectedOptionStyle="check"
                    isMulti
                    closeMenuOnSelect={false}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <FormLabel><Translate label={ClientsLocale.INVOICE_PARTS} /></FormLabel>
              <Controller
                name="invoiceParts"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={invoicePartsOptions}
                    hideSelectedOptions={false}
                    selectedOptionStyle="check"
                    isMulti
                    closeMenuOnSelect={false}
                  />
                )}
              />
            </FormControl>
          </GridItem>
          <GridItem mb={[5, 5, 5, 7, 7, 7]}>
            <Box>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderRadius="md"
                backgroundColor="gray.100"
                p="6px"
                pl={3}
                mt={['15px', '15px', '15px', '30px', '30px', '30px']}
              >
                <label htmlFor="screeningApprovals">{translate(ClientsLocale.SCREENING_APPROVALS, language)}</label>
                <Controller
                  control={control}
                  name="screeningApprovals"
                  render={({ field }) => (
                    <Switch
                      onChange={(e) => { field.onChange(e.target.checked); }}
                      isChecked={field.value}
                      size="lg"
                      colorScheme="green"
                    />
                  )}
                />
              </FormControl>
            </Box>
            <Box>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderRadius="md"
                backgroundColor="gray.100"
                p="6px"
                pl={3}
                mt={['40px', '40px', '40px', '60px', '60px', '60px']}
              >
                <label htmlFor="enabled2fa">{translate(ClientsLocale.ENABLE_2FA, language)}</label>
                <Controller
                  control={control}
                  name="enabled2fa"
                  render={({ field }) => (
                    <Switch
                      onChange={(e) => { field.onChange(e.target.checked); }}
                      isChecked={field.value}
                      size="lg"
                      colorScheme="green"
                    />
                  )}
                />
              </FormControl>
            </Box>
          </GridItem>
          <FormControl>
            <FormLabel><Translate label={ClientsLocale.COMMUNICATION_LANGUAGE} /></FormLabel>
            <Controller
              name="communicationLanguage"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={languageOptions}
                  hideSelectedOptions={false}
                  selectedOptionStyle="check"
                  closeMenuOnSelect
                />
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel><Translate label={ClientsLocale.ALLOWED_PAYMENT_METHODS} /></FormLabel>
            <Controller
              name="allowedPaymentMethods"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={paymentMethodOptionsList}
                  hideSelectedOptions={false}
                  selectedOptionStyle="check"
                  isMulti
                  closeMenuOnSelect={false}
                />
              )}
            />
          </FormControl>
          <FormControl
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderRadius="md"
            backgroundColor="gray.100"
            p="6px"
            pl={3}
            mt="30px"
            mb={6}
          >
            <label htmlFor="allowPrepayment">{translate(ClientsLocale.ALLOW_ADD_PREPAYMENT, language)}</label>
            <Controller
              control={control}
              name="allowPrepayment"
              render={({ field }) => (
                <Switch
                  onChange={(e) => { field.onChange(e.target.checked); }}
                  isChecked={field.value}
                  size="lg"
                  colorScheme="green"
                />
              )}
            />
          </FormControl>
        </SimpleGrid>
        {!client?.id && (
        <>
          <Divider />
          <H3 paddingTop={10} mb={7}>Responsible person:</H3>
          <SimpleGrid columns={[1, 1, 1, 3, 3, 3]} spacing={6} mb={7}>
            <FormControl isRequired isInvalid={!!errors.firstName}>
              <FormLabel htmlFor="firstName">
                <Translate label={ClientsLocale.FIRST_NAME} />
              </FormLabel>
              <Input
                placeholder={translate(ClientsLocale.FIRST_NAME, language)}
                {...register('firstName', {
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                })}
              />
              <FormErrorMessage>
                {errors.firstName && errors.firstName.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.lastName}>
              <FormLabel htmlFor="lastName">
                <Translate label={ClientsLocale.LAST_NAME} />
              </FormLabel>
              <Input
                placeholder={translate(ClientsLocale.LAST_NAME, language)}
                {...register('lastName', {
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                })}
              />
              <FormErrorMessage>
                {errors.lastName && errors.lastName.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired mb={6} isInvalid={!!errors.email}>
              <FormLabel htmlFor="email">
                <Translate label={ClientsLocale.EMAIL} />
              </FormLabel>
              <Input
                placeholder={translate(UsersLocale.EMAIL, language)}
                {...register('email', {
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                  pattern: {
                    // eslint-disable-next-line max-len
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: translate(UserSettingsLocale.EMAIL_ERROR_INVALID, language),
                  },
                })}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl mb={6} isRequired isInvalid={!!errors.phone}>
              <FormLabel htmlFor="phone">
                <Translate label={ClientsLocale.PHONE} />
              </FormLabel>
              <Input
                placeholder={translate(ClientsLocale.PHONE, language)}
                {...register('phone', {
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                  pattern: {
                    value: /^\+?[\d\s-]*$/,
                    message: translate(FormLocale.INVALID_CHARACTERS, language),
                  },
                })}
              />
              <FormErrorMessage>
                {errors.phone && errors.phone.message}
              </FormErrorMessage>
            </FormControl>
            {/*            <FormControl isRequired mb={6} isInvalid={!!errors.teamIds}>
              <FormLabel htmlFor="teamIds">
                <Translate label={ClientsLocale.TEAMS} />
              </FormLabel>
              <Controller
                name="teamIds"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={teamOptions}
                    hideSelectedOptions={false}
                    selectedOptionStyle="check"
                    isMulti
                    closeMenuOnSelect={false}
                  />
                )}
                rules={{
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                }}
              />
            </FormControl> */}
            <FormControl>
              <FormLabel><Translate label={ClientsLocale.DEFAULT_LANGUAGE} /></FormLabel>
              <Controller
                name="language"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={languageOptions}
                    hideSelectedOptions={false}
                    selectedOptionStyle="check"
                    closeMenuOnSelect
                  />
                )}
              />
            </FormControl>
          </SimpleGrid>
        </>
        )}
      </form>
    </>
  );
};
export default ClientDetailForm;
