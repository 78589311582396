import { Translation } from '../../../core/localization/Localization.model';

class CataloguePackagesLocale {
  public static CATALOGUE_PACKAGES: Translation = { EN: 'Package management', CZ: 'Správa balíčků' };
  public static PACKAGE_DETAIL: Translation = { EN: 'Package definition detail', CZ: 'Detail definice balíčku' };
  public static TABLE_DETAIL_GO_TO_DETAIL: Translation = { EN: 'Go to detail', CZ: 'Přejít na detail' };
  public static INTERNAL_NAME: Translation = { EN: 'Name', CZ: 'Jméno' };
  public static PRICE: Translation = { EN: 'Price', CZ: 'Cena' };
  public static TRANSLATION_NAME: Translation = { EN: 'Localized name', CZ: 'Lokalizované jméno' };
  public static TRANSLATION_DESCRIPTION: Translation = { EN: 'Localized description', CZ: 'Lokalizovaný popis' };
  public static COUNTRY: Translation = { EN: 'Country', CZ: 'Země' };
  public static CLIENT: Translation = {
    EN: 'Is this package intended for specific clients?',
    CZ: 'Je balíček určen pro konkrétní klienty?',
  };
  public static SCREENING_DEFINITIONS: Translation = { EN: 'Screenings', CZ: 'Kontroly' };
  public static ACTIVE: Translation = { EN: 'Active', CZ: 'Aktivní' };
  public static NAME: Translation = { EN: 'Name', CZ: 'Jméno' };
  public static COUNT_LIMIT: Translation = { EN: 'Count limit', CZ: 'Limit' };
  public static COUNT: Translation = { EN: 'Count', CZ: 'Počet' };
  public static MODAL_CREATE_PACKAGE_TITLE: Translation = { EN: 'New package', CZ: 'Nový balíček' };
  public static ERROR_GETTING_COUNTRIES: Translation = {
    EN: 'There was a problem getting countries.',
    CZ: 'Problém při načitání zemí',
  };
  public static ERROR_GETTING_TRANSLATIONS: Translation = {
    EN: 'There was a problem getting translations.',
    CZ: 'Problém při načitání překladů',
  };
  public static ERROR_GETTING_CLIENTS: Translation = {
    EN: 'There was a problem getting client list.',
    CZ: 'Problém při načitání listu klientů',
  };
  public static ERROR_GETTING_SCREENING_DEFINITIONS: Translation = {
    EN: 'There was a problem getting screenings.',
    CZ: 'Problém při načitání kontrol',
  };
  public static ERROR_SAVING_SCREENING_DEFINITIONS: Translation = {
    EN: 'There was a problem saving data.',
    CZ: 'Při ukládání dat došlo k problému',
  };
  public static ERROR_GETTING_DATA: Translation = {
    EN:
        'There was a problem getting data',
    CZ: 'Při získávání dat došlo k problému',
  };
}

export default CataloguePackagesLocale;
