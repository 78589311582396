import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Box, Button,
  Collapse,
  Flex, FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FilterButton, InfoContainer, Toast } from '@scaut-sro/meepo';
import { FiFilter, FiX } from 'react-icons/fi';
import { Select } from 'chakra-react-select';
import { CatalogueComponentsTableFilterProps, CatalogueComponentsTableFilterType } from './CatalogueComponents.model';
import Translate from '../../../components/Translate/Translate';
import CatalogueComponentsLocale from './CatalogueComponents.locale';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import { Country } from '../../../build/generated-sources/dto/Country';
import { useGetCountries } from '../../../build/generated-sources/service/QueryService';
import { translate } from '../../../core/localization/LocaleUtils';
import CatalogueScreeningsLocale from '../CatalogueScreenings/CatalogueScreenings.locale';

const CatalogueComponentsTableFilter: React.FunctionComponent<CatalogueComponentsTableFilterProps> = (props) => {
  const { filter, onFilterChange } = props;
  const { language } = useGetUser();
  const {
    handleSubmit, register, reset, setValue,
  } = useForm();
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [countryList, setCountryList] = useState<Country[]>([]);
  const [countries, setCountries] = useState<{ value?: number, label?: string }[]>([]);
  const formRef = useRef<HTMLFormElement>(null);

  useGetCountries(
    {
      id: true,
      translation: {
        id: true,
        localizationStrings: true,
      },
    }, {
      onError: () => {
        Toast({
          title: translate(CatalogueScreeningsLocale.ERROR_GETTING_COUNTRIES, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setCountryList(response.countries);
      },
    },
  );

  const countryOptions = useMemo(() => countryList.map((c) => ({
    value: c.id,
    label: c?.translation?.localizationStrings?.[language],
  })), [countryList, language]);

  const handleFilterSubmit: SubmitHandler<CatalogueComponentsTableFilterType> = (values) => {
    onFilterChange(values);
  };

  const handleFilterCancel = () => {
    handleFilterSubmit({});
    setCountries([]);
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      formRef.current?.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }
  };

  const activeFilters: boolean = useMemo(() => {
    const filteredObjKeys = Object.keys(filter).filter((key) => filter[key] && filter[key].length);
    return !!filteredObjKeys.length;
  }, [filter]);

  useEffect(() => {
    reset({
      fulltext: filter.fulltext,
      countryIds: filter.countryIds,
    });
  }, [filter, reset]);

  return (
    <Box>
      <form
        name="catalogueComponentsTableFilter"
        onSubmit={handleSubmit((values) => {
          handleFilterSubmit(values);
        })}
        ref={formRef}
      >
        <Flex>
          <InputGroup zIndex={0}>
            <InputLeftElement>
              <SearchIcon />
            </InputLeftElement>
            <Input
              id="fulltext"
              variant="filled"
              onKeyUp={handleKeyUp}
              {...register('fulltext')}
            />
          </InputGroup>
          <Box ml={3}>
            <FilterButton
              activeFilters={activeFilters}
              openFilters={() => { setOpenFilters(!openFilters); }}
              cancelFilters={handleFilterCancel}
            />
          </Box>
        </Flex>
        <Collapse in={openFilters} animateOpacity>
          <Box mt={3}>
            <InfoContainer>
              <SimpleGrid gap={6} columns={[1, 1, 2, 2, 2, 3]}>
                <FormControl>
                  <FormLabel fontSize="sm"><Translate label={CatalogueComponentsLocale.COUNTRY} /></FormLabel>
                  <Select
                    name="countryIds"
                    isMulti
                    closeMenuOnSelect={false}
                    value={countries}
                    options={countryOptions}
                    onChange={(values: { value: number, label: string }[]) => {
                      setValue('countryIds', values.map((value) => value.value));
                      setCountries(values);
                    }}
                    hideSelectedOptions={false}
                    selectedOptionStyle="check"
                    size="sm"
                  />
                </FormControl>

              </SimpleGrid>
              <Flex mt={6} justifyContent="flex-end">
                <Button
                  size="sm"
                  colorScheme="red"
                  isDisabled={!activeFilters}
                  onClick={handleFilterCancel}
                  variant="ghost"
                  leftIcon={<FiX />}
                >
                  Cancel filter
                </Button>
                <Button
                  size="sm"
                  ml={3}
                  leftIcon={<FiFilter />}
                  type="submit"
                >
                  Filter
                </Button>
              </Flex>
            </InfoContainer>
          </Box>
        </Collapse>
      </form>
    </Box>
  );
};

export default CatalogueComponentsTableFilter;
