import { Translation } from '../../core/localization/Localization.model';

class NewCheckLocale {
  public static INVITE_CANDIDATE: Translation = { EN: 'Invite candidate', CZ: 'Pozvat kandidáta' };
  public static NEW_CHECK: Translation = { EN: 'New Check', CZ: 'Nová kontrola' };
  public static PACKAGES_AND_CHECKS: Translation = { EN: 'Packages and checks', CZ: 'Výběr balíčků a dílčích kontrol' };
  public static CANDIDATES_INFORMATION: Translation = { EN: 'Candidates information', CZ: 'Informace o kandidátech' };
  public static CANDIDATE_INFORMATION: Translation = { EN: 'Candidate information', CZ: 'Informace o kandidátovi' };
  public static FIRST_NAME: Translation = { EN: 'First Name', CZ: 'Jméno' };
  public static LAST_NAME: Translation = { EN: 'Last Name', CZ: 'Příjmení' };
  public static DATE_OF_BIRTH: Translation = { EN: 'Date Of Birth', CZ: 'Datum Narození' };
  public static EMAIL: Translation = { EN: 'Email', CZ: 'E-mail' };
  public static RECAPITULATION: Translation = { EN: 'Recapitulation', CZ: 'Rekapitulace' };
  public static ORDER_NAME: Translation = { EN: 'Order name', CZ: 'Název objednávky' };
  public static ORDER_NAME_TOOLTIP: Translation = {
    EN: 'If you leave this field empty order name will be autogenerated',
    CZ: 'Pokud nevyplníte, bude název objednávky automaticky vygenerován',
  };
  public static ORDER_CUSTOM_ID: Translation = { EN: 'Order custom ID', CZ: 'Uživatelské ID' };
  public static ASSETS_INFO: Translation = {
    EN: 'After submitting the order it will be necessary to add some information about the candidate to '
      + 'successfully complete the check',
    CZ: 'Pro úspěšné provedení kontroly bude po dokončení objednávky potřeba doplnit některé údaje o kandidátovi',
  };
  public static BACK_TO_CHECKS: Translation = { EN: 'Back to checks', CZ: 'Zpět na kontroly' };
  public static TYPE: Translation = { EN: 'Type', CZ: 'Typ' };
  public static TEAM: Translation = { EN: 'Team', CZ: 'Tým' };
  public static SERVICES: Translation = { EN: 'Services', CZ: 'Služby' };
}

export default NewCheckLocale;
