import React, { ChangeEventHandler, useState } from 'react';
import { Button } from '@chakra-ui/react';
import { useApolloClient } from '@apollo/client';
import { useKeycloak } from '@react-keycloak/web';
import { Toast } from '@scaut-sro/meepo';
import { uploadProcess } from '../../core/service/queries/ProcessUploadService';
import { translate } from '../../core/localization/LocaleUtils';
import FormLocale from '../../core/localization/translations/Form.locale';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';

// eslint-disable-next-line import/prefer-default-export
export const ProcessUpload: React.FunctionComponent = () => {
  const [file, setFile] = useState<File>();
  const { language } = useGetUser();

  const client = useApolloClient();
  const keycloak = useKeycloak();

  const onFileChange = (event: ChangeEventHandler<HTMLInputElement>) => {
    // @ts-ignore
    setFile(event.target.files[0]);
  };

  const onFileUpload = () => {
    // @ts-ignore
    uploadProcess(file, client, keycloak.keycloak.token as string).then((response) => {
      if (response.status === 200) {
        Toast({
          title: translate(FormLocale.SUCCESS, language),
          status: 'success',
        });
      }
    });
  };

  return (
    <>
      <input
        type="file"
        id="myFile"
        name="filename"
          // @ts-ignore
        onChange={onFileChange}
      />
      <Button onClick={onFileUpload}>
        Upload!
      </Button>
    </>
  );
};
