import React, { useEffect } from 'react';
import BpmnJS from 'bpmn-js/lib/NavigatedViewer';
import { Box, Center } from '@chakra-ui/react';
import { WorkflowProps } from './Workflow.model';
import Translate from '../Translate/Translate';
import WorkflowLocale from './Workflow.locale';
import { ProcessActiveStepDto } from '../../build/generated-sources/dto/ProcessActiveStepDto';
import { ProcessActiveStepType } from '../../build/generated-sources/enum/ProcessActiveStepType';

const Workflow:React.FunctionComponent<WorkflowProps> = (props) => {
  const { diagram, activeSteps } = props;
  const containerRef = React.useRef(null);

  useEffect(() => {
    if (diagram) {
      const container = document.getElementById('bpmn-container');
      container?.querySelectorAll('*').forEach((n) => n.remove());
      const viewer = new BpmnJS({
        container,
        keyboard: {
          bindTo: document,
        },
      });

      const setActiveStep = (step: ProcessActiveStepDto) => {
        const elementRegistry = viewer.get('elementRegistry');
        const graphicsFactory = viewer.get('graphicsFactory');
        const element = elementRegistry.get(step.stepName);
        const { businessObject } = element;
        if (ProcessActiveStepType.ACTIVITY === step.stepType) {
          businessObject.di.set('stroke', '#0566F8');
          businessObject.di.set('fill', '#D1E3FE');
        }
        if (ProcessActiveStepType.ERROR === step.stepType) {
          businessObject.di.set('stroke', '#f8050d');
          businessObject.di.set('fill', 'rgba(185,181,181,0.52)');
        }
        const gfx = elementRegistry.getGraphics(element);
        const type = element.waypoints ? 'connection' : 'shape';
        graphicsFactory.update(type, element, gfx);
      };

      (async () => {
        try {
          await viewer.importXML(diagram);
          if (activeSteps) {
            activeSteps.map((activeStep) => {
              setActiveStep(activeStep);
            });
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      })();
    }
  }, [diagram, activeSteps]);

  return (
    <Box h="500px" border="1px solid black">
      { diagram ? (
        <div
          id="bpmn-container"
          ref={containerRef}
          style={{
            height: '100%',
            width: '100%',
          }}
        />
      ) : (
        <Center minH="100%">
          <Translate label={WorkflowLocale.NO_DIAGRAM_AVAILABLE} />
        </Center>
      )}
    </Box>
  );
};

export default Workflow;
