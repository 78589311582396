import { Translation } from '../../core/localization/Localization.model';

class TasksLocale {
  public static STATES: Translation = { EN: 'States', CZ: 'Stavy' };
  public static STATE: Translation = { EN: 'State', CZ: 'Stav' };
  public static RESULT: Translation = { EN: 'Result', CZ: 'Výsledek' };
  public static ORDER_ID: Translation = { EN: 'Order ID', CZ: 'ID objednávky' };
  public static CLIENT: Translation = { EN: 'Client', CZ: 'Klient' };
  public static LABEL: Translation = { EN: 'Label', CZ: 'Název' };
  public static CANDIDATE: Translation = { EN: 'Candidate', CZ: 'Kandidát' };
  public static ASSIGNEE: Translation = { EN: 'Assignee', CZ: 'Přiřazený uživatel' };
  public static ASSIGNEES: Translation = { EN: 'Assignees', CZ: 'Přiřazení uživatelé' };
  public static ASSIGN: Translation = { EN: 'Assign', CZ: 'Přiřadit' };
  public static SET_ASSIGNEE: Translation = { EN: 'Set Assignee', CZ: 'Nastavit příjemce' };
  public static TABLE_DETAIL_GO_TO_DETAIL: Translation = { EN: 'Go to detail', CZ: 'Přejít na detail' };
  public static CANDIDATE_SCREENING_DETAIL: Translation = { EN: 'Candidate screening', CZ: 'Kontrola kandidáta' };
  public static COMPONENT_DETAIL: Translation = { EN: 'Component detail', CZ: 'Detail komponenty' };
  public static CONFIRM: Translation = { EN: 'Confirm', CZ: 'Potvrdit' };
  public static TASKS: Translation = { EN: 'Tasks', CZ: 'Úkoly' };
  public static INTEGRATION: Translation = { EN: 'Integration', CZ: 'Integrace' };
  public static NOTES: Translation = { EN: 'Notes', CZ: 'Poznámky' };
  public static PUBLIC: Translation = { EN: 'Public', CZ: 'Veřejná' };
  public static PRIVATE: Translation = { EN: 'Private', CZ: 'Soukromá' };
  public static SHOW: Translation = { EN: 'Show', CZ: 'Ukázat' };
  public static MORE: Translation = { EN: 'More', CZ: 'více' };
  public static LESS: Translation = { EN: 'Less', CZ: 'méně' };
  public static NOTE_TEXT_PLACEHOLDER: Translation = { EN: 'Note text', CZ: 'Text poznámky' };
  public static CREATE_NOTE_TITLE: Translation = { EN: 'New note', CZ: 'Nová poznámka' };
  public static EDIT_NOTE_TITLE: Translation = { EN: 'Edit note', CZ: 'Upravit poznámku' };
  public static SAVE: Translation = { EN: 'Save', CZ: 'Uložit' };
  public static CANCEL: Translation = { EN: 'Cancel', CZ: 'Zrušit' };
  public static DELETE: Translation = { EN: 'Delete', CZ: 'Smazat' };
  public static NOTE_CREATED: Translation = { EN: 'Created: ', CZ: 'Vytvořeno: ' };
  public static NOTE_UPDATED: Translation = { EN: 'Updated: ', CZ: 'Upraveno: ' };
  public static NEW: Translation = { EN: 'New', CZ: 'Nová' };
  public static GO_TO_DETAIL: Translation = { EN: 'Go to detail', CZ: 'Přejít na detail' };
  public static LOGS: Translation = { EN: 'Logs', CZ: 'Logy' };
  public static ACTIVITY_ID: Translation = { EN: 'Activity id', CZ: 'ID aktivity' };
  public static ACTIVITY_NAME: Translation = { EN: 'Activity name', CZ: 'Název aktivity' };
  public static INTERNAL_NAME: Translation = { EN: 'Name', CZ: 'Jméno' };
  public static DESCRIPTION: Translation = { EN: 'Description', CZ: 'Popis' };
  public static RETRIES: Translation = { EN: 'Retries', CZ: 'Pokusy' };
  public static TYPE: Translation = { EN: 'Type', CZ: 'Typ' };
  public static MAX_RETRIES: Translation = { EN: 'Max retries', CZ: 'Maximum pokusů' };
  public static STATUS_MESSAGE: Translation = { EN: 'Status message', CZ: 'Status' };
  public static FINISHED: Translation = { EN: 'Finished', CZ: 'Dokončeno' };
  public static LAST_RETRY: Translation = { EN: 'Last retry', CZ: 'Poslední pokus' };
  public static MODAL_EDIT_INTEGRATION: Translation = { EN: 'Edit integration', CZ: 'Upravit integraci' };
  public static PROCESS_DEFINITION_ID: Translation = { EN: 'Process definition id', CZ: 'ID definice procesu' };
  public static CREATED: Translation = { EN: 'Created', CZ: 'Vytvořeno' };
  public static VIEW_LOG_VARIABLES: Translation = { EN: 'View variables', CZ: 'Zobrazit proměnné' };
  public static LOG_VARIABLES: Translation = { EN: 'Variables', CZ: 'Proměnné' };
  public static COPY_VARIABLES: Translation = { EN: 'Copy variables', CZ: 'Zkopírovat proměnné' };
  public static RESTART: Translation = { EN: 'Restart', CZ: 'Restartovat' };
  public static SUCCESSFULLY_ASSIGNED: Translation = {
    EN: 'Successfully assigned',
    CZ: 'Úspěšně přiřazeno',
  };
  public static ERROR_GETTING_NOTES: Translation = {
    EN: 'There was a problem getting notes',
    CZ: 'Problém při načitání poznámek',
  };
  public static ARE_YOU_SURE: Translation = {
    EN: 'Are you sure you want to delete note',
    CZ: 'Opravdu chcete smazat poznámku',
  };
  public static NOTE_DELETED: Translation = {
    EN: 'Note deleted',
    CZ: 'Poznámka smazána',
  };
  public static ERROR_ASSIGNING_COMPONENT: Translation = {
    EN: 'There was a problem assigning component',
    CZ: 'Při přiřazování součásti došlo k problému',
  };
  public static ERROR_GETTING_DATA: Translation = {
    EN:
        'There was a problem getting data',
    CZ: 'Při získávání dat došlo k problému',
  };
  public static ERROR_SAVING_TASK: Translation = {
    EN:
        'There was a problem saving task',
    CZ: 'Při ukládání úkolu došlo k problému',
  };
}

export default TasksLocale;
