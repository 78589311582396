import React, { useMemo, useState } from 'react';
import {
  H1, MeepoTable, SectionContainer, Toast,
} from '@scaut-sro/meepo';
import { useHistory } from 'react-router-dom';
import {
  Box, Button, Checkbox,
  Flex,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { PageInfo } from '@scaut-sro/meepo/lib/components/MeepoTable/MeepoTable.model';
import {
  useGetScreeningDefinitions,
} from '../../../build/generated-sources/service/QueryService';
import { Direction } from '../../../build/generated-sources/enum/Direction';
import { translate } from '../../../core/localization/LocaleUtils';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import TableLocale from '../../../core/localization/translations/Table.locale';
import Translate from '../../../components/Translate/Translate';
import DetailHeader from '../../../components/DetailHeader/DetailHeader';
import { Language } from '../../../build/generated-sources/enum/Language';
import { ScreeningDefinition } from '../../../build/generated-sources/dto/ScreeningDefinition';
import {
  CatalogueScreeningsAllFilterType,
  CatalogueScreeningsProps,
  CatalogueScreeningsTableData,
  CatalogueScreeningsTableFilterType,
} from './CatalogueScreenings.model';
import CatalogueScreeningsLocale from './CatalogueScreenings.locale';
import CatalogueScreeningsTableFilter from './CatalogueScreeningsTableFilter';
import CatalogueScreeningsModal from './CatalogueScreeningsModal';

export function getCatalogueScreeningsTableData(openScreeningDetail: (screeningDefinitionId?: number) => void, language: Language,
  data: ScreeningDefinition[] | undefined): CatalogueScreeningsTableData[] {
  if (data) {
    return data.map((screeningDefinition) => ({
      id: screeningDefinition.id || '-undefined-',
      internalName: screeningDefinition.internalName,
      active: <Checkbox isChecked={screeningDefinition.active} isDisabled />,
      country: screeningDefinition.country?.translation?.localizationStrings?.[language],
      detailButton: (
        <Button variant="link" w="100%" onClick={() => openScreeningDetail(screeningDefinition?.id)}>
          <Translate label={CatalogueScreeningsLocale.TABLE_DETAIL_GO_TO_DETAIL} />
        </Button>
      ),
    }));
  }
  return [];
}

const CatalogueScreenings: React.FunctionComponent<CatalogueScreeningsProps> = (props) => {
  const [tableFilter, setTableFilter] = useState<CatalogueScreeningsTableFilterType>({});
  const [allFilters, setAllFilter] = useState<CatalogueScreeningsAllFilterType>({});
  const { filters } = props;
  const [list, setList] = useState<CatalogueScreeningsTableData[]>([]);
  const history = useHistory();
  const { language } = useGetUser();
  const [sorting, setSorting] = useState<{ sortBy?: string, sortDirection?: Direction }>({});
  const [pageInfo, setPageInfo] = useState<PageInfo>({ pageNumber: 0, pageSize: 20, totalPages: 0 });

  const openScreeningDetail = (screeningDefinitionId?: number) => {
    if (screeningDefinitionId) {
      history.push(`/catalogue/screening/${screeningDefinitionId}`);
    } else {
      history.push('/404');
    }
  };

  const { loading } = useGetScreeningDefinitions(
    {
      pageInfo: {
        pageSize: true,
        pageNumber: true,
        numberOfElements: true,
        totalElements: true,
        totalPages: true,
      },
      content: {
        id: true,
        internalName: true,
        active: true,
        country: {
          translation: { localizationStrings: true },
        },
      },
    }, {
      onError: () => {
        Toast({
          title: translate(CatalogueScreeningsLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      },
      variables: {
        paging: {
          page: pageInfo.pageNumber,
          size: pageInfo.pageSize,
          sortBy: sorting.sortBy,
          sortDirection: sorting.sortDirection,
        },
        filter: {
          search: allFilters.fulltext && allFilters.fulltext.length ? allFilters.fulltext : undefined,
          active: allFilters.active && allFilters.active ? allFilters.active : undefined,
          countryIds: allFilters.countryIds && allFilters.countryIds.length ? allFilters.countryIds : undefined,
        },
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        const onCompleteData = getCatalogueScreeningsTableData(openScreeningDetail, language,
          response.screeningDefinitions.content);
        setList(onCompleteData);
        setPageInfo({
          pageSize: response.screeningDefinitions.pageInfo?.pageSize,
          pageNumber: response.screeningDefinitions.pageInfo?.pageNumber || 0,
          totalPages: response.screeningDefinitions.pageInfo?.totalPages || 0,
          numberOfElements: response.screeningDefinitions.pageInfo?.numberOfElements,
          totalElements: response.screeningDefinitions.pageInfo?.totalElements,
        });
      },
    },
  );

  useMemo(() => {
    const newAllFilters: CatalogueScreeningsAllFilterType = {
      ...filters,
      ...tableFilter,
    };
    setAllFilter(newAllFilters);
  }, [filters, tableFilter]);

  return (
    <>
      <SectionContainer>
        <DetailHeader>
          <H1><Translate label={CatalogueScreeningsLocale.CATALOGUE_SCREENINGS} /></H1>
        </DetailHeader>
        <Box mb={20}>
          <CatalogueScreeningsTableFilter
            filter={tableFilter}
            onFilterChange={(newTableFilter) => {
              setTableFilter(newTableFilter);
            }}
          />
          <Spacer />
          <Flex mt={6} justifyContent="flex-end">
            <CatalogueScreeningsModal />
          </Flex>

        </Box>
        <Box overflowX="auto">
          <Box minW="600px">
            <MeepoTable
              columns={[
                { id: 'internalName', label: translate(CatalogueScreeningsLocale.INTERNAL_NAME, language), minW: '200px' },
                { id: 'country', label: translate(CatalogueScreeningsLocale.COUNTRY, language), boxProps: { w: '180px' } },
                {
                  id: 'active',
                  label: translate(CatalogueScreeningsLocale.ACTIVE, language),
                  alignData: 'center',
                  alignLabel: 'center',
                },
                {
                  id: 'detailButton',
                  label: translate(CatalogueScreeningsLocale.SCREENING_DETAIL, language),
                  minW: '150px',
                  boxProps: { w: '200px' },
                  alignLabel: 'center',
                },
              ]}
              data={list}
              isLoading={loading}
              noData={<Text><Translate label={TableLocale.NO_DATA} /></Text>}
              pageInfo={pageInfo}
              setPage={(newPageNumber, newPageSize) => {
                setPageInfo({
                  ...pageInfo,
                  pageNumber: newPageNumber,
                  pageSize: newPageSize,
                });
              }}
              onSort={(newSorting) => {
                const direction: Direction | undefined = newSorting.type === 'asc'
                  ? Direction.ASC
                  : newSorting.type === 'desc'
                    ? Direction.DESC
                    : undefined;
                setSorting({
                  sortBy: newSorting.column,
                  sortDirection: direction,
                });
              }}
              activeSorting={{
                column: sorting.sortBy,
                type: sorting.sortDirection === Direction.ASC
                  ? 'asc'
                  : sorting.sortDirection === Direction.DESC
                    ? 'desc'
                    : 'none',
              }}
            />
          </Box>
        </Box>
      </SectionContainer>
    </>
  );
};

export default CatalogueScreenings;
