import { Box, Button, Text } from '@chakra-ui/react';
import {
  H1, MeepoTable, SectionContainer, Translate, Toast, Language,
  downloadBlob,
} from '@scaut-sro/meepo';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GrDocumentCsv } from 'react-icons/all';
import { useKeycloak } from '@react-keycloak/web';
import { PageInfo } from '@scaut-sro/meepo/lib/components/MeepoTable/MeepoTable.model';
import axios from 'axios';
import DetailHeader from '../../components/DetailHeader/DetailHeader';
import {
  InvoicesProps, InvoicesTableFilterType, InvoicesTableFilterAllFilterType, InvoicesTableData,
} from './Invoices.model';
import InvoicesLocale from './Invoices.locale';
import InvoicesTableFilter from './InvoicesTableFilter';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../core/localization/LocaleUtils';
import { useGetGetInvoices, useGetClients } from '../../build/generated-sources/service/QueryService';
import { Invoice } from '../../build/generated-sources/dto/Invoice';
import UsersLocale from '../Users/Users.locale';
import InvoiceStateBadge from '../../components/InvoiceStateBadge/InvoiceStateBadge';
import { Direction } from '../../build/generated-sources/enum/Direction';
import TableLocale from '../../core/localization/translations/Table.locale';
import { Client } from '../../build/generated-sources/dto/Client';

export function getClientInvoicesTableData(openClientInvoiceDetail: (invoiceId?: number) => void, language: Language,
  data: Invoice[] | undefined): InvoicesTableData[] {
  if (data) {
    return data.map((invoice) => ({
      id: invoice?.id || -1,
      client: invoice?.client?.name,
      ico: invoice?.client?.ico,
      country: invoice?.client?.country?.translation?.localizationStrings?.[language],
      state: <InvoiceStateBadge state={invoice.state || undefined} />,
      price: invoice?.price,
      issueDate: invoice?.issueDate,
      dueDate: invoice?.dueDate,
      chargifyInvoiceNumber: invoice?.chargifyInvoiceNumber,
      invoiceNumber: invoice?.invoiceNumber,
      paymentMethod: invoice?.clientSubscription?.paymentMethod,
      userCustomId: invoice?.primaryOrders?.[0]?.clientOrderCustomId,
      orderName: invoice?.primaryOrders?.[0]?.name,
      scautOrderId: invoice?.primaryOrders?.[0]?.id,
      transactionId: invoice?.transactionId,

      buttons: (
        <Button
          variant="link"
          w="100%"
          onClick={() => openClientInvoiceDetail(invoice?.id)}
        >
          <Translate label={UsersLocale.USER_GO_TO_DETAIL} />
        </Button>
      ),
    }));
  }
  return [];
}

const Invoices:React.FunctionComponent<InvoicesProps> = (props) => {
  const [tableFilter, setTableFilter] = useState<InvoicesTableFilterType>({});
  const [allFilters, setAllFilter] = useState<InvoicesTableFilterType>({});
  const [pageInfo, setPageInfo] = useState<PageInfo>({ pageNumber: 0, pageSize: 20, totalPages: 0 });
  const [list, setList] = useState<InvoicesTableData[]>([]);
  const [sorting, setSorting] = useState<{ sortBy?: string, sortDirection?: Direction }>({});
  const { filters } = props;
  const { language } = useGetUser();
  const history = useHistory();
  const [clientList, setClientList] = useState<Client[]>([]);
  const keycloak = useKeycloak();

  const downloadInvoices = () => {
    if (keycloak.keycloak.token) {
      const body = {
        dueDateFrom: allFilters?.dueDateFrom,
        dueDateTo: allFilters?.dueDateTo,
        issueDateFrom: allFilters?.issueDateFrom,
        issueDateTo: allFilters?.issueDateTo,
        clientId: allFilters?.clients,
        states: allFilters?.states,

      };

      axios.post(`${process.env.REACT_APP_API_URL}/dms/downloadInvoices`, body, {
        headers: {
          Authorization: `Bearer ${keycloak.keycloak.token}`,
        },
      }).then((result) => {
        let filename: string | undefined;
        if (result.headers['content-disposition']) {
          const dispositionParts = result.headers['content-disposition'].split(';');

          dispositionParts.forEach((part) => {
            if (!filename && part.includes('filename=')) {
              const filenameSplit = part.split('filename=');
              if (filenameSplit.length === 1) {
                // eslint-disable-next-line prefer-destructuring
                filename = filenameSplit[0];
              } else if (filenameSplit.length === 2) {
                // eslint-disable-next-line prefer-destructuring
                filename = filenameSplit[1];
              }
            }
          });
        }
        downloadBlob(new Blob([result.data], { type: 'text/plain' }), 'export.csv');
      });
    }
  };

  useGetClients({
    id: true,
    name: true,
  }, {
    onError: () => {
      Toast({
        title: translate(InvoicesLocale.ERROR_GETTING_CLIENTS, language),
        status: 'error',
      });
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      setClientList(response.clients);
    },
  });

  const openClientInvoiceDetail = (invoiceId?: number) => {
    if (invoiceId) {
      history.push(`/invoice/${invoiceId}`);
    } else {
      history.push('/404');
    }
  };

  const { loading } = useGetGetInvoices({
    pageInfo: {
      pageSize: true,
      pageNumber: true,
      numberOfElements: true,
      totalElements: true,
      totalPages: true,
    },
    content: {
      id: true,
      issueDate: true,
      dueDate: true,
      state: true,
      price: true,
      chargifyInvoiceNumber: true,
      invoiceNumber: true,
      transactionId: true,
      clientSubscription: {
        paymentMethod: true,
      },
      primaryOrders: {
        id: true,
        clientOrderCustomId: true,
        name: true,
      },
      client: {
        name: true,
        ico: true,
        country: {
          type: true,
          value: true,
          translation: {
            localizationStrings: true,
          },
        },
      },
    },
  }, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      const onCompleteData = getClientInvoicesTableData(openClientInvoiceDetail, language, response.getInvoices.content);
      if (response.getInvoices === null) {
        Toast({
          title: translate(InvoicesLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      }
      setList(() => [...onCompleteData]);
      setPageInfo({
        pageSize: response.getInvoices.pageInfo?.pageSize,
        pageNumber: response.getInvoices.pageInfo?.pageNumber || 0,
        totalPages: response.getInvoices.pageInfo?.totalPages || 0,
        numberOfElements: response.getInvoices.pageInfo?.numberOfElements,
        totalElements: response.getInvoices.pageInfo?.totalElements,
      });
    },
    onError: () => {
      Toast({
        title: translate(InvoicesLocale.ERROR_GETTING_DATA, language),
        status: 'error',
      });
    },
    variables: {
      clientId: allFilters.clients ? allFilters.clients : undefined,
      filter: {
        issueDateFrom: allFilters.issueDateFrom && allFilters.issueDateFrom.length
          ? allFilters.issueDateFrom : undefined,
        issueDateTo: allFilters.issueDateTo && allFilters.issueDateTo.length ? allFilters.issueDateTo : undefined,
        dueDateFrom: allFilters.dueDateFrom && allFilters.dueDateFrom.length
          ? allFilters.dueDateFrom : undefined,
        dueDateTo: allFilters.dueDateTo && allFilters.dueDateTo.length ? allFilters.dueDateTo : undefined,
        states: allFilters.states && allFilters.states.length ? allFilters.states : undefined,
      },
      paging: {
        page: pageInfo.pageNumber,
        size: pageInfo.pageSize,
        sortBy: sorting.sortBy,
        sortDirection: sorting.sortDirection,
      },
    },
  });

  useMemo(() => {
    const newAllFilters: InvoicesTableFilterAllFilterType = {
      ...filters,
      ...tableFilter,
    };
    setAllFilter(newAllFilters);
  }, [filters, tableFilter]);

  return (
    <>
      <SectionContainer>
        <Box overflow="auto">
          <Box minW="600px">
            <DetailHeader>
              <H1><Translate label={InvoicesLocale.INVOICES} /></H1>
            </DetailHeader>
            <Box float="right">
              <Button onClick={() => downloadInvoices()}>
                <GrDocumentCsv />
              </Button>
            </Box>
            <Box mb={20}>
              <InvoicesTableFilter
                filter={tableFilter}
                clientList={clientList}
                onFilterChange={(newTableFilter) => {
                  setTableFilter(newTableFilter);
                }}
              />
            </Box>
            <Box overflowX="auto">
              <Box minW="600px">
                <MeepoTable
                  columns={[
                    {
                      id: 'id',
                      label: translate(InvoicesLocale.ID, language),
                      minW: '100px',
                    },
                    {
                      id: 'client',
                      label: translate(InvoicesLocale.CLIENT, language),
                      minW: '100px',
                    },
                    {
                      id: 'ico',
                      sortBy: 'client.ico',
                      label: translate(InvoicesLocale.ICO, language),
                      minW: '100px',
                    },
                    {
                      id: 'country',
                      sortBy: 'client.country.value',
                      label: translate(InvoicesLocale.COUNTRY, language),
                      minW: '100px',
                    },
                    {
                      id: 'state',
                      label: translate(InvoicesLocale.STATE, language),
                      alignLabel: 'center',
                      boxProps: {
                        w: '220px',
                      },
                    },
                    {
                      id: 'price',
                      label: translate(InvoicesLocale.PRICE, language),
                      minW: '100px',
                    },
                    {
                      id: 'issueDate',
                      label: translate(InvoicesLocale.ISSUE_DATE, language),
                      minW: '100px',
                    },
                    {
                      id: 'dueDate',
                      label: translate(InvoicesLocale.DUE_DATE, language),
                      minW: '100px',
                    },
                    {
                      id: 'chargifyInvoiceNumber',
                      label: translate(InvoicesLocale.CHARGIFY_NUMBER, language),
                      minW: '100px',
                    },
                    {
                      id: 'invoiceNumber',
                      label: translate(InvoicesLocale.INVOICE_NUMBER, language),
                      minW: '100px',
                    },
                    {
                      id: 'paymentMethod',
                      sortBy: 'clientSubscription.paymentMethod',
                      label: translate(InvoicesLocale.PAYMENT_METHOD, language),
                      minW: '100px',
                    },
                    {
                      id: 'transactionId',
                      label: translate(InvoicesLocale.TRANSACTION_ID, language),
                      minW: '100px',
                    },
                    {
                      id: 'buttons',
                      label: translate(InvoicesLocale.GO_TO_DETAIL, language),
                      minW: '150px',
                      boxProps: { w: '200px' },
                      alignLabel: 'center',
                    },
                    {
                      id: 'scautOrderId',
                      sortBy: 'primaryOrders[0].clientOrderCustomId',
                      label: translate(InvoicesLocale.SCAUT_ORDER_ID, language),
                      minW: '100px',
                    },
                    {
                      id: 'orderName',
                      sortBy: 'primaryOrders[0].name',
                      label: translate(InvoicesLocale.ORDER_NAME, language),
                      minW: '100px',
                    },
                    {
                      id: 'userCustomId',
                      sortBy: 'primaryOrders[0].id',
                      label: translate(InvoicesLocale.USER_CUSTOM_ID, language),
                      minW: '100px',
                    },
                  ]}
                  data={list}
                  isLoading={loading}
                  noData={<Text><Translate label={TableLocale.NO_DATA} /></Text>}
                  pageInfo={pageInfo}
                  setPage={(newPageNumber, newPageSize) => {
                    setPageInfo({
                      ...pageInfo,
                      pageNumber: newPageNumber,
                      pageSize: newPageSize,
                    });
                  }}
                  onSort={(newSorting) => {
                    if (newSorting.column === 'transactionId' || newSorting.column === 'buttons') {
                      return;
                    }
                    const direction: Direction | undefined = newSorting.type === 'asc'
                      ? Direction.ASC
                      : newSorting.type === 'desc'
                        ? Direction.DESC
                        : undefined;
                    setSorting({
                      sortBy: newSorting.column,
                      sortDirection: direction,
                    });
                  }}
                  activeSorting={{
                    column: sorting.sortBy,
                    type: sorting.sortDirection === Direction.ASC
                      ? 'asc'
                      : sorting.sortDirection === Direction.DESC
                        ? 'desc'
                        : 'none',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </SectionContainer>
    </>
  );
};
export default Invoices;
