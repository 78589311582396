export enum State {
  NOT_STARTED = 'NOT_STARTED',
  STARTED = 'STARTED',
  WAITING_FOR_CLIENT = 'WAITING_FOR_CLIENT',
  WAITING_FOR_CANDIDATE = 'WAITING_FOR_CANDIDATE',
  WAITING_FOR_ADMIN = 'WAITING_FOR_ADMIN',
  WAITING_FOR_POA = 'WAITING_FOR_POA',
  FINISHED = 'FINISHED',
  CANNOT_COMPLETE = 'CANNOT_COMPLETE',
}
