import React from 'react';
import {
  Button, HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent, ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ComponentLogModalProps } from '../Components.model';
import Translate from '../../../../components/Translate/Translate';
import FormLocale from '../../../../core/localization/translations/Form.locale';
import TasksLocale from '../../Tasks.locale';
import ComponentLogDetail from './ComponentLogModalDetail';

const ComponentLogModal: React.FunctionComponent<ComponentLogModalProps> = (props) => {
  const { disabled, text, processInstanceLogId } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button variant="link" w="100%" onClick={onOpen} isDisabled={disabled}>
        <Translate label={TasksLocale.VIEW_LOG_VARIABLES} />
      </Button>
      <Modal closeOnOverlayClick={false} size="4xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text><Translate label={TasksLocale.LOG_VARIABLES} /></Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ComponentLogDetail processInstanceLogId={processInstanceLogId} text={text} />
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button variant="ghost" colorScheme="red" onClick={onClose}>
                <Translate label={FormLocale.BACK} />
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ComponentLogModal;
