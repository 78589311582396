import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, FormControl, FormErrorMessage, FormLabel, GridItem, Input,
  SimpleGrid, Switch,
} from '@chakra-ui/react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Toast } from '@scaut-sro/meepo';
import { useHistory } from 'react-router-dom';
import { Select } from 'chakra-react-select';
import { UserDetailProps, UserFormAttr } from '../Users.model';
import Translate from '../../../components/Translate/Translate';
import { translate } from '../../../core/localization/LocaleUtils';
import FormLocale from '../../../core/localization/translations/Form.locale';
import UsersLocale from '../Users.locale';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import UserSettingsLocale from '../../UserSettings/UserSettings.locale';
import { useGetAdminRoles } from '../../../build/generated-sources/service/QueryService';
import { KeycloakRoleDto } from '../../../build/generated-sources/dto/KeycloakRoleDto';
import { useAdminUser } from '../../../build/generated-sources/service/MutationService';
import { handleApolloErrors } from '../../../core/error/ScautErrorHandler';

const createAdminUserMapper = {
  id: true,
};

const UserDetailForm: React.FunctionComponent<UserDetailProps> = (props) => {
  const {
    adminUser, loadingSetter, formRef,
  } = props;
  const history = useHistory();
  const { language } = useGetUser();
  const [roleList, setRoleList] = useState<KeycloakRoleDto[]>([]);
  const [checked, setIsChecked] = useState(true);

  const defaultValues: Partial<UserFormAttr> = useMemo(() => ({
    adminUserId: adminUser?.id,
    firstName: adminUser?.firstName,
    lastName: adminUser?.lastName,
    email: adminUser?.email,
    active: adminUser?.active,
    role: {
      value: adminUser.id ? adminUser.role || '' : 'operator',
      label: adminUser.id ? adminUser.role || '' : 'operator',
    },
  }), [adminUser]);
  const {
    handleSubmit, register, setValue, reset, formState: { errors }, control,
  } = useForm<Partial<UserFormAttr>>({
    defaultValues,
  });

  useGetAdminRoles(
    {
      id: true,
      name: true,
    }, {
      onError: () => {
        Toast({
          title: translate(UsersLocale.ERROR_GETTING_ROLES, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setRoleList(response.adminRoles);
      },
    },
  );

  const roleOptions = useMemo(() => roleList.map((c) => ({
    value: c.id,
    label: c.name,
  })), [roleList]);

  const roleOption = useMemo(() => (roleOptions.find(
    (role) => role.value === adminUser.role,
  )), [roleOptions, adminUser]);

  useEffect(() => {
    reset(defaultValues);
    setValue('role', roleOption as { value: string, label: string });
  }, [
    reset, setValue, defaultValues, roleOption,
  ]);

  const [mutateUser,
    optionsMutationUser] = useAdminUser(createAdminUserMapper, {
    onCompleted: (response) => {
      Toast({
        title: translate(FormLocale.SUCCESSFULLY_SAVED, language),
        status: 'success',
      });
      history.push(`/user/${response.adminUser.id}`);
    },
    onError: (error) => {
      handleApolloErrors(error, language);
    },
  });

  useEffect(() => {
    if (loadingSetter) loadingSetter(optionsMutationUser.loading);
  }, [loadingSetter, optionsMutationUser]);

  const handleUserFormSubmit: SubmitHandler<UserFormAttr> = (values) => {
    mutateUser({
      variables: {
        adminUserId: adminUser?.id,
        adminUser: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          role: values.role.value,
          active: values.active,
          temporaryPassword: values.temporaryPassword,
        },
      },
    });
  };

  const handleCheckbox = () => {
    if (checked) {
      setIsChecked(false);
    } else {
      setIsChecked(true);
    }
  };

  return (
    <>
      <form
        name="userDetailForm"
        onSubmit={handleSubmit(handleUserFormSubmit)}
        ref={formRef}
      >
        <SimpleGrid columns={[1, 1, 1, 3, 3, 3]} spacing={6} mb={7}>
          <GridItem colSpan={[1, 1, 1, 2, 2, 2]}>
            <FormControl mb={6} isInvalid={!!errors.firstName} isRequired>
              <FormLabel htmlFor="firstName">
                <Translate label={UsersLocale.FIRST_NAME} />
              </FormLabel>
              <Input
                placeholder={translate(UsersLocale.FIRST_NAME, language)}
                {...register('firstName', {
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                })}
              />
              <FormErrorMessage>
                {errors.firstName && errors.firstName.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl mb={6} isInvalid={!!errors.lastName} isRequired>
              <FormLabel htmlFor="lastName">
                <Translate label={UsersLocale.LAST_NAME} />
              </FormLabel>
              <Input
                placeholder={translate(UsersLocale.LAST_NAME, language)}
                {...register('lastName', {
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                })}
              />
              <FormErrorMessage>
                {errors.lastName && errors.lastName.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.email} isRequired>
              <FormLabel htmlFor="email">
                <Translate label={UsersLocale.EMAIL} />
              </FormLabel>
              <Input
                placeholder={translate(UsersLocale.EMAIL, language)}
                {...register('email', {
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                  pattern: {
                    // eslint-disable-next-line max-len
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: translate(UserSettingsLocale.EMAIL_ERROR_INVALID, language),
                  },
                })}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
            {!adminUser.id ? (
              <FormControl mt={9} display="flex" justifyContent="space-between">
                <Translate label={UsersLocale.TEMPORARY_PASSWORD} />
                <Switch
                  id="temporaryPassword"
                  {...register('temporaryPassword')}
                  onChange={handleCheckbox}
                  isChecked={checked}
                />
              </FormControl>
            ) : undefined }
          </GridItem>
          <GridItem>
            {adminUser.id && (
              <Box>
                <FormControl
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderRadius="md"
                  backgroundColor="gray.100"
                  p="6px"
                  pl={3}
                  mt="30px"
                  mb={6}
                >
                  <label htmlFor="active">{translate(UsersLocale.ACTIVE, language)}</label>
                  <Controller
                    control={control}
                    name="active"
                    render={({ field }) => (
                      <Switch
                        onChange={(e) => { field.onChange(e.target.checked); }}
                        isChecked={field.value}
                        size="lg"
                        colorScheme="green"
                      />
                    )}
                  />
                </FormControl>
              </Box>
            )}

            <FormControl mb={6} isInvalid={!!errors.role} isRequired>
              <FormLabel htmlFor="role">
                <Translate label={UsersLocale.USER_ROLE} />
              </FormLabel>
              <Controller
                name="role"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={roleOptions}
                    hideSelectedOptions={false}
                    selectedOptionStyle="check"
                  />
                )}
                rules={{
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                }}
              />
              <FormErrorMessage>
                {errors.role && errors.role.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </SimpleGrid>
      </form>
    </>
  );
};
export default UserDetailForm;
