import React, { useEffect, useMemo, useState } from 'react';
import {
  H1, MeepoTable, SectionContainer, Toast,
} from '@scaut-sro/meepo';
import {
  Box, Flex, Spacer, Text,
} from '@chakra-ui/react';
import { PageInfo } from '@scaut-sro/meepo/lib/components/MeepoTable/MeepoTable.model';
import DetailHeader from '../../components/DetailHeader/DetailHeader';
import Translate from '../../components/Translate/Translate';
import { translate } from '../../core/localization/LocaleUtils';
import TableLocale from '../../core/localization/translations/Table.locale';
import { Direction } from '../../build/generated-sources/enum/Direction';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import EnumerationsLocale from './Enumerations.locale';
import {
  EnumerationsProps,
  EnumerationsTableData,
  EnumerationsTableFilterAllFilterType,
  EnumerationsTableFilterType,
} from './Enumeratons.model';
import { useGetEnumerationsPage } from '../../build/generated-sources/service/QueryService';
import { Language } from '../../build/generated-sources/enum/Language';
import { Enumeration } from '../../build/generated-sources/dto/Enumeration';
import EnumerationsTableFilter from './EnumerationsTableFilter';
import EnumerationsDetailModal from './EnumerationsDetailModal';
import LoadingScreen from '../LoadingScreen/LoadingScreen';

export function getEnumerationsTableData(language: Language,
  refreshTable: ()=> void,
  data: Enumeration[] | undefined): EnumerationsTableData[] {
  if (data) {
    return data.map((enumeration) => ({
      id: enumeration.id || '-undefined-',
      type: enumeration.type,
      translation: enumeration.translation?.name,
      buttons: (
        <EnumerationsDetailModal refreshTable={refreshTable} enumeration={enumeration} clicked />
      ),
    }));
  }
  return [];
}

const Enumerations:React.FunctionComponent<EnumerationsProps> = (props) => {
  const [tableFilter, setTableFilter] = useState< EnumerationsTableFilterType>({});
  const [allFilters, setAllFilter] = useState< EnumerationsTableFilterType>({});
  const { filters } = props;
  const [pageInfo, setPageInfo] = useState<PageInfo>({ pageNumber: 0, pageSize: 20, totalPages: 0 });
  const [sorting, setSorting] = useState<{ sortBy?: string, sortDirection?: Direction }>({});
  const [list, setList] = useState<EnumerationsTableData[]>([]);
  const { language } = useGetUser();
  const [refreshCalled, setRefreshCalled] = useState(false);

  const callRefresh = () => {
    setRefreshCalled(true);
  };

  const { loading, refetch } = useGetEnumerationsPage({
    pageInfo: {
      pageSize: true,
      pageNumber: true,
      numberOfElements: true,
      totalElements: true,
      totalPages: true,
    },
    content: {
      id: true,
      value: true,
      type: true,
      translation: {
        id: true,
        name: true,
      },
    },
  }, {
    onError: () => {
      Toast({
        title: translate(EnumerationsLocale.ERROR_GETTING_DATA, language),
        status: 'error',
      });
    },
    variables: {
      paging: {
        page: pageInfo.pageNumber,
        size: pageInfo.pageSize,
        sortBy: sorting.sortBy,
        sortDirection: sorting.sortDirection,
      },
      filter: {
        search: allFilters.fulltext && allFilters.fulltext.length ? allFilters.fulltext : undefined,
        types: allFilters.type && allFilters.type.length ? allFilters.type : undefined,
      },
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      const onCompleteData = getEnumerationsTableData(language, callRefresh, response.enumerationsPage.content);
      setList(onCompleteData);
      setPageInfo({
        pageSize: response.enumerationsPage.pageInfo?.pageSize,
        pageNumber: response.enumerationsPage.pageInfo?.pageNumber || 0,
        totalPages: response.enumerationsPage.pageInfo?.totalPages || 0,
        numberOfElements: response.enumerationsPage.pageInfo?.numberOfElements,
        totalElements: response.enumerationsPage.pageInfo?.totalElements,
      });
    },
  });

  useEffect(() => {
    if (refreshCalled) {
      refetch();
      setRefreshCalled(false);
    }
  }, [refreshCalled, refetch]);

  useMemo(() => {
    const newAllFilters: EnumerationsTableFilterAllFilterType = {
      ...filters,
      ...tableFilter,
    };
    setAllFilter(newAllFilters);
  }, [filters, tableFilter]);

  const refreshData = () => {
    refetch();
  };

  return (
    <>
      <SectionContainer>
        <Box overflowX="auto">
          <Box minW="600px">
            <DetailHeader>
              <H1><Translate label={EnumerationsLocale.ENUMERATIONS} /></H1>
            </DetailHeader>
            <Box mb={20}>
              <EnumerationsTableFilter
                filter={tableFilter}
                onFilterChange={(newTableFilter) => {
                  setTableFilter(newTableFilter);
                }}
              />
              <Spacer />
              <Flex mt={6} justifyContent="flex-end">
                <EnumerationsDetailModal refreshTable={refreshData} />
              </Flex>
            </Box>
            <Box overflowX="auto">
              <Box minW="600px">
                {list ? (
                  <MeepoTable
                    columns={[
                      {
                        id: 'type',
                        label: translate(EnumerationsLocale.TYPE, language),
                        minW: '200px',
                      }, {
                        id: 'translation',
                        label: translate(EnumerationsLocale.NAME, language),
                        minW: '200px',
                      },
                      {
                        id: 'buttons',
                        label: translate(EnumerationsLocale.ENUMERATION_DETAIL, language),
                        minW: '150px',
                        boxProps: { w: '200px' },
                      },
                    ]}
                    data={list}
                    isLoading={loading}
                    noData={<Text><Translate label={TableLocale.NO_DATA} /></Text>}
                    pageInfo={pageInfo}
                    setPage={(newPageNumber, newPageSize) => {
                      setPageInfo({
                        ...pageInfo,
                        pageNumber: newPageNumber,
                        pageSize: newPageSize,
                      });
                    }}
                    onSort={(newSorting) => {
                      const direction: Direction | undefined = newSorting.type === 'asc'
                        ? Direction.ASC
                        : newSorting.type === 'desc'
                          ? Direction.DESC
                          : undefined;
                      setSorting({
                        sortBy: newSorting.column,
                        sortDirection: direction,
                      });
                    }}
                    activeSorting={{
                      column: sorting.sortBy,
                      type: sorting.sortDirection === Direction.ASC
                        ? 'asc'
                        : sorting.sortDirection === Direction.DESC
                          ? 'desc'
                          : 'none',
                    }}
                  />
                ) : <LoadingScreen />}

              </Box>
            </Box>
          </Box>
        </Box>
      </SectionContainer>
    </>
  );
};

export default Enumerations;
