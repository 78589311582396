import React, {
  ChangeEvent, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  H1, H4, MeepoTable, SectionContainer, Toast,
} from '@scaut-sro/meepo';
import {
  Box, Flex, Input, InputGroup, InputLeftElement, Spacer, Text,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import debounce from 'lodash.debounce';
import { PageInfo } from '@scaut-sro/meepo/lib/components/MeepoTable/MeepoTable.model';
import { useLazyGetTranslationsPage } from '../../build/generated-sources/service/QueryService';
import { Direction } from '../../build/generated-sources/enum/Direction';
import Translate from '../../components/Translate/Translate';
import DetailHeader from '../../components/DetailHeader/DetailHeader';
import TableLocale from '../../core/localization/translations/Table.locale';
import { TranslationsProps, TranslationsTableData } from './Translations.model';
import { Translation } from '../../build/generated-sources/dto/Translation';
import TranslationsLocale from './Translations.locale';
import TranslationDetailModal from './TranslationDetail/TranslationDetailModal';
import TranslationDetail from './TranslationDetail/TranslationDetail';
import { translate } from '../../core/localization/LocaleUtils';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';

export function getTranslationsTableData(
  data: Translation[] | undefined,
  isModal?: boolean,
  onTranslationChosen?: (translation: Translation) => void,
  chooseModalOnClose?: () => void,
): TranslationsTableData[] {
  if (data) {
    return data.map((translation) => ({
      id: translation.id || -1,
      name: translation.name,
      localizationStrings: translation.localizationStrings,
      type: translation.type,
      detail: (<TranslationDetail
        translation={translation}
        isModal={isModal}
        onTranslationChosen={onTranslationChosen}
        chooseModalOnClose={chooseModalOnClose}
      />),
    }));
  }
  return [];
}

const Translations:React.FunctionComponent<TranslationsProps> = (props) => {
  const defaultVariables = useMemo(() => ({
    paging: {
      page: 0,
      size: 20,
      sortBy: 'id',
      sortDirection: Direction.ASC,
    },
  }), []);
  const { language } = useGetUser();
  const { isModal, onTranslationChosen, chooseModalOnClose } = props;
  const [list, setList] = useState<TranslationsTableData[]>([]);
  const [search, setSearch] = useState<string>('');
  const [pageInfo, setPageInfo] = useState<PageInfo>({
    pageNumber: defaultVariables.paging.page,
    pageSize: defaultVariables.paging.size,
    totalPages: 0,
  });
  const [sorting, setSorting] = useState<{ sortBy?: string, sortDirection?: Direction }>(
    { sortBy: defaultVariables.paging.sortBy, sortDirection: defaultVariables.paging.sortDirection },
  );

  const [translationsHandle, { loading, refetch }] = useLazyGetTranslationsPage({
    pageInfo: {
      pageSize: true,
      pageNumber: true,
      numberOfElements: true,
      totalElements: true,
      totalPages: true,
    },
    content: {
      id: true,
      name: true,
      type: true,
      localizationStrings: true,
      screeningDefinitionNames: {
        id: true,
        internalName: true,
      },
      screeningDefinitionDescriptions: {
        id: true,
        internalName: true,
      },
      screeningPackageNames: {
        id: true,
        internalName: true,
      },
      screeningPackageDescriptions: {
        id: true,
        internalName: true,
      },
      countryNames: {
        id: true,
      },
      faqQuestions: {
        id: true,
      },
      faqAnswers: {
        id: true,
      },
    },
  }, {
    variables: {
      paging: {
        page: pageInfo.pageNumber,
        size: pageInfo.pageSize,
        sortBy: sorting.sortBy,
        sortDirection: sorting.sortDirection,
      },
    },
    fetchPolicy: 'cache-and-network',
    onError: () => {
      Toast({
        title: translate(TranslationsLocale.ERROR_GETTING_TRANSLATIONS, language),
        status: 'error',
      });
    },
    onCompleted: (response) => {
      const onCompleteData = getTranslationsTableData(response.translationsPage.content,
        isModal, onTranslationChosen, chooseModalOnClose);
      setList(onCompleteData);
      setPageInfo({
        pageSize: response.translationsPage.pageInfo?.pageSize,
        pageNumber: response.translationsPage.pageInfo?.pageNumber || 0,
        totalPages: response.translationsPage.pageInfo?.totalPages || 0,
        numberOfElements: response.translationsPage.pageInfo?.numberOfElements,
        totalElements: response.translationsPage.pageInfo?.totalElements,
      });
    },
  });

  useEffect(() => {
    if (isModal) {
      return;
    }
    translationsHandle({
      variables: defaultVariables,
    });
  }, [isModal, translationsHandle, defaultVariables]);

  const searchChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const searchString = event.target.value;
    setSearch(searchString);
    if (!searchString || searchString === '') {
      translationsHandle({
        variables: defaultVariables,
      });
    } else {
      translationsHandle({
        variables: {
          ...defaultVariables,
          search: searchString,
        },
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchChangeHandler = useCallback(
    debounce(searchChangeHandler, 300),
    [],
  );

  return (
    <>
      <SectionContainer>
        <Box overflowX="auto">
          <Box minW="600px">
            {
                isModal ? (
                  <DetailHeader>
                    <H4><Translate label={TranslationsLocale.FIND_TRANSLATIONS} /></H4>
                  </DetailHeader>
                ) : (
                  <DetailHeader>
                    <H1><Translate label={TranslationsLocale.TRANSLATIONS} /></H1>
                  </DetailHeader>
                )
              }
            <Flex>
              <InputGroup zIndex={0}>
                <InputLeftElement>
                  <SearchIcon />
                </InputLeftElement>
                <Input
                  id="fulltext"
                  variant="filled"
                  onChange={debounceSearchChangeHandler}
                />
              </InputGroup>
            </Flex>
            <Flex mt={6} justifyContent="flex-end">
              <TranslationDetailModal
                onTranslationChosen={onTranslationChosen}
                chooseModalOnClose={chooseModalOnClose}
              />
            </Flex>
            <Box mb={20}>
              <Spacer />
            </Box>
            <Box overflowX="auto">
              <Box minW="600px">
                <MeepoTable
                  columns={[
                    {
                      id: 'id',
                      label: 'ID',
                      minW: '200px',
                    },
                    {
                      id: 'name',
                      label: translate(TranslationsLocale.NAME, language),
                      minW: '200px',
                    },
                    {
                      id: 'type',
                      label: translate(TranslationsLocale.TYPE, language),
                      minW: '200px',
                    },
                  ]}
                  isLoading={loading}
                  data={list}
                  noData={<Text><Translate label={TableLocale.NO_DATA} /></Text>}
                  pageInfo={pageInfo}
                  setPage={(newPageNumber, newPageSize) => {
                    setPageInfo({
                      ...pageInfo,
                      pageNumber: newPageNumber,
                      pageSize: newPageSize,
                    });
                    if (refetch) {
                      refetch({
                        search: !search || search === '' ? undefined : search,
                        paging: {
                          page: newPageNumber,
                          size: newPageSize,
                          sortBy: sorting.sortBy,
                          sortDirection: sorting.sortDirection,
                        },
                      });
                    }
                  }}
                  onSort={(newSorting) => {
                    const direction: Direction | undefined = newSorting.type === 'asc'
                      ? Direction.ASC
                      : newSorting.type === 'desc'
                        ? Direction.DESC
                        : undefined;
                    setSorting({
                      sortBy: newSorting.column,
                      sortDirection: direction,
                    });
                    if (refetch) {
                      refetch({
                        search: !search || search === '' ? undefined : search,
                        paging: {
                          page: pageInfo.pageNumber,
                          size: pageInfo.pageSize,
                          sortBy: newSorting.column,
                          sortDirection: direction,
                        },
                      });
                    }
                  }}
                  activeSorting={{
                    column: sorting.sortBy,
                    type: sorting.sortDirection === Direction.ASC
                      ? 'asc'
                      : sorting.sortDirection === Direction.DESC
                        ? 'desc'
                        : 'none',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </SectionContainer>
    </>
  );
};

export default Translations;
