import React, { useEffect, useState } from 'react';
import {
  SectionContainer, Toast,
} from '@scaut-sro/meepo';
import {
  Box, Button,
  FormControl, Input, Stack, Switch, Container,
} from '@chakra-ui/react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  RiSendPlane2Fill,
} from 'react-icons/all';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  useLazyGetComponentNotes,
} from '../../build/generated-sources/service/QueryService';
import TasksLocale from './Tasks.locale';
import { ComponentNote } from '../../build/generated-sources/dto/ComponentNote';
import Note from './Note';
import { translate } from '../../core/localization/LocaleUtils';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { NotesFormAttr, NotesProps } from './Tasks.model';
import { useComponentNoteCreateOrEdit } from '../../build/generated-sources/service/MutationService';
import FormLocale from '../../core/localization/translations/Form.locale';
import { NoteVisibility } from '../../build/generated-sources/enum/NoteVisibility';
import {
  UseGetComponentNotesData,
} from '../../build/generated-sources/service/QueryServiceModel';
import ClientsLocale from '../Clients/Clients.locale';

const createNoteMapper = {
  id: true,
};

const Notes: React.FunctionComponent<NotesProps> = (props) => {
  const { screeningComponentId } = props;
  const [notesList, setNotesList] = useState<ComponentNote[]>();
  const { params } = useRouteMatch<{ id: string }>();
  const history = useHistory();
  const componentId = Number.isNaN(+params.id);
  const { language } = useGetUser();

  const {
    handleSubmit, formState: { errors }, register, control, setValue,
  } = useForm();

  const onFetchCompleted = (response: UseGetComponentNotesData) => {
    if (response.componentNotes === null) {
      Toast({
        title: translate(ClientsLocale.ERROR_GETTING_DATA, language),
        status: 'error',
      });
    }
    setNotesList(response.componentNotes);
  };

  const [componentNotesHandle, { refetch }] = useLazyGetComponentNotes(
    {
      id: true,
      component: {
        id: true,
      },
      visibility: true,
      created: true,
      createdBy: true,
      updated: true,
      updatedBy: true,
      text: true,
    },
    {
      onCompleted: (response) => {
        if (response.componentNotes === null) {
          history.push('/404');
        }
        setNotesList(response.componentNotes);
      },
      onError: () => {
        history.push('/404');
      },
      variables: {
        componentId: screeningComponentId || +params.id,
      },
    },
  );
  useEffect(() => {
    if (!componentId) {
      componentNotesHandle({
        variables: {
          componentId: screeningComponentId || +params.id,
        },
      });
    }
  }, [componentNotesHandle, params.id, componentId, screeningComponentId]);

  const refreshData = () => {
    if (refetch) {
      refetch().then((response) => onFetchCompleted(response.data));
    }
  };

  const [mutateNote] = useComponentNoteCreateOrEdit(createNoteMapper, {
    onCompleted: () => {
      Toast({
        title: translate(FormLocale.SUCCESSFULLY_SAVED, language),
        status: 'success',
      });
      refreshData();
    },
    onError: () => {
      history.push('/404');
    },
  });

  useEffect(() => {
    register('visibility');
  }, [register]);

  const handleNoteSubmit: SubmitHandler<NotesFormAttr> = (values: NotesFormAttr) => {
    mutateNote({
      variables: {
        componentNoteId: values?.componentNoteId,
        componentId: screeningComponentId || +params.id,
        text: values.text,
        visibility: values.visibility === true ? NoteVisibility.PUBLIC : NoteVisibility.PRIVATE,
      },
    });
    setValue('text', '');
  };

  return (
    <SectionContainer>
      <Box overflowX="auto">
        <Container pb="80px" maxWidth="1000px">
          {notesList?.map((note) => (
            <Note note={note} handleNoteSubmit={handleNoteSubmit} refreshData={refreshData} />
          ))}
          <Box
            p={[0, 8, 0, 8]}
            width="100%"
            backgroundColor="gray.200"
            borderRadius="md"
            boxShadow="sm"
            pos="fixed"
            bottom="0"
            right="0"
          >
            <form name="noteForm" onSubmit={handleSubmit(handleNoteSubmit)}>
              <FormControl isInvalid={!!errors.text}>
                <Input
                  placeholder={translate(TasksLocale.CREATE_NOTE_TITLE, language)}
                  id="text"
                  mb={2}
                  {...register('text', {
                    required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                  })}
                />
              </FormControl>
              <Stack direction="row" justifyContent="space-between" align="bottom">
                <FormControl
                  display="flex"
                  width="50%"
                  justifyContent="space-between"
                  alignItems="center"
                  borderRadius="lg"
                  backgroundColor="gray.100"
                  p="6px"
                  pl={3}
                  mr={3}
                >
                  <label htmlFor="visibility">{translate(TasksLocale.PUBLIC, language)}</label>
                  <Controller
                    control={control}
                    name="visibility"
                    render={({ field }) => (
                      <Switch
                        onChange={(e) => { field.onChange(e.target.checked); }}
                        isChecked={field.value}
                        size="md"
                        colorScheme="red"
                      />
                    )}
                  />
                </FormControl>
                <Button variant="solid" type="submit" colorScheme="blue">
                  <RiSendPlane2Fill height="100%" />
                </Button>
              </Stack>
            </form>
          </Box>
        </Container>
      </Box>
    </SectionContainer>
  );
};

export default Notes;
