import React from 'react';
import {
  SimpleGrid,
  Skeleton, Table, Tbody, Td, Tr, Thead, Th, Box,
} from '@chakra-ui/react';
import { InfoContainer, ReadOnlyInput } from '@scaut-sro/meepo';
import { CandidateDetailProps } from './CandidateDetial.model';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../core/localization/LocaleUtils';
import CandidateDetailLocale from './CandidateDetail.locale';
import { showDate } from '../../core/date/showDate';
import Translate from '../Translate/Translate';

const CandidateDetail: React.FunctionComponent<CandidateDetailProps> = (props) => {
  const { candidates, loading } = props;
  const { language } = useGetUser();
  return (
    <>
      { candidates.length === 1 && !loading ? (
        <InfoContainer>
          <SimpleGrid columns={[1, 2, 2, 3]} spacing={[6]}>
            <ReadOnlyInput
              label={translate(CandidateDetailLocale.FULLNAME, language)}
              value={`${candidates[0].firstName} ${candidates[0].lastName}`}
            />
            <ReadOnlyInput
              label={translate(CandidateDetailLocale.DATE_OF_BIRTH, language)}
              value={showDate(language, candidates[0].dateOfBirth)}
            />
            <ReadOnlyInput
              label={translate(CandidateDetailLocale.EMAIL, language)}
              value={candidates[0].email}
            />
          </SimpleGrid>
        </InfoContainer>
      ) : candidates.length > 1 && !loading ? (

        <InfoContainer>
          <Box overflowX="auto">
            <Table
              variant="simple"
              minW="450px"
            >
              <Thead>
                <Tr>
                  <Th><Translate label={CandidateDetailLocale.FULLNAME} /></Th>
                  <Th><Translate label={CandidateDetailLocale.DATE_OF_BIRTH} /></Th>
                  <Th><Translate label={CandidateDetailLocale.EMAIL} /></Th>
                </Tr>
              </Thead>
              <Tbody>
                {candidates.map((candidate) => (
                  <Tr key={`${candidate.firstName}${candidate.lastName}${candidate.dateOfBirth}`}>
                    <Td>{`${candidate.firstName} ${candidate.lastName}`}</Td>
                    <Td>{candidate.dateOfBirth}</Td>
                    <Td>{candidate.email}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </InfoContainer>

      ) : (
        <>
          <Skeleton>
            <SimpleGrid columns={2} spacing={10} />
            <Table bg="gray.100" mt={6} variant="simple">
              <Thead>
                <Tr />
              </Thead>
              <Tbody>
                <Tr>
                  <Td />
                  <Td />
                  <Td />
                </Tr>
              </Tbody>
            </Table>
          </Skeleton>
        </>
      )}
    </>
  );
};

export default CandidateDetail;
