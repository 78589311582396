import React, { useEffect, useMemo, useState } from 'react';
import {
  ExecutionsDynamicField, Toast,
} from '@scaut-sro/meepo';
import {
  Button, Drawer, DrawerBody, DrawerCloseButton,
  DrawerContent, DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Select,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react';
import {
  FieldValues, FormProvider, SubmitHandler, useForm,
} from 'react-hook-form';
import { useKeycloak } from '@react-keycloak/web';
import { CandidateScreeningFormsData } from './CandidateScreeningDetail.model';
import Translate from '../../components/Translate/Translate';
import CandidateScreeningDetailLocale from './CandidateScreeningDetail.locale';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../core/localization/LocaleUtils';
import FormLocale from '../../core/localization/translations/Form.locale';
import { useAssignComponents, useFillForm } from '../../build/generated-sources/service/MutationService';
import { handleApolloErrors } from '../../core/error/ScautErrorHandler';
import Questionnaire from '../../components/Questionnaire/Questionnaire';
import { State } from '../../build/generated-sources/enum/State';
import { QuestionnaireDefinition } from '../../components/Questionnaire/Questionnaire.model';
import DetailHeader from '../../components/DetailHeader/DetailHeader';
import TasksLocale from '../Tasks/Tasks.locale';
import Notes from '../Tasks/Notes';
import MapFormFieldDtoTODynamicFormField from '../../core/util/ObjectMappers';
import ScautAdminRoles from '../../core/auth/ScautAdminRoles';

const ComponentForms: React.FunctionComponent<CandidateScreeningFormsData> = (props) => {
  const {
    component, adminUsers, refreshData, scautScreeningComparisonTableApi, screeningResultData,
  } = props;
  const { language } = useGetUser();
  const [assignee, setAssignee] = useState<number | string | undefined>('');
  const useFormMethods = useForm();
  const [actualValues, setActualValues] = useState<{ [p: string]: any }>({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { keycloak } = useKeycloak();
  const isSysAdmin = keycloak.hasResourceRole(ScautAdminRoles.SYSADMIN, 'scaut-admin-app');
  const isManager = keycloak.hasResourceRole(ScautAdminRoles.MANAGER, 'scaut-admin-app');

  const executionDefinition = useMemo(() => {
    if (component?.inputData?.form) {
      const keys = Object.keys(component?.inputData?.form);

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < keys.length; i++) {
        if (component?.inputData?.form[keys[i]].properties?.component === 'EXECUTION_DYNAMIC_FIELD') {
          const foundDefinition = component?.inputData?.form[keys[i]];

          return MapFormFieldDtoTODynamicFormField(foundDefinition, language);
        }
      }
    }
    return undefined;
  }, [component, language]);

  const formDefinition: QuestionnaireDefinition = useMemo(() => {
    const definition: QuestionnaireDefinition = {};

    if (component?.inputData?.form) {
      const keys = Object.keys(component?.inputData?.form);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < keys.length; i++) {
        if (component?.inputData?.form[keys[i]].properties?.component !== 'EXECUTION_DYNAMIC_FIELD') {
          definition[keys[i]] = component?.inputData?.form[keys[i]];
        }
      }
    }
    return definition;
  }, [component]);

  useEffect(() => {
    setActualValues(useFormMethods.getValues());
    setAssignee(component?.component?.assigneeId);
  }, [useFormMethods, setActualValues, component]);

  const [mutateAssignee, mutateAssigneeOptions] = useAssignComponents(
    {
      success: true,
    },
    {
      onCompleted: () => {
        Toast({
          title: translate(CandidateScreeningDetailLocale.SUCCESSFULLY_SAVED, language),
          status: 'success',
        });
      },
      onError: (error) => {
        handleApolloErrors(error, language);
      },
    },
  );

  const [mutateFillForm] = useFillForm(
    {
      generalValidationMessages: true,
      invalidFields: {
        fieldId: true,
        isValid: true,
        validationMessages: true,
      },
      isValid: true,
    },
    {
      onCompleted: () => {
        Toast({
          title: translate(CandidateScreeningDetailLocale.SUCCESSFULLY_SAVED, language),
          status: 'success',
        });
        if (refreshData) {
          refreshData();
        }
      },
      onError: (error) => {
        handleApolloErrors(error, language);
      },
    },
  );

  const assignAdminUser = (assignee1: string) => {
    if (isManager || isSysAdmin) {
      mutateAssignee({
        variables: {
          adminUserId: parseInt(assignee1, 10),
          componentIds: [component?.component?.id || -1],
        },
      });
    }
  };

  const handleComponentFormSubmit: SubmitHandler<FieldValues> = (values) => {
    mutateFillForm({
      variables: {
        formId: component?.inputData?.id || -1,
        formData: values,
      },
    });
  };

  const handleFormChange = () => {
    setActualValues(useFormMethods.getValues());
  };

  return (
    <>
      {isManager || isSysAdmin ? (
        <>
          <DetailHeader>
            <Spacer />
            <Flex justifyContent={['flex-start', 'flex-start', 'flex-start', 'flex-end']}>
              <Flex alignItems="center" w="200px" minW="fit-content">
                <FormControl mt={3}>
                  <FormLabel><Translate label={CandidateScreeningDetailLocale.ASSIGNEE} /></FormLabel>
                  <Select
                    onChange={(event) => {
                      setAssignee(event.target.value);
                      assignAdminUser(event.target.value);
                    }}
                    value={assignee}
                    isDisabled={component?.component?.state === State.FINISHED || mutateAssigneeOptions.loading}
                  >
                    <option value="">- Select value -</option>
                    {adminUsers ? (
                      adminUsers.map((userData) => (
                        <option key={userData.id} value={userData.id}>
                          {`${userData.firstName} ${userData.lastName}`}
                        </option>
                      ))
                    ) : undefined}
                  </Select>
                </FormControl>
              </Flex>
            </Flex>
          </DetailHeader>
        </>
      ) : undefined }
      <HStack justifyContent="right" mb={5}>
        <>
          <Button onClick={onOpen}>
            <Translate label={TasksLocale.NOTES} />
          </Button>
          <Drawer
            isOpen={isOpen}
            placement="right"
            size="lg"
            onClose={onClose}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader borderBottomWidth="1px">
                <Translate label={TasksLocale.NOTES} />
              </DrawerHeader>

              <DrawerBody pr={0}>
                <Notes screeningComponentId={component?.component?.id} />
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      </HStack>
      <FormProvider {...useFormMethods}>
        { executionDefinition ? (
          <>
            <ExecutionsDynamicField
              field={executionDefinition}
            />
          </>
        ) : undefined }
        <Questionnaire
          definition={component?.completedData as QuestionnaireDefinition}
          scautScreeningComparisonTableApi={scautScreeningComparisonTableApi}
          dynamicFormProps={{
            styleProps: {
              margin: '10px',
              padding: '20px',
              border: '1px solid #c7c2c2',
              borderRadius: '10px',
              background: '#d4d4d41a',
            },
          }}
          readonly
        />
        <Questionnaire
          definition={screeningResultData as QuestionnaireDefinition}
          scautScreeningComparisonTableApi={scautScreeningComparisonTableApi}
          dynamicFormProps={{
            styleProps: {
              margin: '10px',
              padding: '20px',
              border: '1px solid #c7c2c2',
              borderRadius: '10px',
              background: '#d4d4d41a',
            },
          }}
          readonly
        />
        <form onSubmit={useFormMethods.handleSubmit(handleComponentFormSubmit)} onChange={handleFormChange}>
          { Object.keys(formDefinition).length > 0 ? (
            <Questionnaire
              definition={component?.inputData?.form as QuestionnaireDefinition}
              scautScreeningComparisonTableApi={scautScreeningComparisonTableApi}
              actualValues={actualValues}
              readonly={component?.component?.state === State.FINISHED}
              dynamicFormProps={{
                styleProps: {
                  margin: '10px',
                  padding: '20px',
                  border: '1px solid #c7c2c2',
                  borderRadius: '10px',
                },
              }}
            />
          ) : undefined }
          <Flex>
            <Spacer />
            {component?.component?.state !== State.FINISHED && Object.keys(formDefinition).length > 0 ? (
              <Button mt={6} type="submit">
                <Translate label={FormLocale.SEND} />
              </Button>
            ) : undefined}

          </Flex>
        </form>
      </FormProvider>
    </>
  );
};

export default ComponentForms;
