import React from 'react';
import { useHistory } from 'react-router-dom';
import TasksTable from './TasksTable';
import TasksLocale from './Tasks.locale';
import { ViewType } from './Tasks.model';

const Tasks: React.FunctionComponent = () => {
  const history = useHistory();

  const openCandidateScreeningDetail = (candidateScreeningId?: number) => {
    if (candidateScreeningId) {
      history.push(`/screening/${candidateScreeningId}`);
    } else {
      history.push('/404');
    }
  };

  return (
    <TasksTable
      viewType={ViewType.CANDIDATE_SCREENING}
      openDetail={openCandidateScreeningDetail}
      detailColumnTitle={TasksLocale.CANDIDATE_SCREENING_DETAIL}
    />
  );
};

export default Tasks;
