import React from 'react';
import {
  Box, Button, Center, Divider, Flex, Spacer, Text,
} from '@chakra-ui/react';
import {
  Note,
} from '@scaut-sro/meepo';
import { useHistory } from 'react-router-dom';
import { NotFinishedCheckDetailProps } from './Checks.model';
import Translate from '../../components/Translate/Translate';
import ChecksLocale from './Checks.locale';
import TableLocale from '../../core/localization/translations/Table.locale';

const UnfinishedOrderCheckDetail: React.FunctionComponent<NotFinishedCheckDetailProps> = (props) => {
  const { order } = props;
  const history = useHistory();

  return (
    <>
      {order ? (
        <Box p={3} pt={0}>
          <Divider mb={3} />
          <Flex alignItems="center">
            <Note>
              <Translate label={ChecksLocale.TABLE_DETAIL_UNFINISHED_ORDER} />
            </Note>
            <Spacer />
            <Button ml={3} variant="outline" onClick={() => history.push(`/order/${order?.id}`)}>
              <Translate label={ChecksLocale.TABLE_DETAIL_GO_TO_DETAIL} />
            </Button>
          </Flex>
        </Box>
      ) : (
        <Center w="100%" p={8}>
          <Text><Translate label={TableLocale.NO_DATA} /></Text>
        </Center>
      )}
    </>
  );
};

export default UnfinishedOrderCheckDetail;
