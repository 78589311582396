import React, { useRef, useState } from 'react';
import {
  Button, HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent, ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Toast, Translate } from '@scaut-sro/meepo';
import { CompletedFormEditProps } from '../CandidateScreeningDetail.model';
import FormLocale from '../../../core/localization/translations/Form.locale';
import CandidateScreeningDetailLocale from '../CandidateScreeningDetail.locale';
import CompletedFormEditForm from './CompletedFormEditForm';
import {
  useGetCandidateScreeningCompletedDataGet,
} from '../../../build/generated-sources/service/QueryService';
import { translate } from '../../../core/localization/LocaleUtils';
import CatalogueScreeningsLocale from '../../Catalogue/CatalogueScreenings/CatalogueScreenings.locale';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import { FormFieldDto } from '../../../build/generated-sources/dto/FormFieldDto';

const CompletedFormEditModal: React.FunctionComponent<CompletedFormEditProps> = (props) => {
  const { candidateScreeningId, refreshData } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);
  const { language } = useGetUser();
  const [completedDataForm, setCompletedDataForm] = useState<{ [key: string]: FormFieldDto }>({});

  useGetCandidateScreeningCompletedDataGet(
    {
      completedData: true,
    }, {
      onError: () => {
        Toast({
          title: translate(CatalogueScreeningsLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      },
      variables: {
        candidateScreeningId,
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setCompletedDataForm(response.candidateScreeningCompletedDataGet.completedData || {});
      },
    },
  );

  return (
    <>
      <Button size="md" variant="outline" onClick={onOpen} marginBottom={5}>
        <Translate label={CandidateScreeningDetailLocale.EDIT_FILLED_IN_INFO} />
      </Button>
      <Modal closeOnOverlayClick={false} size="6xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text><Translate label={CandidateScreeningDetailLocale.FILLED_IN_INFO} /></Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CompletedFormEditForm
              candidateScreeningId={candidateScreeningId}
              completedData={completedDataForm}
              loadingSetter={(isLoading) => { setLoading(isLoading); }}
              formRef={formRef}
              onClose={onClose}
              refreshData={refreshData}
            />
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button
                onClick={() => {
                  if (formRef) {
                    formRef.current?.dispatchEvent(new Event('submit', {
                      cancelable: true,
                      bubbles: true,
                    }));
                  }
                }}
                isLoading={loading}
              >
                <Translate label={FormLocale.CONFIRM} />
              </Button>
              <Button variant="ghost" colorScheme="red" onClick={onClose}>
                <Translate label={FormLocale.CANCEL} />
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CompletedFormEditModal;
