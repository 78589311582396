import { Translation } from '../../core/localization/Localization.model';

class ChecksLocale {
  public static CHECKS: Translation = { EN: 'Checks', CZ: 'Kontroly' };
  public static CLIENT: Translation = { EN: 'Client', CZ: 'Klient' };
  public static LABEL: Translation = { EN: 'Label', CZ: 'Název' };
  public static CUSTOM_ID: Translation = { EN: 'Custom ID', CZ: 'Identifikátor' };
  public static TEAM: Translation = { EN: 'Team', CZ: 'Tým' };
  public static ORDER_RESULT: Translation = { EN: 'Result', CZ: 'Výsledek' };
  public static ORDER_STATE: Translation = { EN: 'State', CZ: 'Stav' };
  public static SINGLE_CHECK: Translation = { EN: 'Single check', CZ: 'Jednotlivec' };
  public static MULTIPLE_PEOPLE: Translation = { EN: 'Multiple people', CZ: 'Hromadné zadání' };
  public static CZECH_REPUBLIC: Translation = { EN: 'Czech Republic', CZ: 'Česká Republika' };
  public static ASSIGN: Translation = { EN: 'Assign', CZ: 'Přiřadit' };
  public static SET_ACCOUNT_MANAGER: Translation = { EN: 'Set account manager', CZ: 'Nastavit garanta' };
  public static SLOVAK_REPUBLIC: Translation = { EN: 'Slovak Republic', CZ: 'Slovenská Republika' };
  public static ORDER_TYPE: Translation = { EN: 'Type', CZ: 'Typ' };
  public static ORDER_CREATED: Translation = { EN: 'Ordered', CZ: 'Datum objednání' };
  public static TABLE_DETAIL_GO_TO_DETAIL: Translation = { EN: 'Go to detail', CZ: 'Přejít na detail' };
  public static TABLE_DETAIL_DOWNLOAD_REPORT: Translation = { EN: 'Download report', CZ: 'Stáhnout report' };
  public static TABLE_DETAIL_SHARE: Translation = { EN: 'Share', CZ: 'Sdílet' };
  public static TABLE_DETAIL_INFO: Translation = { EN: 'Information', CZ: 'Informace' };
  public static TABLE_DETAIL_SCREENINGS: Translation = { EN: 'Screenings', CZ: 'Prováděné kontroly' };
  public static TABLE_DETAIL_CANDIDATE_SCREENING_START: Translation = { EN: 'Candidate screening start', CZ: 'Začátek kontroly' };
  public static TABLE_DETAIL_CANDIDATE_SCREENING_END: Translation = { EN: 'Candidate screening end', CZ: 'Dokončení kontroly' };
  public static CONFIRM: Translation = { EN: 'Confirm', CZ: 'Potvrdit' };
  public static SUCCESSFULLY_ASSIGNED: Translation = {
    EN: 'Successfully assigned',
    CZ: 'Úspěšně přiřazeno',
  };
  public static ERROR_ASSIGNING: Translation = {
    EN: 'There was a problem assigning.',
    CZ: 'Při přiřazování došlo k problému.',
  };
  public static ERROR_GETTING_DATA: Translation = {
    EN:
        'There was a problem getting data',
    CZ: 'Při získávání dat došlo k problému',
  };
  public static TABLE_DETAIL_MULTIPLE_CHECK_START: Translation = {
    EN: 'Multiple check start',
    CZ: 'Informace o hromadné kontroly',
  };
  public static TABLE_DETAIL_MULTIPLE_CHECK_END: Translation = { EN: 'Multiple check end', CZ: 'Informace o hromadné kontroly' };
  public static TABLE_DETAIL_CANDIDATE_SCREENING_ANONYMIZED: Translation = {
    EN: 'The candidate screening will be anonymized',
    CZ: 'Kontrola bude anonymizována',
  };
  public static TABLE_DETAIL_UNFINISHED_ORDER: Translation = {
    EN: 'Unfinished order',
    CZ: 'Rozpracovaná objednávka',
  };
  public static TABLE_DETAIL_MULTIPLE_CHECK: Translation = {
    EN: 'Multiple check order',
    CZ: 'Objedávka hromadné kontroly',
  };
  public static TABLE_DETAIL_CANDIDATES_COUNT: Translation = {
    EN: 'Candidates count',
    CZ: 'Počet kontrolovaných osob',
  };
  public static ACCOUNT_MANAGER: Translation = { EN: 'Account manager', CZ: 'Garant' };
  public static TABLE_DETAIL_GO_TO_RECAP: Translation = { EN: 'Order recapitulation', CZ: 'Rekapitulace objednávky' };
}

export default ChecksLocale;
