import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FilterButton, InfoContainer, Toast } from '@scaut-sro/meepo';
import {
  Box, Button, Checkbox,
  Collapse,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { Select } from 'chakra-react-select';
import { FiFilter, FiX } from 'react-icons/fi';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { useGetAdminRoles } from '../../build/generated-sources/service/QueryService';
import { translate } from '../../core/localization/LocaleUtils';
import { UsersTableFilterProps, UsersTableFilterType } from './UsersTable.model';
import Translate from '../../components/Translate/Translate';
import { KeycloakRoleDto } from '../../build/generated-sources/dto/KeycloakRoleDto';
import UsersLocale from './Users.locale';

const UserTableFilter: React.FunctionComponent<UsersTableFilterProps> = (props) => {
  const { filter, onFilterChange } = props;
  const { language } = useGetUser();
  const {
    handleSubmit, register, reset, setValue,
  } = useForm();
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>();
  const [roleList, setRoleList] = useState<KeycloakRoleDto[]>([]);
  const [roles, setRoles] = useState<{ value?: string, label?: string }[]>([]);

  useGetAdminRoles(
    {
      id: true,
      name: true,
    }, {
      onError: () => {
        Toast({
          title: translate(UsersLocale.ERROR_GETTING_ROLES, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setRoleList(response.adminRoles);
      },
    },
  );

  const roleOptions = useMemo(() => roleList.map((c) => ({
    value: c.id,
    label: c?.name,
  })), [roleList]);

  const handleFilterSubmit: SubmitHandler<UsersTableFilterType> = (values) => {
    onFilterChange(values);
  };

  const handleFilterCancel = () => {
    handleFilterSubmit({});
    setRoles([]);
    setChecked((c) => !c);
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmit(handleFilterSubmit);
    }
  };

  const activeFilters: boolean = useMemo(() => {
    const filteredObjKeys = Object.keys(filter).filter((key) => (filter[key] && filter[key].length)
        || (key === 'active' && filter[key] as boolean));
    return !!filteredObjKeys.length;
  }, [filter]);

  useEffect(() => {
    reset({
      fulltext: filter.fulltext,
      roles: filter.roles,
      active: filter.active,
    });
  }, [filter, reset]);
  return (
    <Box>
      <form
        name="UserTableFilterTableFilter"
        onSubmit={handleSubmit((values) => {
          handleFilterSubmit(values);
        })}
      >
        <Flex>
          <InputGroup zIndex={0}>
            <InputLeftElement>
              <SearchIcon />
            </InputLeftElement>
            <Input
              id="fulltext"
              variant="filled"
              onKeyUp={handleKeyUp}
              {...register('fulltext')}
            />
          </InputGroup>
          <Box ml={3}>
            <FilterButton
              activeFilters={activeFilters}
              openFilters={() => { setOpenFilters(!openFilters); }}
              cancelFilters={handleFilterCancel}
            />
          </Box>
        </Flex>
        <Collapse in={openFilters} animateOpacity>
          <Box mt={3}>

            <InfoContainer>

              <SimpleGrid gap={6} columns={[1, 1, 2, 2, 2, 3]}>

                <FormControl>
                  <FormLabel fontSize="sm"><Translate label={UsersLocale.USER_ROLE} /></FormLabel>

                  <Select
                    name="roles"
                    isMulti
                    closeMenuOnSelect={false}
                    value={roles}
                    options={roleOptions}
                    onChange={(values: { value: string, label: string }[]) => {
                      setValue('roles', values.map((value) => value.value));
                      setRoles(values);
                    }}
                    hideSelectedOptions={false}
                    selectedOptionStyle="check"
                    size="sm"
                  />
                </FormControl>
                <FormControl mt={9}>
                  <Translate label={UsersLocale.ACTIVE} />
                  :
                  {' '}
                  <Checkbox
                    id="active"
                    isChecked={checked}
                    mt="5px"
                    {...register('active')}
                    onChange={(e) => setChecked(e.target.checked)}
                  />
                </FormControl>
              </SimpleGrid>

              <Flex mt={6} justifyContent="flex-end">
                <Button
                  size="sm"
                  colorScheme="red"
                  isDisabled={!activeFilters}
                  onClick={handleFilterCancel}
                  variant="ghost"
                  type="reset"
                  leftIcon={<FiX />}
                >
                  Cancel filter
                </Button>
                <Button
                  size="sm"
                  ml={3}
                  leftIcon={<FiFilter />}
                  type="submit"
                >
                  Filter
                </Button>
              </Flex>
            </InfoContainer>
          </Box>
        </Collapse>
      </form>
    </Box>
  );
};

export default UserTableFilter;
