import React, { useRef, useState } from 'react';
import {
  Button, HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent, ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import Translate from '../../components/Translate/Translate';
import FormLocale from '../../core/localization/translations/Form.locale';
import { EnumerationsDetailProps } from './Enumeratons.model';
import EnumerationsDetailForm from './EnumerationsDetailForm';
import EnumerationsLocale from './Enumerations.locale';

const EnumerationsDetailModal: React.FunctionComponent<EnumerationsDetailProps> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);
  const { clicked, enumeration, refreshTable } = props;

  const callRefreshMethods = () => {
    refreshTable();
    onClose();
  };

  return (
    <>
      {clicked ? (
        <Button
          variant="link"
          onClick={onOpen}
        >
          <Translate label={EnumerationsLocale.GO_TO_DETAIL} />
        </Button>
      ) : (
        <Button onClick={onOpen} leftIcon={<AddIcon />}>
          <Translate label={EnumerationsLocale.NEW} />
        </Button>
      )}
      <Modal closeOnOverlayClick={false} size="4xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text>
              {' '}
              {clicked ? (<Translate label={EnumerationsLocale.EDIT} />) : (<Translate label={EnumerationsLocale.NEW} />)}
              {' '}
              <Translate label={EnumerationsLocale.ENUMERATION} />
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <EnumerationsDetailForm
              loadingSetter={(isLoading) => { setLoading(isLoading); }}
              formRef={formRef}
              enumeration={enumeration}
              refreshTable={callRefreshMethods}
            />
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button
                onClick={() => {
                  if (formRef) {
                    formRef.current?.dispatchEvent(new Event('submit', {
                      cancelable: true,
                      bubbles: true,
                    }));
                  }
                }}
                isLoading={loading}
              >
                <Translate label={FormLocale.CONFIRM} />
              </Button>
              <Button variant="ghost" colorScheme="red" onClick={onClose}>
                <Translate label={FormLocale.CANCEL} />
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EnumerationsDetailModal;
