import React, { useState } from 'react';
import {
  Header, Layout, MobileHeader, NotificationIndicator,
} from '@scaut-sro/meepo';
import { Link, useLocation } from 'react-router-dom';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import UserMenu from '../UserMenu/UserMenu';
import MainMenu from '../MainMenu/MainMenu';
import { ScautConst } from '../../theme/ScautAdminTheme';
import MobileMenu from '../MainMenu/MobileMenu';
import ScautLogo from '../Logo/Logo';

const AppLayout: React.FunctionComponent = ({ children }) => {
  const location = useLocation().pathname.substring(1);
  const [menuIsSmall, setSmallMenu] = useState(false);
  const activeItem = location === '' ? 'overview' : location;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Layout
      headerHeight={ScautConst.HEADER_HEIGHT}
      header={(
        <Header
          headerHeight={ScautConst.HEADER_HEIGHT}
          leftMenuWidth={ScautConst.LEFT_MENU_WIDTH}
          isMenuSmall={menuIsSmall}
          toggleMenuWidth={() => setSmallMenu(!menuIsSmall)}
          notificationCenter={(
            <NotificationIndicator />
          )}
          userMenu={<UserMenu>User Menu</UserMenu>}
          logo={(
            <Link to="/">
              <Flex
                stroke="primaryBase"
                fill="primaryBase"
                h="100%"
                alignItems="center"
              >
                <Box mt={-1}><ScautLogo /></Box>
              </Flex>
            </Link>
          )}
        />
      )}
      leftMenu={(
        <MainMenu
          leftMenuWidth={ScautConst.LEFT_MENU_WIDTH}
          headerHeight={ScautConst.HEADER_HEIGHT}
          menuIsSmall={menuIsSmall}
          activeItem={activeItem}
        />
      )}
      mobileHeader={(
        <MobileHeader
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          headerHeight={ScautConst.HEADER_HEIGHT}
          leftMenuWidth={ScautConst.LEFT_MENU_WIDTH}
          notificationCenter={(<NotificationIndicator />)}
          userMenu={(<UserMenu>User Menu</UserMenu>)}
          leftMenu={(
            <MobileMenu
              leftMenuWidth={ScautConst.LEFT_MENU_WIDTH}
              headerHeight={ScautConst.HEADER_HEIGHT}
              menuIsSmall={menuIsSmall}
              activeItem={activeItem}
              handleSetActiveItem={() => onClose()}
            />
          )}
        />
      )}
    >
      { children }
    </Layout>
  );
};

export default AppLayout;
