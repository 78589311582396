import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, FormControl, FormErrorMessage, FormLabel, GridItem, Input,
  SimpleGrid, Switch,
} from '@chakra-ui/react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Toast } from '@scaut-sro/meepo';
import { Select } from 'chakra-react-select';
import {
  CataloguePackageDetailProps, ScreeningPackageFormAttr,
} from '../CataloguePackages.model';
import Translate from '../../../../components/Translate/Translate';
import { translate } from '../../../../core/localization/LocaleUtils';
import { useGetUser } from '../../../../core/store/reducers/UserSettingsReducer';
import FormLocale from '../../../../core/localization/translations/Form.locale';
import CataloguePackagesLocale from '../CataloguePackages.locale';
import { Country } from '../../../../build/generated-sources/dto/Country';
import {
  useGetClients,
  useGetCountries,
} from '../../../../build/generated-sources/service/QueryService';
import { Translation } from '../../../../build/generated-sources/dto/Translation';
import { useScreeningPackage } from '../../../../build/generated-sources/service/MutationService';
import TranslationChooseModal from '../../../Translations/TranslationChooseModal/TranslationChooseModal';
import { ScreeningDefinitionCountDto } from '../../../../build/generated-sources/dto/ScreeningDefinitionCountDto';
import ScreeningDefinitionsEditable from './ScreeningDefinitionsEditable';
import { ScreeningDefinitionCountInput } from '../../../../build/generated-sources/dto/ScreeningDefinitionCountInput';
import { Client } from '../../../../build/generated-sources/dto/Client';

const createScreeningPackageMapper = {
  id: true,
};

const CataloguePackageDetailForm: React.FunctionComponent<CataloguePackageDetailProps> = (props) => {
  const {
    screeningPackageDetail, loadingSetter, formRef, cancelChangesTrigger, refreshData,
  } = props;
  const { language } = useGetUser();
  const [countryList, setCountryList] = useState<Country[]>([]);
  const [clientList, setClientList] = useState<Client[]>([]);
  const [screeningDefinitionCountList, setScreeningDefinitionCountList] = useState<ScreeningDefinitionCountDto[]>([]);
  const [activeButtonDisabled, setActiveButtonDisabled] = useState<boolean>(
    screeningPackageDetail?.screeningDefinitions?.length === 0,
  );

  const defaultValues: Partial<ScreeningPackageFormAttr> = useMemo(() => ({
    id: screeningPackageDetail?.id,
    internalName: screeningPackageDetail?.internalName,
    price: screeningPackageDetail?.price,
    active: screeningPackageDetail?.active,
    translationNameId: screeningPackageDetail?.name?.id || undefined,
    translationDescriptionId: screeningPackageDetail?.description?.id || undefined,
    countryId: screeningPackageDetail?.country?.id ? {
      value: screeningPackageDetail?.country?.id || -1,
      label: screeningPackageDetail?.country?.translation?.localizationStrings?.[language] || '',
    } : undefined,
    clientId: screeningPackageDetail?.clients ? screeningPackageDetail?.clients.map((client) => ({
      value: client.id || -1,
      label: client.name || '',
    })) : [],
    screeningDefinitions: screeningPackageDetail?.screeningDefinitions?.map((screeningDefinition) => ({
      value: screeningDefinition.id || -1,
      label: screeningDefinition.internalName || '',
    })),
  }), [screeningPackageDetail, language]);

  const {
    handleSubmit, register, setValue, reset, formState: { errors }, control, getValues,
  } = useForm<Partial<ScreeningPackageFormAttr>>({
    defaultValues,
  });

  useGetCountries(
    {
      id: true,
      translation: {
        id: true,
        localizationStrings: true,
      },
    }, {
      onError: () => {
        Toast({
          title: translate(CataloguePackagesLocale.ERROR_GETTING_COUNTRIES, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setCountryList(response.countries);
      },
    },
  );

  useGetClients({
    id: true,
    name: true,
  }, {
    onError: () => {
      Toast({
        title: translate(CataloguePackagesLocale.ERROR_GETTING_CLIENTS, language),
        status: 'error',
      });
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      setClientList(response.clients);
    },
  });

  const countryOptions = useMemo(() => countryList.map((c) => ({
    value: c.id,
    label: c?.translation?.localizationStrings?.[language],
  })), [countryList, language]);

  const clientOptions = useMemo(() => clientList.map((c) => ({
    value: c.id,
    label: c.name,
  })), [clientList]);

  useEffect(() => {
    reset(defaultValues);
  }, [
    cancelChangesTrigger, reset, setValue, defaultValues,
  ]);

  useEffect(() => {
    const screeningDefinitionsCountDtos: ScreeningDefinitionCountDto[] = [];
    screeningPackageDetail?.screeningDefinitions?.forEach((screeningDefinition) => {
      screeningDefinitionsCountDtos.push({
        id: screeningDefinition.id,
        internalName: screeningDefinition.internalName,
        count: screeningDefinition.count,
        countLimit: screeningDefinition.countLimit,
        countable: screeningDefinition.countable,
      });
    });
    setScreeningDefinitionCountList(screeningDefinitionsCountDtos);
  }, [screeningPackageDetail]);

  const [mutateCreateScreeningPackage,
    optionsMutationScreeningPackage] = useScreeningPackage(createScreeningPackageMapper, {
    onCompleted: () => {
      Toast({
        title: translate(FormLocale.SUCCESSFULLY_SAVED, language),
        status: 'success',
      });
      if (refreshData) {
        refreshData();
      }
    },
    onError: () => {
      Toast({
        title: translate(CataloguePackagesLocale.ERROR_SAVING_SCREENING_DEFINITIONS, language),
        status: 'error',
      });
    },
  });

  useEffect(() => {
    if (loadingSetter) loadingSetter(optionsMutationScreeningPackage.loading);
  }, [loadingSetter, optionsMutationScreeningPackage]);

  useEffect(() => {
    setActiveButtonDisabled(screeningDefinitionCountList.length === 0);
    if (screeningDefinitionCountList.length === 0) {
      setValue('active', false);
    }
  }, [screeningDefinitionCountList, setValue]);

  const handleScreeningPackageFormSubmit: SubmitHandler<ScreeningPackageFormAttr> = (values) => {
    const screeningDefinitionsInput: ScreeningDefinitionCountInput[] = screeningDefinitionCountList
      .map((screeningDefinition) => ({
        id: screeningDefinition.id,
        count: screeningDefinition.count,
      }));
    mutateCreateScreeningPackage({
      variables: {
        screeningPackage: {
          id: screeningPackageDetail?.id,
          internalName: values.internalName,
          price: values.price,
          countryId: values.countryId.value,
          clientIds: values.clientId.map((client) => client.value),
          active: screeningDefinitionsInput.length === 0 ? false : values.active,
          screeningDefinitions: screeningDefinitionsInput,
          translationNameId: values.translationNameId,
          translationDescriptionId: values.translationDescriptionId,
        },
      },
    });
  };

  const setTranslationName = (translation: Translation) => {
    setValue('translationNameId', translation.id);
  };

  const setTranslationDescription = (translation: Translation) => {
    setValue('translationDescriptionId', translation.id);
  };

  return (
    <>
      <form
        name="screeningPackageForm"
        onSubmit={handleSubmit(handleScreeningPackageFormSubmit)}
        ref={formRef}
      >
        <SimpleGrid columns={[1, 1, 1, 3, 3, 3]} spacing={6} mb={7}>
          <GridItem colSpan={[1, 1, 1, 2, 2, 2]}>
            <FormControl isRequired isInvalid={!!errors.internalName}>
              <FormLabel htmlFor="internalName">
                <Translate label={CataloguePackagesLocale.INTERNAL_NAME} />
              </FormLabel>
              <Input
                placeholder={translate(CataloguePackagesLocale.INTERNAL_NAME, language)}
                {...register('internalName', {
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                })}
              />
              <FormErrorMessage>
                {errors.internalName && errors.internalName.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <Box>
            <FormControl
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderRadius="md"
              backgroundColor="gray.100"
              p="6px"
              pl={3}
              mt="30px"
            >
              <label htmlFor="active">{translate(CataloguePackagesLocale.ACTIVE, language)}</label>
              <Controller
                control={control}
                name="active"
                render={({ field }) => (
                  <Switch
                    onChange={(e) => { field.onChange(e.target.checked); }}
                    isChecked={field.value}
                    size="lg"
                    colorScheme="green"
                    isDisabled={activeButtonDisabled}
                  />
                )}
              />
            </FormControl>
          </Box>

          <FormControl isRequired isInvalid={!!errors.price}>
            <FormLabel htmlFor="price">
              <Translate label={CataloguePackagesLocale.PRICE} />
            </FormLabel>
            <Input
              type="number"
              placeholder={translate(CataloguePackagesLocale.PRICE, language)}
              {...register('price', {
                required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
              })}
            />
            <FormErrorMessage>
              {errors.price && errors.price.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={!!errors.translationNameId}>
            <FormLabel htmlFor="translationNameId">
              <Translate label={CataloguePackagesLocale.TRANSLATION_NAME} />
            </FormLabel>
            <TranslationChooseModal
              translationId={getValues('translationNameId')}
              setValue={setTranslationName}
              type="string"
              {...register('translationNameId', {
                required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
              })}
            />
            <FormErrorMessage>
              {errors.translationNameId && errors.translationNameId.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={!!errors.translationDescriptionId}>
            <FormLabel htmlFor="translationDescriptionId">
              <Translate label={CataloguePackagesLocale.TRANSLATION_DESCRIPTION} />
            </FormLabel>
            <TranslationChooseModal
              translationId={getValues('translationDescriptionId')}
              setValue={setTranslationDescription}
              type="string"
              {...register('translationDescriptionId', {
                required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
              })}
            />
            <FormErrorMessage>
              {errors.translationDescriptionId && errors.translationDescriptionId.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={!!errors.countryId}>
            <FormLabel htmlFor="countryId">
              <Translate label={CataloguePackagesLocale.COUNTRY} />
            </FormLabel>
            <Controller
              name="countryId"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={countryOptions}
                  hideSelectedOptions={false}
                  selectedOptionStyle="check"
                />
              )}
              rules={{
                required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
              }}
            />
            <FormErrorMessage>
              {errors.countryId && errors.countryId.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.clientId}>
            <FormLabel htmlFor="clientId">
              <Translate label={CataloguePackagesLocale.CLIENT} />
            </FormLabel>
            <Controller
              name="clientId"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={clientOptions}
                  isMulti
                  hideSelectedOptions={false}
                  selectedOptionStyle="check"
                />
              )}
            />
            <FormErrorMessage>
              {errors.clientId && errors.clientId.message}
            </FormErrorMessage>
          </FormControl>
        </SimpleGrid>
      </form>
      <Box mb={10}>
        <ScreeningDefinitionsEditable
          screeningDefinitions={screeningDefinitionCountList}
          onScreeningDefinitionsChange={setScreeningDefinitionCountList}
        />
      </Box>

    </>
  );
};

export default CataloguePackageDetailForm;
