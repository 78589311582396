import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { H1, SectionContainer, Toast } from '@scaut-sro/meepo';
import {
  Box, Button, HStack, Spacer,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import UserDetailForm from './UserDetailForm';
import DetailHeader from '../../../components/DetailHeader/DetailHeader';
import Translate from '../../../components/Translate/Translate';
import UsersLocale from '../Users.locale';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import { AdminUser } from '../../../build/generated-sources/dto/AdminUser';
import { useLazyGetAdminUserDetail } from '../../../build/generated-sources/service/QueryService';
import FormLocale from '../../../core/localization/translations/Form.locale';
import { translate } from '../../../core/localization/LocaleUtils';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';

const UserDetail: React.FunctionComponent = () => {
  const { language } = useGetUser();
  const { params } = useRouteMatch<{ id: string }>();
  const userId = Number.isNaN(+params.id);
  const [data, setData] = useState<AdminUser | undefined>(undefined);
  const [formIsLoading, setFormIsLoading] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);
  const history = useHistory();

  const [userHandle] = useLazyGetAdminUserDetail(
    {
      id: true,
      firstName: true,
      lastName: true,
      email: true,
      role: true,
      active: true,
      avatar: true,
      avatarThumbnail: true,
      userPreferences: {
        language: true,
      },
    },
    {
      onCompleted: (response) => {
        if (response.adminUserDetail === null) {
          Toast({
            title: translate(UsersLocale.ERROR_GETTING_DATA, language),
            status: 'error',
          });
        }
        setData(response.adminUserDetail);
      },
      onError: () => {
        Toast({
          title: translate(UsersLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      },
      variables: {
        adminUserId: +params.id,
      },
    },
  );
  useEffect(() => {
    if (!userId) {
      userHandle({
        variables: {
          adminUserId: +params.id,
        },
      });
    }
  }, [userHandle, params.id, userId]);

  const goBack = () => {
    history.goBack();
  };

  return (
    <>
      {!data ? <LoadingScreen /> : (
        <SectionContainer>
          <DetailHeader>
            <H1><Translate label={UsersLocale.USER_DETAIL} /></H1>
          </DetailHeader>
          <Box>
            <UserDetailForm
              adminUser={data}
              loadingSetter={(isLoading) => { setFormIsLoading(isLoading); }}
              formRef={formRef}
            />
          </Box>
          <HStack mt={6}>
            <Button
              variant="ghost"
              onClick={() => goBack()}
              leftIcon={<ArrowBackIcon />}
            >
              <Translate label={FormLocale.BACK} />
            </Button>
            <Spacer />
            <Button
              onClick={() => {
                if (formRef) {
                  formRef.current?.dispatchEvent(new Event('submit', {
                    cancelable: true,
                    bubbles: true,
                  }));
                }
              }}
              isLoading={formIsLoading}
            >
              <Translate label={FormLocale.SAVE} />
            </Button>
          </HStack>
        </SectionContainer>
      )}
    </>
  );
};

export default UserDetail;
