import React, {
  useEffect, useMemo,
} from 'react';
import {
  Box, Text, Textarea,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  MeepoTable, Toast, translate, Translate,
} from '@scaut-sro/meepo';
import {
  CompletedFormEditAttr,
  CompletedFormEditProps,
  CompletedFormEditTableData,
} from '../CandidateScreeningDetail.model';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import TableLocale from '../../../core/localization/translations/Table.locale';
import FormLocale from '../../../core/localization/translations/Form.locale';
import { useUpdateCompletedData } from '../../../build/generated-sources/service/MutationService';
import CandidateScreeningDetailLocale from '../CandidateScreeningDetail.locale';

const CompletedFormEditForm: React.FunctionComponent<CompletedFormEditProps> = (props) => {
  const {
    candidateScreeningId, completedData, loadingSetter, formRef, onClose, refreshData,
  } = props;
  const { language } = useGetUser();

  const {
    handleSubmit, register, setValue,
  } = useForm<Partial<CompletedFormEditAttr>>({
  });

  useEffect(() => {
    if (completedData) {
      Object.keys(completedData).forEach((item) => {
        if (typeof completedData[item].value !== 'string'
            && typeof completedData[item].value !== 'number'
          && typeof completedData[item].value !== 'boolean') {
          setValue(completedData[item].id, JSON.stringify(completedData[item].value));
        } else {
          setValue(completedData[item].id, completedData[item].value);
        }
      });
    }
  }, [completedData, setValue]);

  const rowData: CompletedFormEditTableData[] = useMemo(() => {
    if (completedData) {
      return Object.keys(completedData).map((item) => ({
        id: completedData[item].id,
        localizedName: completedData[item].preloadedTranslations[completedData[item].label]?.localizationStrings?.[language],
        value: (
          <>
            <Textarea
              minHeight="40px"
              {...register(completedData[item].id)}
            />
          </>
        ),
      }));
    }
    return [];
  }, [completedData, language, register]);

  const [mutateUpdateCompletedData,
    mutationUpdateCompletedDataOptions] = useUpdateCompletedData({
    success: true,
  }, {
    onCompleted: () => {
      Toast({
        title: translate(FormLocale.SUCCESSFULLY_SAVED, language),
        status: 'success',
      });
      if (onClose) {
        onClose();
      }
      if (refreshData) {
        refreshData();
      }
    },
    onError: () => {
      Toast({
        title: translate(CandidateScreeningDetailLocale.ERROR_GETTING_DATA, language),
        status: 'error',
      });
    },
  });

  useEffect(() => {
    if (loadingSetter) loadingSetter(mutationUpdateCompletedDataOptions.loading);
  }, [loadingSetter, mutationUpdateCompletedDataOptions]);

  const handleCompletedDataFormSubmit: SubmitHandler<CompletedFormEditAttr> = (values) => {
    mutateUpdateCompletedData({
      variables: {
        candidateScreeningId: candidateScreeningId || -1,
        formData: values,
      },
    });
  };

  return (
    <>
      <form
        name="completedDetailEditForm"
        onSubmit={handleSubmit(handleCompletedDataFormSubmit)}
        ref={formRef}
      >
        <Box>
          <MeepoTable
            columns={[
              { id: 'id', label: translate(CandidateScreeningDetailLocale.DYNAMIC_FIELD_ID, language) },
              { id: 'localizedName', label: translate(CandidateScreeningDetailLocale.DYNAMIC_FIELD_LOCALIZATION, language) },
              { id: 'value', label: translate(CandidateScreeningDetailLocale.DYNAMIC_FIELD_VALUE, language) },
            ]}
            data={rowData}
            noData={<Text><Translate label={TableLocale.NO_DATA} /></Text>}
          />
        </Box>
      </form>
    </>
  );
};
export default CompletedFormEditForm;
