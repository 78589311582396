import { FileUploadHandlerResponse } from '@scaut-sro/meepo/lib/components/FileUpload/FileUpload.model';
import axios from 'axios';
import { ApolloClient } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const uploadProcess = (file: File, client: ApolloClient<any>, token: string): Promise<FileUploadHandlerResponse> => {
  const formData = new FormData();
  formData.append('file', file);
  const filename = file.name;

  const config = {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.post(`${process.env.REACT_APP_API_URL}/dms/deploy/${filename}`, formData, config);
};
