import React, { useState } from 'react';
import {
  MeepoTable, SectionContainer, Toast,
} from '@scaut-sro/meepo';
import { useRouteMatch } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/react';
import Translate from '../../../../components/Translate/Translate';
import TableLocale from '../../../../core/localization/translations/Table.locale';
import { useGetComponentLogs } from '../../../../build/generated-sources/service/QueryService';
import { ProcessInstanceLog } from '../../../../build/generated-sources/dto/ProcessInstanceLog';
import { ComponentLogTableData } from '../Components.model';
import { translate } from '../../../../core/localization/LocaleUtils';
import TasksLocale from '../../Tasks.locale';
import { useGetUser } from '../../../../core/store/reducers/UserSettingsReducer';
import ShowDate from '../../../../components/ShowDate/ShowDate';
import ComponentLogRowDetail from './ComponentLogRowDetail';
import ComponentLogModal from './ComponentLogModal';

export function getComponentLogTableData(data?: ProcessInstanceLog[]): ComponentLogTableData[] {
  if (data) {
    return data.map((componentLog) => {
      const componentLogText = componentLog?.text;
      return {
        id: componentLog.id || '-undefined-',
        detail: componentLog.message ? <ComponentLogRowDetail message={componentLog.message} /> : undefined,
        type: componentLog.logType,
        activityId: componentLog.activityId,
        activityName: componentLog.activityName,
        processDefinitionId: componentLog.processDefinitionId,
        created: <ShowDate date={componentLog?.created} time />,
        detailButton: (
          <ComponentLogModal
            disabled={!componentLog.id}
            text={componentLogText}
            processInstanceLogId={componentLog.id || -1}
          />
        ),
      };
    });
  }
  return [];
}

const ComponentLog: React.FunctionComponent = () => {
  const [list, setList] = useState<ComponentLogTableData[]>([]);
  const { language } = useGetUser();
  const { params } = useRouteMatch<{ id: string }>();
  const componentId = Number(params.id);

  const { loading } = useGetComponentLogs({
    componentId: true,
    logs: {
      text: true,
      message: true,
      id: true,
      activityName: true,
      processDefinitionId: true,
      created: true,
      activityId: true,
      logType: true,
    },
  }, {
    onCompleted: (response) => {
      const onCompleteData = getComponentLogTableData(response.componentLogs.logs);
      if (response.componentLogs.logs === null) {
        Toast({
          title: translate(TasksLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      }
      // eslint-disable-next-line max-len
      setList((prev) => [...prev, ...onCompleteData].sort((one: any, two: any) => new Date(two.created.props.date).getTime() - new Date(one.created.props.date).getTime()));
    },
    onError: () => {
      Toast({
        title: translate(TasksLocale.ERROR_GETTING_DATA, language),
        status: 'error',
      });
    },
    variables: {
      componentId: componentId || -1,
    },
  });

  return (
    <>
      <SectionContainer>
        <Box overflowX="auto">
          <Box minW="600px">
            <Box overflowX="auto">
              <Box minW="600px">
                <MeepoTable
                  columns={[
                    {
                      id: 'type',
                      label: translate(TasksLocale.TYPE, language),
                      minW: '200px',
                    },
                    {
                      id: 'activityId',
                      label: translate(TasksLocale.ACTIVITY_ID, language),
                      minW: '100px',
                    },
                    {
                      id: 'activityName',
                      label: translate(TasksLocale.ACTIVITY_NAME, language),
                      alignData: 'center',
                      alignLabel: 'center',
                    },
                    {
                      id: 'processDefinitionId',
                      label: translate(TasksLocale.PROCESS_DEFINITION_ID, language),
                      alignData: 'center',
                      alignLabel: 'center',
                    },
                    {
                      id: 'created',
                      label: translate(TasksLocale.CREATED, language),
                      alignData: 'center',
                      alignLabel: 'center',
                    },
                    {
                      id: 'detailButton',
                      label: translate(TasksLocale.LOG_VARIABLES, language),
                      minW: '150px',
                      boxProps: { w: '200px' },
                      alignLabel: 'center',
                    },
                  ]}
                  data={list}
                  isLoading={loading}
                  noData={<Text><Translate label={TableLocale.NO_DATA} /></Text>}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </SectionContainer>
    </>
  );
};

export default ComponentLog;
