import React, { useMemo, useState } from 'react';
import {
  Language,
  MeepoTable, SectionContainer, Toast, Translate,
} from '@scaut-sro/meepo';
import {
  Box, Button, Text,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useGetGetInvoices } from '../../../../build/generated-sources/service/QueryService';
import { Direction } from '../../../../build/generated-sources/enum/Direction';
import { useGetUser } from '../../../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../../../core/localization/LocaleUtils';
import TableLocale from '../../../../core/localization/translations/Table.locale';
import { ClientInvoicesProps, ClientInvoicesTableData, ClientInvoicesTableFilterType } from './ClientInvoices.model';
import { ClientTableFilterAllFilterType } from '../../Clients.model';
import ClientInvoicesTableFilter from './ClientInvoicesTableFilter';
import ClientInvoicesLocale from './ClientInvoices.locale';
import UsersLocale from '../../../Users/Users.locale';
import { Invoice } from '../../../../build/generated-sources/dto/Invoice';
import InvoiceStateBadge from '../../../../components/InvoiceStateBadge/InvoiceStateBadge';

export function getClientInvoicesTableData(openClientInvoiceDetail: (invoiceId?: number) => void, language: Language,
  data: Invoice[] | undefined): ClientInvoicesTableData[] {
  if (data) {
    return data.map((invoice) => ({
      id: invoice?.id || -1,
      state: <InvoiceStateBadge state={invoice.state || undefined} />,
      issueDate: invoice?.issueDate,
      dueDate: invoice?.dueDate,
      buttons: (
        <Button
          variant="link"
          w="100%"
          onClick={() => openClientInvoiceDetail(invoice?.id)}
        >
          <Translate label={UsersLocale.USER_GO_TO_DETAIL} />
        </Button>
      ),
    }));
  }
  return [];
}

const ClientInvoices:React.FunctionComponent<ClientInvoicesProps> = (props) => {
  const [tableFilter, setTableFilter] = useState<ClientInvoicesTableFilterType>({});
  const [allFilters, setAllFilter] = useState<ClientInvoicesTableFilterType>({});
  const { clientId, filters } = props;
  const [list, setList] = useState<ClientInvoicesTableData[]>([]);
  const [page, setPage] = useState<number>(0);
  const [sorting, setSorting] = useState<{ sortBy?: string, sortDirection?: Direction }>({});
  const size = 100;
  const history = useHistory();
  const { language } = useGetUser();

  const openClientInvoiceDetail = (invoiceId?: number) => {
    if (invoiceId) {
      history.push(`/invoice/${invoiceId}`);
    } else {
      history.push('/404');
    }
  };

  const { loading } = useGetGetInvoices({
    content: {
      id: true,
      issueDate: true,
      dueDate: true,
      state: true,
      price: true,
      primaryOrders: {
        id: true,
      },
    },
  }, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      const onCompleteData = getClientInvoicesTableData(openClientInvoiceDetail, language, response.getInvoices.content);
      if (response.getInvoices === null) {
        Toast({
          title: translate(ClientInvoicesLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      }
      setList((prev) => [...prev, ...onCompleteData]);
    },
    onError: () => {
      Toast({
        title: translate(ClientInvoicesLocale.ERROR_GETTING_DATA, language),
        status: 'error',
      });
    },
    variables: {
      clientId,
      filter: {
        issueDateFrom: allFilters.issueDateFrom && allFilters.issueDateFrom.length
          ? allFilters.issueDateFrom : undefined,
        issueDateTo: allFilters.issueDateTo && allFilters.issueDateTo.length ? allFilters.issueDateTo : undefined,
        dueDateFrom: allFilters.dueDateFrom && allFilters.dueDateFrom.length
          ? allFilters.dueDateFrom : undefined,
        dueDateTo: allFilters.dueDateTo && allFilters.dueDateTo.length ? allFilters.dueDateTo : undefined,
        states: allFilters.states && allFilters.states.length ? allFilters.states : undefined,
      },
      paging: {
        page,
        size,
        sortBy: sorting.sortBy,
        sortDirection: sorting.sortDirection,
      },
    },
  });

  useMemo(() => {
    const newAllFilters: ClientTableFilterAllFilterType = {
      ...filters,
      ...tableFilter,
    };
    setAllFilter(newAllFilters);
    setPage(0);
  }, [filters, tableFilter]);

  return (
    <>
      <SectionContainer>
        <Box overflowX="auto">
          <Box minW="600px">
            <Box mb={20}>
              <ClientInvoicesTableFilter
                filter={tableFilter}
                onFilterChange={(newTableFilter) => {
                  setTableFilter(newTableFilter);
                }}
              />
            </Box>
            <Box overflowX="auto">
              <Box minW="600px">
                <MeepoTable
                  columns={[
                    {
                      id: 'state',
                      label: translate(ClientInvoicesLocale.STATE, language),
                      alignLabel: 'center',
                      boxProps: {
                        w: '220px',
                      },
                    },
                    {
                      id: 'issueDate',
                      label: translate(ClientInvoicesLocale.ISSUE_DATE, language),
                      minW: '100px',
                    },
                    {
                      id: 'dueDate',
                      label: translate(ClientInvoicesLocale.DUE_DATE, language),
                      minW: '100px',
                    },
                    {
                      id: 'buttons',
                      label: translate(ClientInvoicesLocale.GO_TO_DETAIL, language),
                      minW: '150px',
                      boxProps: { w: '200px' },
                      alignLabel: 'center',
                    },
                  ]}
                  data={list}
                  isLoading={loading}
                  noData={<Text><Translate label={TableLocale.NO_DATA} /></Text>}
                  setPage={() => {
                    setPage((prev) => prev + 1);
                  }}
                  onSort={(newSorting) => {
                    const direction: Direction | undefined = newSorting.type === 'asc'
                      ? Direction.ASC
                      : newSorting.type === 'desc'
                        ? Direction.DESC
                        : undefined;
                    setSorting({
                      sortBy: newSorting.column,
                      sortDirection: direction,
                    });
                  }}
                  activeSorting={{
                    column: sorting.sortBy,
                    type: sorting.sortDirection === Direction.ASC
                      ? 'asc'
                      : sorting.sortDirection === Direction.DESC
                        ? 'desc'
                        : 'none',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </SectionContainer>
    </>
  );
};

export default ClientInvoices;
