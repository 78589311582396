import * as React from 'react';
import { useMemo } from 'react';
import {
  Box, Flex, Heading, Icon,
} from '@chakra-ui/react';
import {
  H1, H2, MeepoTag, TagArea,
} from '@scaut-sro/meepo';
import { FiUser, FiUsers } from 'react-icons/fi';
import { IoShapesOutline } from 'react-icons/io5';
import { AiOutlineNumber, AiOutlineTeam } from 'react-icons/ai';
import { translate } from '../../../core/localization/LocaleUtils';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import CandidateDetail from '../../../components/CandidateDetail/CandidateDetail';
import { ConfirmationProps } from './Confirmation.model';
import Translate from '../../../components/Translate/Translate';
import TotalPrice from '../../../components/TotalPrice/TotalPrice';
import ListOfServices from '../../../components/ListOfServices/ListOfServices';
import NewCheckLocale from '../NewCheck.locale';
import { OrderType } from '../../../build/generated-sources/enum/OrderType';
import ChecksLocale from '../../Checks/Checks.locale';
import DetailHeader from '../../../components/DetailHeader/DetailHeader';

const Confirmation: React.FunctionComponent<ConfirmationProps> = (props) => {
  const { candidates, orderData } = props;
  const { language } = useGetUser();

  const screeningData = useMemo(() => {
    const packages: number[] = [];
    const definitions: number[] = [];
    let price: number = 0;
    orderData.suborderProducts?.[0].products?.forEach((product) => {
      product.screeningPackages?.forEach((pkg) => {
        packages.push(pkg.id || -1);
        price += pkg.price || 0;
      });
      product.screeningDefinitions?.forEach((def) => {
        definitions.push(def.id || -1);
        price += def.price || 0;
      });
    });
    return { packages, definitions, price };
  }, [orderData]);

  return (
    <>
      <DetailHeader>
        <H1 m={0}><Translate label={NewCheckLocale.NEW_CHECK} /></H1>
      </DetailHeader>
      <Box mb={6}>
        <Flex alignItems="center">
          {orderData.order?.type === OrderType.MULTIPLE
            ? <Icon as={FiUsers} fontSize={50} />
            : <Icon as={FiUser} fontSize={50} />}
          <Box ml={4}>
            <Heading size="lg" color="primaryBase" mb={3}>{orderData.order?.name}</Heading>
            <TagArea>
              { orderData.order?.type ? (
                <MeepoTag
                  leftIcon={IoShapesOutline}
                  label={
                    orderData.order?.type === OrderType.MULTIPLE
                      ? translate(ChecksLocale.MULTIPLE_PEOPLE, language)
                      : translate(ChecksLocale.SINGLE_CHECK, language)
                  }
                  tooltip={translate(NewCheckLocale.TYPE, language)}
                />
              )
                : undefined }
              {orderData.order?.team?.name ? (
                <MeepoTag
                  leftIcon={AiOutlineTeam}
                  label={orderData.order?.team?.name}
                  tooltip={translate(NewCheckLocale.TEAM, language)}
                />
              ) : undefined}
              {orderData.order?.clientOrderCustomId ? (
                <MeepoTag
                  leftIcon={AiOutlineNumber}
                  label={orderData.order?.clientOrderCustomId}
                  tooltip={translate(NewCheckLocale.ORDER_CUSTOM_ID, language)}
                />
              ) : undefined}
            </TagArea>
          </Box>
        </Flex>
      </Box>
      <Box mb={8}>
        <CandidateDetail candidates={candidates} />
      </Box>
      <H2 mb={6} mt={8}><Translate label={NewCheckLocale.SERVICES} /></H2>
      <Box mb={8}>
        <ListOfServices data={orderData.suborderProducts?.[0].products} />
      </Box>
      <TotalPrice price={screeningData.price * candidates.length} />
    </>
  );
};

export default Confirmation;
