import React from 'react';
import {
  Box, Divider, FormControl, SimpleGrid, Table, Tbody, Td, Tr,
} from '@chakra-ui/react';
import { H4 } from '@scaut-sro/meepo';
import { TranslationsDetailReadOnlyProps } from '../Translations.model';
import Translate from '../../../components/Translate/Translate';
import TranslationsLocale from '../Translations.locale';
import TranslationUsageDetail from './TranslationUsageDetail';
import { Language } from '../../../build/generated-sources/enum/Language';

const TranslationDetailReadOnly: React.FunctionComponent<TranslationsDetailReadOnlyProps> = (props) => {
  const {
    translation,
  } = props;

  return (
    <>
      <Divider mb={6} />
      <Box p={3} pt={0}>
        <SimpleGrid columns={[1, 1, 2]} spacing={6} mb={7}>
          <Box>
            <FormControl mb={6}>
              <Box>
                <H4>{translation.name}</H4>
              </Box>
            </FormControl>
            <Divider mb={6} />
            <Box>
              <H4><Translate label={TranslationsLocale.USAGES} /></H4>
            </Box>
            <TranslationUsageDetail translation={translation} />
          </Box>
          <Box>
            <Box>
              <H4><Translate label={TranslationsLocale.TRANSLATIONS} /></H4>
            </Box>
            <SimpleGrid columns={[1]} gap={4} mt={4}>
              <Table size="sm">
                <Tbody>
                  {Object.keys(Language).map((lang, index) => (
                    <>
                      <Tr
                        borderTopLeftRadius={index === 0 ? '10px' : '0px'}
                        borderBottomLeftRadius={index === Object.keys(Language).length - 1 ? '10px' : '0px'}
                      >
                        <Td
                          background="#cfd2d575"
                          borderTopLeftRadius={index === 0 ? '10px' : '0px'}
                          borderBottomLeftRadius={index === Object.keys(Language).length - 1 ? '10px' : '0px'}
                          width="8%"
                        >
                          <b>{lang}</b>
                        </Td>
                        <Td>
                          {
                                                        // @ts-ignore
                                                        translation.localizationStrings?.[lang]
                                                    }
                        </Td>
                      </Tr>
                    </>
                  ))}
                </Tbody>
              </Table>
            </SimpleGrid>
          </Box>
        </SimpleGrid>
      </Box>
    </>
  );
};

export default TranslationDetailReadOnly;
