import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  Box,
  Button, ButtonGroup,
  Flex, Icon, Spacer,
} from '@chakra-ui/react';
import { H1, SectionContainer, Toast } from '@scaut-sro/meepo';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { AiOutlineFilePdf, AiOutlineMail } from 'react-icons/ai';
import { useKeycloak } from '@react-keycloak/web';
import FormLocale from '../../../../core/localization/translations/Form.locale';
import LoadingScreen from '../../../LoadingScreen/LoadingScreen';
import DetailHeader from '../../../../components/DetailHeader/DetailHeader';
import Translate from '../../../../components/Translate/Translate';
import { useGetUser } from '../../../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../../../core/localization/LocaleUtils';
import {
  useLazyGetGetInvoiceDetail,
} from '../../../../build/generated-sources/service/QueryService';
import ClientsLocale from '../../Clients.locale';
import ClientInvoiceDetailForm from './ClientInvoiceDetailForm';
import { InvoiceDetail } from '../../../../build/generated-sources/dto/InvoiceDetail';
import ClientInvoicesLocale from './ClientInvoices.locale';
import PayInvoiceModal from './PayInvoiceModal/PayInvoiceModal';
import {
  UseGetGetInvoiceDetailData,
} from '../../../../build/generated-sources/service/QueryServiceModel';
import { InvoiceState } from '../../../../build/generated-sources/enum/InvoiceState';
import InvoiceStateBadge from '../../../../components/InvoiceStateBadge/InvoiceStateBadge';
import { useResendInvoiceEmail } from '../../../../build/generated-sources/service/MutationService';
import { downloadFile } from '../../../../core/service/queries/FileUploadService';

const ClientInvoiceDetail: React.FunctionComponent = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const invoiceId = Number.isNaN(+params.id);
  const { language } = useGetUser();
  const history = useHistory();
  const [data, setData] = useState<InvoiceDetail | undefined>(undefined);
  const keycloak = useKeycloak();

  const onFetchCompleted = (response: UseGetGetInvoiceDetailData) => {
    if (response.getInvoiceDetail === null) {
      Toast({
        title: translate(ClientsLocale.ERROR_GETTING_DATA, language),
        status: 'error',
      });
    }
    setData(response.getInvoiceDetail);
  };

  const [userHandle, { refetch }] = useLazyGetGetInvoiceDetail(
    {
      invoice: {
        id: true,
        issueDate: true,
        dueDate: true,
        state: true,
        price: true,
        primaryOrders: {
          id: true,
        },
      },
      fileMetadata: {
        id: true,
        filename: true,
        size: true,
      },
      emailSent: true,
      emailSentAt: true,
    },
    {
      onCompleted: onFetchCompleted,
      onError: () => {
        Toast({
          title: translate(ClientsLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      },
      variables: {
        invoiceId: +params.id,
      },
    },
  );
  useEffect(() => {
    if (!invoiceId) {
      userHandle({
        variables: {
          invoiceId: +params.id,
        },
      });
    }
  }, [userHandle, params.id, invoiceId]);

  const goBackToClients = () => {
    history.goBack();
  };

  const refreshData = () => {
    if (refetch) {
      refetch().then((response) => onFetchCompleted(response.data));
    }
  };

  const downloadInvoice = () => {
    if (keycloak.keycloak.token) {
      downloadFile(keycloak.keycloak.token, `${process.env.REACT_APP_API_URL}/dms/getFile/${data?.fileMetadata?.id}`);
    }
  };

  const [mutateResendInvoiceEmail] = useResendInvoiceEmail({
    success: true,
  }, {
    onCompleted: () => {
      Toast({
        title: translate(FormLocale.SUCCESSFULLY_SAVED, language),
        status: 'success',
      });
      refreshData();
    },
    onError: () => {
      Toast({
        title: translate(ClientsLocale.ERROR_GETTING_DATA, language),
        status: 'error',
      });
    },
  });

  const resendInvoiceEmail = () => {
    mutateResendInvoiceEmail({
      variables: {
        invoiceId: +params.id,
      },
    });
  };

  return (
    <>
      {!data ? <LoadingScreen /> : (
        <SectionContainer>
          <Box overflowX="auto">
            <Box>
              <DetailHeader>
                <H1><Translate label={ClientInvoicesLocale.INVOICE_DETAIL} /></H1>
                <Flex justifyContent={['flex-start', 'flex-start', 'flex-start', 'flex-end']}>
                  <Flex alignItems="center" w="200px" minW="fit-content">
                    <Box w="100%">
                      <InvoiceStateBadge state={data?.invoice?.state || undefined} />
                    </Box>
                  </Flex>
                  <Flex alignItems="center" w="200px" minW="fit-content">
                    { data?.invoice?.state === InvoiceState.OPEN ? (
                      <Box w="100%">
                        <PayInvoiceModal invoiceId={data?.invoice?.id} refreshData={refreshData} />
                      </Box>
                    ) : undefined}
                  </Flex>
                </Flex>

              </DetailHeader>
              <Box>
                <ClientInvoiceDetailForm
                  invoice={data}
                  downloadInvoice={downloadInvoice}
                />
              </Box>
              <Flex mt={8}>
                <Button
                  variant="ghost"
                  onClick={() => goBackToClients()}
                  leftIcon={<ArrowBackIcon />}
                >
                  <Translate label={FormLocale.BACK} />
                </Button>
                <Spacer />
                <ButtonGroup>
                  <Button
                    leftIcon={<Icon as={AiOutlineMail} />}
                    onClick={() => {
                      resendInvoiceEmail();
                    }}
                  >
                    <Translate label={ClientInvoicesLocale.RESEND_EMAIL} />
                  </Button>
                  <Button
                    leftIcon={<Icon as={AiOutlineFilePdf} />}
                    onClick={() => {
                      downloadInvoice();
                    }}
                  >
                    <Translate label={FormLocale.DOWNLOAD} />
                  </Button>
                </ButtonGroup>
              </Flex>
            </Box>
          </Box>
        </SectionContainer>
      )}
    </>
  );
};

export default ClientInvoiceDetail;
