import React, { useMemo, useState } from 'react';
import {
  H1, MeepoTable, SectionContainer, Toast,
} from '@scaut-sro/meepo';
import { useHistory } from 'react-router-dom';
import {
  Box, Button, Checkbox,
  Flex,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { PageInfo } from '@scaut-sro/meepo/lib/components/MeepoTable/MeepoTable.model';
import {
  useGetScreeningPackages,
} from '../../../build/generated-sources/service/QueryService';
import { Direction } from '../../../build/generated-sources/enum/Direction';
import { translate } from '../../../core/localization/LocaleUtils';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import TableLocale from '../../../core/localization/translations/Table.locale';
import Translate from '../../../components/Translate/Translate';
import DetailHeader from '../../../components/DetailHeader/DetailHeader';
import { Language } from '../../../build/generated-sources/enum/Language';
import { ScreeningPackage } from '../../../build/generated-sources/dto/ScreeningPackage';
import {
  CataloguePackagesAllFilterType,
  CataloguePackagesProps,
  CataloguePackagesTableData,
  CataloguePackagesTableFilterType,
} from './CataloguePackages.model';
import CataloguePackagesLocale from './CataloguePackages.locale';
import CataloguePackagesTableFilter from './CataloguePackagesTableFilter';
import CataloguePackagesModal from './CataloguePackagesModal';

export function getCataloguePackagesTableData(openScreeningDetail: (screeningPackageId?: number) => void, language: Language,
  data: ScreeningPackage[] | undefined): CataloguePackagesTableData[] {
  if (data) {
    return data.map((screeningPackage) => ({
      id: screeningPackage.id || '-undefined-',
      internalName: screeningPackage.internalName,
      active: <Checkbox isChecked={screeningPackage.active} isDisabled />,
      country: screeningPackage.country?.translation?.localizationStrings?.[language],
      detailButton: (
        <Button variant="link" w="100%" onClick={() => openScreeningDetail(screeningPackage?.id)}>
          <Translate label={CataloguePackagesLocale.TABLE_DETAIL_GO_TO_DETAIL} />
        </Button>
      ),
    }));
  }
  return [];
}

const CataloguePackages: React.FunctionComponent<CataloguePackagesProps> = (props) => {
  const [tableFilter, setTableFilter] = useState<CataloguePackagesTableFilterType>({});
  const [allFilters, setAllFilter] = useState<CataloguePackagesAllFilterType>({});
  const { filters } = props;
  const [list, setList] = useState<CataloguePackagesTableData[]>([]);
  const history = useHistory();
  const { language } = useGetUser();
  const [sorting, setSorting] = useState<{ sortBy?: string, sortDirection?: Direction }>({});
  const [pageInfo, setPageInfo] = useState<PageInfo>({ pageNumber: 0, pageSize: 20, totalPages: 0 });

  const openScreeningDetail = (screeningPackageId?: number) => {
    if (screeningPackageId) {
      history.push(`/catalogue/package/${screeningPackageId}`);
    } else {
      history.push('/404');
    }
  };

  const { loading } = useGetScreeningPackages(
    {
      pageInfo: {
        pageSize: true,
        pageNumber: true,
        numberOfElements: true,
        totalElements: true,
        totalPages: true,
      },
      content: {
        id: true,
        internalName: true,
        active: true,
        country: {
          translation: { localizationStrings: true },
        },
      },
    }, {
      onError: () => {
        Toast({
          title: translate(CataloguePackagesLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      },
      variables: {
        paging: {
          page: pageInfo.pageNumber,
          size: pageInfo.pageSize,
          sortBy: sorting.sortBy,
          sortDirection: sorting.sortDirection,
        },
        filter: {
          search: allFilters.fulltext && allFilters.fulltext.length ? allFilters.fulltext : undefined,
          active: allFilters.active && allFilters.active ? allFilters.active : undefined,
          countryIds: allFilters.countryIds && allFilters.countryIds.length ? allFilters.countryIds : undefined,
        },
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        const onCompleteData = getCataloguePackagesTableData(openScreeningDetail, language,
          response.screeningPackages.content);
        setList(onCompleteData);
        setPageInfo({
          pageSize: response.screeningPackages.pageInfo?.pageSize,
          pageNumber: response.screeningPackages.pageInfo?.pageNumber || 0,
          totalPages: response.screeningPackages.pageInfo?.totalPages || 0,
          numberOfElements: response.screeningPackages.pageInfo?.numberOfElements,
          totalElements: response.screeningPackages.pageInfo?.totalElements,
        });
      },
    },
  );

  useMemo(() => {
    const newAllFilters: CataloguePackagesAllFilterType = {
      ...filters,
      ...tableFilter,
    };
    setAllFilter(newAllFilters);
  }, [filters, tableFilter]);

  return (
    <>
      <SectionContainer>
        <DetailHeader>
          <H1><Translate label={CataloguePackagesLocale.CATALOGUE_PACKAGES} /></H1>
        </DetailHeader>
        <Box mb={20}>
          <CataloguePackagesTableFilter
            filter={tableFilter}
            onFilterChange={(newTableFilter) => {
              setTableFilter(newTableFilter);
            }}
          />
          <Spacer />
          <Flex mt={6} justifyContent="flex-end">
            <CataloguePackagesModal />
          </Flex>

        </Box>
        <Box overflowX="auto">
          <Box minW="600px">
            <MeepoTable
              columns={[
                { id: 'internalName', label: translate(CataloguePackagesLocale.INTERNAL_NAME, language), minW: '200px' },
                { id: 'country', label: translate(CataloguePackagesLocale.COUNTRY, language), boxProps: { w: '180px' } },
                {
                  id: 'active',
                  label: translate(CataloguePackagesLocale.ACTIVE, language),
                  alignData: 'center',
                  alignLabel: 'center',
                },
                {
                  id: 'detailButton',
                  label: translate(CataloguePackagesLocale.PACKAGE_DETAIL, language),
                  minW: '150px',
                  boxProps: { w: '200px' },
                  alignLabel: 'center',
                },
              ]}
              data={list}
              isLoading={loading}
              noData={<Text><Translate label={TableLocale.NO_DATA} /></Text>}
              pageInfo={pageInfo}
              setPage={(newPageNumber, newPageSize) => {
                setPageInfo({
                  ...pageInfo,
                  pageNumber: newPageNumber,
                  pageSize: newPageSize,
                });
              }}
              onSort={(newSorting) => {
                const direction: Direction | undefined = newSorting.type === 'asc'
                  ? Direction.ASC
                  : newSorting.type === 'desc'
                    ? Direction.DESC
                    : undefined;
                setSorting({
                  sortBy: newSorting.column,
                  sortDirection: direction,
                });
              }}
              activeSorting={{
                column: sorting.sortBy,
                type: sorting.sortDirection === Direction.ASC
                  ? 'asc'
                  : sorting.sortDirection === Direction.DESC
                    ? 'desc'
                    : 'none',
              }}
            />
          </Box>
        </Box>
      </SectionContainer>
    </>
  );
};

export default CataloguePackages;
