import React, { PropsWithChildren } from 'react';
import { FlexBadge } from '@scaut-sro/meepo';
import {
  InvoiceStateBadgeData, InvoiceStateBadgeProps, InvoiceStatesLocale,
} from './InvoiceStateBadge.model';
import { useTranslation } from '../../core/localization/LocaleUtils';

function getStateBadgeData(state?: string, size?: string): InvoiceStateBadgeData {
  const s = !state ? 'default' : state;
  const stateBadgeData = {
    locale: InvoiceStatesLocale.UNDEFINED,
    color: 'gray',
    fontSize: size || 'md',
    variant: 'outline',
  };
  switch (s) {
    case 'OPEN': {
      return {
        ...stateBadgeData,
        locale: InvoiceStatesLocale.OPEN,
        color: 'cyan',
      };
    }
    case 'PAID': {
      return {
        ...stateBadgeData,
        locale: InvoiceStatesLocale.PAID,
        color: 'green',
      };
    }
    default: {
      return stateBadgeData;
    }
  }
}

const InvoiceStateBadge = (
  props: PropsWithChildren<InvoiceStateBadgeProps>,
) => {
  const { state, size, badgeProps } = props;
  const stateBadgeData = getStateBadgeData(state, size);

  const translation = useTranslation(stateBadgeData.locale || InvoiceStatesLocale.UNDEFINED);
  return (
    <FlexBadge
      {...badgeProps}
      colorScheme={stateBadgeData.color}
      fontSize={stateBadgeData.fontSize}
      variant={stateBadgeData.variant}
    >
      {translation}
    </FlexBadge>
  );
};

export default InvoiceStateBadge;
