import { Translation } from '../../core/localization/Localization.model';

class CountriesLocale {
  public static GO_TO_DETAIL: Translation = { EN: 'Go to detail', CZ: 'Přejít na detail' };
  public static TRANSLATION_NAME: Translation = { EN: 'Localized name', CZ: 'Lokalizované jméno' };
  public static NEW_COUNTRY: Translation = { EN: 'New country', CZ: 'Nová země' };
  public static VISIBLE_BY_DEFAULT: Translation = { EN: 'Visible by default', CZ: 'Ve výchozím nastavení viditelné' };
  public static ACTIVE: Translation = { EN: 'Active', CZ: 'Aktivní' };
  public static YES: Translation = { EN: 'Yes', CZ: 'Ano' };
  public static NO: Translation = { EN: 'No', CZ: 'Ne' };
  public static NEW: Translation = { EN: 'New', CZ: 'Nový' };
  public static COUNTRY_DETAIL: Translation = { EN: 'Country detail', CZ: 'Detail země' };
  public static COUNTRIES: Translation = { EN: 'Countries', CZ: 'Země' };
  public static COUNTRY_ENUMERATION: Translation = { EN: 'Country enumeration', CZ: 'Výčet země' };
  public static ERROR_GETTING_COUNTRIES: Translation = {
    EN: 'There was a problem getting countries.',
    CZ: 'Problém při načitání zemí',
  };
  public static ERROR_DELETING_COUNTRY: Translation = {
    EN: 'There was a problem deleting country.',
    CZ: 'Při mazání země došlo k problému',
  };
  public static ERROR_SAVING_COUNTRY: Translation = {
    EN: 'There was a problem saving country.',
    CZ: 'Při ukládání země došlo k problému',
  };
  public static COUNTRY_DELETED: Translation = {
    EN: 'Country deleted',
    CZ: 'Země smazána',
  };
  public static ARE_YOU_SURE: Translation = {
    EN: 'Are you sure you want to delete country',
    CZ: 'Opravdu chcete smazat země',
  };
  public static ERROR_GETTING_DATA: Translation = {
    EN: 'There was a problem getting data',
    CZ: 'Při získávání dat došlo k problému',
  };
}

export default CountriesLocale;
