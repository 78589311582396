import { Translation } from '../../../core/localization/Localization.model';

class CatalogueScreeningsLocale {
  public static CATALOGUE_SCREENINGS: Translation = { EN: 'Screening management', CZ: 'Správa kontrol' };
  public static SCREENING_DETAIL: Translation = { EN: 'Screening definition detail', CZ: 'Detail definice kontroly' };
  public static TABLE_DETAIL_GO_TO_DETAIL: Translation = { EN: 'Go to detail', CZ: 'Přejít na detail' };
  public static INTERNAL_NAME: Translation = { EN: 'Name', CZ: 'Jméno' };
  public static PRICE: Translation = { EN: 'Price', CZ: 'Cena' };
  public static TRANSLATION_NAME: Translation = { EN: 'Localized name', CZ: 'Lokalizované jméno' };
  public static TRANSLATION_DESCRIPTION: Translation = { EN: 'Localized description', CZ: 'Lokalizovaný popis' };
  public static COUNTRY: Translation = { EN: 'Country', CZ: 'Země' };
  public static COMPONENT_DEFINITIONS: Translation = { EN: 'Components', CZ: 'Komponenty' };
  public static ACTIVE: Translation = { EN: 'Active', CZ: 'Aktivní' };
  public static COUNTABLE: Translation = { EN: 'Countable', CZ: 'Počitatelné' };
  public static COUNT_LIMIT: Translation = { EN: 'Count limit', CZ: 'Limit' };
  public static MODAL_CREATE_SCREENING_TITLE: Translation = { EN: 'New screening', CZ: 'Nová kontrola' };
  public static ERROR_GETTING_COUNTRIES: Translation = {
    EN: 'There was a problem getting countries.',
    CZ: 'Problém při načitání zemí',
  };
  public static ERROR_GETTING_TRANSLATIONS: Translation = {
    EN: 'There was a problem getting translations.',
    CZ: 'Problém při načitání překladů',
  };
  public static ERROR_GETTING_DATA: Translation = {
    EN:
        'There was a problem getting data',
    CZ: 'Při získávání dat došlo k problému',
  };
}

export default CatalogueScreeningsLocale;
