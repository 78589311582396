import React, { useRef, useState } from 'react';
import {
  Button, ButtonGroup, HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent, ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ConfirmationModal, Toast } from '@scaut-sro/meepo';
import { IntegrationDetailProps } from './Components.model';
import IntegrationForm from './IntegrationForm';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import { useFaqDelete } from '../../../build/generated-sources/service/MutationService';
import FaqLocale from '../../Faq/Faq.locale';
import { translate } from '../../../core/localization/LocaleUtils';
import Translate from '../../../components/Translate/Translate';
import FormLocale from '../../../core/localization/translations/Form.locale';
import TasksLocale from '../Tasks.locale';
import { State } from '../../../build/generated-sources/enum/State';

const IntegrationModal: React.FunctionComponent<IntegrationDetailProps> = (props) => {
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const [formIsLoading, setFormIsLoading] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);
  const { language } = useGetUser();
  const { integration, componentState } = props;

  const [mutateDeleteFaq] = useFaqDelete({
    success: true,
  }, {
    onCompleted: () => {
      Toast({
        title: translate(FaqLocale.FAQ_DELETED, language),
        status: 'success',
      });
    },
    onError: () => {
      Toast({
        title: translate(FaqLocale.ERROR_DELETING_QUESTION, language),
        status: 'error',
      });
    },
  });

  const deleteFaq = () => {
    mutateDeleteFaq({
      variables: {
        faqId: integration.id || -1,
      },
    });
    onDeleteClose();
    onModalClose();
  };

  return (
    <>
      <Text color="primaryBase" cursor="pointer" onClick={onModalOpen}>
        <strong><Translate label={TasksLocale.TABLE_DETAIL_GO_TO_DETAIL} /></strong>
      </Text>
      <Modal closeOnOverlayClick={false} size="4xl" isOpen={isModalOpen} onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text><Translate label={TasksLocale.MODAL_EDIT_INTEGRATION} /></Text>
            <ConfirmationModal
              message={translate(FaqLocale.ARE_YOU_SURE, language)}
              yes={translate(FaqLocale.YES, language)}
              no={translate(FaqLocale.NO, language)}
              name={`${integration?.id}?`}
              modalFunction={deleteFaq}
              isOpen={isDeleteOpen}
              onClose={onDeleteClose}
              onOpen={onDeleteOpen}
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <IntegrationForm
              loadingSetter={(isLoading) => { setFormIsLoading(isLoading); }}
              formRef={formRef}
              integration={integration}
            />
          </ModalBody>
          <ModalFooter>
            <HStack>
              <ButtonGroup>
                <Button
                  onClick={() => {
                    if (formRef) {
                      formRef.current?.dispatchEvent(new Event('submit', {
                        cancelable: true,
                        bubbles: true,
                      }));
                      onModalClose();
                    }
                  }}
                  isLoading={formIsLoading}
                  isDisabled={componentState !== State.CANNOT_COMPLETE}
                >
                  <Translate label={FormLocale.SAVE} />
                </Button>
                <Button variant="ghost" colorScheme="red" onClick={onModalClose}>
                  <Translate label={FormLocale.CANCEL} />
                </Button>
              </ButtonGroup>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default IntegrationModal;
