import React from 'react';
import {
  Box, Text,
} from '@chakra-ui/react';
import { Currency, getCurrencyLocalizationFromLanguage } from '@scaut-sro/meepo';
import { TotalPriceProps } from './PrepaidBalance. model';
import Translate from '../Translate/Translate';
import PrepaidBalanceLocale from './PrepaidBalance.locale';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';

// TODO: currency should be taken from redux - client setup

const PrepaidBalance: React.FunctionComponent<TotalPriceProps> = (props) => {
  const { balance } = props;
  const { language } = useGetUser();

  return (
    <>
      <Text fontSize="md">
        <Translate label={PrepaidBalanceLocale.TOTAL_BALANCE} />
      </Text>
      <Box>
        <Box fontSize="2xl">
          <Currency amount={balance} localization={getCurrencyLocalizationFromLanguage(language)} />
        </Box>
      </Box>
    </>
  );
};

export default PrepaidBalance;
