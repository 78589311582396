import React from 'react';
import {
  Box, Button, Center, Divider, Flex, GridItem, Icon, SimpleGrid, Spacer, Text, Wrap, WrapItem,
} from '@chakra-ui/react';
import {
  H4, IconBadge, Note, ReadOnlyInput,
} from '@scaut-sro/meepo';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { FiPackage, FiShare } from 'react-icons/fi';
import { CheckIcon, CloseIcon, TimeIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { SingleCheckDetailProps } from './Checks.model';
import Translate from '../../components/Translate/Translate';
import ChecksLocale from './Checks.locale';
import TableLocale from '../../core/localization/translations/Table.locale';
import { showDate } from '../../core/date/showDate';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../core/localization/LocaleUtils';
import { Result } from '../../build/generated-sources/enum/Result';
import FormLocale from '../../core/localization/translations/Form.locale';
import { downloadFile } from '../../core/service/queries/FileUploadService';

const SingleCheckDetail: React.FunctionComponent<SingleCheckDetailProps> = (props) => {
  const {
    candidateScreening, orderId, screeningPackages, anonymizationDate,
  } = props;
  const { language } = useGetUser();
  const history = useHistory();
  const keycloak = useKeycloak();

  const downloadReport = () => {
    if (keycloak.keycloak.token) {
      downloadFile(keycloak.keycloak.token, `${process.env.REACT_APP_API_URL}/dms/downloadReport/${candidateScreening?.id}`);
    }
  };

  return (
    <>
      {candidateScreening ? (
        <Box p={3} pt={0}>
          <Divider mb={6} />
          <SimpleGrid columns={[1, 1, 3, 3, 3, 3]} gap={6}>
            <GridItem colSpan={[1, 1, 2, 2, 2, 2]}>
              <Flex alignItems="center">
                <H4><Translate label={ChecksLocale.TABLE_DETAIL_SCREENINGS} /></H4>
                <Wrap ml={4}>
                  { screeningPackages && screeningPackages.map((screeningPackage) => (
                    <WrapItem key={`${screeningPackage.id}_${candidateScreening?.id}`}>
                      <IconBadge
                        icon={<FiPackage />}
                        colorScheme="orange"
                      >
                        {screeningPackage.name?.localizationStrings?.[language]}
                      </IconBadge>
                    </WrapItem>
                  ))}
                </Wrap>
              </Flex>
              <SimpleGrid columns={[1, 1, 2, 2, 2, 2]} gap={3} mt={6}>
                {candidateScreening.screenings?.map((screening) => (
                  <IconBadge
                    key={screening.id}
                    icon={
                      screening.result === Result.CLEAR
                        ? <CheckIcon />
                        : screening.result === Result.FOUND_RESULTS
                          ? <CloseIcon />
                          : <TimeIcon />
                    }
                    bgColor={
                      screening.result === Result.CLEAR
                        ? '#e0f8e8'
                        : screening.result === Result.FOUND_RESULTS
                          ? '#fff4e4'
                          : 'gray.100'
                    }
                    color={
                      screening.result === Result.CLEAR
                        ? '#00ce7d'
                        : screening.result === Result.FOUND_RESULTS
                          ? '#f38400'
                          : 'gray.800'
                    }
                  >
                    {screening.screeningDefinition?.name?.localizationStrings?.[language]}
                  </IconBadge>
                ))}
              </SimpleGrid>
            </GridItem>
            <GridItem>
              <SimpleGrid gap={6}>
                <H4><Translate label={ChecksLocale.TABLE_DETAIL_INFO} /></H4>
                <ReadOnlyInput
                  label={translate(ChecksLocale.TABLE_DETAIL_CANDIDATE_SCREENING_START, language)}
                  noValue={translate(FormLocale.NO_VALUE, language)}
                  value={showDate(language, candidateScreening.processStarted)}
                />
                <ReadOnlyInput
                  label={translate(ChecksLocale.TABLE_DETAIL_CANDIDATE_SCREENING_END, language)}
                  noValue={translate(FormLocale.NO_VALUE, language)}
                  value={showDate(language, candidateScreening.processCompleted)}
                />
              </SimpleGrid>
            </GridItem>
          </SimpleGrid>
          <Divider mt={6} mb={3} />
          <Flex alignItems="center">
            <Note>
              <Translate label={ChecksLocale.TABLE_DETAIL_CANDIDATE_SCREENING_ANONYMIZED} />
              {anonymizationDate ? (
                <span>{`: ${showDate(language, anonymizationDate)}`}</span>
              ) : undefined}
            </Note>
            <Spacer />
            <Button ml={3} variant="ghost" leftIcon={<Icon as={AiOutlineFilePdf} />} onClick={downloadReport}>
              <Translate label={ChecksLocale.TABLE_DETAIL_DOWNLOAD_REPORT} />
            </Button>
            <Button ml={3} variant="ghost" leftIcon={<Icon as={FiShare} />}>
              <Translate label={ChecksLocale.TABLE_DETAIL_SHARE} />
            </Button>
            <Button ml={3} variant="outline" onClick={() => history.push(`/order/${orderId}`)}>
              <Translate label={ChecksLocale.TABLE_DETAIL_GO_TO_RECAP} />
            </Button>
            <Button ml={3} variant="outline" onClick={() => history.push(`/screening/${candidateScreening.id}`)}>
              <Translate label={ChecksLocale.TABLE_DETAIL_GO_TO_DETAIL} />
            </Button>
          </Flex>
        </Box>
      ) : (
        <Center w="100%" p={8}>
          <Text><Translate label={TableLocale.NO_DATA} /></Text>
        </Center>
      )}
    </>
  );
};

export default SingleCheckDetail;
