import { Translation } from '../../../core/localization/Localization.model';

class CatalogueComponentsLocale {
  public static CATALOGUE_COMPONENTS: Translation = { EN: 'Component management', CZ: 'Správa komponent' };
  public static COMPONENT_DETAIL: Translation = { EN: 'Component definition detail', CZ: 'Detail definice komponenty' };
  public static TABLE_DETAIL_GO_TO_DETAIL: Translation = { EN: 'Go to detail', CZ: 'Přejít na detail' };
  public static INTERNAL_NAME: Translation = { EN: 'Name', CZ: 'Jméno' };
  public static ACTIVE: Translation = { EN: 'Active', CZ: 'Aktivní' };
  public static PROCESS_DEFINITION: Translation = { EN: 'Camunda process', CZ: 'Camunda proces' };
  public static REQUIRES_POWER_OF_ATTORNEY: Translation = { EN: 'Requires power of attorney', CZ: 'Vyžaduje plnou moc' };
  public static REQUIRES_AUTHORIZATION: Translation = { EN: 'Requires authorization', CZ: 'Vyžaduje pověření' };
  public static PERFORMABLE_AS_GROUP: Translation = {
    EN: 'Availability in multiple check',
    CZ: 'Dostupnost z hromadné kontroly',
  };
  public static POA_LANGUAGE: Translation = { EN: 'Power of Attorney Language', CZ: 'Jazyk plné moci' };
  public static POA_REPORT: Translation = { EN: 'Power of Attorney Jasper Report', CZ: 'Jasper Report plné moci' };
  public static POA_TEXTS: Translation = { EN: 'Power of attorney list', CZ: 'Seznam požadavků pro plnou moc' };
  public static POA_SETTINGS: Translation = { EN: 'Power of attorney settings', CZ: 'Nastavení plné moci' };
  public static AUTHORIZATION_TEXTS: Translation = { EN: 'Authorization list', CZ: 'Seznam požadavků pro pověření' };
  public static DYNAMIC_FIELDS: Translation = { EN: 'Form fields', CZ: 'Pole formuláře' };
  public static ADD_TEXT: Translation = { EN: 'Add text', CZ: 'Přidat text' };
  public static ADD_FIELD: Translation = { EN: 'Add field', CZ: 'Přidat pole' };
  public static REMOVE_TEXT: Translation = { EN: 'Remove text', CZ: 'Odebrat text' };
  public static REMOVE_FIELD: Translation = { EN: 'Remove field', CZ: 'Odebrat pole' };
  public static INTERNAL_EXPENSES: Translation = { EN: 'Internal expenses', CZ: 'Interní výdaj' };
  public static EXTERNAL_EXPENSES: Translation = { EN: 'External expenses', CZ: 'Externí výdaj' };
  public static FEES: Translation = { EN: 'Fees', CZ: 'Poplatky' };
  public static COUNTRY: Translation = { EN: 'Country', CZ: 'Země' };
  public static AVERAGE_RUNTIME: Translation = { EN: 'Average time', CZ: 'Průměrná doba' };
  public static MODAL_CREATE_COMPONENT_TITLE: Translation = { EN: 'New component', CZ: 'Nová komponenta' };
  public static ERROR_GETTING_PROCESS_DEFINITIONS: Translation = {
    EN: 'There was a problem getting Camunda processes.',
    CZ: 'Problém při načitání procesů',
  };
  public static ERROR_GETTING_COUNTRIES: Translation = {
    EN: 'There was a problem getting countries.',
    CZ: 'Problém při načitání zemí',
  };
  public static POWER_OF_ATTORNEY_YES: Translation = { EN: 'Yes', CZ: 'Ano' };
  public static POWER_OF_ATTORNEY_NO: Translation = { EN: 'No', CZ: 'Ne' };
  public static POWER_OF_ATTORNEY_CONDITIONAL: Translation = { EN: 'Conditional', CZ: 'Podmíněně' };
  public static ERROR_GETTING_DATA: Translation = {
    EN:
        'There was a problem getting data',
    CZ: 'Při získávání dat došlo k problému',
  };
  public static ERROR_COMPONENT_DEFINITION_SAVE_TITLE: Translation = {
    EN:
        'Unable to deactivate component definition',
    CZ: 'Nelze deaktivovat definici komponenty',
  };
  public static ERROR_COMPONENT_DEFINITION_SAVE: Translation = {
    EN:
        'Please remove it from the following screening definitions before attempting deactivation: {0}',
    CZ: 'Před pokusem o deaktivaci jej prosíme odstraňte z následujících definic kontrol: {0}',
  };
}

export default CatalogueComponentsLocale;
