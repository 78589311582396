import { BadgeProps } from '@chakra-ui/layout/dist/types/badge';
import { Translation } from '../../core/localization/Localization.model';

export type InvoiceStateBadgeProps = {
  state?: string;
  size?: 'md' | 'lg';
  badgeProps?: BadgeProps
};

export type InvoiceStateBadgeData = {
  locale?: Translation;
  color?: string;
  width?: number;
  fontSize?: string;
  variant?: string;
};

export class InvoiceStatesLocale {
  public static UNDEFINED: Translation = { EN: 'Processing', CZ: 'Zpracovává se' };
  public static OPEN: Translation = { EN: 'To be paid', CZ: 'K zaplacení' };
  public static PAID: Translation = { EN: 'Paid', CZ: 'Zaplaceno' };
}
