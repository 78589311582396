import { Translation } from '../../core/localization/Localization.model';

class CandidateScreeningDetailLocale {
  public static CANDIDATE_SCREENING: Translation = { EN: 'Candidate Screening', CZ: 'Kontrola kandidáta' };
  public static POWER_OF_ATTORNEY: Translation = { EN: 'Power Of Attorney', CZ: 'Plná moc' };
  public static TASKS: Translation = { EN: 'Tasks', CZ: 'Úkoly' };
  public static GLOBAL_DATA: Translation = { EN: 'Global Data', CZ: 'Obecné informace' };
  public static REPORT_TEXT: Translation = { EN: 'Report', CZ: 'Report' };
  public static CANDIDATE_IDENTITY: Translation = { EN: 'Personal details', CZ: 'Osobní údaje' };
  public static TITLE: Translation = { EN: 'Title', CZ: 'Titul' };
  public static FIRST_NAME: Translation = { EN: 'First name', CZ: 'Jméno' };
  public static LAST_NAME: Translation = { EN: 'Last name', CZ: 'Příjmení' };
  public static DATE_OF_BIRTH: Translation = { EN: 'Date of birth', CZ: 'Datum narození' };
  public static DYNAMIC_FIELD_ID: Translation = { EN: 'ID', CZ: 'ID' };
  public static DYNAMIC_FIELD_LOCALIZATION: Translation = { EN: 'Name', CZ: 'Název' };
  public static DYNAMIC_FIELD_VALUE: Translation = { EN: 'Value', CZ: 'Hodnota' };
  public static NO_MANUAL_TASKS: Translation = {
    EN: 'This screening requires no further information.',
    CZ: 'Tato kontrola v tuto chvíli nevyžaduje žádné další informace.',
  };
  public static BACK_TO_ORDER: Translation = { EN: 'Back to order', CZ: 'Zpět na objednávku' };
  public static RESULT: Translation = { EN: 'Screening result', CZ: 'Výsledek kontroly kandidáta' };
  public static TASK_RESULT: Translation = { EN: 'Task result', CZ: 'Výsledek kontroly' };
  public static CHECK_TABLE_NAME: Translation = { EN: 'Name', CZ: 'Název' };
  public static CHECK_TABLE_RESULT: Translation = { EN: 'Result', CZ: 'Výsledek' };
  public static CHECK_TABLE_STATE: Translation = { EN: 'State', CZ: 'Stav' };
  public static CANDIDATE_SCREENING_INFO: Translation = { EN: 'Candidate screening info', CZ: 'Informace o kontrole' };
  public static ORDERED_SERVICES: Translation = {
    EN: 'Ordered packages and checks',
    CZ: 'Objednané balíčky a dílčí kontroly',
  };
  public static FILLED_IN_INFO: Translation = { EN: 'Filled in information', CZ: 'Doplněné informace' };
  public static EDIT_FILLED_IN_INFO: Translation = { EN: 'Edit information', CZ: 'Editovat informace' };
  public static ORDER_CUSTOM_ID: Translation = { EN: 'Order custom ID', CZ: 'Uživatelské ID' };
  public static TEAM: Translation = { EN: 'Team', CZ: 'Tým' };
  public static TYPE: Translation = { EN: 'Order type', CZ: 'Typ objednávky' };
  public static FULLNAME: Translation = { EN: 'Full name', CZ: 'Celé jméno' };
  public static EMAIL: Translation = { EN: 'Email', CZ: 'Email' };
  public static ACCOUNT_MANAGER: Translation = { EN: 'Account manager', CZ: 'Garant' };
  public static COMPONENT_TABLE_INTERNAL_NAME: Translation = { EN: 'Internal name', CZ: 'Interní název' };
  public static COMPONENT_TABLE_RESULT: Translation = { EN: 'Result', CZ: 'Výsledek' };
  public static COMPONENT_TABLE_STATE: Translation = { EN: 'State', CZ: 'Stav' };
  public static COMPONENT_TABLE_DETAIL: Translation = { EN: 'Detail', CZ: 'Detail' };
  public static ASSIGNEE: Translation = { EN: 'Assignee', CZ: 'Přiřazený uživatel' };
  public static REGENERATE_POA: Translation = { EN: 'Regenerate POA templates', CZ: 'Přegenerovat šablony plné moci' };
  public static MARK_POA_RECEIVED: Translation = { EN: 'Mark as Received', CZ: 'Označit jako Přijaté' };
  public static CHANGE_ASSIGNEE_SUCCESS: Translation = {
    EN: 'Assignee changed succesfully', CZ: 'Přiřazený uživatel úspěšně změněn',
  };
  public static ERROR_GETTING_DATA: Translation = {
    EN:
        'There was a problem getting data',
    CZ: 'Při získávání dat došlo k problému',
  };
  public static SUCCESSFULLY_SAVED: Translation = {
    EN: 'Successfully saved',
    CZ: 'Úspěšně uloženo',
  };
}

export default CandidateScreeningDetailLocale;
