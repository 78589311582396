import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  GridItem,
  SimpleGrid,
  Spacer,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import {
  H4, IconBadge, Note, ReadOnlyInput,
} from '@scaut-sro/meepo';
import { useHistory } from 'react-router-dom';
import { FiPackage } from 'react-icons/fi';
import { CheckIcon, CloseIcon, TimeIcon } from '@chakra-ui/icons';
import { MultipleCheckDetailProps } from './Checks.model';
import Translate from '../../components/Translate/Translate';
import ChecksLocale from './Checks.locale';
import TableLocale from '../../core/localization/translations/Table.locale';
import { translate } from '../../core/localization/LocaleUtils';
import { showDate } from '../../core/date/showDate';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { Result } from '../../build/generated-sources/enum/Result';
import { ResultsLocale } from '../../components/ResultBadge/ResultBadge.model';
import FormLocale from '../../core/localization/translations/Form.locale';

const MultipleCheckDetail: React.FunctionComponent<MultipleCheckDetailProps> = (
  props,
) => {
  const {
    order, candidateScreening, screeningPackages, anonymizationDate,
  } = props;
  const history = useHistory();
  const { language } = useGetUser();
  const [screeningsFailedCheck, setScreeningsFailedCheck] = useState<{
    [key: string]: Result;
  }>({});
  const [badResultCount, setBadResultCount] = useState<number>(0);

  useMemo(() => {
    let count = 0;
    order?.subOrders?.[0].candidateScreenings?.forEach((screening) => {
      if (screening.result === Result.FOUND_RESULTS) count += 1;
    });
    setBadResultCount(count);

    const screenings: {
      [key: string]: Result;
    } = {};
    order?.subOrders?.[0].candidateScreenings?.forEach((cs) => {
      cs.screenings?.forEach((screening) => {
        const name = screening.screeningDefinition?.name?.localizationStrings?.[language];
        if (name) {
          const result = screenings[name];
          if (!result) {
            screenings[name] = Result.CLEAR;
          }
          if (screening.result === Result.UNRESOLVED) {
            screenings[name] = Result.UNRESOLVED;
          } else if (
            screenings[name] !== Result.UNRESOLVED
            && screening.result === Result.FOUND_RESULTS
          ) {
            screenings[name] = Result.FOUND_RESULTS;
          }
        }
      });
    });
    setScreeningsFailedCheck(screenings);
  }, [order, language]);

  return (
    <>
      {order ? (
        <Box p={3} pt={0}>
          <Divider mb={3} />
          {candidateScreening ? (
            <SimpleGrid columns={[1, 1, 3, 3, 3, 3]} gap={6}>
              <GridItem colSpan={[1, 1, 2, 2, 2, 2]}>
                <Flex alignItems="center">
                  <H4>
                    <Translate label={ChecksLocale.TABLE_DETAIL_SCREENINGS} />
                  </H4>
                  <Wrap ml={4}>
                    {screeningPackages
                      && screeningPackages.map((screeningPackage) => (
                        <WrapItem
                          key={`${screeningPackage.id}_${candidateScreening?.id}`}
                        >
                          <IconBadge icon={<FiPackage />} colorScheme="orange">
                            {
                              screeningPackage.name?.localizationStrings?.[
                                language
                              ]
                            }
                          </IconBadge>
                        </WrapItem>
                      ))}
                  </Wrap>
                </Flex>
                <SimpleGrid columns={[1, 1, 2, 2, 2, 2]} gap={4} mt={4}>
                  {Object.keys(screeningsFailedCheck)?.map((screening) => (
                    <IconBadge
                      key={screening}
                      icon={
                        screeningsFailedCheck[screening] === Result.CLEAR ? (
                          <CheckIcon />
                        ) : screeningsFailedCheck[screening]
                          === Result.FOUND_RESULTS ? (
                            <CloseIcon />
                          ) : (
                            <TimeIcon />
                          )
                      }
                      bgColor={
                        screeningsFailedCheck[screening] === Result.CLEAR
                          ? '#e0f8e8'
                          : screeningsFailedCheck[screening]
                            === Result.FOUND_RESULTS
                            ? '#fff4e4'
                            : 'gray.100'
                      }
                      color={
                        screeningsFailedCheck[screening] === Result.CLEAR
                          ? '#00ce7d'
                          : screeningsFailedCheck[screening]
                            === Result.FOUND_RESULTS
                            ? '#f38400'
                            : 'gray.800'
                      }
                    >
                      {screening}
                    </IconBadge>
                  ))}
                </SimpleGrid>
              </GridItem>
              <GridItem>
                <SimpleGrid gap={6}>
                  <H4>
                    <Translate label={ChecksLocale.TABLE_DETAIL_INFO} />
                  </H4>
                  <ReadOnlyInput
                    label={translate(
                      ChecksLocale.TABLE_DETAIL_MULTIPLE_CHECK_START,
                      language,
                    )}
                    noValue={translate(FormLocale.NO_VALUE, language)}
                    value={showDate(language, order?.processStarted)}
                  />
                  <ReadOnlyInput
                    label={translate(
                      ChecksLocale.TABLE_DETAIL_MULTIPLE_CHECK_END,
                      language,
                    )}
                    noValue={translate(FormLocale.NO_VALUE, language)}
                    value={showDate(language, order?.processCompleted)}
                  />
                  <ReadOnlyInput
                    label={translate(
                      ChecksLocale.TABLE_DETAIL_CANDIDATES_COUNT,
                      language,
                    )}
                    noValue={translate(FormLocale.NO_VALUE, language)}
                    value={(
                      `${badResultCount}/${order.subOrders?.[0].candidateScreenings?.length}`
                      + ` ${translate(ResultsLocale.FOUND_RESULTS, language)}`
                    ).toLowerCase()}
                  />
                </SimpleGrid>
              </GridItem>
            </SimpleGrid>
          ) : (
            <Center w="100%" p={8}>
              <Text>
                <Translate label={TableLocale.NO_DATA} />
              </Text>
            </Center>
          )}
          <Divider mt={6} mb={3} />
          <Flex alignItems="center">
            <Note>
              <Translate
                label={ChecksLocale.TABLE_DETAIL_CANDIDATE_SCREENING_ANONYMIZED}
              />
              {anonymizationDate ? (
                <span>{`: ${showDate(language, anonymizationDate)}`}</span>
              ) : undefined}
            </Note>
            <Spacer />
            <Button
              ml={3}
              variant="outline"
              onClick={() => history.push(`/order/${order?.id}`)}
            >
              <Translate label={ChecksLocale.TABLE_DETAIL_GO_TO_RECAP} />
            </Button>
            <Button
              ml={3}
              variant="outline"
              onClick={() => history.push(`/multicheck/${order?.id}`)}
            >
              <Translate label={ChecksLocale.TABLE_DETAIL_GO_TO_DETAIL} />
            </Button>
          </Flex>
        </Box>
      ) : (
        <Center w="100%" p={8}>
          <Text>
            <Translate label={TableLocale.NO_DATA} />
          </Text>
        </Center>
      )}
    </>
  );
};

export default MultipleCheckDetail;
