import React, { useEffect, useState } from 'react';
import {
  Link, ListItem, UnorderedList, Text,
} from '@chakra-ui/layout';
import { TranslationsUsageDetailProps, UsageCatalog, UsagesData } from '../Translations.model';
import Translate from '../../../components/Translate/Translate';
import TranslationsLocale from '../Translations.locale';

const TranslationUsageDetail: React.FunctionComponent<TranslationsUsageDetailProps> = (props) => {
  const { translation } = props;
  const [usageData, setUsageData] = useState<UsagesData>();

  useEffect(() => {
    const usageCatalogDefinitionNames: UsageCatalog[] = [];
    const usageCatalogDefinitionDescriptions: UsageCatalog[] = [];
    const usageCatalogPackageNames: UsageCatalog[] = [];
    const usageCatalogPackageDescriptions: UsageCatalog[] = [];
    const usageFaqQuestions: number[] = [];
    const usageFaqAnswers: number[] = [];
    const usageCountries: number[] = [];

    translation?.screeningDefinitionNames?.forEach(
      (definition) => usageCatalogDefinitionNames.push({
        id: definition.id as number,
        internalName: definition.internalName as string,
      }),
    );

    translation?.screeningDefinitionDescriptions?.forEach(
      (definition) => usageCatalogDefinitionDescriptions.push({
        id: definition.id as number,
        internalName: definition.internalName as string,
      }),
    );

    translation?.screeningPackageNames?.forEach(
      (definition) => usageCatalogPackageNames.push({
        id: definition.id as number,
        internalName: definition.internalName as string,
      }),
    );

    translation?.screeningPackageDescriptions?.forEach(
      (definition) => usageCatalogPackageDescriptions.push({
        id: definition.id as number,
        internalName: definition.internalName as string,
      }),
    );

    translation?.faqQuestions?.forEach((faq) => usageFaqQuestions.push(faq.id as number));
    translation?.faqAnswers?.forEach((faq) => usageFaqAnswers.push(faq.id as number));
    translation?.countryNames?.forEach((country) => usageCountries.push(country.id as number));

    const noUsage = usageCatalogDefinitionNames.length === 0
        && usageCatalogPackageDescriptions.length === 0
        && usageCatalogDefinitionDescriptions.length === 0
        && usageCatalogPackageNames.length === 0
        && usageCountries.length === 0
        && usageFaqAnswers.length === 0
        && usageFaqQuestions.length === 0;

    setUsageData({
      usageCatalogDefinitionNames,
      usageCatalogPackageDescriptions,
      usageCatalogDefinitionDescriptions,
      usageCatalogPackageNames,
      usageCountries,
      usageFaqAnswers,
      usageFaqQuestions,
      noUsage,
    });
  }, [translation]);

  return (
    <>
      <UnorderedList mt={4}>
        {
                usageData && !usageData.noUsage ? (
                  <>
                    {
                            usageData.usageCatalogDefinitionNames.length > 0 ? (
                              <ListItem>
                                <Translate label={TranslationsLocale.USAGE_SCREENING_NAME_SENTENCE} />
                                {
                                        usageData.usageCatalogDefinitionNames.map((usage) => (
                                          <Text key={usage.id}>
                                            <Link color="primaryBase" href={`catalogue/screening/${usage.id}`}>
                                              {usage.internalName}
                                            </Link>
                                          </Text>
                                        ))
                                    }
                              </ListItem>
                            ) : (<></>)
                        }
                    {
                            usageData.usageCatalogDefinitionDescriptions.length > 0 ? (
                              <ListItem>
                                <Translate label={TranslationsLocale.USAGE_SCREENING_DESCRIPTION_SENTENCE} />
                                {
                                        usageData.usageCatalogDefinitionDescriptions.map((usage) => (
                                          <Text key={usage.id}>
                                            <Link color="primaryBase" href={`catalogue/screening/${usage.id}`}>
                                              {usage.internalName}
                                            </Link>
                                          </Text>
                                        ))
                                    }
                              </ListItem>
                            ) : (<></>)
                        }
                    {
                            usageData.usageCatalogPackageNames.length > 0 ? (
                              <ListItem>
                                <Translate label={TranslationsLocale.USAGE_PACKAGE_NAME_SENTENCE} />
                                {
                                        usageData.usageCatalogPackageNames.map((usage) => (
                                          <Text key={usage.id}>
                                            <Link color="primaryBase" href={`catalogue/package/${usage.id}`}>
                                              {usage.internalName}
                                            </Link>
                                          </Text>
                                        ))
                                    }
                              </ListItem>
                            ) : (<></>)
                        }
                    {
                            usageData.usageCatalogPackageDescriptions.length > 0 ? (
                              <ListItem>
                                <Translate label={TranslationsLocale.USAGE_PACKAGE_DESCRIPTION_SENTENCE} />
                                {
                                        usageData.usageCatalogPackageDescriptions.map((usage) => (
                                          <Text key={usage.id}>
                                            <Link color="primaryBase" href={`catalogue/package/${usage.id}`}>
                                              {usage.internalName}
                                            </Link>
                                          </Text>
                                        ))
                                    }
                              </ListItem>
                            ) : (<></>)
                        }
                    {
                            usageData.usageFaqQuestions.length > 0 ? (
                              <ListItem><Translate label={TranslationsLocale.USAGE_FAQ_QUESTION_SENTENCE} /></ListItem>
                            ) : (<></>)
                        }
                    {
                            usageData.usageFaqAnswers.length > 0 ? (
                              <ListItem><Translate label={TranslationsLocale.USAGE_FAQ_ANSWER_SENTENCE} /></ListItem>
                            ) : (<></>)
                        }
                    {
                            usageData.usageCountries.length > 0 ? (
                              <ListItem>
                                <Translate label={TranslationsLocale.USAGE_COUNTRY_NAME_SENTENCE} />
                                {
                                        usageData.usageCountries.map((id) => (
                                          <Text key={id}>
                                            <Link color="primaryBase" href={`country/${id}`}>
                                              {id}
                                            </Link>
                                          </Text>
                                        ))
                                    }
                              </ListItem>
                            ) : (<></>)
                        }
                  </>
                ) : (<ListItem><Translate label={TranslationsLocale.NO_USAGE_SENTENCE} /></ListItem>)
            }
      </UnorderedList>
    </>
  );
};

export default TranslationUsageDetail;
