export enum TranslationType {
  GENERAL = 'GENERAL',
  SURVEY = 'SURVEY',
  COUNTRY = 'COUNTRY',
  DYNAMIC_FIELD = 'DYNAMIC_FIELD',
  FAQ = 'FAQ',
  TEMPLATE_INVOICE = 'TEMPLATE_INVOICE',
  TEMPLATE_POA = 'TEMPLATE_POA',
  TEMPLATE_REPORT = 'TEMPLATE_REPORT',
  TEMPLATE_AUTHORIZATION = 'TEMPLATE_AUTHORIZATION',
}
