import { gql } from '@apollo/client';

// eslint-disable-next-line
export const getSurvey = gql`
      query eumerations {
          enumerations @rest(type: "surveyEnumerations", path: "/surveyEnumerations") {
              id
              type
              translation {
                  name
                  localizationStrings {
                      CZ {
                          value
                          language
                      }
                      EN {
                          value
                          language
                      }
                  }
              }
          }
      }
  `;
