import React from 'react';
import { useShowDate } from '../../core/date/showDate';
import { ShowDateProps } from './ShowDate.model';

const ShowDate: React.FunctionComponent<ShowDateProps> = (props) => {
  const { date, time } = props;
  const formatedDate = useShowDate(date, time);
  return <>{ formatedDate }</>;
};

export default ShowDate;
