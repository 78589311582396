import React, { useRef } from 'react';
import {
  Button, HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent, ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import Translate from '../../components/Translate/Translate';
import FormLocale from '../../core/localization/translations/Form.locale';
import ResourceDatabaseLocale from './ResourceDatabase.locale';
import DatabaseItemDetail from './DatabaseItemDetail';
import { ItemDetailProps } from './ResourceDatabase.model';

const ItemDetailModal: React.FunctionComponent<ItemDetailProps> = (props) => {
  const { itemDetail, sectionDetail } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const formRef = useRef<HTMLFormElement>(null);

  return (
    <>
      <Button onClick={onOpen} leftIcon={<AddIcon />}>
        <Translate label={FormLocale.NEW} />
      </Button>
      <Modal closeOnOverlayClick={false} size="6xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text><Translate label={ResourceDatabaseLocale.COUNTRY} /></Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <DatabaseItemDetail
              itemDetail={itemDetail}
              sectionDetail={sectionDetail}
              formRef={formRef}
            />
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button variant="ghost" colorScheme="red" onClick={onClose}>
                <Translate label={FormLocale.CANCEL} />
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ItemDetailModal;
