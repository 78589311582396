import React, { useEffect, useState } from 'react';
import { SectionContainer, Toast } from '@scaut-sro/meepo';
import {
  Tab, TabList, TabPanel, TabPanels, Tabs,
} from '@chakra-ui/react';
import {
  ResourceDatabaseTabsData,
} from './ResourceDatabase.model';
import {
  useLazyGetResourceDatabaseSections,
} from '../../build/generated-sources/service/QueryService';
import DatabaseSectionDetail from './Detail/DatabaseSectionDetail';
import DatabaseSectionDetailNew from './Detail/DatabaseSectionDetailNew';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import {
  ResourceDatabaseSectionDetail,
  ResourceDatabaseSectionDetailMapper,
} from '../../build/generated-sources/dto/ResourceDatabaseSectionDetail';
import { translate } from '../../core/localization/LocaleUtils';
import ResourceDatabaseLocale from './ResourceDatabase.locale';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';

export function getTabsData(data?: ResourceDatabaseSectionDetail[],
  refreshTabs?: () => void): ResourceDatabaseTabsData[] {
  if (data) {
    const sections = data.map((sectionDetail) => ({
      label: sectionDetail.section?.name,
      content: <DatabaseSectionDetail sectionDetail={sectionDetail} />,
    }));
    sections.push({
      label: '+',
      content: <DatabaseSectionDetailNew refreshTabs={refreshTabs} />,
    });
    return sections;
  }
  return [];
}

const ResourceDatabase: React.FunctionComponent = () => {
  const { language } = useGetUser();
  const [sections, setSections] = useState<ResourceDatabaseTabsData[]>([]);
  const [defaultTabIndex, setDefaultTabIndex] = useState<number>(0);

  const sectionsMapper: ResourceDatabaseSectionDetailMapper = {
    form: true,
    section: {
      id: true,
      name: true,
      resourceDatabaseFieldDefinitions: {
        fieldName: true,
        formInputType: true,
        isMandatory: true,
      },
      resourceDatabaseItems: {
        id: true,
        name: true,
        resourceDatabaseFields: {
          resourceDatabaseFieldDefinition: {
            fieldName: true,
            formInputType: true,
            isMandatory: true,
          },
          value: true,
        },
        enumeration: {
          id: true,
          translation: {
            id: true,
            localizationStrings: true,
          },
        },
      },
    },
  };

  const [sectionsHandle, { loading }] = useLazyGetResourceDatabaseSections(
    sectionsMapper, {
      onError: () => {
        Toast({
          title: translate(ResourceDatabaseLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const onCompleteData = getTabsData(response.resourceDatabaseSections, refreshTabs);
        setSections(onCompleteData);
      },
    },
  );

  const [sectionsHandleNew] = useLazyGetResourceDatabaseSections(
    sectionsMapper, {
      onError: () => {
        Toast({
          title: translate(ResourceDatabaseLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const onCompleteData = getTabsData(response.resourceDatabaseSections, refreshTabs);
        setSections(onCompleteData);
        setDefaultTabIndex(response.resourceDatabaseSections.length - 1);
      },
    },
  );

  const refreshTabs = () => {
    sectionsHandleNew();
  };

  useEffect(() => {
    sectionsHandle();
  }, [sectionsHandle]);

  return (
    <>
      {loading
        ? <LoadingScreen />
        : (
          <SectionContainer>
            <Tabs size="lg" defaultIndex={defaultTabIndex}>
              <TabList>
                {sections.map((section, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Tab key={index}>{section.label}</Tab>
                ))}
              </TabList>
              <TabPanels>
                {sections.map((section, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <TabPanel p={4} key={index}>
                    {section.content}
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </SectionContainer>
        )}
    </>
  );
};

export default ResourceDatabase;
