import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  FileUpload,
  H1,
  H3,
  H4,
  Language,
  MeepoTable,
  MeepoTag,
  P,
  ReadOnlyInput,
  SectionContainer,
  TagArea,
  Toast,
} from '@scaut-sro/meepo';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Select,
  SimpleGrid,
  Skeleton,
  Spacer,
  Textarea,
} from '@chakra-ui/react';
import { FiUser } from 'react-icons/fi';
import { AiOutlineNumber, AiOutlineTeam } from 'react-icons/ai';
import { IoShapesOutline } from 'react-icons/io5';
import {
  ScautScreeningComparisonTableApi,
} from '@scaut-sro/meepo/lib/components/ScautScreeningComparisonTable/ScautScreeningComparisonTable.model';
import { useApolloClient } from '@apollo/client';
import { FormProvider, useForm } from 'react-hook-form';
import { useKeycloak } from '@react-keycloak/web';
import { FileUploadApi } from '@scaut-sro/meepo/lib/components/DynamicForm/DynamicForm.model';
import { useGetAdminUsers, useLazyGetCandidateScreening } from '../../build/generated-sources/service/QueryService';
import { ComponentTableData } from './CandidateScreeningDetail.model';
import Translate from '../../components/Translate/Translate';
import CandidateScreeningDetailLocale from './CandidateScreeningDetail.locale';
import { ResultBadge } from '../../components/ResultBadge/ResultBadge';
import { StateBadge } from '../../components/StateBadge/StateBadge';
import DetailHeader from '../../components/DetailHeader/DetailHeader';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../core/localization/LocaleUtils';
import { showDate, showDateTime } from '../../core/date/showDate';
import MultipleCheckDetailLocale from '../MultipleCheckDetail/MultipleCheckDetail.locale';
import FormLocale from '../../core/localization/translations/Form.locale';
import { FormFieldDto } from '../../build/generated-sources/dto/FormFieldDto';
import { OrderType } from '../../build/generated-sources/enum/OrderType';
import ChecksLocale from '../Checks/Checks.locale';
import NewCheckLocale from '../NewCheck/NewCheck.locale';
import {
  useAccountManager,
  useMarkPoaReceived,
  useRegeneratePoa,
  useUpdateReportText,
} from '../../build/generated-sources/service/MutationService';
import { handleApolloErrors } from '../../core/error/ScautErrorHandler';
import { CandidateScreeningAdminDetail } from '../../build/generated-sources/dto/CandidateScreeningAdminDetail';
import ComponentForms from './ComponentForms';
import {
  MAP_ComponentId_CandidateScreeningComponentData,
} from '../../build/scalars/MAP_ComponentId_CandidateScreeningComponentData';
import Questionnaire from '../../components/Questionnaire/Questionnaire';
import { UseGetCandidateScreeningData } from '../../build/generated-sources/service/QueryServiceModel';
import TableLocale from '../../core/localization/translations/Table.locale';
import getCompletedDataFields from '../../core/service/queries/DynamicFormApiService';
import MapFormFieldDtoTODynamicFormField from '../../core/util/ObjectMappers';
import CompletedFormEditModal from './CompletedFormEdit/CompletedFormEditModal';
import { AdminUser } from '../../build/generated-sources/dto/AdminUser';
import { QuestionnaireDefinition } from '../../components/Questionnaire/Questionnaire.model';
import ScautAdminRoles from '../../core/auth/ScautAdminRoles';
import { getFileUploadApi } from '../../core/service/queries/FileUploadService';
import { POAStatus } from '../../build/generated-sources/enum/POAStatus';
import { PAOStateBadge } from '../../components/PAOStateBadge/PAOStateBadge';
import { MAP_ComponentId_DynamicForm } from '../../build/scalars/MAP_ComponentId_DynamicForm';

export function getComponentsFormTableData(
  data: MAP_ComponentId_CandidateScreeningComponentData,
  resultData: MAP_ComponentId_DynamicForm,
  language?: Language,
  refreshData?: () => void,
  candidateScreeningId?: number,
  adminUsers?: AdminUser[],
  screeningApi?: ScautScreeningComparisonTableApi,
):ComponentTableData[] {
  if (data) {
    return Object.keys(data).map((item) => ({
      id: item || '-undefined-',
      detail: (
        <Box
          p={['10px',
            '5px', '5px', '20px', '30px']}
          borderRadius="md"
          mb={2}
        >
          <ComponentForms
            refreshData={refreshData}
            component={data[item]}
            screeningResultData={resultData[item]?.form}
            candidateScreeningId={candidateScreeningId}
            adminUsers={adminUsers}
            scautScreeningComparisonTableApi={screeningApi}
          />
        </Box>
      ),
      name: <H3>{data[item]?.component?.componentDefinitionName}</H3>,
      result: <ResultBadge result={data[item]?.component?.result || undefined} />,
      state: <StateBadge state={data[item]?.component?.state || undefined} />,
    }));
  }
  return [];
}

const CandidateScreeningDetail: React.FunctionComponent = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const [data, setData] = useState<CandidateScreeningAdminDetail | undefined>(undefined);
  const candidateId = Number.isNaN(+params.id);
  const [list, setList] = useState<ComponentTableData[]>([]);
  const [personalDataForm, setPersonalDataForm] = useState<{ [key: string]: FormFieldDto }>({});
  const [refreshDataAgain, setRefreshDataAgain] = useState(false);
  const [reportText, setReportText] = useState<string>('');
  const [screeningApi, setScreeningApi] = useState<ScautScreeningComparisonTableApi>();
  const { language } = useGetUser();
  const { keycloak } = useKeycloak();
  const isSysAdmin = keycloak.hasResourceRole(ScautAdminRoles.SYSADMIN, 'scaut-admin-app');
  const isManager = keycloak.hasResourceRole(ScautAdminRoles.MANAGER, 'scaut-admin-app');

  const [accountManager, setAccountManager] = useState<number | undefined>(undefined);

  const client = useApolloClient();

  const [mutateAccountManager] = useAccountManager(
    {
      id: true,
    },
    {
      onCompleted: () => {
        Toast({
          title: translate(MultipleCheckDetailLocale.CHANGE_ACCOUNT_MANAGER_SUCCESS, language),
          status: 'success',
        });
      },
      onError: (error) => {
        handleApolloErrors(error, language);
      },
    },
  );

  const [mutateReportText, mutateReportTextOptions] = useUpdateReportText(
    {
      success: true,
    },
    {
      onCompleted: () => {
        Toast({
          title: translate(MultipleCheckDetailLocale.CHANGE_REPORT_TEXT_SUCCESS, language),
          status: 'success',
        });
      },
      onError: (error) => {
        handleApolloErrors(error, language);
      },
    },
  );

  const allUsers = useGetAdminUsers({
    id: true,
    firstName: true,
    lastName: true,
  });

  const callRefreshData = () => {
    setRefreshDataAgain(true);
  };

  const useFormMethods = useForm();

  const [actualValues, setActualValues] = useState<{ [p: string]: any }>({});
  const handleFormChange = () => {
    setActualValues(useFormMethods.getValues());
  };

  useEffect(() => {
    setActualValues(useFormMethods.getValues());
  }, [useFormMethods, setActualValues]);

  const fileUploadApi: FileUploadApi = useMemo(() => getFileUploadApi(client, keycloak.token as string), [keycloak, client]);

  /*  const handleScreeningFormSubmit = (values: UnpackNestedValue<any>, formId?: number) => {
    if (formId) {
      mutateFillForm({
        variables: {
          formId,
          formData: values,
        },
      });
    }
  }; */

  const onFetchCompleted = useCallback(
    (response:UseGetCandidateScreeningData) => {
      const getScreeningApi = (candidateScreeningId?: number): ScautScreeningComparisonTableApi => ({
        getFields: ((fieldIds, callback) => {
          if (candidateScreeningId) {
            getCompletedDataFields(client, {
              id: true,
              value: true,
              label: true,
              defaultValue: true,
              type: true,
              properties: true,
              validation: true,
              preloadedTranslations: true,
            }, {
              candidateScreeningDetailId: candidateScreeningId,
              fieldIds,
            }).then((screeningApiResponse) => {
              callback(screeningApiResponse.data.getFields.map((field) => MapFormFieldDtoTODynamicFormField(field, language)));
            });
          }
        }),
      });

      if (response.candidateScreening === null) {
        Toast({
          title: translate(CandidateScreeningDetailLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      } else {
        setData(response.candidateScreening);
        setReportText(response.candidateScreening?.candidateScreening?.reportText || '');
        const scautScreeningApi = getScreeningApi(response.candidateScreening?.candidateScreening?.id);
        setScreeningApi(scautScreeningApi);

        const onCompleteTableData: ComponentTableData[] = getComponentsFormTableData(
          response.candidateScreening.componentForms || {},
          response?.candidateScreening?.resultData || {},
          language,
          callRefreshData,
          +params.id,
          allUsers.data?.adminUsers,
          scautScreeningApi,
        );

        setPersonalDataForm(response.candidateScreening.personalData || {});
        if (!accountManager) {
          setAccountManager(response.candidateScreening.order?.accountManager?.id);
        }
        setList(onCompleteTableData);
      }
    }, [allUsers, language, params, client, accountManager],
  );

  const [candidateScreeningHandle, { loading, refetch }] = useLazyGetCandidateScreening(
    {
      candidateScreening: {
        id: true,
        state: true,
        result: true,
        processStarted: true,
        processCompleted: true,
        reportText: true,
        powerOfAttorney: {
          id: true,
          poaStatus: true,
          generatedTemplates: {
            id: true,
          },
          fileUpload: {
            dynamicUploadId: true,
            accept: true,
            maxFileNumber: true,
            uploadedFiles: {
              id: true,
            },
          },
        },
        screenings: {
          id: true,
          state: true,
          result: true,
          screeningDefinition: {
            id: true,
            name: {
              localizationStrings: true,
            },
            description: {
              localizationStrings: true,
            },
          },
          processCompleted: true,
          processStarted: true,
          components: {
            id: true,
            state: true,
            result: true,
            assignee: {
              id: true,
            },
            componentDefinition: {
              internalName: true,
            },
          },
        },
        candidateIdentity: {
          firstName: true,
          lastName: true,
          dateOfBirth: true,
        },
      },
      order: {
        id: true,
        accountManager: {
          id: true,
        },
        name: true,
        team: {
          name: true,
        },
        type: true,
        clientOrderCustomId: true,
        subOrders: {
          screeningPackages: {
            id: true,
            name: {
              localizationStrings: true,
            },
          },
          screeningDefinitions: {
            id: true,
            name: {
              localizationStrings: true,
            },
          },
        },
      },
      anonymizationDate: true,
      personalData: true,
      componentForms: true,
      completedData: true,
      globalForm: {
        id: true,
        form: true,
      },
      resultData: true,
    },
    {
      onCompleted: onFetchCompleted,
      onError: () => {
        Toast({
          title: translate(CandidateScreeningDetailLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      },
      variables: {
        candidateScreeningId: +params.id,
      },
    },
  );

  const [markReceivedPOAHandle] = useMarkPoaReceived({
    success: true,
  }, {
    onCompleted: () => {
      setRefreshDataAgain(true);
    },
  });

  const [regeneratePOATemplatesHandle] = useRegeneratePoa({
    success: true,
  }, {
    onCompleted: () => {
      setRefreshDataAgain(true);
    },
  });

  useEffect(() => {
    if (!candidateId) {
      candidateScreeningHandle({
        variables: {
          candidateScreeningId: +params.id,
        },
      });
    }
  }, [candidateScreeningHandle, params.id, candidateId]);

  const globalCompletedData = useMemo(() => {
    const completedDataTemp = data?.completedData || {};

    const completedData: QuestionnaireDefinition = {};
    Object.keys(completedDataTemp).forEach((fieldId) => {
      const fieldData = completedDataTemp[fieldId] as any;

      const screeningIds = fieldData?.dynamicFormFieldProps?.screeningIds || [];

      if (!screeningIds || screeningIds.length === 0) {
        completedData[fieldId] = fieldData;
      }
    });

    if (Object.keys(completedData).length > 0) {
      return completedData;
    }

    return undefined;
  }, [data]);

  const services: string = useMemo(() => {
    let str = '';
    data?.order?.subOrders?.[0].screeningPackages?.forEach((scrPackage) => {
      str = `${str + scrPackage.name?.localizationStrings?.[language]}, `;
    });
    data?.order?.subOrders?.[0].screeningDefinitions?.forEach((scrDefinition) => {
      str = `${str + scrDefinition.name?.localizationStrings?.[language]}, `;
    });
    str = str.substring(0, str.length - 2);
    return str;
  }, [data, language]);

  const refreshData = useCallback(
    () => {
      if (refetch) {
        refetch().then((response) => onFetchCompleted(response.data));
      }
    }, [refetch, onFetchCompleted],
  );

  const changeAccountManager = (accountManagerId: number) => {
    if (accountManagerId && (isManager || isSysAdmin)) {
      mutateAccountManager({
        variables: {
          primaryOrderId: data?.order?.id || -1,
          accountManagerId,
        },
      });
      refreshData();
    }
  };

  const updateReportText = () => {
    mutateReportText({
      variables: {
        candidateScreeningId: data?.candidateScreening?.id || -1,
        reportText,
      },
    });
  };

  useEffect(() => {
    if (refreshDataAgain) {
      refreshData();
      setRefreshDataAgain(false);
    }
  }, [refreshDataAgain, refreshData]);

  const poaTemplates: number[] = useMemo(() => {
    const result = data?.candidateScreening?.powerOfAttorney?.generatedTemplates?.map((template) => template.id as number);
    return result || [];
  }, [data]);

  return (
    <>
      <SectionContainer>
        <DetailHeader>
          <H1><Translate label={CandidateScreeningDetailLocale.CANDIDATE_SCREENING} /></H1>
          <Flex justifyContent={['flex-start', 'flex-start', 'flex-start', 'flex-end']}>
            <Flex alignItems="center" w="200px" minW="fit-content">
              <Box w="100%">
                <StateBadge
                  state={data?.candidateScreening?.state}
                  size="lg"
                />
                { isManager || isSysAdmin ? (
                  <FormControl mt={3}>
                    <FormLabel><Translate label={CandidateScreeningDetailLocale.ACCOUNT_MANAGER} /></FormLabel>
                    <Select
                      onChange={(event) => {
                        setAccountManager(+event.target.value);
                        changeAccountManager(+event.target.value);
                      }}
                      value={accountManager}
                      isDisabled={data?.order?.type === OrderType.MULTIPLE}
                    >
                      <option value="">- Select value -</option>
                      { allUsers.data?.adminUsers.map((userData) => (
                        <option key={userData.id} value={userData.id}>
                          {`${userData.firstName} ${userData.lastName}`}
                        </option>
                      )) }
                    </Select>
                  </FormControl>
                ) : undefined }
              </Box>
            </Flex>
          </Flex>
        </DetailHeader>
        <Box mb={4}>
          <Flex alignItems="center">
            <Icon as={FiUser} fontSize={50} />
            <Box ml={4}>
              <Skeleton isLoaded={!loading}>
                <Heading size="lg" color="primaryBase" mb={3}>
                  {
                          `${data?.candidateScreening?.candidateIdentity?.firstName} `
                          + `${data?.candidateScreening?.candidateIdentity?.lastName}`
                          || 'Loading'
                      }
                </Heading>
              </Skeleton>
              <Skeleton isLoaded={!loading}>
                <TagArea>
                  {data?.order?.team ? (
                    <MeepoTag
                      leftIcon={AiOutlineTeam}
                      label={data.order.team.name || ''}
                      tooltip={translate(CandidateScreeningDetailLocale.TEAM, language)}
                    />
                  ) : undefined}
                  { data?.order?.type ? (
                    <MeepoTag
                      leftIcon={IoShapesOutline}
                      label={
                                data?.order?.type === OrderType.MULTIPLE
                                  ? translate(ChecksLocale.MULTIPLE_PEOPLE, language)
                                  : translate(ChecksLocale.SINGLE_CHECK, language)
                              }
                      tooltip={translate(NewCheckLocale.TYPE, language)}
                    />
                  ) : undefined }
                  {data?.order?.clientOrderCustomId ? (
                    <MeepoTag
                      leftIcon={AiOutlineNumber}
                      label={data?.order?.clientOrderCustomId}
                      tooltip={translate(CandidateScreeningDetailLocale.ORDER_CUSTOM_ID, language)}
                    />
                  ) : undefined}
                  {loading ? <MeepoTag label="loading" /> : undefined }
                </TagArea>
              </Skeleton>
            </Box>
          </Flex>
        </Box>
        <Flex mb={4} alignItems="center">
          <H4>
            <Translate label={CandidateScreeningDetailLocale.RESULT} />
            :
          </H4>
          <Box w="200px" ml={5}>
            <ResultBadge result={data?.candidateScreening?.result} />
          </Box>
          <Spacer />
          { data?.completedData ? (
            <CompletedFormEditModal
              completedData={data?.completedData}
              candidateScreeningId={+params.id}
              refreshData={refreshData}
            />
          ) : undefined }
        </Flex>

        <Accordion allowMultiple defaultIndex={[0, 1, 2]}>
          <AccordionItem>
            <AccordionButton p={4}>
              <Box flex="1" textAlign="left">
                <H3><Translate label={CandidateScreeningDetailLocale.CANDIDATE_IDENTITY} /></H3>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} pt={4}>
              <SimpleGrid columns={[1, 2, 2, 3, 3]} spacing={[6]}>
                <ReadOnlyInput
                  label={translate(CandidateScreeningDetailLocale.FULLNAME, language)}
                  value={
                            `${data?.candidateScreening?.candidateIdentity?.firstName} `
                            + `${data?.candidateScreening?.candidateIdentity?.lastName}`
                        }
                />
                <ReadOnlyInput
                  label={translate(CandidateScreeningDetailLocale.DATE_OF_BIRTH, language)}
                  value={showDate(language, data?.candidateScreening?.candidateIdentity?.dateOfBirth)}
                />
                <ReadOnlyInput
                  label={translate(CandidateScreeningDetailLocale.EMAIL, language)}
                  value={data?.candidateScreening?.candidateIdentity?.email}
                />
              </SimpleGrid>
              <SimpleGrid>
                <Questionnaire
                  definition={personalDataForm}
                  scautScreeningComparisonTableApi={screeningApi}
                  dynamicFormProps={{
                    hideSectionNames: true,
                    styleProps: {
                      margin: '10px',
                      padding: '20px',
                      border: '1px solid #c7c2c2',
                      borderRadius: '10px',
                      background: '#d4d4d41a',
                    },
                  }}
                  readonly
                />
              </SimpleGrid>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton p={4}>
              <Box flex="1" textAlign="left">
                <H3><Translate label={CandidateScreeningDetailLocale.CANDIDATE_SCREENING_INFO} /></H3>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} pt={4}>
              <Flex mb={5}>
                <Box mr={3}>
                  <P>
                    <Translate label={CandidateScreeningDetailLocale.ORDERED_SERVICES} />
                    :
                  </P>
                </Box>
                <Box>
                  <P>{services}</P>
                </Box>
              </Flex>

              <SimpleGrid columns={[1, 2, 2, 3, 3]} spacing={[6]}>
                <ReadOnlyInput
                  label={translate(MultipleCheckDetailLocale.CANDIDATE_SCREENING_START, language)}
                  noValue={translate(FormLocale.NO_VALUE, language)}
                  value={showDateTime(language, data?.candidateScreening?.processStarted)}
                />
                <ReadOnlyInput
                  label={translate(MultipleCheckDetailLocale.CANDIDATE_SCREENING_END, language)}
                  noValue={translate(FormLocale.NO_VALUE, language)}
                  value={showDateTime(language, data?.candidateScreening?.processCompleted)}
                />
                <ReadOnlyInput
                  label={translate(MultipleCheckDetailLocale.PLANNED_ANONYMIZATION, language)}
                  noValue={translate(FormLocale.NO_VALUE, language)}
                  value={showDate(language, data?.anonymizationDate)}
                />
              </SimpleGrid>
            </AccordionPanel>
          </AccordionItem>
          {
            data?.candidateScreening?.powerOfAttorney?.fileUpload ? (
              <AccordionItem>
                <AccordionButton p={4}>
                  <Box flex="1" textAlign="left">
                    <Flex>
                      <H3><Translate label={CandidateScreeningDetailLocale.POWER_OF_ATTORNEY} /></H3>
                      <Spacer />
                      <PAOStateBadge poaStatus={data?.candidateScreening?.powerOfAttorney.poaStatus as POAStatus} />
                    </Flex>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4} pt={4}>
                  <FileUpload
                    valueSetter={() => {}}
                    onFileUpload={fileUploadApi.onFileUpload}
                    onFileDownload={fileUploadApi.onFileDownload}
                    onFileDelete={fileUploadApi.onFileDelete}
                    filesInfoGetter={fileUploadApi.filesInfoGetter}
                    uploadedFiledInfoGetter={fileUploadApi.uploadedFilesInfoGetter}
                    fileUploadId={data.candidateScreening.powerOfAttorney.fileUpload.dynamicUploadId as string}
                    accept={data.candidateScreening.powerOfAttorney.fileUpload.accept as string}
                    maxFileNumber={data.candidateScreening.powerOfAttorney.fileUpload.maxFileNumber}
                    templateFiles={poaTemplates}
                  />
                  <Flex>
                    <Spacer />
                    <ButtonGroup>
                      {
                        data?.candidateScreening?.powerOfAttorney?.poaStatus === POAStatus.GENERATED ? (
                          <Button
                            variant="outline"
                            onClick={
                            () => markReceivedPOAHandle(
                              { variables: { candidateScreeningId: data?.candidateScreening?.id as number } },
                            )
                          }
                          >
                            <Translate label={CandidateScreeningDetailLocale.MARK_POA_RECEIVED} />
                          </Button>
                        ) : undefined
                      }
                      <Button
                        variant="ghost"
                        colorScheme="red"
                        onClick={
                        () => regeneratePOATemplatesHandle(
                          { variables: { candidateScreeningId: data?.candidateScreening?.id as number } },
                        )
                      }
                      >
                        <Translate label={CandidateScreeningDetailLocale.REGENERATE_POA} />
                      </Button>
                    </ButtonGroup>
                  </Flex>
                </AccordionPanel>
              </AccordionItem>
            ) : undefined
          }

          {/* <AccordionItem> */}
          {/*   <AccordionButton p={4}> */}
          {/*     <Box flex="1" textAlign="left"> */}
          {/*       <H3><Translate label={CandidateScreeningDetailLocale.FILLED_IN_INFO} /></H3> */}
          {/*     </Box> */}
          {/*     <AccordionIcon /> */}
          {/*   </AccordionButton> */}
          {/*   <AccordionPanel pb={4} pt={4}> */}

          {/*     <Questionnaire */}
          {/*       definition={completedDataForm} */}
          {/*       scautScreeningComparisonTableApi={screeningApi} */}
          {/*       readonly */}
          {/*       dynamicFormProps={{ */}
          {/*         hideSectionNames: true, */}
          {/*         styleProps: { */}
          {/*           margin: '10px', */}
          {/*           padding: '20px', */}
          {/*           border: '1px solid #c7c2c2', */}
          {/*           borderRadius: '10px', */}
          {/*           background: '#d4d4d41a', */}
          {/*         }, */}
          {/*       }} */}
          {/*     /> */}
          {/*   </AccordionPanel> */}
          {/* </AccordionItem> */}

          {
            (data?.globalForm?.form && Object.keys(data.globalForm.form).length > 0) || globalCompletedData ? (
              <AccordionItem>
                <AccordionButton p={4}>
                  <Box flex="1" textAlign="left">
                    <H3><Translate label={CandidateScreeningDetailLocale.GLOBAL_DATA} /></H3>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4} pt={4}>
                  {
                      globalCompletedData ? (
                        <Questionnaire
                          definition={globalCompletedData as QuestionnaireDefinition}
                          readonly
                        />
                      ) : (<></>)
                    }
                  {
                      data?.globalForm?.form && Object.keys(data.globalForm.form).length > 0 ? (
                        <FormProvider {...useFormMethods}>
                          <form
/*                            onSubmit={useFormMethods.handleSubmit(
                              (values) => handleScreeningFormSubmit(
                                values, data.globalForm?.id,
                              ),
                            )} */
                            onChange={handleFormChange}
                          >
                            <Questionnaire
                              definition={data.globalForm.form as QuestionnaireDefinition}
                              actualValues={actualValues}
                            />
                            <Flex>
                              <Spacer />
                              <Button mt={6} type="submit">
                                <Translate label={FormLocale.SEND} />
                              </Button>
                            </Flex>
                          </form>
                        </FormProvider>
                      ) : (<></>)
                    }
                </AccordionPanel>
              </AccordionItem>
            ) : (<></>)
          }

        </Accordion>

        <Box mt={5}>
          <H3><Translate label={CandidateScreeningDetailLocale.TASKS} /></H3>
          <MeepoTable
            columns={[
              { id: 'name' },
              {
                id: 'result',
                label: translate(ChecksLocale.ORDER_RESULT, language),
                boxProps: {
                  w: '220px',
                },
              },
              {
                id: 'state',
                label: translate(ChecksLocale.ORDER_STATE, language),
                boxProps: {
                  w: '220px',
                },
              },
            ]}
            data={list}
            isLoading={loading}
            noData={<Translate label={TableLocale.NO_DATA} />}
          />
        </Box>
        <Box mt={10}>
          <H3><Translate label={CandidateScreeningDetailLocale.REPORT_TEXT} /></H3>
          <Textarea
            mt={5}
            minHeight="60px"
            value={reportText}
            onChange={(event) => setReportText(event.target.value)}
          />
          <Flex mt={8}>
            <Spacer />
            <Button
              ml={3}
              onClick={updateReportText}
              isLoading={mutateReportTextOptions.loading}
            >
              <Translate label={FormLocale.SAVE} />
            </Button>
          </Flex>
        </Box>

      </SectionContainer>
    </>
  );
};

export default CandidateScreeningDetail;
