import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Box, Button,
  Collapse,
  Flex, FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FilterButton, InfoContainer } from '@scaut-sro/meepo';
import { FiFilter, FiX } from 'react-icons/fi';
import { Select } from 'chakra-react-select';
import { TasksTableFilterProps, TasksTableFilterType } from './Tasks.model';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../core/localization/LocaleUtils';
import { StatesLocale } from '../../components/StateBadge/StateBadge.model';
import TasksLocale from './Tasks.locale';
import Translate from '../../components/Translate/Translate';
import { useGetAdminUsers } from '../../build/generated-sources/service/QueryService';

const TasksTableFilter: React.FunctionComponent<TasksTableFilterProps> = (props) => {
  const { filter, onFilterChange, routeFilter } = props;
  const { language } = useGetUser();
  const [states, setStates] = useState<{ value: string, label: string }[]>([]);
  const [assigneeIds, setAssigneeIds] = useState<{ value: number, label: string }[]>([]);
  const {
    handleSubmit, register, reset, setValue,
  } = useForm();
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);

  const handleFilterSubmit: SubmitHandler<TasksTableFilterType> = (values) => {
    onFilterChange(values);
  };

  const handleFilterCancel = () => {
    handleFilterSubmit({});
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      formRef.current?.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }
  };

  const { data } = useGetAdminUsers({
    id: true,
    firstName: true,
    lastName: true,
  });

  const assigneeIdsOptions = useMemo(() => (
    data?.adminUsers.map((dataUser) => ({
      value: dataUser.id || -1,
      label: `${dataUser.firstName} ${dataUser.lastName}`,
    }))
  ), [data]);

  const activeFilters: boolean = useMemo(() => {
    const filteredObjKeys = Object.keys(filter).filter((key) => filter[key] && filter[key].length);
    return !!filteredObjKeys.length;
  }, [filter]);

  const stateOptions = useMemo(() => ([
    { value: 'STARTED', label: translate(StatesLocale.STARTED, language) },
    { value: 'FINISHED', label: translate(StatesLocale.FINISHED, language) },
  ]), [language]);

  useEffect(() => {
    register('states');
    register('orderId');
    register('client');
    register('candidate');
    register('assigneeIds');
  }, [register]);

  useEffect(() => {
    reset({
      states: filter.states,
      fulltext: filter.fulltext,
      orderId: filter.orderId,
      client: filter.client,
      candidate: filter.candidate,
      assigneeIds: filter.assigneeIds,
    });
    setStates(filter.states?.map((rec) => stateOptions.filter((option) => option.value === rec as string)[0]) || []);
    if (assigneeIdsOptions) {
      setAssigneeIds(
        filter.assigneeIds?.map(
          (rec) => assigneeIdsOptions.filter(
            (option) => option.value === rec,
          )[0],
        ) || [],
      );
    }
  }, [filter, reset, stateOptions, assigneeIdsOptions]);

  return (
    <Box>
      <form
        name="checkTableFilter"
        onSubmit={handleSubmit((values) => {
          handleFilterSubmit(values);
        })}
        ref={formRef}
      >
        <Flex>
          <InputGroup zIndex={0}>
            <InputLeftElement>
              <SearchIcon />
            </InputLeftElement>
            <Input
              id="fulltext"
              variant="filled"
              onKeyUp={handleKeyUp}
              {...register('fulltext')}
            />
          </InputGroup>
          <Box ml={3}>
            <FilterButton
              activeFilters={activeFilters}
              openFilters={() => { setOpenFilters(!openFilters); }}
              cancelFilters={handleFilterCancel}
            />
          </Box>
        </Flex>
        <Collapse in={openFilters} animateOpacity>
          <Box mt={3}>
            <InfoContainer>
              <SimpleGrid gap={6} columns={[1, 1, 2, 2, 2, 3]}>
                <FormControl>
                  <FormLabel fontSize="sm"><Translate label={TasksLocale.STATES} /></FormLabel>
                  <Select
                    size="sm"
                    name="states"
                    isMulti
                    closeMenuOnSelect={false}
                    value={states}
                    options={stateOptions}
                    onChange={(values: { value: string, label: string }[]) => {
                      setValue('states', values.map((value) => value.value));
                      setStates(values);
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="sm"><Translate label={TasksLocale.ORDER_ID} /></FormLabel>
                  <Input
                    size="sm"
                    {...register('orderId')}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="sm"><Translate label={TasksLocale.CLIENT} /></FormLabel>
                  <Input
                    size="sm"
                    {...register('client')}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="sm"><Translate label={TasksLocale.CANDIDATE} /></FormLabel>
                  <Input
                    size="sm"
                    {...register('candidate')}
                  />
                </FormControl>
                { routeFilter && routeFilter !== 'assigned-to-me' && routeFilter !== 'unassigned' && (
                <FormControl>
                  <FormLabel fontSize="sm"><Translate label={TasksLocale.ASSIGNEE} /></FormLabel>
                  <Select
                    size="sm"
                    name="assigneeIds"
                    isMulti
                    closeMenuOnSelect={false}
                    value={assigneeIds}
                    options={assigneeIdsOptions}
                    onChange={(values: { value: number, label: string }[]) => {
                      setValue('assigneeIds', values.map((value) => value.value));
                      setAssigneeIds(values);
                    }}
                  />
                </FormControl>
                )}

              </SimpleGrid>
              <Flex mt={6} justifyContent="flex-end">
                <Button
                  size="sm"
                  colorScheme="red"
                  isDisabled={!activeFilters}
                  onClick={handleFilterCancel}
                  variant="ghost"
                  leftIcon={<FiX />}
                >
                  Cancel filter
                </Button>
                <Button
                  size="sm"
                  ml={3}
                  leftIcon={<FiFilter />}
                  type="submit"
                >
                  Filter
                </Button>
              </Flex>
            </InfoContainer>
          </Box>
        </Collapse>
      </form>
    </Box>
  );
};

export default TasksTableFilter;
