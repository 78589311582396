import React, { useRef, useState } from 'react';
import {
  Button, ButtonGroup, HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent, ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { Translate } from '@scaut-sro/meepo';
import PayInvoiceForm from './PayInvoiceForm';
import FormLocale from '../../../../../core/localization/translations/Form.locale';
import { ClientPayInvoiceProps } from '../ClientInvoices.model';
import ClientInvoicesLocale from '../ClientInvoices.locale';

const PayInvoiceModal: React.FunctionComponent<ClientPayInvoiceProps> = (props) => {
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const [formIsLoading, setFormIsLoading] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);
  const { invoiceId, refreshData } = props;

  return (
    <>
      <Button onClick={onModalOpen} variant="ghost" colorScheme="green" leftIcon={<AddIcon />}>
        <Translate label={ClientInvoicesLocale.PAY_INVOICE} />
      </Button>
      <Modal closeOnOverlayClick={false} size="4xl" isOpen={isModalOpen} onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text><Translate label={ClientInvoicesLocale.PAY_INVOICE} /></Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PayInvoiceForm
              refreshData={refreshData}
              loadingSetter={(isLoading) => { setFormIsLoading(isLoading); }}
              formRef={formRef}
              invoiceId={invoiceId}
            />
          </ModalBody>
          <ModalFooter>
            <HStack>
              <ButtonGroup>
                <Button
                  onClick={() => {
                    if (formRef) {
                      formRef.current?.dispatchEvent(new Event('submit', {
                        cancelable: true,
                        bubbles: true,
                      }));
                      onModalClose();
                    }
                  }}
                  isLoading={formIsLoading}
                >
                  <Translate label={FormLocale.SAVE} />
                </Button>
                <Button variant="ghost" colorScheme="red" onClick={onModalClose}>
                  <Translate label={FormLocale.CANCEL} />
                </Button>
              </ButtonGroup>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PayInvoiceModal;
