export enum OrderState {
  DRAFT = 'DRAFT',
  WAITING_FOR_CLIENT = 'WAITING_FOR_CLIENT',
  WAITING_FOR_CANDIDATE = 'WAITING_FOR_CANDIDATE',
  WAITING_FOR_ADMIN = 'WAITING_FOR_ADMIN',
  WAITING_FOR_POA = 'WAITING_FOR_POA',
  PROCESSING = 'PROCESSING',
  FOR_APPROVAL = 'FOR_APPROVAL',
  FINISHED = 'FINISHED',
  CANNOT_COMPLETE = 'CANNOT_COMPLETE',
}
