import React from 'react';
import { Route } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { RouteProps } from 'react-router';
import NoMatch from '../../views/404/404';
import ScautAdminRoles from '../../core/auth/ScautAdminRoles';

interface PrivateRouteProps extends RouteProps{
  requiredRoles: ScautAdminRoles[]
}

const PrivateRoute: React.FunctionComponent<PrivateRouteProps> = (
  { children, requiredRoles, ...route },
) => {
  const { keycloak } = useKeycloak();

  let isAuthorized = false;

  requiredRoles.forEach((role) => {
    if (keycloak.hasResourceRole(role, 'scaut-admin-app')) {
      isAuthorized = true;
    }
  });

  return (
    <>
      {
          isAuthorized ? (
            <Route {...route}>
              {children}
            </Route>
          ) : (<NoMatch />)
        }
    </>
  );
};

export default PrivateRoute;
