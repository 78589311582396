import { Translation } from '../../core/localization/Localization.model';

class ClientsLocale {
  public static USER_DETAIL: Translation = { EN: 'User detail', CZ: 'Seznam detail' };
  public static NAME: Translation = { EN: 'Name', CZ: 'Název' };
  public static ID: Translation = { EN: 'ID', CZ: 'IČO' };
  public static VAT: Translation = { EN: 'VAT NR.', CZ: 'DIČ' };
  public static CLIENT_EMAIL: Translation = { EN: 'Company email', CZ: 'Email společnosti' };
  public static CLIENT_PHONE: Translation = { EN: 'Company phone', CZ: 'Telefon společnosti' };
  public static COUNTRY: Translation = { EN: 'Country', CZ: 'Země' };
  public static COUNTRIES: Translation = { EN: 'Countries', CZ: 'Zemí' };
  public static CLIENTS: Translation = { EN: 'Clients', CZ: 'Klienti' };
  public static FIRST_NAME: Translation = { EN: 'First name', CZ: 'Jméno' };
  public static LAST_NAME: Translation = { EN: 'Last name', CZ: 'Příjmení' };
  public static YES: Translation = { EN: 'Yes', CZ: 'Ano' };
  public static NO: Translation = { EN: 'No', CZ: 'Ne' };
  public static EMAIL: Translation = { EN: 'Email', CZ: 'Email' };
  public static EMAIL_PLACEHOLDER: Translation = { EN: 'john.doe@mycompany.com', CZ: 'jan.novak@mojefirma.CZ' };
  public static PHONE: Translation = { EN: 'Phone', CZ: 'Telefon' };
  public static PHONE_PLACEHOLDER: Translation = { EN: '+44 1234 1234', CZ: '+420123456789' };
  public static PHONE_ERROR_MIN_LENGTH: Translation = {
    EN: 'Phone has to have more than 8 numbers', CZ: 'Telefon musí mít více jak 8 číslic',
  };
  public static PHONE_ERROR_MAX_LENGTH: Translation = {
    EN: 'Phone has to have less than 15 numbers', CZ: 'Telefon musí mít méně než 15 číslic',
  };
  public static STREET: Translation = { EN: 'Street', CZ: 'Ulice' };
  public static CITY: Translation = { EN: 'City', CZ: 'Město' };
  public static ZIP: Translation = { EN: 'ZIP', CZ: 'PSČ' };
  public static CLIENT_DETAIL: Translation = { EN: 'Client detail', CZ: 'Klient detail' };
  public static CLIENT_USER_DETAIL: Translation = { EN: 'Client user detail', CZ: 'Detail uživatele klienta' };
  public static ACTIVE: Translation = { EN: 'Active', CZ: 'Aktivní' };
  public static DEACTIVATED: Translation = { EN: 'Deactivated', CZ: 'Deaktivovan' };
  public static SCREENING_APPROVALS: Translation = { EN: 'Screening approvals', CZ: 'Kontrolní povolení' };
  public static ANONYMIZE_AFTER: Translation = { EN: 'Anonymize after', CZ: 'Anonymizovat po' };
  public static ALLOWED_PAYMENT_METHODS: Translation = { EN: 'Payment methods', CZ: 'Platební metody' };
  public static ALLOW_ADD_PREPAYMENT: Translation = { EN: 'Allow client to prepay', CZ: 'Povolit předplacení klientovi' };
  public static CARD_PAYMENT: Translation = { EN: 'Card payment', CZ: 'Platební karta' };
  public static INVOICE_AFTER_ORDER: Translation = { EN: 'Invoice after order', CZ: 'Faktura po objednávce' };
  public static INVOICE_MONTHLY: Translation = { EN: 'Invoice monthly', CZ: 'Měsíční faktura' };
  public static PREPAID: Translation = { EN: 'Prepaid', CZ: 'Předplacené' };
  public static TEN_DAYS: Translation = { EN: '10 days', CZ: '10 dnů' };
  public static THIRTY_DAYS: Translation = { EN: '30 days', CZ: '30 dnů' };
  public static SIXTY_DAYS: Translation = { EN: '60 days', CZ: '60 dnů' };
  public static NINETY_DAYS: Translation = { EN: '90 days', CZ: '90 dnů' };
  public static YEAR: Translation = { EN: '1 year', CZ: '1 rok' };
  public static INVOICE_PARTS: Translation = { EN: 'Invoice parts', CZ: 'Části faktury' };
  public static DEFAULT_LANGUAGE: Translation = { EN: 'Default language', CZ: 'Výchozí jazyk' };
  public static COMMUNICATION_LANGUAGE: Translation = { EN: 'Communication language', CZ: 'Jazyk komunikace' };
  public static INCLUDE_INDIVIDUAL_CHECKS: Translation = { EN: 'Include individual checks', CZ: 'Včetně jednotlivých kontrol' };
  public static LIST_CANDIDATES: Translation = { EN: 'Candidates list', CZ: 'Seznam uchazečů' };
  public static SUPERVISOR: Translation = { EN: 'Supervisor', CZ: 'Nadřízený' };
  public static ALLOWED_COUNTRIES: Translation = { EN: 'Allowed countries', CZ: 'Povolené země' };
  public static DEFAULT_COUNTRIES: Translation = { EN: 'Default countries', CZ: 'Hlavní země' };
  public static SCREENING_PACKAGES: Translation = { EN: 'Screening packages', CZ: 'Produktové balíčky' };
  public static LAST_ACTIVE: Translation = { EN: 'Last active', CZ: 'Poslední aktivní' };
  public static USER_ROLE: Translation = { EN: 'Role', CZ: 'Role' };
  public static ENTERPRISE: Translation = { EN: 'Enterprise', CZ: 'Podnik' };
  public static ENABLE_2FA: Translation = { EN: 'Two-factor authentication', CZ: 'Dvoufaktorové ověření' };
  public static TEAMS: Translation = { EN: 'Teams', CZ: 'Týmy' };
  public static GENERAL: Translation = { EN: 'General', CZ: 'Obecné' };
  public static USERS: Translation = { EN: 'Users', CZ: 'Uživatelé' };
  public static ORDERS: Translation = { EN: 'Orders', CZ: 'Objednávky' };
  public static INVOICES: Translation = { EN: 'Invoices', CZ: 'Faktury' };
  public static SELECT_VALUE: Translation = { EN: 'Select value', CZ: 'Vyberte hodnotu' };
  public static ADD_PREPAYMENT: Translation = { EN: 'Add prepayment', CZ: 'Předplatit' };
  public static PAYMENT_TYPE: Translation = { EN: 'Payment type', CZ: 'Typ platby' };
  public static NOTE: Translation = { EN: 'Note', CZ: 'Poznámka' };
  public static CASH: Translation = { EN: 'Cash', CZ: 'Hotovost' };
  public static CHECK: Translation = { EN: 'Check', CZ: 'Šek' };
  public static OTHER: Translation = { EN: 'Other', CZ: 'Jiné' };
  public static AMOUNT: Translation = { EN: 'Amount', CZ: 'Částka' };
  public static MODAL_CREATE_CLIENT_TITLE: Translation = { EN: 'Client registration', CZ: 'Registrace klienta' };
  public static EN: Translation = { EN: 'English', CZ: 'Angličtina' };
  public static ENGLISH: Translation = { EN: 'English', CZ: 'Angličtina' };
  public static CZ: Translation = { EN: 'Czech', CZ: 'Čeština' };
  public static CZECH: Translation = { EN: 'Czech', CZ: 'Čeština' };
  public static DELETE_CLIENT: Translation = { EN: 'Delete client', CZ: 'Smazat klienta' };
  public static DEACTIVATE_CLIENT: Translation = { EN: 'Deactivate client', CZ: 'Deaktivovat klienta' };
  public static ACTIVATE_CLIENT: Translation = { EN: 'Activate client', CZ: 'Aktivovat klienta' };
  public static DELETE_CLIENT_ARE_YOU_SURE: Translation = {
    EN: 'Are you sure you want to delete client',
    CZ: 'Opravdu chcete smazat klienta',
  };
  public static DEACTIVATE_CLIENT_ARE_YOU_SURE: Translation = {
    EN: 'Are you sure you want to deactivate client',
    CZ: 'Opravdu chcete deaktivovat klienta',
  };
  public static ACTIVATE_CLIENT_ARE_YOU_SURE: Translation = {
    EN: 'Are you sure you want to activate client',
    CZ: 'Opravdu chcete aktivovat klienta',
  };
  public static ERROR_GETTING_CLIENT_DATA: Translation = {
    EN: 'There was a problem getting client data.',
    CZ: 'Při získávání klientských dat došlo k problému.',
  };
  public static ERROR_GETTING_SUPERVISORS: Translation = {
    EN: 'There was a problem getting supervisors data.',
    CZ: 'Při získávání seznamu nadřízených došlo k problému.',
  };
  public static ERROR_GETTING_COUNTRIES: Translation = {
    EN: 'There was a problem getting countries.',
    CZ: 'Problém při načitání zemí',
  };
  public static ERROR_SAVING_DATA: Translation = {
    EN: 'There was a problem saving data.',
    CZ: 'Při ukládání dat došlo k problému.',
  };
  public static CLIENT_DELETED: Translation = {
    EN: 'client deleted',
    CZ: 'Klient smazán',
  };
  public static ARE_YOU_SURE: Translation = {
    EN: 'Are you sure you want to delete client',
    CZ: 'Opravdu chcete smazat klienta',
  };
  public static ERROR_GETTING_TEAMS: Translation = {
    EN: 'There was a problem getting teams.',
    CZ: 'Problém při načitání týmů.',
  };
  public static ERROR_GETTING_DATA: Translation = {
    EN: 'There was a problem getting data',
    CZ: 'Při získávání dat došlo k problému',
  };
  public static ERROR_DELETING_USER: Translation = {
    EN: 'There was a problem deleting user.',
    CZ: 'Při mazání uživatele došlo k problému.',
  };
}

export default ClientsLocale;
