import { Translation } from '../Localization.model';

class MainMenuLocale {
  public static OVERVIEW: Translation = { EN: 'Overview', CZ: 'Přehled' };
  public static ORDERS: Translation = { EN: 'Orders', CZ: 'Objednávky' };
  public static OPEN_ORDERS: Translation = { EN: 'Open', CZ: 'Otevřené' };
  public static ASSIGNED_TO_ME_ORDERS: Translation = { EN: 'Assigned to me', CZ: 'Přiřazené na mě' };
  public static UNASSIGNED_ORDERS: Translation = { EN: 'Unassigned', CZ: 'Nepřiřazené' };
  public static OPEN_TASKS: Translation = { EN: 'Open', CZ: 'Otevřené' };
  public static ASSIGNED_TO_ME_TASKS: Translation = { EN: 'Assigned to me', CZ: 'Přiřazené na mě' };
  public static UNASSIGNED_TASKS: Translation = { EN: 'Unassigned', CZ: 'Nepřiřazené' };
  public static TASKS: Translation = { EN: 'Tasks', CZ: 'Úkoly' };
  public static CLIENTS: Translation = { EN: 'Clients', CZ: 'Klienti' };
  public static CATALOGUE: Translation = { EN: 'Catalogue', CZ: 'Katalog' };
  public static COMPONENTS: Translation = { EN: 'Components', CZ: 'Komponenty' };
  public static SCREENINGS: Translation = { EN: 'Screenings', CZ: 'Kontroly' };
  public static PACKAGES: Translation = { EN: 'Packages', CZ: 'Balíčky' };
  public static TRANSLATION: Translation = { EN: 'Translations', CZ: 'Překlady' };
  public static DATABASE: Translation = { EN: 'Database', CZ: 'Databáze zdrojů' };
  public static USERS: Translation = { EN: 'Users', CZ: 'Uživatelé' };
  public static COUNTRIES: Translation = { EN: 'Countries', CZ: 'Země' };
  public static FAQ: Translation = { EN: 'FAQ', CZ: 'FAQ' };
  public static PROCESS_UPLOAD: Translation = { EN: 'Process Upload', CZ: 'Upload procesu' };
  public static ENUMERATIONS: Translation = { EN: 'Enumerations', CZ: 'Výčty' };
  public static INVOICES: Translation = { EN: 'Invoices', CZ: 'Faktury' };
}

export default MainMenuLocale;
