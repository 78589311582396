import { Translation } from '../../core/localization/Localization.model';

class NotificationBoxLocale {
  public static NEWLY_COMPLETED: Translation = { EN: 'Newly completed', CZ: 'Nově dokončené' };
  public static WAITING_FOR_YOU: Translation = { EN: 'Waiting for you', CZ: 'Čeká na vás' };
  public static WAITING_FOR_CANDIDATE: Translation = { EN: 'Waiting for candidate', CZ: 'Čeká na kandidáta' };
  public static PROCESSING: Translation = { EN: 'Processing', CZ: 'Zpracovává se' };
  public static TOTAL: Translation = { EN: 'persons total', CZ: 'osob celkem' };
  public static PROCESSED: Translation = { EN: 'persons checked', CZ: 'prověřených osob' };
  public static WITH_RESULTS: Translation = { EN: 'with result', CZ: 's nálezem' };
  public static CLEAR: Translation = { EN: 'clear', CZ: 'v pořádku' };
  public static LAST: Translation = { EN: 'Last record', CZ: 'Poslední' };
}

export default NotificationBoxLocale;
