import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Divider,
  FormControl, FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Tr, Center,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { getOption, H4, Toast } from '@scaut-sro/meepo';
import { Select } from 'chakra-react-select';
import { TranslationsDetailProps, TranslationsFormAttr } from '../Translations.model';
import { translate } from '../../../core/localization/LocaleUtils';
import FormLocale from '../../../core/localization/translations/Form.locale';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import { useTranslation } from '../../../build/generated-sources/service/MutationService';
import TranslationsLocale from '../Translations.locale';
import { Language } from '../../../build/generated-sources/enum/Language';
import Translate from '../../../components/Translate/Translate';
import TranslationUsageDetail from './TranslationUsageDetail';
import { LocalizationStringGraphQLInput } from '../../../build/generated-sources/dto/LocalizationStringGraphQLInput';
import { TranslationType } from '../../../build/generated-sources/enum/TranslationType';

const TranslationDetailForm: React.FunctionComponent<TranslationsDetailProps> = (props) => {
  const {
    translation, loadingSetter, formRef, onClose, onTranslationChosen, chooseModalOnClose,
  } = props;
  const { language } = useGetUser();
  const [error, setErrror] = useState(false);

  const translationTypeOptionsList = useMemo(() => ([
    { value: 'GENERAL', label: translate(TranslationsLocale.GENERAL, language) },
    { value: 'SURVEY', label: translate(TranslationsLocale.SURVEY, language) },
    { value: 'COUNTRY', label: translate(TranslationsLocale.COUNTRY, language) },
    { value: 'DYNAMIC_FIELD', label: translate(TranslationsLocale.DYNAMIC_FIELD, language) },
    { value: 'FAQ', label: translate(TranslationsLocale.FAQ, language) },
    { value: 'TEMPLATE_INVOICE', label: translate(TranslationsLocale.TEMPLATE_INVOICE, language) },
    { value: 'TEMPLATE_POA', label: translate(TranslationsLocale.TEMPLATE_POA, language) },
    { value: 'TEMPLATE_REPORT', label: translate(TranslationsLocale.TEMPLATE_REPORT, language) },
    { value: 'TEMPLATE_AUTHORIZATION', label: translate(TranslationsLocale.TEMPLATE_AUTHORIZATION, language) },
  ]), [language]);

  const defaultValues: Partial<TranslationsFormAttr> = useMemo(() => {
    const data = {
      id: translation?.id,
      name: translation?.name,
      type: translation?.type
        ? getOption(translation?.type, translationTypeOptionsList)
        : { value: TranslationType.GENERAL, label: translate(TranslationsLocale.GENERAL, language) },
    };
    Object.keys(Language).forEach((lang) => {
      // @ts-ignore
      data[lang] = translation?.localizationStrings?.[lang] || '';
    });

    return data;
  }, [translation, language, translationTypeOptionsList]);

  const {
    register, getValues, formState: { errors }, control, trigger,
  } = useForm<Partial<TranslationsFormAttr>>({
    defaultValues,
  });

  const [mutateTranslation,
    optionsMutationTranslation] = useTranslation({
    id: true,
    name: true,
    localizationStrings: true,
    screeningDefinitionNames: {
      id: true,
      internalName: true,
    },
    screeningDefinitionDescriptions: {
      id: true,
      internalName: true,
    },
    screeningPackageNames: {
      id: true,
      internalName: true,
    },
    screeningPackageDescriptions: {
      id: true,
      internalName: true,
    },
    countryNames: {
      id: true,
    },
    faqQuestions: {
      id: true,
    },
    faqAnswers: {
      id: true,
    },
  }, {
    onCompleted: (response) => {
      Toast({
        title: translate(FormLocale.SUCCESSFULLY_SAVED, language),
        status: 'success',
      });
      setErrror(true);
      if (onClose) {
        onClose();
      }
      if (onTranslationChosen) {
        onTranslationChosen(response.translation);
      }
      if (chooseModalOnClose) {
        chooseModalOnClose();
      }
    },
    onError: () => {
      setErrror(true);
    },
  });

  useEffect(() => {
    if (loadingSetter) {
      loadingSetter(optionsMutationTranslation.loading);
    }
  }, [loadingSetter, optionsMutationTranslation]);

  const translationsFormSubmit = () => {
    let valid = true;
    if (!getValues().name) {
      valid = false;
    }
    Object.keys(Language).map((lang) => {
      if (!getValues()[lang]) {
        valid = false;
      }
    });
    if (valid) {
      const localizationStrings: LocalizationStringGraphQLInput[] = [];
      Object.keys(Language).forEach((lang) => {
        localizationStrings.push({
          key: lang as Language,
          value: getValues()[lang],
        });
      });
      mutateTranslation({
        variables: {
          translationId: translation?.id,
          translation: {
            name: getValues().name,
            type: getValues().type?.value as TranslationType,
            localizationStrings,
          },
        },
      });
    }
  };

  return (
    <>
      <form
        name="translationDetailForm"
        onSubmit={(event) => {
          event.stopPropagation();
          trigger();
          return translationsFormSubmit();
        }}
        ref={formRef}
      >
        <SimpleGrid columns={[1, 1, 2]} spacing={6} mb={7}>
          <Box>
            <Box>
              <H4><Translate label={TranslationsLocale.GENERAL} /></H4>
            </Box>
            <FormControl isRequired isInvalid={!!errors.name} mb={6}>
              <SimpleGrid columns={[1]} gap={4} mt={4}>
                <FormLabel htmlFor="name">
                  <Translate label={TranslationsLocale.NAME} />
                </FormLabel>
              </SimpleGrid>
              <Input
                isInvalid={error}
                errorBorderColor="red.300"
                placeholder={translate(TranslationsLocale.NAME, language)}
                {...register('name', {
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                })}
              />
              {error
                && (
                <Center color="red" fontSize="13">
                  <Translate label={TranslationsLocale.ERROR_NAME_TRANSLATION} />
                </Center>
                )}
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.type} mb={6}>
              <SimpleGrid columns={[1]} gap={4} mt={4}>
                <FormLabel htmlFor="type">
                  <Translate label={TranslationsLocale.TYPE} />
                </FormLabel>
              </SimpleGrid>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={translationTypeOptionsList}
                    hideSelectedOptions={false}
                    selectedOptionStyle="check"
                  />
                )}
              />
              <FormErrorMessage>
                {errors.type && errors.type.message}
              </FormErrorMessage>
            </FormControl>
            <Divider mb={6} />
            <Box>
              <H4><Translate label={TranslationsLocale.USAGES} /></H4>
            </Box>
            <TranslationUsageDetail translation={translation} />
          </Box>
          <Box>
            <Box>
              <H4><Translate label={TranslationsLocale.TRANSLATIONS} /></H4>
            </Box>
            <SimpleGrid columns={[1]} gap={4} mt={4}>
              <Table mt={6} size="sm">
                <Tbody>
                  {Object.keys(Language).map((lang, index) => (
                    <>
                      <Tr
                        borderTopLeftRadius={index === 0 ? '10px' : '0px'}
                        borderBottomLeftRadius={index === Object.keys(Language).length - 1 ? '10px' : '0px'}
                      >
                        <Td
                          background="#cfd2d575"
                          borderTopLeftRadius={index === 0 ? '10px' : '0px'}
                          borderBottomLeftRadius={index === Object.keys(Language).length - 1 ? '10px' : '0px'}
                          width="8%"
                        >
                          <b>{lang}</b>
                        </Td>
                        <Td padding="0">
                          <FormControl isRequired isInvalid={!!errors[lang]} mb={6}>
                            <Input
                              height="60px"
                              border="none"
                              placeholder={lang}
                              {...register(lang, {
                                required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                              })}
                            />
                            <FormErrorMessage>
                              {errors[lang] && errors[lang].message}
                            </FormErrorMessage>
                          </FormControl>
                        </Td>
                      </Tr>
                    </>
                  ))}
                </Tbody>
              </Table>
            </SimpleGrid>
          </Box>
        </SimpleGrid>
      </form>
    </>
  );
};

export default TranslationDetailForm;
