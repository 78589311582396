import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { H4, Note } from '@scaut-sro/meepo';

const ListOfServiceLoader: React.FunctionComponent = () => (
  <Accordion defaultIndex={[0]} allowMultiple>
    <AccordionItem>
      <Skeleton>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            <H4 mt="12px">This is Country label</H4>
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </Skeleton>
      <AccordionPanel p={6}>
        <Box mb={2}>
          <Skeleton><Text>Item</Text></Skeleton>
          <Skeleton><Note ml={3}>Subitem</Note></Skeleton>
          <Skeleton><Note ml={3}>Subitem</Note></Skeleton>
        </Box>
        <Box mb={2}>
          <Skeleton><Text>Item</Text></Skeleton>
        </Box>
        <Box mb={2}>
          <Skeleton><Text>Item</Text></Skeleton>
        </Box>
      </AccordionPanel>
    </AccordionItem>
    <AccordionItem>
      <Skeleton>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            <H4 mt="12px">This is Country label</H4>
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </Skeleton>
    </AccordionItem>
  </Accordion>
);

export default ListOfServiceLoader;
