import React, { useEffect, useMemo, useState } from 'react';
import {
  H1, H3, H4, MeepoTable, MeepoTag, P, ReadOnlyInput, SectionContainer, TagArea, Toast,
} from '@scaut-sro/meepo';
import {
  Accordion,
  AccordionButton, AccordionIcon, AccordionItem, AccordionPanel,
  Box, Button, Flex, FormControl, FormLabel, Heading, Icon, Select, SimpleGrid, Skeleton, Spacer, Text, VStack,
} from '@chakra-ui/react';
import { useRouteMatch } from 'react-router-dom';
import { AiOutlineNumber, AiOutlineTeam } from 'react-icons/ai';
import { FiUsers } from 'react-icons/fi';
import { SubmitHandler, useForm } from 'react-hook-form';
import Translate from '../../components/Translate/Translate';
import { useGetAdminUsers, useLazyGetPrimaryOrderDetail } from '../../build/generated-sources/service/QueryService';
import { StateBadge } from '../../components/StateBadge/StateBadge';
import { PrimaryOrderDetail } from '../../build/generated-sources/dto/PrimaryOrderDetail';
import { ResultBadge } from '../../components/ResultBadge/ResultBadge';
import { translate } from '../../core/localization/LocaleUtils';
import ChecksLocale from '../Checks/Checks.locale';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import DetailHeader from '../../components/DetailHeader/DetailHeader';
import MultipleCheckDetailLocale from './MultipleCheckDetail.locale';
import FormLocale from '../../core/localization/translations/Form.locale';
import { showDate } from '../../core/date/showDate';
import { CandidateScreening } from '../../build/generated-sources/dto/CandidateScreening';
import TableLocale from '../../core/localization/translations/Table.locale';
import { ScreeningsTableData } from './MultipleChecksDetail.model';
import { OrderType } from '../../build/generated-sources/enum/OrderType';
import UserIcon from '../Checks/UserIcon';
import SingleCheckDetail from '../Checks/SingleCheckDetail';
import ShowDate from '../../components/ShowDate/ShowDate';
import { ResultsLocale } from '../../components/ResultBadge/ResultBadge.model';
import { Result } from '../../build/generated-sources/enum/Result';
import CandidateScreeningDetailLocale from '../CandidateScreeningDetail/CandidateScreeningDetail.locale';
import { useAccountManager } from '../../build/generated-sources/service/MutationService';
import { handleApolloErrors } from '../../core/error/ScautErrorHandler';
import { LocalDate } from '../../build/scalars/LocalDate';

function getScreeningsTableData(data: CandidateScreening[] | undefined, anonymizationDate?: LocalDate): ScreeningsTableData[] {
  if (data) {
    return data.map((candidateScreening) => ({
      id: candidateScreening.id || '-undefined-',
      detail: <SingleCheckDetail candidateScreening={candidateScreening} anonymizationDate={anonymizationDate} />,
      type: <UserIcon type={OrderType.SINGLE} />,
      firstName: candidateScreening.candidateIdentity?.firstName,
      lastName: candidateScreening.candidateIdentity?.lastName,
      dateOfBirth: <ShowDate date={candidateScreening.candidateIdentity?.dateOfBirth} />,
      result: <ResultBadge result={candidateScreening.result || undefined} />,
      state: <StateBadge state={candidateScreening.state || undefined} />,
    }));
  }
  return [];
}

const MultipleCheckDetail: React.FunctionComponent = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const primaryOrderId = Number.isNaN(+params.id);
  const [data, setData] = useState<PrimaryOrderDetail | undefined>();
  const { language } = useGetUser();
  const [list, setList] = useState<ScreeningsTableData[]>([]);
  const {
    register, setValue, handleSubmit,
  } = useForm();
  const [accountManager, setAccountManager] = useState<number | string | undefined>('');
  const [formChanged, setFormChanged] = useState<boolean>(false);

  const [mutateAccountManager, mutateAccountManagerOptions] = useAccountManager(
    {
      id: true,
    },
    {
      onCompleted: () => {
        Toast({
          title: translate(MultipleCheckDetailLocale.CHANGE_ACCOUNT_MANAGER_SUCCESS, language),
          status: 'success',
        });
        setFormChanged(false);
      },
      onError: (error) => {
        handleApolloErrors(error, language);
      },
    },
  );

  const allUsers = useGetAdminUsers({
    id: true,
    firstName: true,
    lastName: true,
  });

  useEffect(() => {
    register('accountManager');
  }, [register]);

  const [orderDetailHandle, orderDetailHandleOptions] = useLazyGetPrimaryOrderDetail(
    {
      anonymizationDate: true,
      accountManager: {
        id: true,
        firstName: true,
        lastName: true,
      },
      order: {
        id: true,
        name: true,
        clientOrderCustomId: true,
        type: true,
        price: true,
        processStarted: true,
        processCompleted: true,
        team: {
          name: true,
        },
        orderState: true,
        result: true,
        subOrders: {
          candidateScreenings: {
            id: true,
            result: true,
          },
          screeningPackages: {
            id: true,
            name: {
              localizationStrings: true,
            },
          },
          screeningDefinitions: {
            id: true,
            name: {
              localizationStrings: true,
            },
          },
        },
      },
      suborderProducts: {
        subOrder: {
          candidateScreenings: {
            id: true,
            state: true,
            result: true,
            processStarted: true,
            processCompleted: true,
            candidateIdentity: {
              firstName: true,
              lastName: true,
              dateOfBirth: true,
              email: true,
            },
            screenings: {
              id: true,
              result: true,
              state: true,
              screeningDefinition: {
                id: true,
                name: { localizationStrings: true },
                description: { localizationStrings: true },
              },
            },
          },
          candidateIdentities: {
            firstName: true,
            lastName: true,
            dateOfBirth: true,
          },
        },
        products: {
          screeningDefinitions: {
            id: true,
            name: { localizationStrings: true },
            description: { localizationStrings: true },
            price: true,
          },
          screeningPackages: {
            id: true,
            name: { localizationStrings: true },
            description: { localizationStrings: true },
            price: true,
            screeningDefinitions: {
              id: true,
              name: { localizationStrings: true },
              description: { localizationStrings: true },
            },
          },
          country: {
            translation: { localizationStrings: true },
          },
        },
      },
    }, {
      onCompleted: (response) => {
        const onCompleteData = getScreeningsTableData(
          response?.primaryOrderDetail?.suborderProducts?.[0].subOrder?.candidateScreenings,
          response?.primaryOrderDetail?.anonymizationDate,
        );
        setList(onCompleteData);
        setData(response.primaryOrderDetail);
        setAccountManager(response.primaryOrderDetail.accountManager?.id);
      },
      onError: () => {
        Toast({
          title: translate(MultipleCheckDetailLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      },
      variables: {
        primaryOrderId: +params.id,
      },
    },
  );

  useEffect(() => {
    if (!primaryOrderId) {
      orderDetailHandle({
        variables: {
          primaryOrderId: +params.id,
        },
      });
    }
  }, [primaryOrderId, params.id, orderDetailHandle]);

  const badResultCount = useMemo(() => {
    let count = 0;
    data?.order?.subOrders?.[0].candidateScreenings?.forEach((screening) => {
      if (screening.result === Result.FOUND_RESULTS) count += 1;
    });
    return count;
  }, [data]);

  const services: string = useMemo(() => {
    let str = '';
    data?.order?.subOrders?.[0].screeningPackages?.forEach((scrPackage) => {
      str = `${str + scrPackage.name?.localizationStrings?.[language]}, `;
    });
    data?.order?.subOrders?.[0].screeningDefinitions?.forEach((scrDefinition) => {
      str = `${str + scrDefinition.name?.localizationStrings?.[language]}, `;
    });
    str = str.substring(0, str.length - 2);
    return str;
  }, [data, language]);

  const formOnSubmit: SubmitHandler<{ accountManager: string; }> = (values) => {
    mutateAccountManager({
      variables: {
        primaryOrderId: data?.order?.id || -1,
        accountManagerId: parseInt(values.accountManager, 10),
      },
    });
  };

  const formOnCancel = () => {
    setAccountManager(orderDetailHandleOptions.data?.primaryOrderDetail.accountManager?.id);
    setFormChanged(false);
  };

  return (
    <>
      <SectionContainer>
        <form onSubmit={handleSubmit(formOnSubmit)}>
          <DetailHeader>
            <H1 m={0} mr={6}><Translate label={MultipleCheckDetailLocale.MULTICHECK} /></H1>
            <Flex justifyContent={['flex-start', 'flex-start', 'flex-start', 'flex-end']}>
              <Flex alignItems="center" w="200px" minW="fit-content">
                <Box w="100%" ml={4}>
                  <StateBadge
                    state={data?.order?.orderState}
                    size="lg"
                  />
                  <FormControl mt={3}>
                    <FormLabel><Translate label={CandidateScreeningDetailLocale.ACCOUNT_MANAGER} /></FormLabel>
                    <Select
                      onChange={(event) => {
                        setValue('accountManager', event.target.value);
                        setAccountManager(event.target.value);
                        setFormChanged(true);
                      }}
                      value={accountManager}
                    >
                      <option value="">- Select value -</option>
                      { allUsers.data?.adminUsers.map((userData) => (
                        <option key={userData.id} value={userData.id}>
                          {`${userData.firstName} ${userData.lastName}`}
                        </option>
                      )) }
                    </Select>
                  </FormControl>
                </Box>
              </Flex>
            </Flex>
          </DetailHeader>

          <Box mb={4}>
            <Flex alignItems="center">
              <Icon as={FiUsers} fontSize={50} />
              <Box ml={4}>
                <Skeleton isLoaded={!orderDetailHandleOptions.loading}>
                  <Heading size="lg" color="primaryBase" mb={3}>
                    {data?.order?.name || 'loading'}
                  </Heading>
                </Skeleton>
                <Skeleton isLoaded={!orderDetailHandleOptions.loading}>
                  <TagArea>
                    {data?.order?.team?.name ? (
                      <MeepoTag
                        leftIcon={AiOutlineTeam}
                        label={data?.order?.team?.name}
                        tooltip={translate(MultipleCheckDetailLocale.TEAM, language)}
                      />
                    ) : undefined}
                    {data?.order?.clientOrderCustomId ? (
                      <MeepoTag
                        leftIcon={AiOutlineNumber}
                        label={data?.order?.clientOrderCustomId}
                        tooltip={translate(MultipleCheckDetailLocale.ORDER_CUSTOM_ID, language)}
                      />
                    ) : undefined}
                    {orderDetailHandleOptions.loading ? <MeepoTag label="loading" /> : undefined }
                  </TagArea>
                </Skeleton>
              </Box>
            </Flex>
          </Box>

          <Flex mb={4} alignItems="center">
            <H4>
              <Translate label={MultipleCheckDetailLocale.RESULT} />
              :
            </H4>
            <Box w="200px" ml={5}>
              <ResultBadge result={data?.order?.result} />
            </Box>
          </Flex>

          <VStack
            spacing={10}
            align="stretch"
          >
            <Accordion allowMultiple defaultIndex={[0]}>
              <AccordionItem>
                <AccordionButton p={4}>
                  <Box flex="1" textAlign="left">
                    <H3><Translate label={MultipleCheckDetailLocale.MULTIPLE_CHECK_INFO} /></H3>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4} pt={4}>
                  <Flex mb={5}>
                    <Box mr={3}>
                      <P>
                        <Translate label={MultipleCheckDetailLocale.ORDERED_SERVICES} />
                        :
                      </P>
                    </Box>
                    <Box>
                      <P>{services}</P>
                    </Box>
                  </Flex>

                  <SimpleGrid columns={[1, 2, 2, 3, 3, 4]} mt={6} gap={6}>
                    <ReadOnlyInput
                      label={translate(MultipleCheckDetailLocale.MULTIPLE_CHECK_START, language)}
                      noValue={translate(FormLocale.NO_VALUE, language)}
                      value={showDate(language, data?.order?.processStarted)}
                    />
                    <ReadOnlyInput
                      label={translate(MultipleCheckDetailLocale.MULTIPLE_CHECK_END, language)}
                      noValue={translate(FormLocale.NO_VALUE, language)}
                      value={showDate(language, data?.order?.processCompleted)}
                    />
                    <ReadOnlyInput
                      label={translate(MultipleCheckDetailLocale.PLANNED_ANONYMIZATION, language)}
                      noValue={translate(FormLocale.NO_VALUE, language)}
                      value={showDate(language, data?.anonymizationDate)}
                    />
                    <ReadOnlyInput
                      label={translate(ChecksLocale.TABLE_DETAIL_CANDIDATES_COUNT, language)}
                      noValue={translate(FormLocale.NO_VALUE, language)}
                      value={
                            (
                              `${badResultCount}/${data?.order?.subOrders?.[0].candidateScreenings?.length}`
                                + ` ${translate(ResultsLocale.FOUND_RESULTS, language)}`
                            ).toLowerCase()
                          }
                    />
                  </SimpleGrid>

                </AccordionPanel>
              </AccordionItem>
            </Accordion>

            <Box>
              <H3 mb={6}><Translate label={MultipleCheckDetailLocale.CANDIDATE_SCREENINGS} /></H3>
              <Box overflowX="auto">
                <Box minW="500px">
                  <MeepoTable
                    columns={[
                      { id: 'type' },
                      { id: 'firstName', label: translate(MultipleCheckDetailLocale.TABLE_FIRST_NAME, language) },
                      { id: 'lastName', label: translate(MultipleCheckDetailLocale.TABLE_LAST_NAME, language) },
                      { id: 'dateOfBirth', label: translate(MultipleCheckDetailLocale.TABLE_DATE_OF_BIRTH, language) },
                      {
                        id: 'result',
                        label: translate(MultipleCheckDetailLocale.TABLE_RESULT, language),
                        boxProps: {
                          w: '220px',
                        },
                      },
                      {
                        id: 'state',
                        label: translate(MultipleCheckDetailLocale.TABLE_STATE, language),
                        boxProps: {
                          w: '220px',
                        },
                      },
                    ]}
                    data={list}
                    isLoading={orderDetailHandleOptions.loading}
                    noData={<Text><Translate label={TableLocale.NO_DATA} /></Text>}
                  />
                </Box>
              </Box>
            </Box>

            { formChanged ? (
              <Flex mt={8}>
                <Spacer />
                <Button variant="ghost" colorScheme="red" onClick={formOnCancel}>
                  <Translate label={FormLocale.CANCEL} />
                </Button>
                <Button
                  ml={3}
                  isLoading={mutateAccountManagerOptions.loading}
                  type="submit"
                >
                  <Translate label={FormLocale.CONFIRM} />
                </Button>
              </Flex>
            ) : undefined }
          </VStack>
        </form>
      </SectionContainer>
    </>
  );
};

export default MultipleCheckDetail;
