import React, { useMemo, useState } from 'react';
import {
  H1, MeepoTable, SectionContainer,
} from '@scaut-sro/meepo';
import {
  Box, Button, Checkbox, Flex, Spacer, Text,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useGetAdminUsersPage } from '../../build/generated-sources/service/QueryService';
import { Direction } from '../../build/generated-sources/enum/Direction';
import Translate from '../../components/Translate/Translate';
import DetailHeader from '../../components/DetailHeader/DetailHeader';
import TableLocale from '../../core/localization/translations/Table.locale';
import UsersLocale from './Users.locale';
import { Language } from '../../build/generated-sources/enum/Language';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../core/localization/LocaleUtils';
import {
  UsersProps, UsersTableData, UsersTableFilterAllFilterType, UsersTableFilterType,
} from './UsersTable.model';
import UserTableFilter from './UserTableFilter';
import UserDetailModal from './UserDetail/UserDetailModal';
import { handleApolloErrors } from '../../core/error/ScautErrorHandler';
import { AdminUserDto } from '../../build/generated-sources/dto/AdminUserDto';

export function getUserTableData(openUserDetail: (adminUserId?: number) => void, language: Language,
  data: AdminUserDto[] | undefined): UsersTableData[] {
  if (data) {
    return data.map((adminUsers) => ({
      id: adminUsers.id || '-undefined-',
      firstName: adminUsers.firstName,
      lastName: adminUsers.lastName,
      active: <Checkbox isChecked={adminUsers.active} isDisabled />,
      email: adminUsers.email,
      avatar: adminUsers.avatar,
      buttons: (
        <Button
          variant="link"
          w="100%"
          onClick={() => openUserDetail(adminUsers?.id)}
        >
          <Translate label={UsersLocale.USER_GO_TO_DETAIL} />
        </Button>
      ),
    }));
  }
  return [];
}

const Users:React.FunctionComponent<UsersProps> = (props) => {
  const [tableFilter, setTableFilter] = useState< UsersTableFilterType>({});
  const [allFilters, setAllFilter] = useState< UsersTableFilterType>({});
  const { filters } = props;
  const history = useHistory();
  const { language } = useGetUser();
  const [list, setList] = useState<UsersTableData[]>([]);
  const [page, setPage] = useState<number>(0);
  const [sorting, setSorting] = useState<{ sortBy?: string, sortDirection?: Direction }>({});
  const size = 100;

  const openUserDetail = (adminUserId?: number) => {
    if (adminUserId) {
      history.push(`/user/${adminUserId}`);
    } else {
      history.push('/404');
    }
  };

  const { loading } = useGetAdminUsersPage({
    content: {
      firstName: true,
      lastName: true,
      email: true,
      active: true,
      id: true,
      role: true,
    },
  }, {
    onError: (error) => {
      handleApolloErrors(error, language);
    },

    variables: {
      paging: {
        page,
        size,
        sortBy: sorting.sortBy,
        sortDirection: sorting.sortDirection,
      },
      filter: {
        search: allFilters.fulltext && allFilters.fulltext.length ? allFilters.fulltext : undefined,
        roles: allFilters.roles && allFilters.roles.length ? allFilters.roles : undefined,
        active: allFilters.active ? allFilters.active : undefined,
      },
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      const onCompleteData = getUserTableData(openUserDetail, language, response.adminUsersPage.content);
      if (page === 0) {
        setList(onCompleteData);
      } else {
        setList((prev) => [...prev, ...onCompleteData]);
      }
    },

  });

  useMemo(() => {
    const newAllFilters: UsersTableFilterAllFilterType = {
      ...filters,
      ...tableFilter,
    };
    setAllFilter(newAllFilters);
    setPage(0);
  }, [filters, tableFilter]);

  return (
    <>
      <SectionContainer>
        <Box overflowX="auto">
          <Box minW="600px">
            <DetailHeader>
              <H1><Translate label={UsersLocale.USER_LIST} /></H1>
            </DetailHeader>
            <Box mb={20}>
              <UserTableFilter
                filter={tableFilter}
                onFilterChange={(newTableFilter) => {
                  setTableFilter(newTableFilter);
                }}
              />
              <Spacer />
              <Flex mt={6} justifyContent="flex-end">
                <UserDetailModal />
              </Flex>
            </Box>
            <Box overflowX="auto">
              <Box minW="600px">
                <MeepoTable
                  columns={[
                    {
                      id: 'firstName',
                      label: translate(UsersLocale.FIRST_NAME, language),
                      minW: '200px',
                    },
                    {
                      id: 'lastName',
                      label: translate(UsersLocale.LAST_NAME, language),
                      minW: '100px',
                    },
                    {
                      id: 'active',
                      label: translate(UsersLocale.ACTIVE, language),
                      alignData: 'center',
                      alignLabel: 'center',
                    },
                    {
                      id: 'email',
                      label: translate(UsersLocale.EMAIL, language),
                      boxProps: { w: '180px' },
                    },
                    {
                      id: 'buttons',
                      label: translate(UsersLocale.USER_DETAIL, language),
                      minW: '150px',
                      boxProps: { w: '200px' },
                      alignLabel: 'center',
                    },
                  ]}
                  data={list}
                  isLoading={loading}
                  noData={<Text><Translate label={TableLocale.NO_DATA} /></Text>}
                  setPage={() => {
                    setPage((prev) => prev + 1);
                  }}
                  onSort={(newSorting) => {
                    const direction: Direction | undefined = newSorting.type === 'asc'
                      ? Direction.ASC
                      : newSorting.type === 'desc'
                        ? Direction.DESC
                        : undefined;
                    setSorting({
                      sortBy: newSorting.column,
                      sortDirection: direction,
                    });
                  }}
                  activeSorting={{
                    column: sorting.sortBy,
                    type: sorting.sortDirection === Direction.ASC
                      ? 'asc'
                      : sorting.sortDirection === Direction.DESC
                        ? 'desc'
                        : 'none',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </SectionContainer>
    </>
  );
};

export default Users;
