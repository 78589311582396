import React from 'react';
import {
  Box, Text, Wrap,
} from '@chakra-ui/react';
import { ComponentLogDetailProps } from '../Components.model';

const ComponentLogRowDetail: React.FunctionComponent<ComponentLogDetailProps> = (props) => {
  const { message } = props;

  return (
    <>
      <Box p={6} backgroundColor="gray.50">
        <Wrap>
          <Text fontSize="sm" color="blackAlpha.700">
            {message?.split('\n').map((it) => <p key={Math.random()}>{it}</p>)}
          </Text>
        </Wrap>
      </Box>
    </>
  );
};

export default ComponentLogRowDetail;
