import React, {
  useEffect, useRef, useState,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  H1, SectionContainer, Toast,
} from '@scaut-sro/meepo';
import {
  useLazyGetItemDetail,
} from '../../../build/generated-sources/service/QueryService';
import Translate from '../../../components/Translate/Translate';
import ResourceDatabaseLocale from '../ResourceDatabase.locale';
import { ResourceDatabaseItemDetail } from '../../../build/generated-sources/dto/ResourceDatabaseItemDetail';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import DetailHeader from '../../../components/DetailHeader/DetailHeader';
import DatabaseItemDetail from '../DatabaseItemDetail';
import { translate } from '../../../core/localization/LocaleUtils';
import CatalogueComponentsLocale from '../../Catalogue/CatalogueComponents/CatalogueComponents.locale';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';

const ItemDetail: React.FunctionComponent = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const itemId = Number.isNaN(+params.id);
  const [data, setData] = useState<ResourceDatabaseItemDetail | undefined>(undefined);
  const formRef = useRef<HTMLFormElement>(null);
  const [resetForm] = useState<number>(0);
  const { language } = useGetUser();

  const [itemHandle] = useLazyGetItemDetail(
    {
      item: {
        id: true,
        name: true,
        enumeration: {
          id: true,
          translation: {
            id: true,
            localizationStrings: true,
          },
        },
      },
      sectionId: true,
      form: true,
    },
    {
      onCompleted: (response) => {
        if (response.itemDetail === null) {
          Toast({
            title: translate(CatalogueComponentsLocale.ERROR_GETTING_COUNTRIES, language),
            status: 'error',
          });
        }
        setData(response.itemDetail);
      },
      onError: () => {
        Toast({
          title: translate(CatalogueComponentsLocale.ERROR_GETTING_COUNTRIES, language),
          status: 'error',
        });
      },
      variables: {
        itemId: +params.id,
      },
    },
  );

  useEffect(() => {
    if (!itemId) {
      itemHandle({
        variables: {
          itemId: +params.id,
        },
      });
    }
  }, [itemHandle, params.id, itemId]);

  return (
    <>
      {!data ? <LoadingScreen /> : (
        <>
          <SectionContainer>
            <DetailHeader>
              <H1><Translate label={ResourceDatabaseLocale.ITEM_DETAIL} /></H1>
            </DetailHeader>
            <DatabaseItemDetail
              itemDetail={data}
              formRef={formRef}
              cancelChangesTrigger={resetForm}
            />
          </SectionContainer>
        </>
      )}
    </>
  );
};

export default ItemDetail;
