import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { UserSettingsReducerState } from './UserSettingsReducer.model';
import { Language } from '../../../build/generated-sources/enum/Language';
import { AdminUser } from '../../../build/generated-sources/dto/AdminUser';

const initialState: UserSettingsReducerState = {
  language: Language.EN,
};

const userSettingsSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLanguage(state, action: PayloadAction<Language>) {
      state.language = action.payload;
    },
    setUserProfile(state, action: PayloadAction<AdminUser>) {
      state.userProfile = action.payload;
    },
    setAvatar(state, action: PayloadAction<string>) {
      if (state.userProfile) {
        state.userProfile.avatar = action.payload;
      }
    },
  },
});

// Getter Hooks
export function useGetUser(): UserSettingsReducerState {
  return useSelector((state: any) => state.user);
}

export const { setUserProfile, setLanguage } = userSettingsSlice.actions;

// Reduce Hooks
export const useUserSettingsReduce = () => {
  const dispatch = useDispatch();
  const user = useGetUser();
  return {
    setLanguage(language: Language) {
      dispatch(userSettingsSlice.actions.setLanguage(language));
    },
    setUserProfile: (userProfile: AdminUser, reload?: boolean) => {
      if (!user.userProfile || reload) {
        dispatch(userSettingsSlice.actions.setUserProfile(userProfile));
      }
    },
    setAvatar: (avatar: string) => {
      dispatch(userSettingsSlice.actions.setAvatar(avatar));
    },
  };
};

export default userSettingsSlice.reducer;
