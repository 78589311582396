import { BadgeProps } from '@chakra-ui/layout/dist/types/badge';
import { Translation } from '../../core/localization/Localization.model';
import { POAStatus } from '../../build/generated-sources/enum/POAStatus';

export type PAOStateBadgeProps = {
  poaStatus?: POAStatus;
  size?: 'md' | 'lg';
  badgeProps?: BadgeProps
};

export type PAOStateBadgeData = {
  locale?: Translation;
  color?: string;
  width?: number;
  fontSize?: string;
  variant?: string;
};

export class PAOStateBadgeLocale {
  public static WONT_GENERATE: Translation = { EN: 'Won\'t be generated', CZ: 'Nebude generováno' };
  public static UNDECIDED: Translation = { EN: 'Generation not yet decided', CZ: 'Generování zatím není rozhodnuto' };
  public static READY_TO_GENERATE: Translation = { EN: 'Ready to Generate', CZ: 'Připraveno ke Generování' };
  public static GENERATED: Translation = { EN: 'Generated and sent', CZ: 'Vegenerováno a odesláno' };
  public static RECEIVED: Translation = { EN: 'Received', CZ: 'Přijato' };
}
