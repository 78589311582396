import React, { useRef, useState } from 'react';
import {
  Button, HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent, ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import Translate from '../../../../../components/Translate/Translate';
import FormLocale from '../../../../../core/localization/translations/Form.locale';
import ClientUserRegistrationLocale from './ClientUserRegistration.locale';
import ClientUserRegistrationForm from './ClientUserRegistrationForm';

const ClientUserRegistrationModal: React.FunctionComponent = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<number>(0);
  const formRef = useRef<HTMLFormElement>(null);

  return (
    <>
      <Button onClick={onOpen} leftIcon={<AddIcon />}>
        <Translate label={ClientUserRegistrationLocale.INVITE} />
      </Button>
      <Modal closeOnOverlayClick={false} size="4xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text><Translate label={ClientUserRegistrationLocale.USER_INVITATION} /></Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ClientUserRegistrationForm
              loadingSetter={(isLoading) => { setLoading(isLoading); }}
              formRef={formRef}
              cancelChangesTrigger={resetForm}
            />
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button
                onClick={() => {
                  if (formRef) {
                    formRef.current?.dispatchEvent(new Event('submit', {
                      cancelable: true,
                      bubbles: true,
                    }));
                  }
                }}
                isLoading={loading}
              >
                <Translate label={FormLocale.SEND} />
              </Button>
              <Button
                variant="ghost"
                colorScheme="red"
                onClick={() => {
                  onClose();
                  setResetForm(resetForm + 1);
                }}
              >
                <Translate label={FormLocale.CANCEL} />
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ClientUserRegistrationModal;
