import { Translation } from '../../core/localization/Localization.model';

class InvoicesLocale {
  public static INVOICES: Translation = { EN: 'Invoices', CZ: 'Faktury' };
  public static ERROR_GETTING_DATA: Translation = {
    EN: 'There was a problem getting invoices',
    CZ: 'Při získávání faktur došlo k problému',
  };
  public static STATE: Translation = { EN: 'State', CZ: 'Stav' };
  public static GO_TO_DETAIL: Translation = { EN: 'Go to detail', CZ: 'Přejít na detail' };
  public static INVOICE_STATE_OPEN: Translation = { EN: 'To be paid', CZ: 'K zaplacení' };
  public static INVOICE_STATE_PAID: Translation = { EN: 'Paid', CZ: 'Zaplaceno' };
  public static COUNTRIES: Translation = { EN: 'Countries', CZ: 'Zemí' };
  public static ISSUE_DATE: Translation = { EN: 'Issue date', CZ: 'Vystaveno' };
  public static DUE_DATE: Translation = { EN: 'Due date', CZ: 'Splatnost' };
  public static STATES: Translation = { EN: 'States', CZ: 'Stavy' };
  public static CLIENT: Translation = { EN: 'Client', CZ: 'Klient' };
  public static CLIENTS: Translation = { EN: 'Clients', CZ: 'Klienti' };
  public static ERROR_GETTING_CLIENTS: Translation = {
    EN: 'There was a problem getting client list.',
    CZ: 'Problém při načitání listu klientů',
  };
  public static ICO: Translation = { EN: 'ID No', CZ: 'ICO' };
  public static ID: Translation = { EN: 'ID', CZ: 'ID' };
  public static COUNTRY: Translation = { EN: 'Country', CZ: 'Stát' };
  public static PRICE: Translation = { EN: 'Price', CZ: 'Cena' };
  public static CHARGIFY_NUMBER: Translation = { EN: 'Chargify invoice number', CZ: 'Chargify číslo faktury' };
  public static INVOICE_NUMBER: Translation = { EN: 'Scaut invoice number', CZ: 'Scaut číslo faktury' };
  public static SCAUT_ORDER_ID: Translation = { EN: 'Scaut order ID', CZ: 'Scaut číslo objednávky' };
  public static ORDER_NAME: Translation = { EN: 'ORDER NAME', CZ: 'Název objednávky' };
  public static USER_CUSTOM_ID: Translation = { EN: 'User custom id', CZ: 'Uživatelské číslo objednávky' };
  public static PAYMENT_METHOD: Translation = { EN: 'Payment method', CZ: 'Platební metoda' };
  public static TRANSACTION_ID: Translation = { EN: 'Transaction Id', CZ: 'Číslo transakce' };
}

export default InvoicesLocale;
