import React, { useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { H1, SectionContainer, Toast } from '@scaut-sro/meepo';
import {
  Button, ButtonGroup, HStack, Spacer,
} from '@chakra-ui/react';
import { useLazyGetScreeningDefinitionDetail } from '../../../../build/generated-sources/service/QueryService';
import CatalogueScreeningDetailForm from './CatalogueScreeningDetailForm';
import DetailHeader from '../../../../components/DetailHeader/DetailHeader';
import Translate from '../../../../components/Translate/Translate';
import CatalogueScreeningsLocale from '../CatalogueScreenings.locale';
import LoadingScreen from '../../../LoadingScreen/LoadingScreen';
import { ScreeningDefinitionDetail } from '../../../../build/generated-sources/dto/ScreeningDefinitionDetail';
import FormLocale from '../../../../core/localization/translations/Form.locale';
import { translate } from '../../../../core/localization/LocaleUtils';
import { useGetUser } from '../../../../core/store/reducers/UserSettingsReducer';

const CatalogueScreeningDetail: React.FunctionComponent = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const screeningDefinitionId = Number.isNaN(+params.id);
  const { language } = useGetUser();
  const [data, setData] = useState<ScreeningDefinitionDetail | undefined>(undefined);

  const formRef = useRef<HTMLFormElement>(null);
  const [formIsLoading, setFormIsLoading] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<number>(0);

  const [screeningDefinitionHandle] = useLazyGetScreeningDefinitionDetail(
    {
      screeningDefinition: {
        id: true,
        internalName: true,
        active: true,
        countable: true,
        countLimit: true,
        country: {
          id: true,
          translation: {
            id: true,
            localizationStrings: true,
          },
        },
        name: {
          id: true,
          localizationStrings: true,
        },
        description: {
          id: true,
          localizationStrings: true,
        },
        componentDefinitions: {
          id: true,
          internalName: true,
          processDefinition: true,
          averageRuntime: true,
          internalExpenses: true,
          externalExpenses: true,
          fees: true,
          requiresPowerOfAttorney: true,
          performableAsGroup: true,
          country: {
            id: true,
            translation: {
              id: true,
              localizationStrings: true,
            },
          },
        },
      },
      price: true,

    },
    {
      onCompleted: (response) => {
        if (response.screeningDefinitionDetail === null) {
          Toast({
            title: translate(CatalogueScreeningsLocale.ERROR_GETTING_DATA, language),
            status: 'error',
          });
        }
        setData(response.screeningDefinitionDetail);
      },
      onError: () => {
        Toast({
          title: translate(CatalogueScreeningsLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      },
      variables: {
        screeningDefinitionId: +params.id,
      },
    },
  );

  const refreshData = () => {
    screeningDefinitionHandle({
      variables: {
        screeningDefinitionId: +params.id,
      },
    });
  };

  useEffect(() => {
    if (!screeningDefinitionId) {
      screeningDefinitionHandle({
        variables: {
          screeningDefinitionId: +params.id,
        },
      });
    }
  }, [screeningDefinitionHandle, params.id, screeningDefinitionId]);

  return (
    <>
      {!data ? <LoadingScreen /> : (
        <>
          <SectionContainer>
            <DetailHeader>
              <H1><Translate label={CatalogueScreeningsLocale.SCREENING_DETAIL} /></H1>
            </DetailHeader>
            <CatalogueScreeningDetailForm
              screeningDefinitionDetail={data}
              loadingSetter={(isLoading) => { setFormIsLoading(isLoading); }}
              formRef={formRef}
              cancelChangesTrigger={resetForm}
              refreshData={refreshData}
            />
            <HStack mt={6}>
              <Spacer />
              <ButtonGroup>
                <Button
                  onClick={() => {
                    if (formRef) {
                      formRef.current?.dispatchEvent(new Event('submit', {
                        cancelable: true,
                        bubbles: true,
                      }));
                    }
                  }}
                  isLoading={formIsLoading}
                >
                  <Translate label={FormLocale.SAVE} />
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="red"
                  isDisabled={formIsLoading}
                  onClick={() => {
                    setResetForm(resetForm + 1);
                  }}
                >
                  <Translate label={FormLocale.CANCEL} />
                </Button>
              </ButtonGroup>
            </HStack>
          </SectionContainer>
        </>
      )}
    </>
  );
};

export default CatalogueScreeningDetail;
