import React from 'react';
import {
  Box, Flex, Spacer, Text,
} from '@chakra-ui/react';
import { Currency, getCurrencyLocalizationFromLanguage } from '@scaut-sro/meepo';
import { TotalPriceProps } from './TotalPrice. model';
import Translate from '../Translate/Translate';
import TotalPriceLocale from './TotalPrice.locale';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';

// TODO: currency should be taken from redux - client setup

const TotalPrice: React.FunctionComponent<TotalPriceProps> = (props) => {
  const { price, perPerson } = props;
  const { language } = useGetUser();

  return (
    <Flex p="32px 0">
      <Box>
        <Box>
          <Text fontSize="lg">
            <Translate label={perPerson ? TotalPriceLocale.PRICE_PER_PERSON : TotalPriceLocale.TOTAL_PRICE} />
          </Text>
        </Box>
        <Box><Text fontSize="xs"><Translate label={TotalPriceLocale.INCLUDING_TAXES} /></Text></Box>
      </Box>
      <Spacer />
      <Box>
        <Text fontSize="2xl">
          <Currency amount={price} localization={getCurrencyLocalizationFromLanguage(language)} />
        </Text>
      </Box>
    </Flex>
  );
};

export default TotalPrice;
