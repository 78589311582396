import React, { useMemo, useState } from 'react';
import {
  H1, MeepoTable, SectionContainer,
} from '@scaut-sro/meepo';
import {
  Box, Button, Checkbox, Flex, Text,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { PageInfo } from '@scaut-sro/meepo/lib/components/MeepoTable/MeepoTable.model';
import { useGetClientsPage } from '../../build/generated-sources/service/QueryService';
import { Direction } from '../../build/generated-sources/enum/Direction';
import Translate from '../../components/Translate/Translate';
import DetailHeader from '../../components/DetailHeader/DetailHeader';
import TableLocale from '../../core/localization/translations/Table.locale';
import { Language } from '../../build/generated-sources/enum/Language';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../core/localization/LocaleUtils';
import { Client } from '../../build/generated-sources/dto/Client';
import {
  ClientsProps,
  ClientsTableData,
  ClientTableFilterAllFilterType,
  ClientTableFilterType,
} from './Clients.model';
import ClientsLocale from './Clients.locale';
import ClientsTableFilter from './ClientsTableFilter';
import UsersLocale from '../Users/Users.locale';
import ClientDetailModal from './ClientDetail/ClientDetail/ClientDetailModal';
import { handleApolloErrors } from '../../core/error/ScautErrorHandler';

export function getClientTableData(openClientDetail: (clientId?: number) => void, language: Language,
  data: Client[] | undefined): ClientsTableData[] {
  if (data) {
    return data.map((client) => ({
      id: client.id || '-undefined-',
      name: client.name,
      ico: client.ico,
      active: <Checkbox isChecked={client.active} isDisabled />,
      country: client.country?.translation?.localizationStrings?.[language],
      buttons: (
        <Button
          variant="link"
          w="100%"
          onClick={() => openClientDetail(client?.id)}
        >
          <Translate label={UsersLocale.USER_GO_TO_DETAIL} />
        </Button>
      ),
    }));
  }
  return [];
}

const Clients:React.FunctionComponent<ClientsProps> = (props) => {
  const [tableFilter, setTableFilter] = useState<ClientTableFilterType>({});
  const [allFilters, setAllFilter] = useState<ClientTableFilterType>({});
  const { filters } = props;
  const history = useHistory();
  const { language } = useGetUser();
  const [list, setList] = useState<ClientsTableData[]>([]);
  const [sorting, setSorting] = useState<{ sortBy?: string, sortDirection?: Direction }>({});
  const [pageInfo, setPageInfo] = useState<PageInfo>({ pageNumber: 0, pageSize: 20, totalPages: 0 });

  const openClientDetail = (clientId?: number) => {
    if (clientId) {
      history.push(`/client/${clientId}`);
    } else {
      history.push('/404');
    }
  };

  const { loading } = useGetClientsPage({
    pageInfo: {
      pageSize: true,
      pageNumber: true,
      numberOfElements: true,
      totalElements: true,
      totalPages: true,
    },
    content: {
      id: true,
      name: true,
      active: true,
      ico: true,
      country: {
        id: true,
        translation: {
          id: true,
          localizationStrings: true,
        },
      },
    },
  }, {
    onError: (error) => {
      handleApolloErrors(error, language);
    },
    variables: {
      filter: {
        search: allFilters.fulltext && allFilters.fulltext.length ? allFilters.fulltext : undefined,
        countryIds: allFilters.countryIds && allFilters.countryIds?.length ? allFilters.countryIds : undefined,
      },
      paging: {
        page: pageInfo.pageNumber,
        size: pageInfo.pageSize,
        sortBy: sorting.sortBy,
        sortDirection: sorting.sortDirection,
      },
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      const onCompleteData = getClientTableData(openClientDetail, language, response.clientsPage.content);
      setList(onCompleteData);
      setPageInfo({
        pageSize: response.clientsPage.pageInfo?.pageSize,
        pageNumber: response.clientsPage.pageInfo?.pageNumber || 0,
        totalPages: response.clientsPage.pageInfo?.totalPages || 0,
        numberOfElements: response.clientsPage.pageInfo?.numberOfElements,
        totalElements: response.clientsPage.pageInfo?.totalElements,
      });
    },

  });

  useMemo(() => {
    const newAllFilters: ClientTableFilterAllFilterType = {
      ...filters,
      ...tableFilter,
    };
    setAllFilter(newAllFilters);
  }, [filters, tableFilter]);

  return (
    <>
      <SectionContainer>
        <Box overflowX="auto">
          <Box minW="600px">
            <DetailHeader>
              <H1><Translate label={ClientsLocale.CLIENTS} /></H1>
            </DetailHeader>
            <Box mb={20}>
              <ClientsTableFilter
                filter={tableFilter}
                onFilterChange={(newTableFilter) => {
                  setTableFilter(newTableFilter);
                }}
              />
            </Box>
            <Box mb={10}>
              <Flex justifyContent="flex-end">
                <ClientDetailModal />
              </Flex>
            </Box>
            <Box overflowX="auto">
              <Box minW="600px">
                <MeepoTable
                  columns={[
                    {
                      id: 'name',
                      label: translate(ClientsLocale.NAME, language),
                      minW: '200px',
                    },
                    {
                      id: 'ico',
                      label: translate(ClientsLocale.ID, language),
                      minW: '100px',
                    },
                    {
                      id: 'active',
                      label: translate(ClientsLocale.ACTIVE, language),
                      alignData: 'center',
                      alignLabel: 'center',
                    },
                    {
                      id: 'country',
                      label: translate(ClientsLocale.COUNTRY, language),
                      alignData: 'center',
                      alignLabel: 'center',
                    },
                    {
                      id: 'buttons',
                      label: translate(ClientsLocale.CLIENT_DETAIL, language),
                      minW: '150px',
                      boxProps: { w: '200px' },
                      alignLabel: 'center',
                    },
                  ]}
                  data={list}
                  isLoading={loading}
                  noData={<Text><Translate label={TableLocale.NO_DATA} /></Text>}
                  pageInfo={pageInfo}
                  setPage={(newPageNumber, newPageSize) => {
                    setPageInfo({
                      ...pageInfo,
                      pageNumber: newPageNumber,
                      pageSize: newPageSize,
                    });
                  }}
                  onSort={(newSorting) => {
                    const direction: Direction | undefined = newSorting.type === 'asc'
                      ? Direction.ASC
                      : newSorting.type === 'desc'
                        ? Direction.DESC
                        : undefined;
                    setSorting({
                      sortBy: newSorting.column,
                      sortDirection: direction,
                    });
                  }}
                  activeSorting={{
                    column: sorting.sortBy,
                    type: sorting.sortDirection === Direction.ASC
                      ? 'asc'
                      : sorting.sortDirection === Direction.DESC
                        ? 'desc'
                        : 'none',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </SectionContainer>
    </>
  );
};

export default Clients;
