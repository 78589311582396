import React, { useState } from 'react';
import {
  H1, MeepoTable, SectionContainer, Toast,
} from '@scaut-sro/meepo';
import {
  Box, Button, Checkbox, Flex, Spacer, Text,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useGetCountries } from '../../build/generated-sources/service/QueryService';
import Translate from '../../components/Translate/Translate';
import DetailHeader from '../../components/DetailHeader/DetailHeader';
import TableLocale from '../../core/localization/translations/Table.locale';
import CountriesLocale from './Countries.locale';
import { Language } from '../../build/generated-sources/enum/Language';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../core/localization/LocaleUtils';
import CountriesDetailModal from './CountriesDetail/CountriesDetailModal';
import { Country } from '../../build/generated-sources/dto/Country';
import { CountriesTableData } from './Countries.model';

export function getCountriesTableData(openCountriesDetail: (countryId?: number) => void, language: Language,
  data: Country[] | undefined): CountriesTableData[] {
  if (data) {
    return data.map((country) => ({
      id: country.id || '-undefined-',
      translationName: country?.translation?.name,
      visibleByDefault: <Checkbox isChecked={country.visibleByDefault} isDisabled />,
      active: <Checkbox isChecked={country.active} isDisabled />,
      buttons: (
        <Button
          variant="link"
          w="100%"
          onClick={() => openCountriesDetail(country?.id)}
        >
          <Translate label={CountriesLocale.GO_TO_DETAIL} />
        </Button>
      ),
    }));
  }
  return [];
}

const Countries:React.FunctionComponent = () => {
  const history = useHistory();
  const { language } = useGetUser();
  const [list, setList] = useState<CountriesTableData[]>([]);

  const openCountriesDetail = (countryId?: number) => {
    if (countryId) {
      history.push(`/country/${countryId}`);
    } else {
      history.push('/404');
    }
  };

  const { loading } = useGetCountries({
    id: true,
    visibleByDefault: true,
    active: true,
    translation: {
      id: true,
      name: true,
      localizationStrings: true,
    },
  }, {
    onError: () => {
      Toast({
        title: translate(CountriesLocale.ERROR_GETTING_COUNTRIES, language),
        status: 'error',
      });
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      const onCompleteData = getCountriesTableData(openCountriesDetail, language, response.countries);
      setList(onCompleteData);
    },

  });
  return (
    <>
      <SectionContainer>
        <Box overflowX="auto">
          <Box minW="600px">
            <DetailHeader>
              <H1><Translate label={CountriesLocale.COUNTRIES} /></H1>
            </DetailHeader>
            <Box mb={20}>
              <Spacer />
              <Flex mt={6} justifyContent="flex-end">
                <CountriesDetailModal />
              </Flex>
            </Box>
            <Box overflowX="auto">
              <Box minW="600px">
                <MeepoTable
                  columns={[
                    {
                      id: 'translationName',
                      label: translate(CountriesLocale.TRANSLATION_NAME, language),
                      minW: '200px',
                    }, {
                      id: 'visibleByDefault',
                      label: translate(CountriesLocale.VISIBLE_BY_DEFAULT, language),
                      minW: '200px',
                      alignLabel: 'center',
                      alignData: 'center',
                    }, {
                      id: 'active',
                      label: translate(CountriesLocale.ACTIVE, language),
                      minW: '200px',
                      alignLabel: 'center',
                      alignData: 'center',
                    },
                    {
                      id: 'buttons',
                      label: translate(CountriesLocale.COUNTRY_DETAIL, language),
                      minW: '150px',
                      boxProps: { w: '200px' },
                      alignLabel: 'center',
                    },
                  ]}
                  data={list}
                  isLoading={loading}
                  noData={<Text><Translate label={TableLocale.NO_DATA} /></Text>}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </SectionContainer>
    </>
  );
};

export default Countries;
