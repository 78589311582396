import React, { useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Toast } from '@scaut-sro/meepo';
import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { IntegrationDetailProps, IntegrationFormAttr } from './Components.model';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import { useCamelIntegrationEdit } from '../../../build/generated-sources/service/MutationService';
import TasksLocale from '../Tasks.locale';
import { translate } from '../../../core/localization/LocaleUtils';
import FormLocale from '../../../core/localization/translations/Form.locale';

const IntegrationForm: React.FunctionComponent<IntegrationDetailProps> = (props) => {
  const {
    loadingSetter, formRef, integration,
  } = props;
  const { language } = useGetUser();

  const defaultValues: Partial<IntegrationFormAttr> = useMemo(() => ({
    id: integration?.id,
    retries: integration.retries,
    maxRetries: integration.maxRetries,
  }), [integration]);

  const {
    handleSubmit, register,
  } = useForm<Partial<IntegrationFormAttr>>({ defaultValues });

  const [mutateIntegration,
    mutateIntegrationOptions] = useCamelIntegrationEdit({
    success: true,
  }, {
    onCompleted: () => {
      Toast({
        title: translate(FormLocale.SUCCESSFULLY_SAVED, language),
        status: 'success',
      });
    },
    onError: () => {
      Toast({
        title: translate(TasksLocale.ERROR_SAVING_TASK, language),
        status: 'error',
      });
    },
  });

  useEffect(() => {
    if (loadingSetter) loadingSetter(mutateIntegrationOptions.loading);
  }, [loadingSetter, mutateIntegrationOptions]);

  const handleIntegrationFormSubmit: SubmitHandler<IntegrationFormAttr> = (values) => {
    mutateIntegration({
      variables: {
        camelIntegrationId: integration?.id,
        retries: values.retries,
        maxRetries: values.maxRetries,
      },
    });
  };

  return (
    <>
      <form
        name="integrationForm"
        onSubmit={handleSubmit(handleIntegrationFormSubmit)}
        ref={formRef}
      >
        <FormControl>
          <FormLabel htmlFor="retries">{translate(TasksLocale.RETRIES, language)}</FormLabel>
          <Input
            id="retries"
            placeholder={translate(TasksLocale.RETRIES, language)}
            {...register('retries')}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="maxRetries">{translate(TasksLocale.MAX_RETRIES, language)}</FormLabel>
          <Input
            id="maxRetries"
            placeholder={translate(TasksLocale.MAX_RETRIES, language)}
            {...register('maxRetries')}
          />
        </FormControl>
      </form>
    </>
  );
};
export default IntegrationForm;
