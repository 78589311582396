import { Translation } from '../../core/localization/Localization.model';

class OverviewLastChecksLocale {
  public static LAST_CHECKS: Translation = { EN: 'Checks', CZ: 'Jednotlivé kontroly' };
  public static ALL: Translation = { EN: 'ALL', CZ: 'VŠE' };
  public static ALL_FILTER: Translation = { EN: 'All', CZ: 'Všechny' };
  public static SINGLE: Translation = { EN: 'SINGLE', CZ: 'JEDNOTLIVÉ' };
  public static SINGLE_FILTER: Translation = { EN: 'Single', CZ: 'Jednotlivé' };
  public static MULTIPLE: Translation = { EN: 'MULTIPLE', CZ: 'HROMADNÉ' };
  public static MULTIPLE_FILTER: Translation = { EN: 'Multiple', CZ: 'Hromadné' };
  public static ERROR_GETTING_ORDERS: Translation = {
    EN:
      'There was a problem getting orders',
    CZ: 'Při získávání objednávek došlo k problému\n',
  };
  public static OVERVIEW: Translation = { EN: 'My overview', CZ: 'Můj přehled kontrol' };
}

export default OverviewLastChecksLocale;
