import React, { useEffect, useState } from 'react';
import { H4, Toast } from '@scaut-sro/meepo';
import {
  Button, Flex, Spacer, Tooltip,
} from '@chakra-ui/react';
import { BiCopy } from 'react-icons/all';
import ReactJson from 'react-json-view';
import { ComponentLogModalProps } from '../Components.model';
import { useLazyGetComponentLogVariables } from '../../../../build/generated-sources/service/QueryService';
import { translate } from '../../../../core/localization/LocaleUtils';
import TasksLocale from '../../Tasks.locale';
import { useGetUser } from '../../../../core/store/reducers/UserSettingsReducer';
import LoadingScreen from '../../../LoadingScreen/LoadingScreen';

const LogDetail: React.FunctionComponent<ComponentLogModalProps> = (props) => {
  const { text, processInstanceLogId } = props;
  const { language } = useGetUser();
  const [variables, setVariables] = useState<string>('');
  const [jsonVariables, setJsonVariables] = useState<object>();

  const [getVariablesHandle, { loading }] = useLazyGetComponentLogVariables({
    variables: true,
  }, {
    onCompleted: (response) => {
      const parsedJson = JSON.parse(response.componentLogVariables.variables || '');
      setJsonVariables(parsedJson);
      setVariables(JSON.stringify(parsedJson, null, 2));
    },
    onError: () => {
      Toast({
        title: translate(TasksLocale.ERROR_GETTING_DATA, language),
        status: 'error',
      });
    },
    variables: {
      processInstanceLogId,
    },
    // TODO: for some reason cach-first fetch policy doesn't work well.
    //  It doesn't fetch right process when all processes are cached.
    //  I changed fetchPolicy to 'cache-and-network' as a workaround but it's not ideal.
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    getVariablesHandle({
      variables: {
        processInstanceLogId,
      },
    });
  }, [getVariablesHandle, processInstanceLogId]);

  return (
    <>
      { loading ? (
        <LoadingScreen />
      ) : (
        <>
          <Flex>
            <H4>{text}</H4>
            <Spacer />
            <Tooltip label={translate(TasksLocale.COPY_VARIABLES, language)} placement="top-start">
              <Button
                variant="ghost"
                size="lg"
                onClick={() => { navigator.clipboard.writeText(variables); }}
                iconSpacing={1}
                leftIcon={<BiCopy size="30px" />}
              />
            </Tooltip>
          </Flex>
          {
            jsonVariables
              ? (
            // @ts-ignore
                <ReactJson
                  src={jsonVariables}
                  name="data"
                  enableClipboard={false}
                />
              ) : undefined
          }
        </>
      )}
    </>
  );
};

export default LogDetail;
