import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import {GraphqlBuilder, GraphQLType} from '@scaut-sro/npm-core-generator';
import { PrimaryOrderMapper } from '../dto/PrimaryOrder'
import {UseAccountManagerData, UseAccountManagerVariables} from './MutationServiceModel'
import { ResultResponseMapper } from '../dto/ResultResponse'
import {UseAccountManagerBulkChangeData, UseAccountManagerBulkChangeVariables} from './MutationServiceModel'
import {UseUpdateCompletedDataData, UseUpdateCompletedDataVariables} from './MutationServiceModel'
import {UseUpdateReportTextData, UseUpdateReportTextVariables} from './MutationServiceModel'
import {UseRegeneratePoaData, UseRegeneratePoaVariables} from './MutationServiceModel'
import {UseMarkPoaReceivedData, UseMarkPoaReceivedVariables} from './MutationServiceModel'
import {UsePayInvoiceData, UsePayInvoiceVariables} from './MutationServiceModel'
import {UseResendInvoiceEmailData, UseResendInvoiceEmailVariables} from './MutationServiceModel'
import { ScreeningPackageMapper } from '../dto/ScreeningPackage'
import {UseScreeningPackageData, UseScreeningPackageVariables} from './MutationServiceModel'
import { ScreeningDefinitionMapper } from '../dto/ScreeningDefinition'
import {UseScreeningDefinitionData, UseScreeningDefinitionVariables} from './MutationServiceModel'
import { ComponentDefinitionMapper } from '../dto/ComponentDefinition'
import {UseComponentDefinitionData, UseComponentDefinitionVariables} from './MutationServiceModel'
import { CountryMapper } from '../dto/Country'
import {UseCountryData, UseCountryVariables} from './MutationServiceModel'
import {UseCountryDeleteData, UseCountryDeleteVariables} from './MutationServiceModel'
import { AdminUserMapper } from '../dto/AdminUser'
import {UseUpdateUserProfileData, UseUpdateUserProfileVariables} from './MutationServiceModel'
import { UserPreferencesMapper } from '../dto/UserPreferences'
import {UseChangeLanguageData, UseChangeLanguageVariables} from './MutationServiceModel'
import {UseChangePasswordData, UseChangePasswordVariables} from './MutationServiceModel'
import {UseAdminUserData, UseAdminUserVariables} from './MutationServiceModel'
import { ClientMapper } from '../dto/Client'
import {UseClientData, UseClientVariables} from './MutationServiceModel'
import { ClientDtoMapper } from '../dto/ClientDto'
import {UseClientRegistrationData, UseClientRegistrationVariables} from './MutationServiceModel'
import {UseClientDeactivateData, UseClientDeactivateVariables} from './MutationServiceModel'
import {UseClientActivateData, UseClientActivateVariables} from './MutationServiceModel'
import {UseClientDeleteData, UseClientDeleteVariables} from './MutationServiceModel'
import { ClientUserDtoMapper } from '../dto/ClientUserDto'
import {UseClientUserData, UseClientUserVariables} from './MutationServiceModel'
import {UseClientUserCreateData, UseClientUserCreateVariables} from './MutationServiceModel'
import {UseClientUserDeleteData, UseClientUserDeleteVariables} from './MutationServiceModel'
import {UseAddPrepaymentData, UseAddPrepaymentVariables} from './MutationServiceModel'
import { EnumerationMapper } from '../dto/Enumeration'
import {UseEnumerationCreateOrEditData, UseEnumerationCreateOrEditVariables} from './MutationServiceModel'
import {UseAssignComponentData, UseAssignComponentVariables} from './MutationServiceModel'
import {UseAssignComponentsData, UseAssignComponentsVariables} from './MutationServiceModel'
import {UseCamelIntegrationEditData, UseCamelIntegrationEditVariables} from './MutationServiceModel'
import {UseReplaceComponentProcessData, UseReplaceComponentProcessVariables} from './MutationServiceModel'
import { DynamicFormValidationResultMapper } from '../dto/DynamicFormValidationResult'
import {UseFillFormData, UseFillFormVariables} from './MutationServiceModel'
import { ComponentNoteMapper } from '../dto/ComponentNote'
import {UseComponentNoteCreateOrEditData, UseComponentNoteCreateOrEditVariables} from './MutationServiceModel'
import {UseComponentNoteDeleteData, UseComponentNoteDeleteVariables} from './MutationServiceModel'
import {UseTaskTypeData, UseTaskTypeVariables} from './MutationServiceModel'
import { TranslationMapper } from '../dto/Translation'
import {UseTranslationData, UseTranslationVariables} from './MutationServiceModel'
import { ResourceDatabaseSectionMapper } from '../dto/ResourceDatabaseSection'
import {UseResourceDatabaseSectionData, UseResourceDatabaseSectionVariables} from './MutationServiceModel'
import { ResourceDatabaseItemMapper } from '../dto/ResourceDatabaseItem'
import {UseResourceDatabaseItemData, UseResourceDatabaseItemVariables} from './MutationServiceModel'
import { FAQMapper } from '../dto/FAQ'
import {UseFaqData, UseFaqVariables} from './MutationServiceModel'
import {UseFaqDeleteData, UseFaqDeleteVariables} from './MutationServiceModel'
import { FileDeleteHandlerResponseMapper } from '../dto/FileDeleteHandlerResponse'
import {UseDeleteFileData, UseDeleteFileVariables} from './MutationServiceModel'

export function useAccountManager(
  mapper: PrimaryOrderMapper,
  options?: MutationHookOptions<UseAccountManagerData, UseAccountManagerVariables>
): MutationTuple<UseAccountManagerData, UseAccountManagerVariables> {
  const mutationBuilder: GraphqlBuilder<PrimaryOrderMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('accountManager')
      .setReturnProperties(mapper)
      .declareVariables({
        primaryOrderId: 'Long!',
        accountManagerId: 'Long!',        
      })
      .setProperties(
        `primaryOrderId: $primaryOrderId,
        accountManagerId: $accountManagerId`
      )
      .build(),
    options
  );
}


export function useAccountManagerBulkChange(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseAccountManagerBulkChangeData, UseAccountManagerBulkChangeVariables>
): MutationTuple<UseAccountManagerBulkChangeData, UseAccountManagerBulkChangeVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('accountManagerBulkChange')
      .setReturnProperties(mapper)
      .declareVariables({
        accountManagerId: 'Long!',
        primaryOrderIds: '[Long]',        
      })
      .setProperties(
        `accountManagerId: $accountManagerId,
        primaryOrderIds: $primaryOrderIds`
      )
      .build(),
    options
  );
}


export function useUpdateCompletedData(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseUpdateCompletedDataData, UseUpdateCompletedDataVariables>
): MutationTuple<UseUpdateCompletedDataData, UseUpdateCompletedDataVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('updateCompletedData')
      .setReturnProperties(mapper)
      .declareVariables({
        candidateScreeningId: 'Long!',
        formData: 'MAP_String_Object!',        
      })
      .setProperties(
        `candidateScreeningId: $candidateScreeningId,
        formData: $formData`
      )
      .build(),
    options
  );
}


export function useUpdateReportText(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseUpdateReportTextData, UseUpdateReportTextVariables>
): MutationTuple<UseUpdateReportTextData, UseUpdateReportTextVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('updateReportText')
      .setReturnProperties(mapper)
      .declareVariables({
        candidateScreeningId: 'Long!',
        reportText: 'String',        
      })
      .setProperties(
        `candidateScreeningId: $candidateScreeningId,
        reportText: $reportText`
      )
      .build(),
    options
  );
}


export function useRegeneratePoa(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseRegeneratePoaData, UseRegeneratePoaVariables>
): MutationTuple<UseRegeneratePoaData, UseRegeneratePoaVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('regeneratePoa')
      .setReturnProperties(mapper)
      .declareVariables({
        candidateScreeningId: 'Long!',        
      })
      .setProperties(
        `candidateScreeningId: $candidateScreeningId`
      )
      .build(),
    options
  );
}


export function useMarkPoaReceived(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseMarkPoaReceivedData, UseMarkPoaReceivedVariables>
): MutationTuple<UseMarkPoaReceivedData, UseMarkPoaReceivedVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('markPoaReceived')
      .setReturnProperties(mapper)
      .declareVariables({
        candidateScreeningId: 'Long!',        
      })
      .setProperties(
        `candidateScreeningId: $candidateScreeningId`
      )
      .build(),
    options
  );
}


export function usePayInvoice(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UsePayInvoiceData, UsePayInvoiceVariables>
): MutationTuple<UsePayInvoiceData, UsePayInvoiceVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('payInvoice')
      .setReturnProperties(mapper)
      .declareVariables({
        invoiceId: 'Long!',
        paymentType: 'PaymentType!',
        note: 'String!',        
      })
      .setProperties(
        `invoiceId: $invoiceId,
        paymentType: $paymentType,
        note: $note`
      )
      .build(),
    options
  );
}


export function useResendInvoiceEmail(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseResendInvoiceEmailData, UseResendInvoiceEmailVariables>
): MutationTuple<UseResendInvoiceEmailData, UseResendInvoiceEmailVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('resendInvoiceEmail')
      .setReturnProperties(mapper)
      .declareVariables({
        invoiceId: 'Long!',        
      })
      .setProperties(
        `invoiceId: $invoiceId`
      )
      .build(),
    options
  );
}


export function useScreeningPackage(
  mapper: ScreeningPackageMapper,
  options?: MutationHookOptions<UseScreeningPackageData, UseScreeningPackageVariables>
): MutationTuple<UseScreeningPackageData, UseScreeningPackageVariables> {
  const mutationBuilder: GraphqlBuilder<ScreeningPackageMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('screeningPackage')
      .setReturnProperties(mapper)
      .declareVariables({
        screeningPackage: 'ScreeningPackageGraphQLInput!',        
      })
      .setProperties(
        `screeningPackage: $screeningPackage`
      )
      .build(),
    options
  );
}


export function useScreeningDefinition(
  mapper: ScreeningDefinitionMapper,
  options?: MutationHookOptions<UseScreeningDefinitionData, UseScreeningDefinitionVariables>
): MutationTuple<UseScreeningDefinitionData, UseScreeningDefinitionVariables> {
  const mutationBuilder: GraphqlBuilder<ScreeningDefinitionMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('screeningDefinition')
      .setReturnProperties(mapper)
      .declareVariables({
        screeningDefinition: 'ScreeningDefinitionGraphQLInput!',        
      })
      .setProperties(
        `screeningDefinition: $screeningDefinition`
      )
      .build(),
    options
  );
}


export function useComponentDefinition(
  mapper: ComponentDefinitionMapper,
  options?: MutationHookOptions<UseComponentDefinitionData, UseComponentDefinitionVariables>
): MutationTuple<UseComponentDefinitionData, UseComponentDefinitionVariables> {
  const mutationBuilder: GraphqlBuilder<ComponentDefinitionMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('componentDefinition')
      .setReturnProperties(mapper)
      .declareVariables({
        componentDefinition: 'ComponentDefinitionGraphQLInput!',        
      })
      .setProperties(
        `componentDefinition: $componentDefinition`
      )
      .build(),
    options
  );
}


export function useCountry(
  mapper: CountryMapper,
  options?: MutationHookOptions<UseCountryData, UseCountryVariables>
): MutationTuple<UseCountryData, UseCountryVariables> {
  const mutationBuilder: GraphqlBuilder<CountryMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('country')
      .setReturnProperties(mapper)
      .declareVariables({
        countryId: 'Long',
        country: 'CountryGraphQLInput',        
      })
      .setProperties(
        `countryId: $countryId,
        country: $country`
      )
      .build(),
    options
  );
}


export function useCountryDelete(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseCountryDeleteData, UseCountryDeleteVariables>
): MutationTuple<UseCountryDeleteData, UseCountryDeleteVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('countryDelete')
      .setReturnProperties(mapper)
      .declareVariables({
        countryId: 'Long!',        
      })
      .setProperties(
        `countryId: $countryId`
      )
      .build(),
    options
  );
}


export function useUpdateUserProfile(
  mapper: AdminUserMapper,
  options?: MutationHookOptions<UseUpdateUserProfileData, UseUpdateUserProfileVariables>
): MutationTuple<UseUpdateUserProfileData, UseUpdateUserProfileVariables> {
  const mutationBuilder: GraphqlBuilder<AdminUserMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('updateUserProfile')
      .setReturnProperties(mapper)
      .declareVariables({
        adminUser: 'AdminUserGraphQLInput!',        
      })
      .setProperties(
        `adminUser: $adminUser`
      )
      .build(),
    options
  );
}


export function useChangeLanguage(
  mapper: UserPreferencesMapper,
  options?: MutationHookOptions<UseChangeLanguageData, UseChangeLanguageVariables>
): MutationTuple<UseChangeLanguageData, UseChangeLanguageVariables> {
  const mutationBuilder: GraphqlBuilder<UserPreferencesMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('changeLanguage')
      .setReturnProperties(mapper)
      .declareVariables({
        language: 'Language!',        
      })
      .setProperties(
        `language: $language`
      )
      .build(),
    options
  );
}


export function useChangePassword(
  mapper: AdminUserMapper,
  options?: MutationHookOptions<UseChangePasswordData, UseChangePasswordVariables>
): MutationTuple<UseChangePasswordData, UseChangePasswordVariables> {
  const mutationBuilder: GraphqlBuilder<AdminUserMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('changePassword')
      .setReturnProperties(mapper)
      .declareVariables({
        originalPassword: 'String!',
        password: 'String!',
        passwordConfirmation: 'String!',        
      })
      .setProperties(
        `originalPassword: $originalPassword,
        password: $password,
        passwordConfirmation: $passwordConfirmation`
      )
      .build(),
    options
  );
}


export function useAdminUser(
  mapper: AdminUserMapper,
  options?: MutationHookOptions<UseAdminUserData, UseAdminUserVariables>
): MutationTuple<UseAdminUserData, UseAdminUserVariables> {
  const mutationBuilder: GraphqlBuilder<AdminUserMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('adminUser')
      .setReturnProperties(mapper)
      .declareVariables({
        adminUserId: 'Long',
        adminUser: 'AdminUserDetailGraphQLInput!',        
      })
      .setProperties(
        `adminUserId: $adminUserId,
        adminUser: $adminUser`
      )
      .build(),
    options
  );
}


export function useClient(
  mapper: ClientMapper,
  options?: MutationHookOptions<UseClientData, UseClientVariables>
): MutationTuple<UseClientData, UseClientVariables> {
  const mutationBuilder: GraphqlBuilder<ClientMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('client')
      .setReturnProperties(mapper)
      .declareVariables({
        clientId: 'Long!',
        client: 'ClientGraphQLInput',        
      })
      .setProperties(
        `clientId: $clientId,
        client: $client`
      )
      .build(),
    options
  );
}


export function useClientRegistration(
  mapper: ClientDtoMapper,
  options?: MutationHookOptions<UseClientRegistrationData, UseClientRegistrationVariables>
): MutationTuple<UseClientRegistrationData, UseClientRegistrationVariables> {
  const mutationBuilder: GraphqlBuilder<ClientDtoMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('clientRegistration')
      .setReturnProperties(mapper)
      .declareVariables({
        client: 'ClientRegistrationGraphQLInput',        
      })
      .setProperties(
        `client: $client`
      )
      .build(),
    options
  );
}


export function useClientDeactivate(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseClientDeactivateData, UseClientDeactivateVariables>
): MutationTuple<UseClientDeactivateData, UseClientDeactivateVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('clientDeactivate')
      .setReturnProperties(mapper)
      .declareVariables({
        clientId: 'Long!',        
      })
      .setProperties(
        `clientId: $clientId`
      )
      .build(),
    options
  );
}


export function useClientActivate(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseClientActivateData, UseClientActivateVariables>
): MutationTuple<UseClientActivateData, UseClientActivateVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('clientActivate')
      .setReturnProperties(mapper)
      .declareVariables({
        clientId: 'Long!',        
      })
      .setProperties(
        `clientId: $clientId`
      )
      .build(),
    options
  );
}


export function useClientDelete(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseClientDeleteData, UseClientDeleteVariables>
): MutationTuple<UseClientDeleteData, UseClientDeleteVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('clientDelete')
      .setReturnProperties(mapper)
      .declareVariables({
        clientId: 'Long!',        
      })
      .setProperties(
        `clientId: $clientId`
      )
      .build(),
    options
  );
}


export function useClientUser(
  mapper: ClientUserDtoMapper,
  options?: MutationHookOptions<UseClientUserData, UseClientUserVariables>
): MutationTuple<UseClientUserData, UseClientUserVariables> {
  const mutationBuilder: GraphqlBuilder<ClientUserDtoMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('clientUser')
      .setReturnProperties(mapper)
      .declareVariables({
        clientUserId: 'Long!',
        clientUser: 'ClientUserGraphQLInput',        
      })
      .setProperties(
        `clientUserId: $clientUserId,
        clientUser: $clientUser`
      )
      .build(),
    options
  );
}


export function useClientUserCreate(
  mapper: ClientUserDtoMapper,
  options?: MutationHookOptions<UseClientUserCreateData, UseClientUserCreateVariables>
): MutationTuple<UseClientUserCreateData, UseClientUserCreateVariables> {
  const mutationBuilder: GraphqlBuilder<ClientUserDtoMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('clientUserCreate')
      .setReturnProperties(mapper)
      .declareVariables({
        clientId: 'Long!',
        clientUser: 'ClientUserCreateGraphQLInput',        
      })
      .setProperties(
        `clientId: $clientId,
        clientUser: $clientUser`
      )
      .build(),
    options
  );
}


export function useClientUserDelete(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseClientUserDeleteData, UseClientUserDeleteVariables>
): MutationTuple<UseClientUserDeleteData, UseClientUserDeleteVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('clientUserDelete')
      .setReturnProperties(mapper)
      .declareVariables({
        clientUserId: 'Long!',        
      })
      .setProperties(
        `clientUserId: $clientUserId`
      )
      .build(),
    options
  );
}


export function useAddPrepayment(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseAddPrepaymentData, UseAddPrepaymentVariables>
): MutationTuple<UseAddPrepaymentData, UseAddPrepaymentVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('addPrepayment')
      .setReturnProperties(mapper)
      .declareVariables({
        clientId: 'Long!',
        balance: 'Long!',
        paymentType: 'PaymentType!',
        note: 'String!',        
      })
      .setProperties(
        `clientId: $clientId,
        balance: $balance,
        paymentType: $paymentType,
        note: $note`
      )
      .build(),
    options
  );
}


export function useEnumerationCreateOrEdit(
  mapper: EnumerationMapper,
  options?: MutationHookOptions<UseEnumerationCreateOrEditData, UseEnumerationCreateOrEditVariables>
): MutationTuple<UseEnumerationCreateOrEditData, UseEnumerationCreateOrEditVariables> {
  const mutationBuilder: GraphqlBuilder<EnumerationMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('enumerationCreateOrEdit')
      .setReturnProperties(mapper)
      .declareVariables({
        enumerationId: 'Long',
        enumeration: 'EnumerationGraphQLInput',        
      })
      .setProperties(
        `enumerationId: $enumerationId,
        enumeration: $enumeration`
      )
      .build(),
    options
  );
}


export function useAssignComponent(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseAssignComponentData, UseAssignComponentVariables>
): MutationTuple<UseAssignComponentData, UseAssignComponentVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('assignComponent')
      .setReturnProperties(mapper)
      .declareVariables({
        adminUserId: 'Long!',
        componentId: 'Long!',        
      })
      .setProperties(
        `adminUserId: $adminUserId,
        componentId: $componentId`
      )
      .build(),
    options
  );
}


export function useAssignComponents(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseAssignComponentsData, UseAssignComponentsVariables>
): MutationTuple<UseAssignComponentsData, UseAssignComponentsVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('assignComponents')
      .setReturnProperties(mapper)
      .declareVariables({
        adminUserId: 'Long!',
        componentIds: '[Long]',        
      })
      .setProperties(
        `adminUserId: $adminUserId,
        componentIds: $componentIds`
      )
      .build(),
    options
  );
}


export function useCamelIntegrationEdit(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseCamelIntegrationEditData, UseCamelIntegrationEditVariables>
): MutationTuple<UseCamelIntegrationEditData, UseCamelIntegrationEditVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('camelIntegrationEdit')
      .setReturnProperties(mapper)
      .declareVariables({
        camelIntegrationId: 'Long',
        retries: 'Int',
        maxRetries: 'Int',        
      })
      .setProperties(
        `camelIntegrationId: $camelIntegrationId,
        retries: $retries,
        maxRetries: $maxRetries`
      )
      .build(),
    options
  );
}


export function useReplaceComponentProcess(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseReplaceComponentProcessData, UseReplaceComponentProcessVariables>
): MutationTuple<UseReplaceComponentProcessData, UseReplaceComponentProcessVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('replaceComponentProcess')
      .setReturnProperties(mapper)
      .declareVariables({
        componentId: 'Long!',
        replaceProcess: 'ReplaceProcessDtoInput',        
      })
      .setProperties(
        `componentId: $componentId,
        replaceProcess: $replaceProcess`
      )
      .build(),
    options
  );
}


export function useFillForm(
  mapper: DynamicFormValidationResultMapper,
  options?: MutationHookOptions<UseFillFormData, UseFillFormVariables>
): MutationTuple<UseFillFormData, UseFillFormVariables> {
  const mutationBuilder: GraphqlBuilder<DynamicFormValidationResultMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('fillForm')
      .setReturnProperties(mapper)
      .declareVariables({
        formId: 'Long!',
        formData: 'MAP_String_Object!',        
      })
      .setProperties(
        `formId: $formId,
        formData: $formData`
      )
      .build(),
    options
  );
}


export function useComponentNoteCreateOrEdit(
  mapper: ComponentNoteMapper,
  options?: MutationHookOptions<UseComponentNoteCreateOrEditData, UseComponentNoteCreateOrEditVariables>
): MutationTuple<UseComponentNoteCreateOrEditData, UseComponentNoteCreateOrEditVariables> {
  const mutationBuilder: GraphqlBuilder<ComponentNoteMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('componentNoteCreateOrEdit')
      .setReturnProperties(mapper)
      .declareVariables({
        componentNoteId: 'Long',
        componentId: 'Long',
        title: 'String',
        text: 'String',
        visibility: 'NoteVisibility',        
      })
      .setProperties(
        `componentNoteId: $componentNoteId,
        componentId: $componentId,
        title: $title,
        text: $text,
        visibility: $visibility`
      )
      .build(),
    options
  );
}


export function useComponentNoteDelete(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseComponentNoteDeleteData, UseComponentNoteDeleteVariables>
): MutationTuple<UseComponentNoteDeleteData, UseComponentNoteDeleteVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('componentNoteDelete')
      .setReturnProperties(mapper)
      .declareVariables({
        componentNoteId: 'Long!',        
      })
      .setProperties(
        `componentNoteId: $componentNoteId`
      )
      .build(),
    options
  );
}


export function useTaskType(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseTaskTypeData, UseTaskTypeVariables>
): MutationTuple<UseTaskTypeData, UseTaskTypeVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('taskType')
      .setReturnProperties(mapper)
      .declareVariables({
        componentId: 'Long!',
        taskType: 'TaskType!',        
      })
      .setProperties(
        `componentId: $componentId,
        taskType: $taskType`
      )
      .build(),
    options
  );
}


export function useTranslation(
  mapper: TranslationMapper,
  options?: MutationHookOptions<UseTranslationData, UseTranslationVariables>
): MutationTuple<UseTranslationData, UseTranslationVariables> {
  const mutationBuilder: GraphqlBuilder<TranslationMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('translation')
      .setReturnProperties(mapper)
      .declareVariables({
        translationId: 'Long',
        translation: 'TranslationGraphQLInput',        
      })
      .setProperties(
        `translationId: $translationId,
        translation: $translation`
      )
      .build(),
    options
  );
}


export function useResourceDatabaseSection(
  mapper: ResourceDatabaseSectionMapper,
  options?: MutationHookOptions<UseResourceDatabaseSectionData, UseResourceDatabaseSectionVariables>
): MutationTuple<UseResourceDatabaseSectionData, UseResourceDatabaseSectionVariables> {
  const mutationBuilder: GraphqlBuilder<ResourceDatabaseSectionMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('resourceDatabaseSection')
      .setReturnProperties(mapper)
      .declareVariables({
        section: 'ResourceDbSectionGraphQLInput!',        
      })
      .setProperties(
        `section: $section`
      )
      .build(),
    options
  );
}


export function useResourceDatabaseItem(
  mapper: ResourceDatabaseItemMapper,
  options?: MutationHookOptions<UseResourceDatabaseItemData, UseResourceDatabaseItemVariables>
): MutationTuple<UseResourceDatabaseItemData, UseResourceDatabaseItemVariables> {
  const mutationBuilder: GraphqlBuilder<ResourceDatabaseItemMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('resourceDatabaseItem')
      .setReturnProperties(mapper)
      .declareVariables({
        sectionId: 'Long!',
        item: 'ResourceDbItemGraphQLInput!',        
      })
      .setProperties(
        `sectionId: $sectionId,
        item: $item`
      )
      .build(),
    options
  );
}


export function useFaq(
  mapper: FAQMapper,
  options?: MutationHookOptions<UseFaqData, UseFaqVariables>
): MutationTuple<UseFaqData, UseFaqVariables> {
  const mutationBuilder: GraphqlBuilder<FAQMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('faq')
      .setReturnProperties(mapper)
      .declareVariables({
        faqId: 'Long',
        faq: 'FAQGraphQLInput',        
      })
      .setProperties(
        `faqId: $faqId,
        faq: $faq`
      )
      .build(),
    options
  );
}


export function useFaqDelete(
  mapper: ResultResponseMapper,
  options?: MutationHookOptions<UseFaqDeleteData, UseFaqDeleteVariables>
): MutationTuple<UseFaqDeleteData, UseFaqDeleteVariables> {
  const mutationBuilder: GraphqlBuilder<ResultResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('faqDelete')
      .setReturnProperties(mapper)
      .declareVariables({
        faqId: 'Long!',        
      })
      .setProperties(
        `faqId: $faqId`
      )
      .build(),
    options
  );
}


export function useDeleteFile(
  mapper: FileDeleteHandlerResponseMapper,
  options?: MutationHookOptions<UseDeleteFileData, UseDeleteFileVariables>
): MutationTuple<UseDeleteFileData, UseDeleteFileVariables> {
  const mutationBuilder: GraphqlBuilder<FileDeleteHandlerResponseMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('deleteFile')
      .setReturnProperties(mapper)
      .declareVariables({
        id: 'Long!',        
      })
      .setProperties(
        `id: $id`
      )
      .build(),
    options
  );
}


