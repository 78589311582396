import React, { useRef, useState } from 'react';
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import Translate from '../../../components/Translate/Translate';
import TranslationsLocale from '../Translations.locale';
import FormLocale from '../../../core/localization/translations/Form.locale';
import TranslationDetailForm from './TranslationDetailForm';
import { TranslationsDetailModalProps } from '../Translations.model';

const TranslationDetailModal: React.FunctionComponent<TranslationsDetailModalProps> = (props) => {
  const { onTranslationChosen, chooseModalOnClose } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);

  return (
    <>
      <Button onClick={onOpen} leftIcon={<AddIcon />}>
        <Translate label={FormLocale.NEW} />
      </Button>
      <Modal closeOnOverlayClick={false} size="4xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text><Translate label={TranslationsLocale.TRANSLATION} /></Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TranslationDetailForm
              loadingSetter={(isLoading) => { setLoading(isLoading); }}
              formRef={formRef}
              onClose={onClose}
              onTranslationChosen={onTranslationChosen}
              chooseModalOnClose={chooseModalOnClose}
            />
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button
                onClick={() => {
                  if (formRef) {
                    formRef.current?.dispatchEvent(new Event('submit', {
                      cancelable: true,
                      bubbles: true,
                    }));
                  }
                }}
                isLoading={loading}
              >
                <Translate label={FormLocale.CONFIRM} />
              </Button>
              <Button variant="ghost" colorScheme="red" onClick={onClose}>
                <Translate label={FormLocale.CANCEL} />
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TranslationDetailModal;
