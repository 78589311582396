import React from 'react';
import { Center, Icon } from '@chakra-ui/react';
import { FiUser, FiUsers } from 'react-icons/fi';
import { OrderType } from '../../build/generated-sources/enum/OrderType';

const UserIcon: React.FunctionComponent<{ type: OrderType; }> = ({ type }) => (
  <Center
    bgColor="gray.100"
    h={10}
    w={10}
    borderRadius="100%"
  >
    <Icon as={type === OrderType.SINGLE ? FiUser : FiUsers} />
  </Center>
);

export default UserIcon;
