import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Toast } from '@scaut-sro/meepo';
import { CheckIcon, SearchIcon } from '@chakra-ui/icons';
import Translate from '../../../components/Translate/Translate';
import TranslationsLocale from '../Translations.locale';
import FormLocale from '../../../core/localization/translations/Form.locale';
import Translations from '../Translations';
import { Translation } from '../../../build/generated-sources/dto/Translation';
import { TranslationChooseModalProps } from '../Translations.model';
import { useLazyGetFindTranslation } from '../../../build/generated-sources/service/QueryService';
import TranslationDetailReadOnly from '../TranslationDetail/TranslationDetailReadOnly';
import { translate } from '../../../core/localization/LocaleUtils';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';

const TranslationChooseModal:React.FunctionComponent<TranslationChooseModalProps> = (props) => {
  const { translationId, setValue } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [translation, setTranslation] = useState<Translation>();
  const { language } = useGetUser();

  const [getTranslationHandle] = useLazyGetFindTranslation({
    id: true,
    name: true,
    localizationStrings: true,
    screeningDefinitionNames: {
      id: true,
      internalName: true,
    },
    screeningDefinitionDescriptions: {
      id: true,
      internalName: true,
    },
    screeningPackageNames: {
      id: true,
      internalName: true,
    },
    screeningPackageDescriptions: {
      id: true,
      internalName: true,
    },
    countryNames: {
      id: true,
    },
    faqQuestions: {
      id: true,
    },
    faqAnswers: {
      id: true,
    },
  }, {
    onError: () => {
      Toast({
        title: translate(TranslationsLocale.ERROR_GETTING_TRANSLATIONS, language),
        status: 'error',
      });
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      setTranslation(response.findTranslation);
    },
  });

  useEffect(() => {
    if (translationId) {
      getTranslationHandle({ variables: { id: translationId } });
    }
  }, [translationId, getTranslationHandle]);

  return (
    <>
      <Input {...props} value={translation?.id} display="none" />
      {
                translation?.name ? (
                  <Button
                    rightIcon={<CheckIcon />}
                    variant="ghost"
                    colorScheme="green"
                    whiteSpace="normal"
                    onClick={onOpen}
                  >
                    { translation?.name }
                  </Button>
                ) : (
                  <Button
                    rightIcon={<SearchIcon />}
                    variant="outline"
                    colorScheme="green"
                    onClick={onOpen}
                  >
                    <Translate label={TranslationsLocale.CHOOSE_TRANSLATION} />
                  </Button>
                )
            }

      <Modal closeOnOverlayClick={false} size="6xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text><Translate label={TranslationsLocale.TRANSLATION} /></Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {
              translation ? (
                <Box>
                  <TranslationDetailReadOnly translation={translation} />
                </Box>
              ) : (<></>)
            }
            <Box>
              <Translations
                isModal
                onTranslationChosen={(chosenTranslation) => {
                  setTranslation(chosenTranslation);
                  if (setValue) {
                    setValue(chosenTranslation);
                  }
                }}
                chooseModalOnClose={onClose}
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button variant="ghost" colorScheme="red" onClick={onClose}>
                <Translate label={FormLocale.CANCEL} />
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TranslationChooseModal;
