import { Translation } from '../../core/localization/Localization.model';

class EnumerationsLocale {
  public static GO_TO_DETAIL: Translation = { EN: 'Go to detail', CZ: 'Přejít na detail' };
  public static NAME: Translation = { EN: 'Name', CZ: 'Název' };
  public static NEW_COUNTRY: Translation = { EN: 'New country', CZ: 'Nová země' };
  public static TRANSLATION_NAME: Translation = { EN: 'Localized name', CZ: 'Lokalizované jméno' };
  public static VISIBLE_BY_DEFAULT: Translation = { EN: 'Visible by default', CZ: 'Ve výchozím nastavení viditelné' };
  public static ACTIVE: Translation = { EN: 'Active', CZ: 'Aktivní' };
  public static YES: Translation = { EN: 'Yes', CZ: 'Ano' };
  public static EDIT: Translation = { EN: 'Edit', CZ: 'Upravit' };
  public static NO: Translation = { EN: 'No', CZ: 'Ne' };
  public static TYPE: Translation = { EN: 'Type', CZ: 'Typ' };
  public static COUNTRY: Translation = { EN: 'COUNTRY', CZ: 'ZEMĚ' };
  public static COUNTRY_CODE: Translation = { EN: 'Country code', CZ: 'Kód země' };
  public static SURVEY: Translation = { EN: 'SURVEY', CZ: 'PRŮZKUM' };
  public static NEW: Translation = { EN: 'New', CZ: 'Nový' };
  public static ENUMERATION_DETAIL: Translation = { EN: 'Enumeration detail', CZ: 'Detail výčtu' };
  public static COUNTRIES: Translation = { EN: 'Countries', CZ: 'země' };
  public static ENUMERATIONS: Translation = { EN: 'Enumerations', CZ: 'Výčty' };
  public static ENUMERATION: Translation = { EN: 'Enumeration', CZ: 'Výčet' };
  public static ERROR_GETTING_TRANSLATIONS: Translation = {
    EN: 'There was a problem getting translations.',
    CZ: 'Problém při načitání překladů',
  };
  public static ERROR_DELETING_TRANSLATIONS: Translation = {
    EN: 'There was a problem deleting translation.',
    CZ: 'Při mazání překladu došlo k problému',
  };

  public static ERROR_SAVING_ENUMERATION: Translation = {
    EN: 'There was a problem saving enumeration.',
    CZ: 'Při ukládání výčtu došlo k problému',
  };
  public static COUNTRY_DELETED: Translation = {
    EN: 'Country deleted',
    CZ: 'Země smazána',
  };
  public static ARE_YOU_SURE: Translation = {
    EN: 'Are you sure you want to delete translation',
    CZ: 'Opravdu chcete smazat překlad',
  };
  public static ERROR_GETTING_DATA: Translation = {
    EN: 'There was a problem getting data',
    CZ: 'Při získávání dat došlo k problému',
  };
}

export default EnumerationsLocale;
