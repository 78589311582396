import React, { useState } from 'react';
import {
  MeepoTable, SectionContainer, Toast,
} from '@scaut-sro/meepo';
import { Box, Checkbox, Text } from '@chakra-ui/react';
import { useGetIntegrations } from '../../../build/generated-sources/service/QueryService';
import { CamelIntegrationDto } from '../../../build/generated-sources/dto/CamelIntegrationDto';
import ShowDate from '../../../components/ShowDate/ShowDate';
import { CamelIntegrationsTableData, IntegrationsProps } from './Components.model';
import Translate from '../../../components/Translate/Translate';
import { translate } from '../../../core/localization/LocaleUtils';
import TableLocale from '../../../core/localization/translations/Table.locale';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import TasksLocale from '../Tasks.locale';
import IntegrationModal from './IntegrationModal';
import { State } from '../../../build/generated-sources/enum/State';

export function getCamelIntegrationsTableData(data: CamelIntegrationDto[] | undefined,
  componentState?: State): CamelIntegrationsTableData[] {
  if (data) {
    return data.map((camelIntegration) => ({
      id: camelIntegration.id || '-undefined-',
      retries: camelIntegration.retries,
      maxRetries: camelIntegration.maxRetries,
      statusMessage: camelIntegration.statusMessage,
      finished: <Checkbox isChecked={camelIntegration.finished} isDisabled />,
      lastRetry: <ShowDate date={camelIntegration.lastRetry} time />,
      buttons: (
        <IntegrationModal integration={camelIntegration} componentState={componentState} />
      ),
    }));
  }
  return [];
}

const IntegrationDetail: React.FunctionComponent<IntegrationsProps> = (props) => {
  const { componentId, componentState } = props;
  const [list, setList] = useState<CamelIntegrationsTableData[]>([]);
  const { language } = useGetUser();

  const { loading } = useGetIntegrations({
    id: true,
    finished: true,
    lastRetry: true,
    maxRetries: true,
    retries: true,
    statusMessage: true,
  }, {
    onCompleted: (response) => {
      const onCompleteData = getCamelIntegrationsTableData(response.integrations, componentState);
      if (response.integrations === null) {
        Toast({
          title: translate(TasksLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      }
      setList((prev) => [...prev, ...onCompleteData]);
    },
    onError: () => {
      Toast({
        title: translate(TasksLocale.ERROR_GETTING_DATA, language),
        status: 'error',
      });
    },
    variables: {
      componentId: componentId || -1,
    },
  });

  return (
    <>
      <SectionContainer>
        <Box overflowX="auto">
          <Box minW="600px">
            <Box overflowX="auto">
              <Box minW="600px">
                <MeepoTable
                  columns={[
                    {
                      id: 'retries',
                      label: translate(TasksLocale.RETRIES, language),
                      minW: '200px',
                    },
                    {
                      id: 'maxRetries',
                      label: translate(TasksLocale.MAX_RETRIES, language),
                      minW: '100px',
                    },
                    {
                      id: 'statusMessage',
                      label: translate(TasksLocale.STATUS_MESSAGE, language),
                      alignData: 'center',
                      alignLabel: 'center',
                    },
                    {
                      id: 'finished',
                      label: translate(TasksLocale.FINISHED, language),
                      alignData: 'center',
                      alignLabel: 'center',
                    },
                    {
                      id: 'lastRetry',
                      label: translate(TasksLocale.LAST_RETRY, language),
                      alignData: 'center',
                      alignLabel: 'center',
                    },
                    {
                      id: 'buttons',
                      label: translate(TasksLocale.TABLE_DETAIL_GO_TO_DETAIL, language),
                      minW: '150px',
                      boxProps: { w: '200px' },
                    },
                  ]}
                  data={list}
                  isLoading={loading}
                  noData={<Text><Translate label={TableLocale.NO_DATA} /></Text>}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </SectionContainer>
    </>
  );
};

export default IntegrationDetail;
