import React, { useRef, useState } from 'react';
import {
  Button, ButtonGroup, HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent, ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { ConfirmationModal, Toast } from '@scaut-sro/meepo';
import Translate from '../../components/Translate/Translate';
import FaqLocale from './Faq.locale';
import FormLocale from '../../core/localization/translations/Form.locale';
import FaqForm from './FaqForm';
import { FaqDetailProps } from './Faq.model';
import { translate } from '../../core/localization/LocaleUtils';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { useFaqDelete } from '../../build/generated-sources/service/MutationService';

const FaqModal: React.FunctionComponent<FaqDetailProps> = (props) => {
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const [formIsLoading, setFormIsLoading] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);
  const { language } = useGetUser();
  const { faq, refreshData } = props;

  const [mutateDeleteFaq] = useFaqDelete({
    success: true,
  }, {
    onCompleted: () => {
      Toast({
        title: translate(FaqLocale.FAQ_DELETED, language),
        status: 'success',
      });
      refreshData();
    },
    onError: () => {
      Toast({
        title: translate(FaqLocale.ERROR_DELETING_QUESTION, language),
        status: 'error',
      });
    },
  });

  const deleteFaq = () => {
    mutateDeleteFaq({
      variables: {
        faqId: faq.id || -1,
      },
    });
    onDeleteClose();
    onModalClose();
  };

  return (
    <>
      {faq?.id ? (
        <Text color="primaryBase" cursor="pointer" onClick={onModalOpen}>
          <strong><Translate label={FaqLocale.GO_TO_DETAIL} /></strong>
        </Text>
      ) : (
        <Button onClick={onModalOpen} leftIcon={<AddIcon />}>
          <Translate label={FormLocale.NEW} />
        </Button>
      )}
      <Modal closeOnOverlayClick={false} size="4xl" isOpen={isModalOpen} onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {faq?.id ? (
              <Text><Translate label={FaqLocale.MODAL_EDIT_QUESTION_TITLE} /></Text>
            ) : (
              <Text><Translate label={FaqLocale.MODAL_CREATE_QUESTION_TITLE} /></Text>
            )}
            {faq?.id && (
              <HStack justifyContent="right">
                <Button
                  variant="outline"
                  colorScheme="red"
                  onClick={onDeleteOpen}
                  isDisabled={formIsLoading}
                >
                  <Translate label={FormLocale.DELETE} />
                </Button>
              </HStack>
            )}
            <ConfirmationModal
              message={translate(FaqLocale.ARE_YOU_SURE, language)}
              yes={translate(FaqLocale.YES, language)}
              no={translate(FaqLocale.NO, language)}
              name={`${faq?.question?.name}?`}
              modalFunction={deleteFaq}
              isOpen={isDeleteOpen}
              onClose={onDeleteClose}
              onOpen={onDeleteOpen}
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FaqForm
              refreshData={refreshData}
              loadingSetter={(isLoading) => { setFormIsLoading(isLoading); }}
              formRef={formRef}
              faq={faq}
            />
          </ModalBody>
          <ModalFooter>
            <HStack>
              <ButtonGroup>
                <Button
                  onClick={() => {
                    if (formRef) {
                      formRef.current?.dispatchEvent(new Event('submit', {
                        cancelable: true,
                        bubbles: true,
                      }));
                      onModalClose();
                    }
                  }}
                  isLoading={formIsLoading}
                >
                  <Translate label={FormLocale.SAVE} />
                </Button>
                <Button variant="ghost" colorScheme="red" onClick={onModalClose}>
                  <Translate label={FormLocale.CANCEL} />
                </Button>
              </ButtonGroup>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FaqModal;
