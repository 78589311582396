import React, { useState } from 'react';
import {
  Language,
  MeepoTable, Toast, Translate,
} from '@scaut-sro/meepo';
import {
  Box, Button, Checkbox, HStack, Text,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { ClientUserDto } from '../../../../build/generated-sources/dto/ClientUserDto';
import ShowDate from '../../../../components/ShowDate/ShowDate';
import { useGetClientUsersPage } from '../../../../build/generated-sources/service/QueryService';
import ClientUserRegistrationModal from './ClientUserRegistration/ClientUserRegistrationModal';
import UsersLocale from '../../../Users/Users.locale';
import { Direction } from '../../../../build/generated-sources/enum/Direction';
import { useGetUser } from '../../../../core/store/reducers/UserSettingsReducer';
import ClientsLocale from '../../Clients.locale';
import { translate } from '../../../../core/localization/LocaleUtils';
import TableLocale from '../../../../core/localization/translations/Table.locale';
import { ClientUsersProps, ClientUsersTableData } from './ClientUsers.model';

export function getClientUsersTableData(openClientUserDetail: (clientUserDtoId?: number) => void, language: Language,
  data: ClientUserDto[] | undefined): ClientUsersTableData[] {
  if (data) {
    return data.map((clientUserDto) => {
      let teamsString = '';
      clientUserDto.teams?.map((team, index) => {
        if (team.name) {
          if (clientUserDto.teams!.length === index + 1) {
            teamsString += team.name;
          } else {
            teamsString += `${team.name}, `;
          }
        }
      });
      return {
        id: clientUserDto?.id || '-undefined-',
        name: clientUserDto?.firstName && clientUserDto?.lastName
          ? `${clientUserDto?.firstName} ${clientUserDto?.lastName}` : clientUserDto?.email,
        phone: clientUserDto.phone,
        teams: teamsString,
        role: clientUserDto.role,
        email: clientUserDto.email,
        active: <Checkbox isChecked={clientUserDto.active} isDisabled />,
        lastActive: <ShowDate date={clientUserDto.lastActive} time />,
        buttons: (
          <Button
            variant="link"
            w="100%"
            onClick={() => openClientUserDetail(clientUserDto?.id)}
          >
            <Translate label={UsersLocale.USER_GO_TO_DETAIL} />
          </Button>
        ),
      };
    });
  }
  return [];
}

const ClientUsers:React.FunctionComponent<ClientUsersProps> = (props) => {
  const { clientId } = props;
  const [list, setList] = useState<ClientUsersTableData[]>([]);
  const [page, setPage] = useState<number>(0);
  const [sorting, setSorting] = useState<{ sortBy?: string, sortDirection?: Direction }>({});
  const size = 100;
  const history = useHistory();
  const { language } = useGetUser();

  const openClientUserDetail = (clientUserDtoId?: number) => {
    if (clientUserDtoId) {
      history.push(`/client-user/${clientUserDtoId}`);
    } else {
      history.push('/404');
    }
  };

  const { loading } = useGetClientUsersPage({
    content: {
      id: true,
      email: true,
      firstName: true,
      lastName: true,
      phone: true,
      teams: {
        name: true,
      },
      role: true,
      active: true,
      lastActive: true,
    },
  }, {
    onCompleted: (response) => {
      const onCompleteData = getClientUsersTableData(openClientUserDetail, language, response.clientUsersPage.content);
      if (response.clientUsersPage === null) {
        Toast({
          title: translate(ClientsLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      }
      setList((prev) => [...prev, ...onCompleteData]);
    },
    onError: () => {
      Toast({
        title: translate(ClientsLocale.ERROR_GETTING_DATA, language),
        status: 'error',
      });
    },
    variables: {
      clientId,
      paging: {
        page,
        size,
        sortBy: sorting.sortBy,
        sortDirection: sorting.sortDirection,
      },
    },
  });

  return (
    <>
      <Box minW="600px">
        <HStack
          justifyContent="right"
          mr={12}
          mb={5}
          mt={5}
        >
          <ClientUserRegistrationModal />
        </HStack>
        <MeepoTable
          columns={[
            {
              id: 'name',
              label: translate(ClientsLocale.NAME, language),
              minW: '200px',
            },
            {
              id: 'email',
              label: translate(ClientsLocale.EMAIL, language),
              minW: '100px',
            },
            {
              id: 'phone',
              label: translate(ClientsLocale.PHONE, language),
              minW: '100px',
            },
            {
              id: 'teams',
              label: translate(ClientsLocale.TEAMS, language),
              minW: '100px',
            },
            {
              id: 'role',
              label: translate(ClientsLocale.USER_ROLE, language),
              minW: '100px',
            },
            {
              id: 'active',
              label: translate(ClientsLocale.ACTIVE, language),
              minW: '100px',
              alignLabel: 'center',
              alignData: 'center',
            },
            {
              id: 'lastActive',
              label: translate(ClientsLocale.LAST_ACTIVE, language),
              minW: '100px',
            },
            {
              id: 'buttons',
              label: translate(ClientsLocale.CLIENT_USER_DETAIL, language),
              minW: '150px',
              boxProps: { w: '200px' },
              alignLabel: 'center',
            },
          ]}
          data={list}
          isLoading={loading}
          noData={<Text><Translate label={TableLocale.NO_DATA} /></Text>}
          setPage={() => {
            setPage((prev) => prev + 1);
          }}
          onSort={(newSorting) => {
            const direction: Direction | undefined = newSorting.type === 'asc'
              ? Direction.ASC
              : newSorting.type === 'desc'
                ? Direction.DESC
                : undefined;
            setSorting({
              sortBy: newSorting.column,
              sortDirection: direction,
            });
          }}
          activeSorting={{
            column: sorting.sortBy,
            type: sorting.sortDirection === Direction.ASC
              ? 'asc'
              : sorting.sortDirection === Direction.DESC
                ? 'desc'
                : 'none',
          }}
        />
      </Box>
    </>
  );
};

export default ClientUsers;
