import React, { PropsWithChildren } from 'react';
import { Badge } from '@chakra-ui/react';
import {
  ResultBadgeProps, ResultsLocale,
} from './ResultBadge.model';
import Translate from '../Translate/Translate';

export const ResultBadge = (
  props: PropsWithChildren<ResultBadgeProps>,
) => {
  const { result } = props;
  return (
    <>
      { result === 'CLEAR' ? (
        <Badge
          bg="#e0f8e8"
          variant="solid"
          fontSize="xs"
          color="#00ce7d"
          p={1}
          w={24}
          textAlign="center"
          borderRadius="md"
        >
          <Translate label={ResultsLocale.CLEAR} />
        </Badge>
      ) : result === 'FOUND_RESULTS' ? (
        <Badge
          bg="#fff4e4"
          variant="solid"
          fontSize="xs"
          color="#f38400"
          p={1}
          w={24}
          textAlign="center"
          borderRadius="md"
        >
          <Translate label={ResultsLocale.FOUND_RESULTS} />
        </Badge>
      ) : (<></>)}
    </>
  );
};

export default ResultBadge;
