import React from 'react';
import {
  LeftMenu, LeftMenuItem, SubmenuItem, LeftMenuCategory,
} from '@scaut-sro/meepo';
import { Link } from 'react-router-dom';
import {
  FiBook, FiCheckCircle, FiDatabase, FiHome, FiUsers,
} from 'react-icons/fi';
import { VscOrganization } from 'react-icons/vsc';
import { BiTask } from 'react-icons/bi';
import {
  AiOutlineTranslation, FaRegQuestionCircle, GiWorld, TbFileInvoice,
} from 'react-icons/all';
import { useKeycloak } from '@react-keycloak/web';
import { MainMenuProps } from './MainMenu.model';
import Translate from '../Translate/Translate';
import MainMenuLocale from '../../core/localization/translations/MainMenu.locale';
import ScautAdminRoles from '../../core/auth/ScautAdminRoles';

const MainMenu: React.FunctionComponent<MainMenuProps> = (props) => {
  const {
    menuIsSmall, leftMenuWidth, headerHeight, activeItem, handleSetActiveItem,
  } = props;
  const { keycloak } = useKeycloak();
  const isSysAdmin = keycloak.hasResourceRole(ScautAdminRoles.SYSADMIN, 'scaut-admin-app');
  const isManager = keycloak.hasResourceRole(ScautAdminRoles.MANAGER, 'scaut-admin-app');
  const isAccountant = keycloak.hasResourceRole(ScautAdminRoles.ACCOUNTANT, 'scaut-admin-app');

  return (
    <LeftMenu
      leftMenuWidth={leftMenuWidth}
      headerHeight={headerHeight}
      isMenuSmall={menuIsSmall}
    >
      <Link to="/">
        <LeftMenuItem
          activeItem={activeItem}
          handleActiveItemChange={handleSetActiveItem}
          itemId="overview"
          headerHeight={headerHeight}
          leftMenuWidth={leftMenuWidth}
          icon={<FiHome />}
        >
          <Translate label={MainMenuLocale.OVERVIEW} />
        </LeftMenuItem>
      </Link>
      { isManager || isSysAdmin ? (
        <LeftMenuCategory
          leftMenuWidth={leftMenuWidth}
          activeItem={activeItem}
          categoryId="checks"
          headerHeight={headerHeight}
          icon={<FiCheckCircle />}
          isMenuSmall={menuIsSmall || false}
          popupmenu={[
            {
              id: '/checks/open',
              item: (
                <Link to="/checks/open">
                  <SubmenuItem
                    leftMenuWidth={leftMenuWidth}
                    headerHeight={headerHeight}
                    submenuItemId="/checks/open"
                    handleActiveItemChange={handleSetActiveItem}
                    activeItem={activeItem}
                  >
                    <Translate label={MainMenuLocale.OPEN_ORDERS} />
                  </SubmenuItem>
                </Link>
              ),
            },
            {
              id: '/checks/assigned-to-me',
              item: (
                <Link to="/checks/assigned-to-me">
                  <SubmenuItem
                    leftMenuWidth={leftMenuWidth}
                    headerHeight={headerHeight}
                    submenuItemId="/checks/assigned-to-me"
                    handleActiveItemChange={handleSetActiveItem}
                    activeItem={activeItem}
                  >
                    <Translate label={MainMenuLocale.ASSIGNED_TO_ME_ORDERS} />
                  </SubmenuItem>
                </Link>
              ),
            },
            {
              id: '/checks/unassigned',
              item: (
                <Link to="/checks/unassigned">
                  <SubmenuItem
                    leftMenuWidth={leftMenuWidth}
                    headerHeight={headerHeight}
                    submenuItemId="/checks/unassigned"
                    handleActiveItemChange={handleSetActiveItem}
                    activeItem={activeItem}
                  >
                    <Translate label={MainMenuLocale.UNASSIGNED_ORDERS} />
                  </SubmenuItem>
                </Link>
              ),
            },
          ]}
        >
          <Translate label={MainMenuLocale.ORDERS} />
        </LeftMenuCategory>
      ) : (
        <Link to="/checks/assigned-to-me">
          <LeftMenuItem
            activeItem={activeItem}
            handleActiveItemChange={handleSetActiveItem}
            itemId="checks/assigned-to-me"
            headerHeight={headerHeight}
            leftMenuWidth={leftMenuWidth}
            icon={<FiCheckCircle />}
          >
            <Translate label={MainMenuLocale.ORDERS} />
          </LeftMenuItem>
        </Link>
      )}
      { isManager || isSysAdmin ? (
        <LeftMenuCategory
          leftMenuWidth={leftMenuWidth}
          activeItem={activeItem}
          categoryId="tasks"
          headerHeight={headerHeight}
          icon={<BiTask />}
          isMenuSmall={menuIsSmall || false}
          popupmenu={[
            {
              id: '/tasks/open',
              item: (
                <Link to="/tasks/open">
                  <SubmenuItem
                    leftMenuWidth={leftMenuWidth}
                    headerHeight={headerHeight}
                    submenuItemId="/tasks/open"
                    handleActiveItemChange={handleSetActiveItem}
                    activeItem={activeItem}
                  >
                    <Translate label={MainMenuLocale.OPEN_TASKS} />
                  </SubmenuItem>
                </Link>
              ),
            },
            {
              id: '/tasks/assigned-to-me',
              item: (
                <Link to="/tasks/assigned-to-me">
                  <SubmenuItem
                    leftMenuWidth={leftMenuWidth}
                    headerHeight={headerHeight}
                    submenuItemId="/tasks/assigned-to-me"
                    handleActiveItemChange={handleSetActiveItem}
                    activeItem={activeItem}
                  >
                    <Translate label={MainMenuLocale.ASSIGNED_TO_ME_TASKS} />
                  </SubmenuItem>
                </Link>
              ),
            },
            {
              id: '/tasks/unassigned',
              item: (
                <Link to="/tasks/unassigned">
                  <SubmenuItem
                    leftMenuWidth={leftMenuWidth}
                    headerHeight={headerHeight}
                    submenuItemId="/tasks/unassigned"
                    handleActiveItemChange={handleSetActiveItem}
                    activeItem={activeItem}
                  >
                    <Translate label={MainMenuLocale.UNASSIGNED_TASKS} />
                  </SubmenuItem>
                </Link>
              ),
            },
          ]}
        >
          <Translate label={MainMenuLocale.TASKS} />
        </LeftMenuCategory>
      ) : (
        <Link to="/tasks/assigned-to-me">
          <LeftMenuItem
            activeItem={activeItem}
            handleActiveItemChange={handleSetActiveItem}
            itemId="tasks/assigned-to-me"
            headerHeight={headerHeight}
            leftMenuWidth={leftMenuWidth}
            icon={<BiTask />}
          >
            <Translate label={MainMenuLocale.TASKS} />
          </LeftMenuItem>
        </Link>
      )}
      { isSysAdmin ? (
        <Link to="/components">
          <LeftMenuItem
            activeItem={activeItem}
            handleActiveItemChange={handleSetActiveItem}
            itemId="components"
            headerHeight={headerHeight}
            leftMenuWidth={leftMenuWidth}
            icon={<BiTask />}
          >
            <Translate label={MainMenuLocale.COMPONENTS} />
          </LeftMenuItem>
        </Link>
      ) : undefined}
      { isSysAdmin || isManager ? (
        <>
          <Link to="/clients">
            <LeftMenuItem
              activeItem={activeItem}
              handleActiveItemChange={handleSetActiveItem}
              itemId="clients"
              headerHeight={headerHeight}
              leftMenuWidth={leftMenuWidth}
              icon={<VscOrganization />}
            >
              <Translate label={MainMenuLocale.CLIENTS} />
            </LeftMenuItem>
          </Link>
          <LeftMenuCategory
            leftMenuWidth={leftMenuWidth}
            activeItem={activeItem}
            categoryId="catalogue"
            headerHeight={headerHeight}
            icon={<FiBook />}
            isMenuSmall={menuIsSmall || false}
            popupmenu={[
              {
                id: 'catalogue/components',
                item: (
                  <Link to="/catalogue/components">
                    <SubmenuItem
                      leftMenuWidth={leftMenuWidth}
                      headerHeight={headerHeight}
                      submenuItemId="catalogue/components"
                      handleActiveItemChange={handleSetActiveItem}
                      activeItem={activeItem}
                    >
                      <Translate label={MainMenuLocale.COMPONENTS} />
                    </SubmenuItem>
                  </Link>
                ),
              },
              {
                id: 'catalogue/screenings',
                item: (
                  <Link to="/catalogue/screenings">
                    <SubmenuItem
                      leftMenuWidth={leftMenuWidth}
                      headerHeight={headerHeight}
                      submenuItemId="catalogue/screenings"
                      handleActiveItemChange={handleSetActiveItem}
                      activeItem={activeItem}
                    >
                      <Translate label={MainMenuLocale.SCREENINGS} />
                    </SubmenuItem>
                  </Link>
                ),
              },
              {
                id: 'catalogue/packages',
                item: (
                  <Link to="/catalogue/packages">
                    <SubmenuItem
                      leftMenuWidth={leftMenuWidth}
                      headerHeight={headerHeight}
                      submenuItemId="catalogue/packages"
                      handleActiveItemChange={handleSetActiveItem}
                      activeItem={activeItem}
                    >
                      <Translate label={MainMenuLocale.PACKAGES} />
                    </SubmenuItem>
                  </Link>
                ),
              },
            ]}
          >
            <Translate label={MainMenuLocale.CATALOGUE} />
          </LeftMenuCategory>
          <Link to="/translations">
            <LeftMenuItem
              activeItem={activeItem}
              handleActiveItemChange={handleSetActiveItem}
              itemId="translations"
              headerHeight={headerHeight}
              leftMenuWidth={leftMenuWidth}
              icon={<AiOutlineTranslation />}
            >
              <Translate label={MainMenuLocale.TRANSLATION} />
            </LeftMenuItem>
          </Link>
        </>
      ) : undefined }
      <Link to="/database">
        <LeftMenuItem
          activeItem={activeItem}
          handleActiveItemChange={handleSetActiveItem}
          itemId="database"
          headerHeight={headerHeight}
          leftMenuWidth={leftMenuWidth}
          icon={<FiDatabase />}
        >
          <Translate label={MainMenuLocale.DATABASE} />
        </LeftMenuItem>
      </Link>
      {isSysAdmin ? (
        <Link to="/users">
          <LeftMenuItem
            activeItem={activeItem}
            handleActiveItemChange={handleSetActiveItem}
            itemId="users"
            headerHeight={headerHeight}
            leftMenuWidth={leftMenuWidth}
            icon={<FiUsers />}
          >
            <Translate label={MainMenuLocale.USERS} />
          </LeftMenuItem>
        </Link>
      ) : undefined }
      { isSysAdmin || isManager ? (
        <>
          <Link to="/countries">
            <LeftMenuItem
              activeItem={activeItem}
              handleActiveItemChange={handleSetActiveItem}
              itemId="countries"
              headerHeight={headerHeight}
              leftMenuWidth={leftMenuWidth}
              icon={<GiWorld />}
            >
              <Translate label={MainMenuLocale.COUNTRIES} />
            </LeftMenuItem>
          </Link>
          <Link to="/enumerations">
            <LeftMenuItem
              activeItem={activeItem}
              handleActiveItemChange={handleSetActiveItem}
              itemId="enumerations"
              headerHeight={headerHeight}
              leftMenuWidth={leftMenuWidth}
              icon={<FaRegQuestionCircle />}
            >
              <Translate label={MainMenuLocale.ENUMERATIONS} />
            </LeftMenuItem>
          </Link>
          <Link to="/faq">
            <LeftMenuItem
              activeItem={activeItem}
              handleActiveItemChange={handleSetActiveItem}
              itemId="faq"
              headerHeight={headerHeight}
              leftMenuWidth={leftMenuWidth}
              icon={<FaRegQuestionCircle />}
            >
              <Translate label={MainMenuLocale.FAQ} />
            </LeftMenuItem>
          </Link>
          <Link to="/process-upload">
            <LeftMenuItem
              activeItem={activeItem}
              handleActiveItemChange={handleSetActiveItem}
              itemId="process-upload"
              headerHeight={headerHeight}
              leftMenuWidth={leftMenuWidth}
              icon={<FaRegQuestionCircle />}
            >
              <Translate label={MainMenuLocale.PROCESS_UPLOAD} />
            </LeftMenuItem>
          </Link>
        </>
      ) : undefined }
      { isAccountant || isManager ? (
        <>
          <Link to="/all-invoices">
            <LeftMenuItem
              activeItem={activeItem}
              handleActiveItemChange={handleSetActiveItem}
              itemId="all-invoices"
              headerHeight={headerHeight}
              leftMenuWidth={leftMenuWidth}
              icon={<TbFileInvoice />}
            >
              <Translate label={MainMenuLocale.INVOICES} />
            </LeftMenuItem>
          </Link>
        </>
      ) : undefined }
    </LeftMenu>
  );
};

export default MainMenu;
