import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Box, Button,
  Collapse,
  Flex, FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FilterButton, InfoContainer } from '@scaut-sro/meepo';
import { Select } from 'chakra-react-select';
import { FiFilter, FiX } from 'react-icons/fi';
import { ChecksTableFilterProps, ChecksTableFilterType } from './Checks.model';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../core/localization/LocaleUtils';
import { ResultsLocale } from '../../components/ResultBadge/ResultBadge.model';
import { StatesLocale } from '../../components/StateBadge/StateBadge.model';
import ChecksLocale from './Checks.locale';
import Translate from '../../components/Translate/Translate';
import { useGetAdminUsers, useGetClients } from '../../build/generated-sources/service/QueryService';

const ChecksTableFilter: React.FunctionComponent<ChecksTableFilterProps> = (props) => {
  const { filter, onFilterChange, routeFilter } = props;
  const { language } = useGetUser();
  const [type, setType] = useState<{ value: string, label: string }[]>([]);
  const [result, setResult] = useState<{ value: string, label: string }[]>([]);
  const [orderState, setOrderState] = useState<{ value: string, label: string }[]>([]);
  const [accountManagerIds, setAccountManagerIds] = useState<{ value: number, label: string }[]>([]);
  const [clientIds, setClientIds] = useState<{ value: number, label: string }[]>([]);
  const {
    handleSubmit, register, reset, setValue,
  } = useForm();
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);

  const { data } = useGetAdminUsers({
    id: true,
    firstName: true,
    lastName: true,
  });

  const clients = useGetClients({
    id: true,
    name: true,
  });

  const handleFilterSubmit: SubmitHandler<ChecksTableFilterType> = (values) => {
    onFilterChange(values);
  };

  const handleFilterCancel = () => {
    handleFilterSubmit({});
    setAccountManagerIds([]);
    setClientIds([]);
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      formRef.current?.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }
  };

  const activeFilters: boolean = useMemo(() => {
    const filteredObjKeys = Object.keys(filter).filter((key) => filter[key] && filter[key].length);
    return !!filteredObjKeys.length;
  }, [filter]);

  const typeOptions = useMemo(() => ([
    { value: 'SINGLE', label: translate(ChecksLocale.SINGLE_CHECK, language) },
    { value: 'MULTIPLE', label: translate(ChecksLocale.MULTIPLE_PEOPLE, language) },
  ]), [language]);

  const resultOptions = useMemo(() => ([
    { value: 'UNRESOLVED', label: translate(ResultsLocale.UNDEFINED, language) },
    { value: 'CLEAR', label: translate(ResultsLocale.CLEAR, language) },
    { value: 'FOUND_RESULTS', label: translate(ResultsLocale.FOUND_RESULTS, language) },
  ]), [language]);

  const orderStateOptions = useMemo(() => ([
    { value: 'DRAFT', label: translate(StatesLocale.UNDEFINED, language) },
    { value: 'WAITING_FOR_CLIENT', label: translate(StatesLocale.WAITING_FOR_CLIENT, language) },
    { value: 'PROCESSING', label: translate(StatesLocale.PROCESSING, language) },
    { value: 'FINISHED', label: translate(StatesLocale.FINISHED, language) },
    { value: 'FOR_APPROVAL', label: translate(StatesLocale.FOR_APPROVAL, language) },
    { value: 'CANNOT_COMPLETE', label: translate(StatesLocale.CANNOT_COMPLETE, language) },
    { value: 'WAITING_FOR_POA', label: translate(StatesLocale.WAITING_FOR_POWER_OF_ATTORNEY, language) },
  ]), [language]);

  const accountManagerIdsOptions = useMemo(() => (
    data?.adminUsers.map((dataUser) => ({
      value: dataUser.id || -1,
      label: `${dataUser.firstName} ${dataUser.lastName}`,
    }))
  ), [data]);

  const clientIdsOptions = useMemo(() => (
    clients?.data?.clients.map((client) => ({
      value: client.id || -1,
      label: `${client.name}`,
    }))
  ), [clients]);

  useEffect(() => {
    register('type');
    register('result');
    register('orderState');
    register('accountManagerIds');
    register('clientIds');
  }, [register]);

  useEffect(() => {
    reset({
      fulltext: filter.fulltext,
      type: filter.type,
      name: filter.name,
      clientOrderCustomId: filter.clientOrderCustomId,
      orderedFrom: filter.orderedFrom,
      orderedTo: filter.orderedTo,
      result: filter.result,
      orderState: filter.orderState,
      accountManagerIds: filter.accountManagerIds,
      clientIds: filter.clientIds,
    });
    setType(filter.type?.map((rec) => typeOptions.filter((option) => option.value === rec as string)[0]) || []);
    setResult(filter.result?.map((rec) => resultOptions.filter((option) => option.value === rec as string)[0]) || []);
    setOrderState(
      filter.orderState?.map(
        (rec) => orderStateOptions.filter(
          (option) => option.value === rec as string,
        )[0],
      ) || [],
    );
    if (accountManagerIdsOptions) {
      setAccountManagerIds(
        filter.accountManagerIds?.map(
          (rec) => accountManagerIdsOptions.filter(
            (option) => option.value === rec,
          )[0],
        ) || [],
      );
    }
    if (clientIdsOptions) {
      setClientIds(
        filter.clientIds?.map(
          (rec) => clientIdsOptions.filter(
            (option) => option.value === rec,
          )[0],
        ) || [],
      );
    }
  }, [filter, reset, orderStateOptions, resultOptions, typeOptions, accountManagerIdsOptions, clientIdsOptions]);

  return (
    <Box>
      <form
        name="checkTableFilter"
        onSubmit={handleSubmit((values) => {
          handleFilterSubmit(values);
        })}
        ref={formRef}
      >
        <Flex>
          <InputGroup zIndex={0}>
            <InputLeftElement>
              <SearchIcon />
            </InputLeftElement>
            <Input
              id="fulltext"
              variant="filled"
              onKeyUp={handleKeyUp}
              {...register('fulltext')}
            />
          </InputGroup>
          <Box ml={3}>
            <FilterButton
              activeFilters={activeFilters}
              openFilters={() => { setOpenFilters(!openFilters); }}
              cancelFilters={handleFilterCancel}
            />
          </Box>
        </Flex>
        <Collapse in={openFilters} animateOpacity>
          <Box mt={3}>
            <InfoContainer>
              <SimpleGrid gap={6} columns={[1, 1, 2, 2, 2, 3]}>
                <FormControl>
                  <FormLabel fontSize="sm"><Translate label={ChecksLocale.ORDER_TYPE} /></FormLabel>
                  <Select
                    name="type"
                    isMulti
                    closeMenuOnSelect={false}
                    value={type}
                    options={typeOptions}
                    onChange={(values: { value: string, label: string }[]) => {
                      setValue('type', values.map((value) => value.value));
                      setType(values);
                    }}
                    hideSelectedOptions={false}
                    selectedOptionStyle="check"
                    size="sm"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="sm"><Translate label={ChecksLocale.LABEL} /></FormLabel>
                  <Input
                    size="sm"
                    {...register('name')}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="sm"><Translate label={ChecksLocale.CUSTOM_ID} /></FormLabel>
                  <Input
                    size="sm"
                    {...register('clientOrderCustomId')}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="sm"><Translate label={ChecksLocale.ORDER_CREATED} /></FormLabel>
                  <Flex alignItems="center">
                    <Input
                      size="sm"
                      type="datetime-local"
                      {...register('orderedFrom')}
                    />
                    <Box ml={1} mr={1}>-</Box>
                    <Input
                      size="sm"
                      type="datetime-local"
                      {...register('orderedTo')}
                    />
                  </Flex>
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="sm"><Translate label={ChecksLocale.ORDER_RESULT} /></FormLabel>
                  <Select
                    size="sm"
                    name="result"
                    isMulti
                    closeMenuOnSelect={false}
                    value={result}
                    options={resultOptions}
                    onChange={(values: { value: string, label: string }[]) => {
                      setValue('result', values.map((value) => value.value));
                      setResult(values);
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="sm"><Translate label={ChecksLocale.ORDER_STATE} /></FormLabel>
                  <Select
                    size="sm"
                    name="orderState"
                    isMulti
                    closeMenuOnSelect={false}
                    value={orderState}
                    options={orderStateOptions}
                    onChange={(values: { value: string, label: string }[]) => {
                      setValue('orderState', values.map((value) => value.value));
                      setOrderState(values);
                    }}
                  />
                </FormControl>
                { routeFilter && routeFilter !== 'assigned-to-me' && routeFilter !== 'unassigned' && (
                  <FormControl>
                    <FormLabel fontSize="sm"><Translate label={ChecksLocale.ACCOUNT_MANAGER} /></FormLabel>
                    <Select
                      size="sm"
                      name="accountManagerIds"
                      isMulti
                      closeMenuOnSelect={false}
                      value={accountManagerIds}
                      options={accountManagerIdsOptions}
                      onChange={(values: { value: number, label: string }[]) => {
                        setValue('accountManagerIds', values.map((value) => value.value));
                        setAccountManagerIds(values);
                      }}
                    />
                  </FormControl>
                )}
                { routeFilter && (
                <FormControl>
                  <FormLabel fontSize="sm"><Translate label={ChecksLocale.CLIENT} /></FormLabel>
                  <Select
                    size="sm"
                    name="clientIds"
                    isMulti
                    closeMenuOnSelect={false}
                    value={clientIds}
                    options={clientIdsOptions}
                    onChange={(values: { value: number, label: string }[]) => {
                      setValue('clientIds', values.map((value) => value.value));
                      setClientIds(values);
                    }}
                  />
                </FormControl>
                )}
              </SimpleGrid>
              <Flex mt={6} justifyContent="flex-end">
                <Button
                  size="sm"
                  colorScheme="red"
                  isDisabled={!activeFilters}
                  onClick={handleFilterCancel}
                  variant="ghost"
                  leftIcon={<FiX />}
                >
                  Cancel filter
                </Button>
                <Button
                  size="sm"
                  ml={3}
                  leftIcon={<FiFilter />}
                  type="submit"
                >
                  Filter
                </Button>
              </Flex>
            </InfoContainer>
          </Box>
        </Collapse>
      </form>
    </Box>
  );
};

export default ChecksTableFilter;
