import React, {
  useEffect, useMemo, useState,
} from 'react';
import {
  Box, Button,
  Checkbox, Divider, Flex, Input, Table, Tbody, Td, Th, Thead, Tr,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { Toast } from '@scaut-sro/meepo';
import {
  ResourceDbFieldDefinitionGraphQLInput
  as FieldDefinition,
} from '../../../build/generated-sources/dto/ResourceDbFieldDefinitionGraphQLInput';
import { translate } from '../../../core/localization/LocaleUtils';
import ResourceDatabaseLocale from '../ResourceDatabase.locale';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import { FormInputType } from '../../../build/generated-sources/enum/FormInputType';
import { useResourceDatabaseSection } from '../../../build/generated-sources/service/MutationService';
import { handleApolloErrors } from '../../../core/error/ScautErrorHandler';
import { ResourceDatabaseNewSectionData } from '../ResourceDatabase.model';
import FormLocale from '../../../core/localization/translations/Form.locale';
import Translate from '../../../components/Translate/Translate';

const DatabaseSectionDetailNew: React.FunctionComponent<ResourceDatabaseNewSectionData> = (props) => {
  const { refreshTabs } = props;
  const { language } = useGetUser();
  const [fieldDefinitions, setFieldDefinitions] = useState<FieldDefinition[]>([]);
  const [rows, setRows] = useState<FieldDefinition[]>([]);
  const {
    handleSubmit, register,
  } = useForm();

  const formInputTypeOptions = useMemo(() => Object.keys(FormInputType).map((enumKey) => (
    { value: enumKey, label: enumKey })), []);

  const [mutateSaveSection] = useResourceDatabaseSection(
    {
      id: true,
    },
    {
      onCompleted: () => {
        Toast({
          title: translate(FormLocale.SUCCESSFULLY_SAVED, language),
          status: 'success',
        });
        if (refreshTabs) {
          refreshTabs();
        }
      },
      onError: (error) => {
        handleApolloErrors(error, language);
      },
    },
  );

  useEffect(() => {
    const newRows = [...fieldDefinitions] || [];
    if ((newRows.length === 0) || (
      newRows[newRows.length - 1] && (
        !!newRows[newRows.length - 1].fieldName
            || !!newRows[newRows.length - 1].formInputType
            || !!newRows[newRows.length - 1].isMandatory
      ))) {
      newRows.push({});
    }
    setRows(newRows);
  }, [setRows, fieldDefinitions]);

  const handleFieldDefinitionChange = (index: number, fieldName: string, value: any) => {
    const newFields = [...rows];

    newFields[index] = { ...fieldDefinitions[index] };
    // TODO: Remove this ts-ignore
    // @ts-ignore
    newFields[index][fieldName] = value;

    setFieldDefinitions(newFields);
  };

  const handleFilterSubmit: SubmitHandler<FieldValues> = (values) => {
    const fieldDefs = fieldDefinitions.filter((fieldDef) => (
      fieldDef.fieldName || fieldDef.formInputType || fieldDef.isMandatory || fieldDef.isPrivate
    ));
    mutateSaveSection({
      variables: {
        section: {
          name: values.name,
          fieldDefinitions: fieldDefs,
        },
      },

    });
  };

  return (
    <>
      <Box p={4}>
        <form
          name="checkTableFilter"
          onSubmit={handleSubmit((values) => {
            handleFilterSubmit(values);
          })}
        >
          <Input
            id="name"
            placeholder={translate(ResourceDatabaseLocale.NAME, language)}
            {...register('name', {
              required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
            })}
          />
          <Divider mt={4} mb={4} />
          <Table size="sm">
            <Thead>
              <Tr>
                <Th />
                <Th>{translate(ResourceDatabaseLocale.FIELD_NAME, language)}</Th>
                <Th>{translate(ResourceDatabaseLocale.FORM_INPUT_TYPE, language)}</Th>
                <Th>{translate(ResourceDatabaseLocale.IS_MANDATORY, language)}</Th>
                <Th>{translate(ResourceDatabaseLocale.IS_PRIVATE, language)}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {rows.map((fieldDefinition, index) => (
              // eslint-disable-next-line react/no-array-index-key
                <Tr key={index}>
                  <Td>{index + 1}</Td>
                  <Td>
                    <Input
                      id={`${index + 1}-fieldName`}
                      name="fieldName"
                      placeholder={translate(ResourceDatabaseLocale.FIELD_NAME, language)}
                      defaultValue={fieldDefinition.fieldName}
                      onBlur={(event) => handleFieldDefinitionChange(index, 'fieldName', event.target.value)}
                    />
                  </Td>
                  <Td>
                    <Select
                      id={`${index + 1}-formInputType`}
                      name="formInputType"
                      options={formInputTypeOptions}
                      hideSelectedOptions={false}
                      selectedOptionStyle="check"
                      onChange={(values: { value: string, label: string }) => {
                        handleFieldDefinitionChange(index, 'formInputType', values.value);
                      }}
                    />
                  </Td>
                  <Td>
                    <Checkbox
                      id={`${index + 1}-isMandatory`}
                      name="isMandatory"
                      placeholder={translate(ResourceDatabaseLocale.IS_MANDATORY, language)}
                      defaultChecked={fieldDefinition.isMandatory}
                      onChange={(event) => handleFieldDefinitionChange(index, 'isMandatory', event.target.checked)}
                    />
                  </Td>
                  <Td>
                    <Checkbox
                      id={`${index + 1}-isPrivate`}
                      name="isPrivate"
                      placeholder={translate(ResourceDatabaseLocale.IS_PRIVATE, language)}
                      defaultChecked={fieldDefinition.isPrivate}
                      onChange={(event) => handleFieldDefinitionChange(index, 'isPrivate', event.target.checked)}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Flex mt={6} justifyContent="flex-end">
            <Button mt={6} type="submit">
              <Translate label={FormLocale.SEND} />
            </Button>
          </Flex>
        </form>
      </Box>
    </>
  );
};

export default DatabaseSectionDetailNew;
