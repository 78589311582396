import React, { useEffect, useMemo } from 'react';
import {
  FormControl, FormLabel,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Toast } from '@scaut-sro/meepo';
import Translate from '../../components/Translate/Translate';
import { translate } from '../../core/localization/LocaleUtils';
import FormLocale from '../../core/localization/translations/Form.locale';
import FaqLocale from './Faq.locale';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { useFaq } from '../../build/generated-sources/service/MutationService';
import { FaqDetailProps, FaqFormAttr } from './Faq.model';
import { Translation } from '../../build/generated-sources/dto/Translation';
import TranslationChooseModal from '../Translations/TranslationChooseModal/TranslationChooseModal';
import { handleApolloErrors } from '../../core/error/ScautErrorHandler';

const createFaqMapper = {
  id: true,
};

const FaqForm: React.FunctionComponent<FaqDetailProps> = (props) => {
  const {
    loadingSetter, formRef, faq, refreshData,
  } = props;
  const { language } = useGetUser();

  const defaultValues: Partial<FaqFormAttr> = useMemo(() => ({
    id: faq?.id,
    questionId: faq?.question?.id || -1,
    answerId: faq?.answer?.id || -1,
  }), [faq]);

  const {
    handleSubmit, setValue, getValues, formState: { errors }, register,
  } = useForm<Partial<FaqFormAttr>>({ defaultValues });

  const [mutateFaq,
    optionsFaq] = useFaq(createFaqMapper, {
    onCompleted: () => {
      Toast({
        title: translate(FormLocale.SUCCESSFULLY_SAVED, language),
        status: 'success',
      });
      refreshData();
    },
    onError: (error) => {
      handleApolloErrors(error, language);
    },
  });

  useEffect(() => {
    if (loadingSetter) loadingSetter(optionsFaq.loading);
  }, [loadingSetter, optionsFaq]);

  const handleFaqFormSubmit: SubmitHandler<FaqFormAttr> = (values) => {
    mutateFaq({
      variables: {
        faqId: faq?.id,
        faq: {
          questionId: values.questionId,
          answerId: values.answerId,
        },
      },
    });
  };

  const setQuestionId = (question: Translation) => {
    setValue('questionId', question.id);
  };
  const setAnswerId = (answer: Translation) => {
    setValue('answerId', answer.id);
  };

  return (
    <>
      <form
        name="faqForm"
        onSubmit={handleSubmit(handleFaqFormSubmit)}
        ref={formRef}
      >
        <FormControl mb={6} isInvalid={!!errors.questionId}>
          <FormLabel htmlFor="questionId">
            <Translate label={FaqLocale.TRANSLATION_NAME_QUESTION} />
          </FormLabel>
          <TranslationChooseModal
            translationId={getValues('questionId')}
            setValue={setQuestionId}
            type="string"
            {...register('questionId', {
              required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
            })}
          />
        </FormControl>
        <FormControl mb={6} isInvalid={!!errors.answerId}>
          <FormLabel htmlFor="answerId">
            <Translate label={FaqLocale.TRANSLATION_NAME_ANSWER} />
          </FormLabel>
          <TranslationChooseModal
            translationId={getValues('answerId')}
            setValue={setAnswerId}
            type="string"
            {...register('answerId', {
              required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
            })}
          />
        </FormControl>
      </form>
    </>
  );
};
export default FaqForm;
