import React, { useMemo, useState } from 'react';
import {
  Text,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  AccordionIcon, Spacer,
} from '@chakra-ui/react';
import { H1, SectionContainer, Toast } from '@scaut-sro/meepo';
import Translate from '../../components/Translate/Translate';
import FaqLocale from './Faq.locale';
import { useGetFaqs } from '../../build/generated-sources/service/QueryService';
import { translate } from '../../core/localization/LocaleUtils';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { FAQ } from '../../build/generated-sources/dto/FAQ';
import DetailHeader from '../../components/DetailHeader/DetailHeader';
import FaqModal from './FaqModal';

const Faq:React.FunctionComponent = () => {
  const [faqList, setFaqList] = useState<FAQ[]>([]);
  const { language } = useGetUser();

  const { refetch } = useGetFaqs(
    {
      id: true,
      question: {
        id: true,
        name: true,
        localizationStrings: true,
      },
      answer: {
        id: true,
        name: true,
        localizationStrings: true,
      },
    }, {
      onError: () => {
        Toast({
          title: translate(FaqLocale.ERROR_GETTING_FAQ, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setFaqList(response.faqs);
      },
    },
  );

  const faqOptions = useMemo(() => faqList.map((f) => ({
    id: f?.id,
    question: {
      id: f?.question?.id,
      name: f?.question?.name,
      localizationString: f?.question?.localizationStrings?.[language],
    },
    answer: {
      id: f?.answer?.id,
      name: f?.answer?.name,
      localizationString: f?.answer?.localizationStrings?.[language],
    },
  })), [faqList, language]);

  const refreshData = () => {
    refetch();
  };

  return (
    <SectionContainer>
      <Box overflowX="auto">
        <Box minW="600px">
          <DetailHeader>
            <H1><Translate label={FaqLocale.MODAL_FAQ_HEADER} /></H1>
          </DetailHeader>
          <Box mb={20}>
            <Flex mt={6} justifyContent="flex-end">
              <FaqModal refreshData={refreshData} faq={{}} />
            </Flex>
          </Box>
          <Box overflowX="auto">
            <Box minW="600px">
              <Flex direction="column" p="4">
                <Accordion allowToggle allowMultiple>
                  {
                    faqOptions.map((faq) => (
                      <AccordionItem key={faq.id}>
                        <AccordionButton>
                          <Box flex="1" textAlign="left">
                            <Text fontWeight="semibold">
                              {faq.question.localizationString}
                            </Text>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb="4">
                          <Flex>
                            <Box flex="1" textAlign="left">
                              <Text>
                                {faq.answer.localizationString}
                              </Text>
                            </Box>
                            <Spacer />
                            <FaqModal refreshData={refreshData} faq={faq} />
                          </Flex>
                        </AccordionPanel>
                      </AccordionItem>
                    ))
                  }
                </Accordion>
              </Flex>
            </Box>
          </Box>
        </Box>
      </Box>
    </SectionContainer>
  );
};

export default Faq;
