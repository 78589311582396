import { Translation } from '../../core/localization/Localization.model';

class UsersLocale {
  public static USER_GO_TO_DETAIL: Translation = { EN: 'Go to detail', CZ: 'Přejít na detail' };
  public static FIRST_NAME: Translation = { EN: 'First name', CZ: 'Jméno' };
  public static LAST_NAME: Translation = { EN: 'Last name', CZ: 'Příjmení' };
  public static EMAIL: Translation = { EN: 'Email', CZ: 'Email' };
  public static USER_LIST: Translation = { EN: 'User list', CZ: 'Seznam uživatelů' };
  public static USER_DETAIL: Translation = { EN: 'User detail', CZ: 'Seznam detail' };
  public static ACTIVE: Translation = { EN: 'Active', CZ: 'Aktivní' };
  public static USER_ROLE: Translation = { EN: 'Role', CZ: 'Role' };
  public static NEW: Translation = { EN: 'New', CZ: 'Nový' };
  public static TEMPORARY_PASSWORD: Translation = { EN: 'Temporary Password', CZ: 'Dočasné heslo' };
  public static MODAL_CREATE_PACKAGE_TITLE: Translation = { EN: 'New user', CZ: 'Nový uživatel' };
  public static ERROR_GETTING_ROLES: Translation = {
    EN: 'There was a problem getting roles.',
    CZ: 'Problém při načitání rolí.',
  };
  public static ERROR_GETTING_USERS: Translation = {
    EN: 'There was a problem getting users.',
    CZ: 'Při získávání uživatelů došlo k problému.',
  };

  public static ERROR_SAVING_USER: Translation = {
    EN: 'There was a problem saving user.',
    CZ: 'Při ukládání uživatele došlo k problému.',
  };
  public static ERROR_GETTING_DATA: Translation = {
    EN:
        'There was a problem getting data',
    CZ: 'Při získávání dat došlo k problému',
  };
}

export default UsersLocale;
