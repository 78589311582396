import { Merge, Row } from '@scaut-sro/meepo';
import { LocalDate } from '../../build/scalars/LocalDate';
import { State } from '../../build/generated-sources/enum/State';
import { Translation } from '../../core/localization/Localization.model';
import { ComponentNote } from '../../build/generated-sources/dto/ComponentNote';
import { AssigneeType } from '../../build/generated-sources/enum/AssigneeType';

export type TasksTableFilterType = {
  states?: State[];
  fulltext?: string;
  orderId?: number;
  assigneeIds?: number[];
  assigneeType?: AssigneeType;
  client?: string;
  candidate?: string;
} & { [key: string]: string | string[] | LocalDate };

export type TasksOtherFilterType = {};

export type TasksAllFilterType = Merge<TasksOtherFilterType, TasksTableFilterType>;

export type TasksRouteFilter = 'open' | 'assigned-to-me' | 'unassigned';

export type ComponentsTableProps = {
  viewType: ViewType,
  openDetail: (id?: number) => void;
  detailColumnTitle: Translation;
  filters?: TasksAllFilterType;
};

export type TasksTableFilterProps = {
  filter: TasksTableFilterType;
  onFilterChange: (newFilter: TasksTableFilterType) => void;
  routeFilter: TasksRouteFilter;
};

export type ComponentsTableData = {
  candidate?: any;
  processDefinitionName?: any;
  result?: any;
  state?: any;
  assignee?: any;
} & Row;

export type TasksBulkChangeModalProps = {
  componentIds?: Array<number>;
  isDisabled?: boolean;
  loadingSetter?: (isLoading: boolean) => void;
  formRef?: React.MutableRefObject<HTMLFormElement | null>;
  refreshTable?: () => void;
};

export enum ViewType {
  COMPONENT,
  CANDIDATE_SCREENING,
}

export type NoteDetailProps = {
  note : ComponentNote;
  handleNoteSubmit?: (values: NotesFormAttr) => void;
  refreshData?: () => void;
  componentId?: number;
  loadingSetter?: (isLoading: boolean) => void;
  formRef?: React.MutableRefObject<HTMLFormElement | null>;
};

export type NotesProps = {
  screeningComponentId?: number;
};

export type NotesFormAttr = {
  componentNoteId?: number;
  componentId?: number;
  text?: string;
  visibility?: boolean;
};
