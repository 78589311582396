import { Translation } from '../../core/localization/Localization.model';

class TranslationsLocale {
  public static TRANSLATIONS: Translation = { EN: 'Translations', CZ: 'Překlady' };
  public static FIND_TRANSLATIONS: Translation = { EN: 'Find Translations', CZ: 'Najít překlady' };
  public static TRANSLATION: Translation = { EN: 'Translation', CZ: 'Překlad' };
  public static CHOOSE_TRANSLATION: Translation = { EN: 'Choose Translation', CZ: 'Vybrat Překlad' };
  public static GENERAL: Translation = { EN: 'General', CZ: 'Obecné' };
  public static SCREENING_DEFINITION_NAME_USAGE: Translation = { EN: 'Used as name on: ', CZ: 'Využito jako jméno na: ' };
  public static NAME: Translation = { EN: 'Name', CZ: 'Jméno' };
  public static TYPE: Translation = { EN: 'Type', CZ: 'Typ' };
  public static SURVEY: Translation = { EN: 'Survey', CZ: 'Průzkum' };
  public static COUNTRY: Translation = { EN: 'Country', CZ: 'Země' };
  public static DYNAMIC_FIELD: Translation = { EN: 'Dynamic field', CZ: 'Dynamické pole' };
  public static FAQ: Translation = { EN: 'FAQ', CZ: 'FAQ' };
  public static TEMPLATE_INVOICE: Translation = { EN: 'Invoice template', CZ: 'Šablona faktury' };
  public static TEMPLATE_POA: Translation = { EN: 'Power of attorney template', CZ: 'Šablona plné moci' };
  public static TEMPLATE_REPORT: Translation = { EN: 'Report template', CZ: 'Šablona reportu' };
  public static TEMPLATE_AUTHORIZATION: Translation = { EN: 'Authorization template', CZ: 'Šablona autorizace' };
  public static USAGES: Translation = { EN: 'Usages', CZ: 'Využití' };
  public static SCREENING_DEFINITION_DESCRIPTIONS_USAGE: Translation = {
    EN: 'Used as description on: ',
    CZ: 'Využito jako popis na: ',
  };
  public static NO_USAGE_SENTENCE: Translation = {
    EN: 'Translation is not being used anywhere at the moment',
    CZ: 'Překlad není v tuto chvíli nikde využit',
  };
  public static USAGE_SCREENING_NAME_SENTENCE: Translation = {
    EN: 'Translation is being used as a name on the following screenings: ',
    CZ: 'Překlad je využíván jako jméno pro následující kontroly: ',
  };
  public static USAGE_SCREENING_DESCRIPTION_SENTENCE: Translation = {
    EN: 'Translation is being used as a description on the following screenings: ',
    CZ: 'Překlad je využíván jako popis pro následující kontroly: ',
  };
  public static USAGE_PACKAGE_NAME_SENTENCE: Translation = {
    EN: 'Translation is being used as a name on the following packages: ',
    CZ: 'Překlad je využíván jako jméno pro následující balíčky: ',
  };
  public static USAGE_PACKAGE_DESCRIPTION_SENTENCE: Translation = {
    EN: 'Translation is being used as a description on the following packages: ',
    CZ: 'Překlad je využíván jako popis pro následující balíčky: ',
  };
  public static USAGE_FAQ_QUESTION_SENTENCE: Translation = {
    EN: 'Translation is being used as one of the FAQ questions',
    CZ: 'Překlad je využíván jako jedna z FAQ otázek',
  };
  public static USAGE_FAQ_ANSWER_SENTENCE: Translation = {
    EN: 'Translation is being used as one of the FAQ answers',
    CZ: 'Překlad je využíván jako jedna z FAQ odpovědí',
  };
  public static USAGE_COUNTRY_NAME_SENTENCE: Translation = {
    EN: 'Translation is being used as a name on the following countries: ',
    CZ: 'Překlad je využíván jako jméno pro následující země: ',
  };
  public static ERROR_GETTING_DATA: Translation = {
    EN: 'There was a problem getting data',
    CZ: 'Při získávání dat došlo k problému',
  };
  public static ERROR_GETTING_TRANSLATIONS: Translation = {
    EN: 'There was a problem getting translations',
    CZ: 'Při získávání dat došlo k problému',
  };
  public static ERROR_SAVING_TRANSLATION: Translation = {
    EN: 'There was a problem saving translation',
    CZ: 'Při ukládání překladu došlo k problému',
  };
  public static ERROR_NAME_TRANSLATION: Translation = {
    EN: 'Translation name must be unique. Choose another one.',
    CZ: 'Název pro překlad musí být unikátní. Zvolte prosím jiný.',
  };
}

export default TranslationsLocale;
