import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react';
import { H1, SectionContainer, Toast } from '@scaut-sro/meepo';
import { ArrowBackIcon, WarningTwoIcon } from '@chakra-ui/icons';
import FormLocale from '../../../../core/localization/translations/Form.locale';
import { useClientUserDelete } from '../../../../build/generated-sources/service/MutationService';
import LoadingScreen from '../../../LoadingScreen/LoadingScreen';
import DetailHeader from '../../../../components/DetailHeader/DetailHeader';
import Translate from '../../../../components/Translate/Translate';
import { useGetUser } from '../../../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../../../core/localization/LocaleUtils';
import { ClientUserDto } from '../../../../build/generated-sources/dto/ClientUserDto';
import { useLazyGetClientUserDetail } from '../../../../build/generated-sources/service/QueryService';
import ClientsLocale from '../../Clients.locale';
import ClientUserDetailForm from './ClientUserDetailForm';

const ClientUserDetail: React.FunctionComponent = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const userId = Number.isNaN(+params.id);
  const { language } = useGetUser();
  const history = useHistory();
  const [data, setData] = useState<ClientUserDto | undefined>(undefined);
  const [formIsLoading, setFormIsLoading] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [userHandle] = useLazyGetClientUserDetail(
    {
      id: true,
      firstName: true,
      lastName: true,
      email: true,
      active: true,
      phone: true,
      role: true,
      lastActive: true,
      teams: {
        id: true,
        name: true,
      },
      clientId: true,
    },
    {
      onCompleted: (response) => {
        if (response.clientUserDetail === null) {
          Toast({
            title: translate(ClientsLocale.ERROR_GETTING_DATA, language),
            status: 'error',
          });
        }
        setData(response.clientUserDetail);
      },
      onError: () => {
        Toast({
          title: translate(ClientsLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      },
      variables: {
        clientUserId: +params.id,
      },
    },
  );
  useEffect(() => {
    if (!userId) {
      userHandle({
        variables: {
          clientUserId: +params.id,
        },
      });
    }
  }, [userHandle, params.id, userId]);

  const goBackToClients = () => {
    history.goBack();
  };

  const [mutateDeleteUser] = useClientUserDelete({
    success: true,
  }, {
    onCompleted: () => {
      Toast({
        title: translate(ClientsLocale.CLIENT_DELETED, language),
        status: 'success',
      });
      history.goBack();
    },
    onError: () => {
      Toast({
        title: translate(ClientsLocale.ERROR_DELETING_USER, language),
        status: 'error',
      });
    },
  });

  const deleteUser = () => {
    mutateDeleteUser({
      variables: {
        clientUserId: +params.id,
      },
    });
  };

  return (
    <>
      {!data ? <LoadingScreen /> : (
        <SectionContainer>
          <DetailHeader>
            <H1><Translate label={ClientsLocale.CLIENT_USER_DETAIL} /></H1>
          </DetailHeader>
          <HStack justifyContent="right">
            <Button
              variant="outline"
              colorScheme="red"
              onClick={onOpen}
              isDisabled={formIsLoading}
            >
              <Translate label={FormLocale.DELETE} />
            </Button>
          </HStack>
          <Box>
            <ClientUserDetailForm
              clientUser={data}
              loadingSetter={(isLoading) => { setFormIsLoading(isLoading); }}
              formRef={formRef}
            />
          </Box>
          <Flex mt={8}>
            <Button
              variant="ghost"
              onClick={() => goBackToClients()}
              leftIcon={<ArrowBackIcon />}
            >
              <Translate label={FormLocale.BACK} />
            </Button>
            <Spacer />
            <ButtonGroup>
              <Button
                onClick={() => {
                  if (formRef) {
                    formRef.current?.dispatchEvent(new Event('submit', {
                      cancelable: true,
                      bubbles: true,
                    }));
                  }
                }}
                isLoading={formIsLoading}
              >
                <Translate label={FormLocale.SAVE} />
              </Button>
            </ButtonGroup>
          </Flex>
          <Modal
            isOpen={isOpen}
            onClose={onClose}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <HStack justifyContent="center">
                  <WarningTwoIcon color="red" fontSize={30} />
                </HStack>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <Translate label={ClientsLocale.ARE_YOU_SURE} />
                :
                {(` ${data.firstName} ${data.lastName}`)}
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="outline"
                  colorScheme="red"
                  mr={3}
                  onClick={() => deleteUser()}
                >
                  <Translate label={ClientsLocale.YES} />
                </Button>
                <Button onClick={onClose}><Translate label={ClientsLocale.NO} /></Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </SectionContainer>
      )}
    </>
  );
};

export default ClientUserDetail;
