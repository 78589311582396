import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, FormControl, FormLabel, GridItem, SimpleGrid, Switch,
} from '@chakra-ui/react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Toast } from '@scaut-sro/meepo';
import { useHistory } from 'react-router-dom';
import { Select } from 'chakra-react-select';
import { CountryDetailProps, CountryFormAttr } from '../Countries.model';
import { translate } from '../../../core/localization/LocaleUtils';
import FormLocale from '../../../core/localization/translations/Form.locale';
import CountriesLocale from '../Countries.locale';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import { useCountry } from '../../../build/generated-sources/service/MutationService';
import { Translation } from '../../../build/generated-sources/dto/Translation';
import Translate from '../../../components/Translate/Translate';
import TranslationChooseModal from '../../Translations/TranslationChooseModal/TranslationChooseModal';
import { useGetEnumerations } from '../../../build/generated-sources/service/QueryService';
import { EnumerationType } from '../../../build/generated-sources/enum/EnumerationType';
import ClientsLocale from '../../Clients/Clients.locale';
import { Enumeration } from '../../../build/generated-sources/dto/Enumeration';

const createCountryMapper = {
  id: true,
};

const CountriesDetailForm: React.FunctionComponent<CountryDetailProps> = (props) => {
  const {
    country, loadingSetter, formRef, cancelChangesTrigger,
  } = props;
  const history = useHistory();
  const { language } = useGetUser();
  const [countryEnumList, setCountryEnumList] = useState<Enumeration[]>([]);

  const defaultValues: Partial<CountryFormAttr> = useMemo(() => ({
    countryId: country?.id,
    active: country?.active,
    visibleByDefault: country?.visibleByDefault,
    translationId: country?.translation?.id || -1,
    enumeration: country?.enumeration?.id
      ? {
        value: country?.enumeration.id,
        label: country?.enumeration.translation?.localizationStrings?.[language] || '',
      }
      : undefined,
  }), [country, language]);
  const {
    handleSubmit, setValue, reset, formState: { errors }, control, register, getValues,
  } = useForm<Partial<CountryFormAttr>>({
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [
    cancelChangesTrigger, reset, setValue, defaultValues,
  ]);

  const [mutateCountry,
    optionsMutationCountry] = useCountry(createCountryMapper, {
    onCompleted: (response) => {
      Toast({
        title: translate(FormLocale.SUCCESSFULLY_SAVED, language),
        status: 'success',
      });
      history.push(`/country/${response.country.id}`);
    },
    onError: () => {
      Toast({
        title: translate(CountriesLocale.ERROR_SAVING_COUNTRY, language),
        status: 'error',
      });
    },
  });

  useEffect(() => {
    if (loadingSetter) loadingSetter(optionsMutationCountry.loading);
  }, [loadingSetter, optionsMutationCountry]);

  const handleCountryFormSubmit: SubmitHandler<CountryFormAttr> = (values) => {
    mutateCountry({
      variables: {
        countryId: country.id,
        country: {
          active: values.active,
          visibleByDefault: values.visibleByDefault,
          translationNameId: values.translationId,
          enumerationId: values.enumeration.value,
        },
      },
    });
  };

  useGetEnumerations(
    {
      id: true,
      value: true,
      translation: {
        id: true,
        localizationStrings: true,
      },
    }, {
      variables: {
        enumerationType: EnumerationType.COUNTRY,
      },
      onError: () => {
        Toast({
          title: translate(ClientsLocale.ERROR_GETTING_CLIENT_DATA, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setCountryEnumList(response.enumerations);
      },
    },
  );

  const countryEnumOptions = useMemo(() => countryEnumList.map((c) => ({
    value: c.id,
    label: c.translation?.localizationStrings?.[language],
  })), [countryEnumList, language]);

  const setTranslationId = (translation: Translation) => {
    setValue('translationId', translation.id);
  };

  return (
    <>
      <form
        name="countriesDetailForm"
        onSubmit={handleSubmit(handleCountryFormSubmit)}
        ref={formRef}
      >
        <SimpleGrid columns={[1, 1, 1, 3, 3, 3]} spacing={6} mb={7}>
          <FormControl mb={6} isInvalid={!!errors.translationId}>
            <FormLabel htmlFor="translationId">
              <Translate label={CountriesLocale.TRANSLATION_NAME} />
            </FormLabel>
            <TranslationChooseModal
              translationId={getValues('translationId')}
              setValue={setTranslationId}
              type="string"
              {...register('translationId', {
                required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
              })}
            />
          </FormControl>
          <FormControl isRequired isInvalid={!!errors.enumeration}>
            <FormLabel><Translate label={CountriesLocale.COUNTRY_ENUMERATION} /></FormLabel>
            <Controller
              name="enumeration"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={countryEnumOptions}
                  hideSelectedOptions={false}
                  selectedOptionStyle="check"
                />
              )}
              rules={{
                required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
              }}
            />
          </FormControl>
          <GridItem colSpan={[1, 1, 1, 2, 2, 2]}>
            <Box>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderRadius="md"
                backgroundColor="gray.100"
                p="6px"
                pl={3}
                mt="30px"
                mb={6}
              >
                <label htmlFor="active">{translate(CountriesLocale.ACTIVE, language)}</label>
                <Controller
                  control={control}
                  name="active"
                  render={({ field }) => (
                    <Switch
                      onChange={(e) => { field.onChange(e.target.checked); }}
                      isChecked={field.value}
                      size="lg"
                      colorScheme="green"
                    />
                  )}
                />
              </FormControl>
            </Box>
            <Box>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderRadius="md"
                backgroundColor="gray.100"
                p="6px"
                pl={3}
                mt="30px"
                mb={6}
              >
                <label htmlFor="active">{translate(CountriesLocale.VISIBLE_BY_DEFAULT, language)}</label>
                <Controller
                  control={control}
                  name="visibleByDefault"
                  render={({ field }) => (
                    <Switch
                      onChange={(e) => { field.onChange(e.target.checked); }}
                      isChecked={field.value}
                      size="lg"
                      colorScheme="green"
                    />
                  )}
                />
              </FormControl>
            </Box>
          </GridItem>
        </SimpleGrid>
      </form>
    </>
  );
};
export default CountriesDetailForm;
