import React from 'react';
import {
  MeepoTable,
} from '@scaut-sro/meepo';
import {
  Box, SimpleGrid, Text,
} from '@chakra-ui/react';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import Translate from '../../../components/Translate/Translate';
import TasksLocale from '../Tasks.locale';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../../core/localization/LocaleUtils';
import TableLocale from '../../../core/localization/translations/Table.locale';
import Workflow from '../../../components/Workflow/Workflow';
import { TasksProps } from './Components.model';

const TasksDetail: React.FunctionComponent<TasksProps> = (props) => {
  const { tasks, processDefinitionModel, loading } = props;
  const { language } = useGetUser();

  return (
    <>
      {!tasks ? <LoadingScreen /> : (
        <>
          <SimpleGrid columns={[1, 1, 1, 1, 1, 1]} spacing={6}>
            <Box>
              <MeepoTable
                columns={[
                  { id: 'internalName', label: translate(TasksLocale.INTERNAL_NAME, language) },
                  { id: 'description', label: translate(TasksLocale.DESCRIPTION, language) },
                  {
                    id: 'state',
                    label: translate(TasksLocale.STATE, language),
                    alignLabel: 'center',
                    boxProps: {
                      w: '220px',
                    },
                  },
                ]}
                data={tasks}
                isLoading={loading}
                noData={<Text><Translate label={TableLocale.NO_DATA} /></Text>}
              />
            </Box>

            <Workflow
              diagram={processDefinitionModel?.processDefinitionModel}
              activeSteps={processDefinitionModel?.activeSteps}
            />
          </SimpleGrid>
        </>
      )}
    </>
  );
};

export default TasksDetail;
