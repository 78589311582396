import {
  QueryHookOptions,
  useQuery,
  useLazyQuery,
  QueryResult,
  QueryTuple,
} from '@apollo/client';
import {GraphqlBuilder, GraphQLType} from '@scaut-sro/npm-core-generator';
import { ProcessDefinitionsResultMapper } from '../dto/ProcessDefinitionsResult'
import {UseGetProcessDefinitionsData, UseGetProcessDefinitionsVariables} from './QueryServiceModel'
import { ComponentDefinitionMapper } from '../dto/ComponentDefinition'
import {UseGetComponentDefinitionDetailData, UseGetComponentDefinitionDetailVariables} from './QueryServiceModel'
import { PageableResponse__ComponentDefinitionMapper } from '../dto/PageableResponse__ComponentDefinition'
import {UseGetComponentDefinitionsData, UseGetComponentDefinitionsVariables} from './QueryServiceModel'
import {UseGetActiveComponentDefinitionsData, UseGetActiveComponentDefinitionsVariables} from './QueryServiceModel'
import { ScreeningDefinitionMapper } from '../dto/ScreeningDefinition'
import {UseGetComponentDefinitionScreeningDefinitionsData, UseGetComponentDefinitionScreeningDefinitionsVariables} from './QueryServiceModel'
import { ScreeningDefinitionDetailMapper } from '../dto/ScreeningDefinitionDetail'
import {UseGetScreeningDefinitionDetailData, UseGetScreeningDefinitionDetailVariables} from './QueryServiceModel'
import { PageableResponse__ScreeningDefinitionMapper } from '../dto/PageableResponse__ScreeningDefinition'
import {UseGetScreeningDefinitionsData, UseGetScreeningDefinitionsVariables} from './QueryServiceModel'
import {UseGetActiveScreeningDefinitionsData, UseGetActiveScreeningDefinitionsVariables} from './QueryServiceModel'
import { ScreeningPackageMapper } from '../dto/ScreeningPackage'
import {UseGetScreeningDefinitionScreeningPackagesData, UseGetScreeningDefinitionScreeningPackagesVariables} from './QueryServiceModel'
import { ScreeningPackageDetailMapper } from '../dto/ScreeningPackageDetail'
import {UseGetScreeningPackageDetailData, UseGetScreeningPackageDetailVariables} from './QueryServiceModel'
import { PageableResponse__ScreeningPackageMapper } from '../dto/PageableResponse__ScreeningPackage'
import {UseGetScreeningPackagesData, UseGetScreeningPackagesVariables} from './QueryServiceModel'
import {UseGetActiveScreeningPackagesData, UseGetActiveScreeningPackagesVariables} from './QueryServiceModel'
import { CountryMapper } from '../dto/Country'
import {UseGetCountriesData, UseGetCountriesVariables} from './QueryServiceModel'
import {UseGetCountryDetailData, UseGetCountryDetailVariables} from './QueryServiceModel'
import { JasperReportMapper } from '../dto/JasperReport'
import {UseGetPoaReportsData, UseGetPoaReportsVariables} from './QueryServiceModel'
import { AdminUserMapper } from '../dto/AdminUser'
import {UseGetUserProfileData, UseGetUserProfileVariables} from './QueryServiceModel'
import { UserPreferencesMapper } from '../dto/UserPreferences'
import {UseGetUserPreferencesData, UseGetUserPreferencesVariables} from './QueryServiceModel'
import { AdminUserDtoMapper } from '../dto/AdminUserDto'
import {UseGetAdminUserDetailData, UseGetAdminUserDetailVariables} from './QueryServiceModel'
import {UseGetAdminUsersData, UseGetAdminUsersVariables} from './QueryServiceModel'
import { PageableResponse__AdminUserDtoMapper } from '../dto/PageableResponse__AdminUserDto'
import {UseGetAdminUsersPageData, UseGetAdminUsersPageVariables} from './QueryServiceModel'
import { KeycloakRoleDtoMapper } from '../dto/KeycloakRoleDto'
import {UseGetAdminRolesData, UseGetAdminRolesVariables} from './QueryServiceModel'
import { KeycloakPasswordPolicyDtoMapper } from '../dto/KeycloakPasswordPolicyDto'
import {UseGetPasswordPoliciesData, UseGetPasswordPoliciesVariables} from './QueryServiceModel'
import { ClientDetailMapper } from '../dto/ClientDetail'
import {UseGetClientDetailData, UseGetClientDetailVariables} from './QueryServiceModel'
import { ClientUserDtoMapper } from '../dto/ClientUserDto'
import {UseGetClientUserDetailData, UseGetClientUserDetailVariables} from './QueryServiceModel'
import { PageableResponse__ClientMapper } from '../dto/PageableResponse__Client'
import {UseGetClientsPageData, UseGetClientsPageVariables} from './QueryServiceModel'
import { ClientMapper } from '../dto/Client'
import {UseGetClientsData, UseGetClientsVariables} from './QueryServiceModel'
import { PageableResponse__ClientUserDtoMapper } from '../dto/PageableResponse__ClientUserDto'
import {UseGetClientUsersPageData, UseGetClientUsersPageVariables} from './QueryServiceModel'
import { TeamDtoMapper } from '../dto/TeamDto'
import {UseGetTeamsData, UseGetTeamsVariables} from './QueryServiceModel'
import {UseGetClientRolesData, UseGetClientRolesVariables} from './QueryServiceModel'
import { EnumerationMapper } from '../dto/Enumeration'
import {UseGetEnumerationsData, UseGetEnumerationsVariables} from './QueryServiceModel'
import { PageableResponse__EnumerationMapper } from '../dto/PageableResponse__Enumeration'
import {UseGetEnumerationsPageData, UseGetEnumerationsPageVariables} from './QueryServiceModel'
import {UseGetEnumerationGetData, UseGetEnumerationGetVariables} from './QueryServiceModel'
import { TranslationMapper } from '../dto/Translation'
import {UseGetFindTranslationData, UseGetFindTranslationVariables} from './QueryServiceModel'
import { PageableResponse__TranslationMapper } from '../dto/PageableResponse__Translation'
import {UseGetTranslationsPageData, UseGetTranslationsPageVariables} from './QueryServiceModel'
import { LanguageWrapperMapper } from '../dto/LanguageWrapper'
import {UseGetLanguagesData, UseGetLanguagesVariables} from './QueryServiceModel'
import { PrimaryOrderDetailMapper } from '../dto/PrimaryOrderDetail'
import {UseGetPrimaryOrderDetailData, UseGetPrimaryOrderDetailVariables} from './QueryServiceModel'
import { PageableResponse__PrimaryOrderDetailMapper } from '../dto/PageableResponse__PrimaryOrderDetail'
import {UseGetPrimaryOrdersData, UseGetPrimaryOrdersVariables} from './QueryServiceModel'
import { PrimaryOrderOverviewDtoMapper } from '../dto/PrimaryOrderOverviewDto'
import {UseGetPrimaryOrdersOverviewData, UseGetPrimaryOrdersOverviewVariables} from './QueryServiceModel'
import { PageableResponse__InvoiceMapper } from '../dto/PageableResponse__Invoice'
import {UseGetGetInvoicesData, UseGetGetInvoicesVariables} from './QueryServiceModel'
import { InvoiceDetailMapper } from '../dto/InvoiceDetail'
import {UseGetGetInvoiceDetailData, UseGetGetInvoiceDetailVariables} from './QueryServiceModel'
import { CandidateScreeningAdminDetailMapper } from '../dto/CandidateScreeningAdminDetail'
import {UseGetCandidateScreeningData, UseGetCandidateScreeningVariables} from './QueryServiceModel'
import { ComponentDetailMapper } from '../dto/ComponentDetail'
import {UseGetComponentDetailData, UseGetComponentDetailVariables} from './QueryServiceModel'
import { PageableResponse__ComponentListDetailMapper } from '../dto/PageableResponse__ComponentListDetail'
import {UseGetComponentsData, UseGetComponentsVariables} from './QueryServiceModel'
import { ProcessDefinitionModelDtoMapper } from '../dto/ProcessDefinitionModelDto'
import {UseGetProcessDefinitionModelData, UseGetProcessDefinitionModelVariables} from './QueryServiceModel'
import { ProcessVariablesDtoMapper } from '../dto/ProcessVariablesDto'
import {UseGetProcessVariablesData, UseGetProcessVariablesVariables} from './QueryServiceModel'
import { CamelIntegrationDtoMapper } from '../dto/CamelIntegrationDto'
import {UseGetIntegrationsData, UseGetIntegrationsVariables} from './QueryServiceModel'
import { ComponentNoteMapper } from '../dto/ComponentNote'
import {UseGetComponentNotesData, UseGetComponentNotesVariables} from './QueryServiceModel'
import { ComponentLogDtoMapper } from '../dto/ComponentLogDto'
import {UseGetComponentLogsData, UseGetComponentLogsVariables} from './QueryServiceModel'
import { ComponentLogVariablesDtoMapper } from '../dto/ComponentLogVariablesDto'
import {UseGetComponentLogVariablesData, UseGetComponentLogVariablesVariables} from './QueryServiceModel'
import { ResourceDatabaseSectionDetailMapper } from '../dto/ResourceDatabaseSectionDetail'
import {UseGetResourceDatabaseSectionsData, UseGetResourceDatabaseSectionsVariables} from './QueryServiceModel'
import { ResourceDatabaseItemMapper } from '../dto/ResourceDatabaseItem'
import {UseGetResourceDatabaseItemsData, UseGetResourceDatabaseItemsVariables} from './QueryServiceModel'
import {UseGetGetResourceDatabaseItemData, UseGetGetResourceDatabaseItemVariables} from './QueryServiceModel'
import { ResourceDatabaseItemDetailMapper } from '../dto/ResourceDatabaseItemDetail'
import {UseGetItemDetailData, UseGetItemDetailVariables} from './QueryServiceModel'
import { FormFieldDtoMapper } from '../dto/FormFieldDto'
import {UseGetGetFieldsData, UseGetGetFieldsVariables} from './QueryServiceModel'
import { CandidateScreeningCompletedDataMapper } from '../dto/CandidateScreeningCompletedData'
import {UseGetCandidateScreeningCompletedDataGetData, UseGetCandidateScreeningCompletedDataGetVariables} from './QueryServiceModel'
import { FAQMapper } from '../dto/FAQ'
import {UseGetFaqsData, UseGetFaqsVariables} from './QueryServiceModel'
import { FileInfoMapper } from '../dto/FileInfo'
import {UseGetGetFilesInfoData, UseGetGetFilesInfoVariables} from './QueryServiceModel'
import {UseGetGetUploadedFilesInfoData, UseGetGetUploadedFilesInfoVariables} from './QueryServiceModel'

export function useGetProcessDefinitions(
  mapper: ProcessDefinitionsResultMapper,
  options?: QueryHookOptions<UseGetProcessDefinitionsData, UseGetProcessDefinitionsVariables>
): QueryResult<UseGetProcessDefinitionsData, UseGetProcessDefinitionsVariables> {
  const queryBuilder: GraphqlBuilder<ProcessDefinitionsResultMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('processDefinitions')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetProcessDefinitions(
  mapper: ProcessDefinitionsResultMapper,
  options?: QueryHookOptions<UseGetProcessDefinitionsData, UseGetProcessDefinitionsVariables>
): QueryTuple<UseGetProcessDefinitionsData, UseGetProcessDefinitionsVariables> {
  const queryBuilder: GraphqlBuilder<ProcessDefinitionsResultMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('processDefinitions')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetComponentDefinitionDetail(
  mapper: ComponentDefinitionMapper,
  options?: QueryHookOptions<UseGetComponentDefinitionDetailData, UseGetComponentDefinitionDetailVariables>
): QueryResult<UseGetComponentDefinitionDetailData, UseGetComponentDefinitionDetailVariables> {
  const queryBuilder: GraphqlBuilder<ComponentDefinitionMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('componentDefinitionDetail')
      .setReturnProperties(mapper)
      .declareVariables({
        componentDefinitionId: 'Long!',        
      })
      .setProperties(`componentDefinitionId: $componentDefinitionId`)
      .build(),
    options
  );
}

export function useLazyGetComponentDefinitionDetail(
  mapper: ComponentDefinitionMapper,
  options?: QueryHookOptions<UseGetComponentDefinitionDetailData, UseGetComponentDefinitionDetailVariables>
): QueryTuple<UseGetComponentDefinitionDetailData, UseGetComponentDefinitionDetailVariables> {
  const queryBuilder: GraphqlBuilder<ComponentDefinitionMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('componentDefinitionDetail')
      .setReturnProperties(mapper)
      .declareVariables({
        componentDefinitionId: 'Long!',        
      })
      .setProperties(`componentDefinitionId: $componentDefinitionId`)
      .build(),
    options
  );
}


export function useGetComponentDefinitions(
  mapper: PageableResponse__ComponentDefinitionMapper,
  options?: QueryHookOptions<UseGetComponentDefinitionsData, UseGetComponentDefinitionsVariables>
): QueryResult<UseGetComponentDefinitionsData, UseGetComponentDefinitionsVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__ComponentDefinitionMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('componentDefinitions')
      .setReturnProperties(mapper)
      .declareVariables({
        filter: 'ComponentDefinitionFilterDto',
        paging: 'PageableInput!',        
      })
      .setProperties(`filter: $filter, paging: $paging`)
      .build(),
    options
  );
}

export function useLazyGetComponentDefinitions(
  mapper: PageableResponse__ComponentDefinitionMapper,
  options?: QueryHookOptions<UseGetComponentDefinitionsData, UseGetComponentDefinitionsVariables>
): QueryTuple<UseGetComponentDefinitionsData, UseGetComponentDefinitionsVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__ComponentDefinitionMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('componentDefinitions')
      .setReturnProperties(mapper)
      .declareVariables({
        filter: 'ComponentDefinitionFilterDto',
        paging: 'PageableInput!',        
      })
      .setProperties(`filter: $filter, paging: $paging`)
      .build(),
    options
  );
}


export function useGetActiveComponentDefinitions(
  mapper: ComponentDefinitionMapper,
  options?: QueryHookOptions<UseGetActiveComponentDefinitionsData, UseGetActiveComponentDefinitionsVariables>
): QueryResult<UseGetActiveComponentDefinitionsData, UseGetActiveComponentDefinitionsVariables> {
  const queryBuilder: GraphqlBuilder<ComponentDefinitionMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('activeComponentDefinitions')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetActiveComponentDefinitions(
  mapper: ComponentDefinitionMapper,
  options?: QueryHookOptions<UseGetActiveComponentDefinitionsData, UseGetActiveComponentDefinitionsVariables>
): QueryTuple<UseGetActiveComponentDefinitionsData, UseGetActiveComponentDefinitionsVariables> {
  const queryBuilder: GraphqlBuilder<ComponentDefinitionMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('activeComponentDefinitions')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetComponentDefinitionScreeningDefinitions(
  mapper: ScreeningDefinitionMapper,
  options?: QueryHookOptions<UseGetComponentDefinitionScreeningDefinitionsData, UseGetComponentDefinitionScreeningDefinitionsVariables>
): QueryResult<UseGetComponentDefinitionScreeningDefinitionsData, UseGetComponentDefinitionScreeningDefinitionsVariables> {
  const queryBuilder: GraphqlBuilder<ScreeningDefinitionMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('componentDefinitionScreeningDefinitions')
      .setReturnProperties(mapper)
      .declareVariables({
        componentDefinitionId: 'Long!',        
      })
      .setProperties(`componentDefinitionId: $componentDefinitionId`)
      .build(),
    options
  );
}

export function useLazyGetComponentDefinitionScreeningDefinitions(
  mapper: ScreeningDefinitionMapper,
  options?: QueryHookOptions<UseGetComponentDefinitionScreeningDefinitionsData, UseGetComponentDefinitionScreeningDefinitionsVariables>
): QueryTuple<UseGetComponentDefinitionScreeningDefinitionsData, UseGetComponentDefinitionScreeningDefinitionsVariables> {
  const queryBuilder: GraphqlBuilder<ScreeningDefinitionMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('componentDefinitionScreeningDefinitions')
      .setReturnProperties(mapper)
      .declareVariables({
        componentDefinitionId: 'Long!',        
      })
      .setProperties(`componentDefinitionId: $componentDefinitionId`)
      .build(),
    options
  );
}


export function useGetScreeningDefinitionDetail(
  mapper: ScreeningDefinitionDetailMapper,
  options?: QueryHookOptions<UseGetScreeningDefinitionDetailData, UseGetScreeningDefinitionDetailVariables>
): QueryResult<UseGetScreeningDefinitionDetailData, UseGetScreeningDefinitionDetailVariables> {
  const queryBuilder: GraphqlBuilder<ScreeningDefinitionDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('screeningDefinitionDetail')
      .setReturnProperties(mapper)
      .declareVariables({
        screeningDefinitionId: 'Long!',        
      })
      .setProperties(`screeningDefinitionId: $screeningDefinitionId`)
      .build(),
    options
  );
}

export function useLazyGetScreeningDefinitionDetail(
  mapper: ScreeningDefinitionDetailMapper,
  options?: QueryHookOptions<UseGetScreeningDefinitionDetailData, UseGetScreeningDefinitionDetailVariables>
): QueryTuple<UseGetScreeningDefinitionDetailData, UseGetScreeningDefinitionDetailVariables> {
  const queryBuilder: GraphqlBuilder<ScreeningDefinitionDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('screeningDefinitionDetail')
      .setReturnProperties(mapper)
      .declareVariables({
        screeningDefinitionId: 'Long!',        
      })
      .setProperties(`screeningDefinitionId: $screeningDefinitionId`)
      .build(),
    options
  );
}


export function useGetScreeningDefinitions(
  mapper: PageableResponse__ScreeningDefinitionMapper,
  options?: QueryHookOptions<UseGetScreeningDefinitionsData, UseGetScreeningDefinitionsVariables>
): QueryResult<UseGetScreeningDefinitionsData, UseGetScreeningDefinitionsVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__ScreeningDefinitionMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('screeningDefinitions')
      .setReturnProperties(mapper)
      .declareVariables({
        filter: 'ScreeningDefinitionFilterDto',
        paging: 'PageableInput!',        
      })
      .setProperties(`filter: $filter, paging: $paging`)
      .build(),
    options
  );
}

export function useLazyGetScreeningDefinitions(
  mapper: PageableResponse__ScreeningDefinitionMapper,
  options?: QueryHookOptions<UseGetScreeningDefinitionsData, UseGetScreeningDefinitionsVariables>
): QueryTuple<UseGetScreeningDefinitionsData, UseGetScreeningDefinitionsVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__ScreeningDefinitionMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('screeningDefinitions')
      .setReturnProperties(mapper)
      .declareVariables({
        filter: 'ScreeningDefinitionFilterDto',
        paging: 'PageableInput!',        
      })
      .setProperties(`filter: $filter, paging: $paging`)
      .build(),
    options
  );
}


export function useGetActiveScreeningDefinitions(
  mapper: ScreeningDefinitionMapper,
  options?: QueryHookOptions<UseGetActiveScreeningDefinitionsData, UseGetActiveScreeningDefinitionsVariables>
): QueryResult<UseGetActiveScreeningDefinitionsData, UseGetActiveScreeningDefinitionsVariables> {
  const queryBuilder: GraphqlBuilder<ScreeningDefinitionMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('activeScreeningDefinitions')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetActiveScreeningDefinitions(
  mapper: ScreeningDefinitionMapper,
  options?: QueryHookOptions<UseGetActiveScreeningDefinitionsData, UseGetActiveScreeningDefinitionsVariables>
): QueryTuple<UseGetActiveScreeningDefinitionsData, UseGetActiveScreeningDefinitionsVariables> {
  const queryBuilder: GraphqlBuilder<ScreeningDefinitionMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('activeScreeningDefinitions')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetScreeningDefinitionScreeningPackages(
  mapper: ScreeningPackageMapper,
  options?: QueryHookOptions<UseGetScreeningDefinitionScreeningPackagesData, UseGetScreeningDefinitionScreeningPackagesVariables>
): QueryResult<UseGetScreeningDefinitionScreeningPackagesData, UseGetScreeningDefinitionScreeningPackagesVariables> {
  const queryBuilder: GraphqlBuilder<ScreeningPackageMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('screeningDefinitionScreeningPackages')
      .setReturnProperties(mapper)
      .declareVariables({
        screeningDefinitionId: 'Long!',        
      })
      .setProperties(`screeningDefinitionId: $screeningDefinitionId`)
      .build(),
    options
  );
}

export function useLazyGetScreeningDefinitionScreeningPackages(
  mapper: ScreeningPackageMapper,
  options?: QueryHookOptions<UseGetScreeningDefinitionScreeningPackagesData, UseGetScreeningDefinitionScreeningPackagesVariables>
): QueryTuple<UseGetScreeningDefinitionScreeningPackagesData, UseGetScreeningDefinitionScreeningPackagesVariables> {
  const queryBuilder: GraphqlBuilder<ScreeningPackageMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('screeningDefinitionScreeningPackages')
      .setReturnProperties(mapper)
      .declareVariables({
        screeningDefinitionId: 'Long!',        
      })
      .setProperties(`screeningDefinitionId: $screeningDefinitionId`)
      .build(),
    options
  );
}


export function useGetScreeningPackageDetail(
  mapper: ScreeningPackageDetailMapper,
  options?: QueryHookOptions<UseGetScreeningPackageDetailData, UseGetScreeningPackageDetailVariables>
): QueryResult<UseGetScreeningPackageDetailData, UseGetScreeningPackageDetailVariables> {
  const queryBuilder: GraphqlBuilder<ScreeningPackageDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('screeningPackageDetail')
      .setReturnProperties(mapper)
      .declareVariables({
        screeningPackageId: 'Long!',        
      })
      .setProperties(`screeningPackageId: $screeningPackageId`)
      .build(),
    options
  );
}

export function useLazyGetScreeningPackageDetail(
  mapper: ScreeningPackageDetailMapper,
  options?: QueryHookOptions<UseGetScreeningPackageDetailData, UseGetScreeningPackageDetailVariables>
): QueryTuple<UseGetScreeningPackageDetailData, UseGetScreeningPackageDetailVariables> {
  const queryBuilder: GraphqlBuilder<ScreeningPackageDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('screeningPackageDetail')
      .setReturnProperties(mapper)
      .declareVariables({
        screeningPackageId: 'Long!',        
      })
      .setProperties(`screeningPackageId: $screeningPackageId`)
      .build(),
    options
  );
}


export function useGetScreeningPackages(
  mapper: PageableResponse__ScreeningPackageMapper,
  options?: QueryHookOptions<UseGetScreeningPackagesData, UseGetScreeningPackagesVariables>
): QueryResult<UseGetScreeningPackagesData, UseGetScreeningPackagesVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__ScreeningPackageMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('screeningPackages')
      .setReturnProperties(mapper)
      .declareVariables({
        filter: 'ScreeningPackageFilterDto',
        paging: 'PageableInput!',        
      })
      .setProperties(`filter: $filter, paging: $paging`)
      .build(),
    options
  );
}

export function useLazyGetScreeningPackages(
  mapper: PageableResponse__ScreeningPackageMapper,
  options?: QueryHookOptions<UseGetScreeningPackagesData, UseGetScreeningPackagesVariables>
): QueryTuple<UseGetScreeningPackagesData, UseGetScreeningPackagesVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__ScreeningPackageMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('screeningPackages')
      .setReturnProperties(mapper)
      .declareVariables({
        filter: 'ScreeningPackageFilterDto',
        paging: 'PageableInput!',        
      })
      .setProperties(`filter: $filter, paging: $paging`)
      .build(),
    options
  );
}


export function useGetActiveScreeningPackages(
  mapper: ScreeningPackageMapper,
  options?: QueryHookOptions<UseGetActiveScreeningPackagesData, UseGetActiveScreeningPackagesVariables>
): QueryResult<UseGetActiveScreeningPackagesData, UseGetActiveScreeningPackagesVariables> {
  const queryBuilder: GraphqlBuilder<ScreeningPackageMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('activeScreeningPackages')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetActiveScreeningPackages(
  mapper: ScreeningPackageMapper,
  options?: QueryHookOptions<UseGetActiveScreeningPackagesData, UseGetActiveScreeningPackagesVariables>
): QueryTuple<UseGetActiveScreeningPackagesData, UseGetActiveScreeningPackagesVariables> {
  const queryBuilder: GraphqlBuilder<ScreeningPackageMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('activeScreeningPackages')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetCountries(
  mapper: CountryMapper,
  options?: QueryHookOptions<UseGetCountriesData, UseGetCountriesVariables>
): QueryResult<UseGetCountriesData, UseGetCountriesVariables> {
  const queryBuilder: GraphqlBuilder<CountryMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('countries')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetCountries(
  mapper: CountryMapper,
  options?: QueryHookOptions<UseGetCountriesData, UseGetCountriesVariables>
): QueryTuple<UseGetCountriesData, UseGetCountriesVariables> {
  const queryBuilder: GraphqlBuilder<CountryMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('countries')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetCountryDetail(
  mapper: CountryMapper,
  options?: QueryHookOptions<UseGetCountryDetailData, UseGetCountryDetailVariables>
): QueryResult<UseGetCountryDetailData, UseGetCountryDetailVariables> {
  const queryBuilder: GraphqlBuilder<CountryMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('countryDetail')
      .setReturnProperties(mapper)
      .declareVariables({
        countryId: 'Long!',        
      })
      .setProperties(`countryId: $countryId`)
      .build(),
    options
  );
}

export function useLazyGetCountryDetail(
  mapper: CountryMapper,
  options?: QueryHookOptions<UseGetCountryDetailData, UseGetCountryDetailVariables>
): QueryTuple<UseGetCountryDetailData, UseGetCountryDetailVariables> {
  const queryBuilder: GraphqlBuilder<CountryMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('countryDetail')
      .setReturnProperties(mapper)
      .declareVariables({
        countryId: 'Long!',        
      })
      .setProperties(`countryId: $countryId`)
      .build(),
    options
  );
}


export function useGetPoaReports(
  mapper: JasperReportMapper,
  options?: QueryHookOptions<UseGetPoaReportsData, UseGetPoaReportsVariables>
): QueryResult<UseGetPoaReportsData, UseGetPoaReportsVariables> {
  const queryBuilder: GraphqlBuilder<JasperReportMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('poaReports')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetPoaReports(
  mapper: JasperReportMapper,
  options?: QueryHookOptions<UseGetPoaReportsData, UseGetPoaReportsVariables>
): QueryTuple<UseGetPoaReportsData, UseGetPoaReportsVariables> {
  const queryBuilder: GraphqlBuilder<JasperReportMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('poaReports')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetUserProfile(
  mapper: AdminUserMapper,
  options?: QueryHookOptions<UseGetUserProfileData, UseGetUserProfileVariables>
): QueryResult<UseGetUserProfileData, UseGetUserProfileVariables> {
  const queryBuilder: GraphqlBuilder<AdminUserMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('userProfile')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetUserProfile(
  mapper: AdminUserMapper,
  options?: QueryHookOptions<UseGetUserProfileData, UseGetUserProfileVariables>
): QueryTuple<UseGetUserProfileData, UseGetUserProfileVariables> {
  const queryBuilder: GraphqlBuilder<AdminUserMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('userProfile')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetUserPreferences(
  mapper: UserPreferencesMapper,
  options?: QueryHookOptions<UseGetUserPreferencesData, UseGetUserPreferencesVariables>
): QueryResult<UseGetUserPreferencesData, UseGetUserPreferencesVariables> {
  const queryBuilder: GraphqlBuilder<UserPreferencesMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('userPreferences')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetUserPreferences(
  mapper: UserPreferencesMapper,
  options?: QueryHookOptions<UseGetUserPreferencesData, UseGetUserPreferencesVariables>
): QueryTuple<UseGetUserPreferencesData, UseGetUserPreferencesVariables> {
  const queryBuilder: GraphqlBuilder<UserPreferencesMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('userPreferences')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetAdminUserDetail(
  mapper: AdminUserDtoMapper,
  options?: QueryHookOptions<UseGetAdminUserDetailData, UseGetAdminUserDetailVariables>
): QueryResult<UseGetAdminUserDetailData, UseGetAdminUserDetailVariables> {
  const queryBuilder: GraphqlBuilder<AdminUserDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('adminUserDetail')
      .setReturnProperties(mapper)
      .declareVariables({
        adminUserId: 'Long!',        
      })
      .setProperties(`adminUserId: $adminUserId`)
      .build(),
    options
  );
}

export function useLazyGetAdminUserDetail(
  mapper: AdminUserDtoMapper,
  options?: QueryHookOptions<UseGetAdminUserDetailData, UseGetAdminUserDetailVariables>
): QueryTuple<UseGetAdminUserDetailData, UseGetAdminUserDetailVariables> {
  const queryBuilder: GraphqlBuilder<AdminUserDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('adminUserDetail')
      .setReturnProperties(mapper)
      .declareVariables({
        adminUserId: 'Long!',        
      })
      .setProperties(`adminUserId: $adminUserId`)
      .build(),
    options
  );
}


export function useGetAdminUsers(
  mapper: AdminUserMapper,
  options?: QueryHookOptions<UseGetAdminUsersData, UseGetAdminUsersVariables>
): QueryResult<UseGetAdminUsersData, UseGetAdminUsersVariables> {
  const queryBuilder: GraphqlBuilder<AdminUserMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('adminUsers')
      .setReturnProperties(mapper)
      .declareVariables({
        search: 'String',        
      })
      .setProperties(`search: $search`)
      .build(),
    options
  );
}

export function useLazyGetAdminUsers(
  mapper: AdminUserMapper,
  options?: QueryHookOptions<UseGetAdminUsersData, UseGetAdminUsersVariables>
): QueryTuple<UseGetAdminUsersData, UseGetAdminUsersVariables> {
  const queryBuilder: GraphqlBuilder<AdminUserMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('adminUsers')
      .setReturnProperties(mapper)
      .declareVariables({
        search: 'String',        
      })
      .setProperties(`search: $search`)
      .build(),
    options
  );
}


export function useGetAdminUsersPage(
  mapper: PageableResponse__AdminUserDtoMapper,
  options?: QueryHookOptions<UseGetAdminUsersPageData, UseGetAdminUsersPageVariables>
): QueryResult<UseGetAdminUsersPageData, UseGetAdminUsersPageVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__AdminUserDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('adminUsersPage')
      .setReturnProperties(mapper)
      .declareVariables({
        filter: 'AdminUserFilterDto',
        paging: 'PageableInput!',        
      })
      .setProperties(`filter: $filter, paging: $paging`)
      .build(),
    options
  );
}

export function useLazyGetAdminUsersPage(
  mapper: PageableResponse__AdminUserDtoMapper,
  options?: QueryHookOptions<UseGetAdminUsersPageData, UseGetAdminUsersPageVariables>
): QueryTuple<UseGetAdminUsersPageData, UseGetAdminUsersPageVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__AdminUserDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('adminUsersPage')
      .setReturnProperties(mapper)
      .declareVariables({
        filter: 'AdminUserFilterDto',
        paging: 'PageableInput!',        
      })
      .setProperties(`filter: $filter, paging: $paging`)
      .build(),
    options
  );
}


export function useGetAdminRoles(
  mapper: KeycloakRoleDtoMapper,
  options?: QueryHookOptions<UseGetAdminRolesData, UseGetAdminRolesVariables>
): QueryResult<UseGetAdminRolesData, UseGetAdminRolesVariables> {
  const queryBuilder: GraphqlBuilder<KeycloakRoleDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('adminRoles')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetAdminRoles(
  mapper: KeycloakRoleDtoMapper,
  options?: QueryHookOptions<UseGetAdminRolesData, UseGetAdminRolesVariables>
): QueryTuple<UseGetAdminRolesData, UseGetAdminRolesVariables> {
  const queryBuilder: GraphqlBuilder<KeycloakRoleDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('adminRoles')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetPasswordPolicies(
  mapper: KeycloakPasswordPolicyDtoMapper,
  options?: QueryHookOptions<UseGetPasswordPoliciesData, UseGetPasswordPoliciesVariables>
): QueryResult<UseGetPasswordPoliciesData, UseGetPasswordPoliciesVariables> {
  const queryBuilder: GraphqlBuilder<KeycloakPasswordPolicyDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('passwordPolicies')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetPasswordPolicies(
  mapper: KeycloakPasswordPolicyDtoMapper,
  options?: QueryHookOptions<UseGetPasswordPoliciesData, UseGetPasswordPoliciesVariables>
): QueryTuple<UseGetPasswordPoliciesData, UseGetPasswordPoliciesVariables> {
  const queryBuilder: GraphqlBuilder<KeycloakPasswordPolicyDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('passwordPolicies')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetClientDetail(
  mapper: ClientDetailMapper,
  options?: QueryHookOptions<UseGetClientDetailData, UseGetClientDetailVariables>
): QueryResult<UseGetClientDetailData, UseGetClientDetailVariables> {
  const queryBuilder: GraphqlBuilder<ClientDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('clientDetail')
      .setReturnProperties(mapper)
      .declareVariables({
        clientId: 'Long!',        
      })
      .setProperties(`clientId: $clientId`)
      .build(),
    options
  );
}

export function useLazyGetClientDetail(
  mapper: ClientDetailMapper,
  options?: QueryHookOptions<UseGetClientDetailData, UseGetClientDetailVariables>
): QueryTuple<UseGetClientDetailData, UseGetClientDetailVariables> {
  const queryBuilder: GraphqlBuilder<ClientDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('clientDetail')
      .setReturnProperties(mapper)
      .declareVariables({
        clientId: 'Long!',        
      })
      .setProperties(`clientId: $clientId`)
      .build(),
    options
  );
}


export function useGetClientUserDetail(
  mapper: ClientUserDtoMapper,
  options?: QueryHookOptions<UseGetClientUserDetailData, UseGetClientUserDetailVariables>
): QueryResult<UseGetClientUserDetailData, UseGetClientUserDetailVariables> {
  const queryBuilder: GraphqlBuilder<ClientUserDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('clientUserDetail')
      .setReturnProperties(mapper)
      .declareVariables({
        clientUserId: 'Long!',        
      })
      .setProperties(`clientUserId: $clientUserId`)
      .build(),
    options
  );
}

export function useLazyGetClientUserDetail(
  mapper: ClientUserDtoMapper,
  options?: QueryHookOptions<UseGetClientUserDetailData, UseGetClientUserDetailVariables>
): QueryTuple<UseGetClientUserDetailData, UseGetClientUserDetailVariables> {
  const queryBuilder: GraphqlBuilder<ClientUserDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('clientUserDetail')
      .setReturnProperties(mapper)
      .declareVariables({
        clientUserId: 'Long!',        
      })
      .setProperties(`clientUserId: $clientUserId`)
      .build(),
    options
  );
}


export function useGetClientsPage(
  mapper: PageableResponse__ClientMapper,
  options?: QueryHookOptions<UseGetClientsPageData, UseGetClientsPageVariables>
): QueryResult<UseGetClientsPageData, UseGetClientsPageVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__ClientMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('clientsPage')
      .setReturnProperties(mapper)
      .declareVariables({
        filter: 'ClientFilterDto',
        paging: 'PageableInput!',        
      })
      .setProperties(`filter: $filter, paging: $paging`)
      .build(),
    options
  );
}

export function useLazyGetClientsPage(
  mapper: PageableResponse__ClientMapper,
  options?: QueryHookOptions<UseGetClientsPageData, UseGetClientsPageVariables>
): QueryTuple<UseGetClientsPageData, UseGetClientsPageVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__ClientMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('clientsPage')
      .setReturnProperties(mapper)
      .declareVariables({
        filter: 'ClientFilterDto',
        paging: 'PageableInput!',        
      })
      .setProperties(`filter: $filter, paging: $paging`)
      .build(),
    options
  );
}


export function useGetClients(
  mapper: ClientMapper,
  options?: QueryHookOptions<UseGetClientsData, UseGetClientsVariables>
): QueryResult<UseGetClientsData, UseGetClientsVariables> {
  const queryBuilder: GraphqlBuilder<ClientMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('clients')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetClients(
  mapper: ClientMapper,
  options?: QueryHookOptions<UseGetClientsData, UseGetClientsVariables>
): QueryTuple<UseGetClientsData, UseGetClientsVariables> {
  const queryBuilder: GraphqlBuilder<ClientMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('clients')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetClientUsersPage(
  mapper: PageableResponse__ClientUserDtoMapper,
  options?: QueryHookOptions<UseGetClientUsersPageData, UseGetClientUsersPageVariables>
): QueryResult<UseGetClientUsersPageData, UseGetClientUsersPageVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__ClientUserDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('clientUsersPage')
      .setReturnProperties(mapper)
      .declareVariables({
        clientId: 'Long!',
        paging: 'PageableInput!',        
      })
      .setProperties(`clientId: $clientId, paging: $paging`)
      .build(),
    options
  );
}

export function useLazyGetClientUsersPage(
  mapper: PageableResponse__ClientUserDtoMapper,
  options?: QueryHookOptions<UseGetClientUsersPageData, UseGetClientUsersPageVariables>
): QueryTuple<UseGetClientUsersPageData, UseGetClientUsersPageVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__ClientUserDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('clientUsersPage')
      .setReturnProperties(mapper)
      .declareVariables({
        clientId: 'Long!',
        paging: 'PageableInput!',        
      })
      .setProperties(`clientId: $clientId, paging: $paging`)
      .build(),
    options
  );
}


export function useGetTeams(
  mapper: TeamDtoMapper,
  options?: QueryHookOptions<UseGetTeamsData, UseGetTeamsVariables>
): QueryResult<UseGetTeamsData, UseGetTeamsVariables> {
  const queryBuilder: GraphqlBuilder<TeamDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('teams')
      .setReturnProperties(mapper)
      .declareVariables({
        clientId: 'Long!',        
      })
      .setProperties(`clientId: $clientId`)
      .build(),
    options
  );
}

export function useLazyGetTeams(
  mapper: TeamDtoMapper,
  options?: QueryHookOptions<UseGetTeamsData, UseGetTeamsVariables>
): QueryTuple<UseGetTeamsData, UseGetTeamsVariables> {
  const queryBuilder: GraphqlBuilder<TeamDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('teams')
      .setReturnProperties(mapper)
      .declareVariables({
        clientId: 'Long!',        
      })
      .setProperties(`clientId: $clientId`)
      .build(),
    options
  );
}


export function useGetClientRoles(
  mapper: KeycloakRoleDtoMapper,
  options?: QueryHookOptions<UseGetClientRolesData, UseGetClientRolesVariables>
): QueryResult<UseGetClientRolesData, UseGetClientRolesVariables> {
  const queryBuilder: GraphqlBuilder<KeycloakRoleDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('clientRoles')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetClientRoles(
  mapper: KeycloakRoleDtoMapper,
  options?: QueryHookOptions<UseGetClientRolesData, UseGetClientRolesVariables>
): QueryTuple<UseGetClientRolesData, UseGetClientRolesVariables> {
  const queryBuilder: GraphqlBuilder<KeycloakRoleDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('clientRoles')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetEnumerations(
  mapper: EnumerationMapper,
  options?: QueryHookOptions<UseGetEnumerationsData, UseGetEnumerationsVariables>
): QueryResult<UseGetEnumerationsData, UseGetEnumerationsVariables> {
  const queryBuilder: GraphqlBuilder<EnumerationMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('enumerations')
      .setReturnProperties(mapper)
      .declareVariables({
        enumerationType: 'EnumerationType!',        
      })
      .setProperties(`enumerationType: $enumerationType`)
      .build(),
    options
  );
}

export function useLazyGetEnumerations(
  mapper: EnumerationMapper,
  options?: QueryHookOptions<UseGetEnumerationsData, UseGetEnumerationsVariables>
): QueryTuple<UseGetEnumerationsData, UseGetEnumerationsVariables> {
  const queryBuilder: GraphqlBuilder<EnumerationMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('enumerations')
      .setReturnProperties(mapper)
      .declareVariables({
        enumerationType: 'EnumerationType!',        
      })
      .setProperties(`enumerationType: $enumerationType`)
      .build(),
    options
  );
}


export function useGetEnumerationsPage(
  mapper: PageableResponse__EnumerationMapper,
  options?: QueryHookOptions<UseGetEnumerationsPageData, UseGetEnumerationsPageVariables>
): QueryResult<UseGetEnumerationsPageData, UseGetEnumerationsPageVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__EnumerationMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('enumerationsPage')
      .setReturnProperties(mapper)
      .declareVariables({
        filter: 'EnumerationFilterDto',
        paging: 'PageableInput!',        
      })
      .setProperties(`filter: $filter, paging: $paging`)
      .build(),
    options
  );
}

export function useLazyGetEnumerationsPage(
  mapper: PageableResponse__EnumerationMapper,
  options?: QueryHookOptions<UseGetEnumerationsPageData, UseGetEnumerationsPageVariables>
): QueryTuple<UseGetEnumerationsPageData, UseGetEnumerationsPageVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__EnumerationMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('enumerationsPage')
      .setReturnProperties(mapper)
      .declareVariables({
        filter: 'EnumerationFilterDto',
        paging: 'PageableInput!',        
      })
      .setProperties(`filter: $filter, paging: $paging`)
      .build(),
    options
  );
}


export function useGetEnumerationGet(
  mapper: EnumerationMapper,
  options?: QueryHookOptions<UseGetEnumerationGetData, UseGetEnumerationGetVariables>
): QueryResult<UseGetEnumerationGetData, UseGetEnumerationGetVariables> {
  const queryBuilder: GraphqlBuilder<EnumerationMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('enumerationGet')
      .setReturnProperties(mapper)
      .declareVariables({
        enumerationId: 'Long!',        
      })
      .setProperties(`enumerationId: $enumerationId`)
      .build(),
    options
  );
}

export function useLazyGetEnumerationGet(
  mapper: EnumerationMapper,
  options?: QueryHookOptions<UseGetEnumerationGetData, UseGetEnumerationGetVariables>
): QueryTuple<UseGetEnumerationGetData, UseGetEnumerationGetVariables> {
  const queryBuilder: GraphqlBuilder<EnumerationMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('enumerationGet')
      .setReturnProperties(mapper)
      .declareVariables({
        enumerationId: 'Long!',        
      })
      .setProperties(`enumerationId: $enumerationId`)
      .build(),
    options
  );
}


export function useGetFindTranslation(
  mapper: TranslationMapper,
  options?: QueryHookOptions<UseGetFindTranslationData, UseGetFindTranslationVariables>
): QueryResult<UseGetFindTranslationData, UseGetFindTranslationVariables> {
  const queryBuilder: GraphqlBuilder<TranslationMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('findTranslation')
      .setReturnProperties(mapper)
      .declareVariables({
        id: 'Long!',        
      })
      .setProperties(`id: $id`)
      .build(),
    options
  );
}

export function useLazyGetFindTranslation(
  mapper: TranslationMapper,
  options?: QueryHookOptions<UseGetFindTranslationData, UseGetFindTranslationVariables>
): QueryTuple<UseGetFindTranslationData, UseGetFindTranslationVariables> {
  const queryBuilder: GraphqlBuilder<TranslationMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('findTranslation')
      .setReturnProperties(mapper)
      .declareVariables({
        id: 'Long!',        
      })
      .setProperties(`id: $id`)
      .build(),
    options
  );
}


export function useGetTranslationsPage(
  mapper: PageableResponse__TranslationMapper,
  options?: QueryHookOptions<UseGetTranslationsPageData, UseGetTranslationsPageVariables>
): QueryResult<UseGetTranslationsPageData, UseGetTranslationsPageVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__TranslationMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('translationsPage')
      .setReturnProperties(mapper)
      .declareVariables({
        search: 'String',
        paging: 'PageableInput!',        
      })
      .setProperties(`search: $search, paging: $paging`)
      .build(),
    options
  );
}

export function useLazyGetTranslationsPage(
  mapper: PageableResponse__TranslationMapper,
  options?: QueryHookOptions<UseGetTranslationsPageData, UseGetTranslationsPageVariables>
): QueryTuple<UseGetTranslationsPageData, UseGetTranslationsPageVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__TranslationMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('translationsPage')
      .setReturnProperties(mapper)
      .declareVariables({
        search: 'String',
        paging: 'PageableInput!',        
      })
      .setProperties(`search: $search, paging: $paging`)
      .build(),
    options
  );
}


export function useGetLanguages(
  mapper: LanguageWrapperMapper,
  options?: QueryHookOptions<UseGetLanguagesData, UseGetLanguagesVariables>
): QueryResult<UseGetLanguagesData, UseGetLanguagesVariables> {
  const queryBuilder: GraphqlBuilder<LanguageWrapperMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('languages')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetLanguages(
  mapper: LanguageWrapperMapper,
  options?: QueryHookOptions<UseGetLanguagesData, UseGetLanguagesVariables>
): QueryTuple<UseGetLanguagesData, UseGetLanguagesVariables> {
  const queryBuilder: GraphqlBuilder<LanguageWrapperMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('languages')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetPrimaryOrderDetail(
  mapper: PrimaryOrderDetailMapper,
  options?: QueryHookOptions<UseGetPrimaryOrderDetailData, UseGetPrimaryOrderDetailVariables>
): QueryResult<UseGetPrimaryOrderDetailData, UseGetPrimaryOrderDetailVariables> {
  const queryBuilder: GraphqlBuilder<PrimaryOrderDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('primaryOrderDetail')
      .setReturnProperties(mapper)
      .declareVariables({
        primaryOrderId: 'Long!',        
      })
      .setProperties(`primaryOrderId: $primaryOrderId`)
      .build(),
    options
  );
}

export function useLazyGetPrimaryOrderDetail(
  mapper: PrimaryOrderDetailMapper,
  options?: QueryHookOptions<UseGetPrimaryOrderDetailData, UseGetPrimaryOrderDetailVariables>
): QueryTuple<UseGetPrimaryOrderDetailData, UseGetPrimaryOrderDetailVariables> {
  const queryBuilder: GraphqlBuilder<PrimaryOrderDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('primaryOrderDetail')
      .setReturnProperties(mapper)
      .declareVariables({
        primaryOrderId: 'Long!',        
      })
      .setProperties(`primaryOrderId: $primaryOrderId`)
      .build(),
    options
  );
}


export function useGetPrimaryOrders(
  mapper: PageableResponse__PrimaryOrderDetailMapper,
  options?: QueryHookOptions<UseGetPrimaryOrdersData, UseGetPrimaryOrdersVariables>
): QueryResult<UseGetPrimaryOrdersData, UseGetPrimaryOrdersVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__PrimaryOrderDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('primaryOrders')
      .setReturnProperties(mapper)
      .declareVariables({
        filter: 'PrimaryOrderFilterDto',
        paging: 'PageableInput!',        
      })
      .setProperties(`filter: $filter, paging: $paging`)
      .build(),
    options
  );
}

export function useLazyGetPrimaryOrders(
  mapper: PageableResponse__PrimaryOrderDetailMapper,
  options?: QueryHookOptions<UseGetPrimaryOrdersData, UseGetPrimaryOrdersVariables>
): QueryTuple<UseGetPrimaryOrdersData, UseGetPrimaryOrdersVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__PrimaryOrderDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('primaryOrders')
      .setReturnProperties(mapper)
      .declareVariables({
        filter: 'PrimaryOrderFilterDto',
        paging: 'PageableInput!',        
      })
      .setProperties(`filter: $filter, paging: $paging`)
      .build(),
    options
  );
}


export function useGetPrimaryOrdersOverview(
  mapper: PrimaryOrderOverviewDtoMapper,
  options?: QueryHookOptions<UseGetPrimaryOrdersOverviewData, UseGetPrimaryOrdersOverviewVariables>
): QueryResult<UseGetPrimaryOrdersOverviewData, UseGetPrimaryOrdersOverviewVariables> {
  const queryBuilder: GraphqlBuilder<PrimaryOrderOverviewDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('primaryOrdersOverview')
      .setReturnProperties(mapper)
      .declareVariables({
        filters: '[PrimaryOrderOverviewFilter]',        
      })
      .setProperties(`filters: $filters`)
      .build(),
    options
  );
}

export function useLazyGetPrimaryOrdersOverview(
  mapper: PrimaryOrderOverviewDtoMapper,
  options?: QueryHookOptions<UseGetPrimaryOrdersOverviewData, UseGetPrimaryOrdersOverviewVariables>
): QueryTuple<UseGetPrimaryOrdersOverviewData, UseGetPrimaryOrdersOverviewVariables> {
  const queryBuilder: GraphqlBuilder<PrimaryOrderOverviewDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('primaryOrdersOverview')
      .setReturnProperties(mapper)
      .declareVariables({
        filters: '[PrimaryOrderOverviewFilter]',        
      })
      .setProperties(`filters: $filters`)
      .build(),
    options
  );
}


export function useGetGetInvoices(
  mapper: PageableResponse__InvoiceMapper,
  options?: QueryHookOptions<UseGetGetInvoicesData, UseGetGetInvoicesVariables>
): QueryResult<UseGetGetInvoicesData, UseGetGetInvoicesVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__InvoiceMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('getInvoices')
      .setReturnProperties(mapper)
      .declareVariables({
        clientId: 'Long',
        filter: 'InvoiceFilterDto',
        paging: 'PageableInput!',        
      })
      .setProperties(`clientId: $clientId, filter: $filter, paging: $paging`)
      .build(),
    options
  );
}

export function useLazyGetGetInvoices(
  mapper: PageableResponse__InvoiceMapper,
  options?: QueryHookOptions<UseGetGetInvoicesData, UseGetGetInvoicesVariables>
): QueryTuple<UseGetGetInvoicesData, UseGetGetInvoicesVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__InvoiceMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('getInvoices')
      .setReturnProperties(mapper)
      .declareVariables({
        clientId: 'Long',
        filter: 'InvoiceFilterDto',
        paging: 'PageableInput!',        
      })
      .setProperties(`clientId: $clientId, filter: $filter, paging: $paging`)
      .build(),
    options
  );
}


export function useGetGetInvoiceDetail(
  mapper: InvoiceDetailMapper,
  options?: QueryHookOptions<UseGetGetInvoiceDetailData, UseGetGetInvoiceDetailVariables>
): QueryResult<UseGetGetInvoiceDetailData, UseGetGetInvoiceDetailVariables> {
  const queryBuilder: GraphqlBuilder<InvoiceDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('getInvoiceDetail')
      .setReturnProperties(mapper)
      .declareVariables({
        invoiceId: 'Long!',        
      })
      .setProperties(`invoiceId: $invoiceId`)
      .build(),
    options
  );
}

export function useLazyGetGetInvoiceDetail(
  mapper: InvoiceDetailMapper,
  options?: QueryHookOptions<UseGetGetInvoiceDetailData, UseGetGetInvoiceDetailVariables>
): QueryTuple<UseGetGetInvoiceDetailData, UseGetGetInvoiceDetailVariables> {
  const queryBuilder: GraphqlBuilder<InvoiceDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('getInvoiceDetail')
      .setReturnProperties(mapper)
      .declareVariables({
        invoiceId: 'Long!',        
      })
      .setProperties(`invoiceId: $invoiceId`)
      .build(),
    options
  );
}


export function useGetCandidateScreening(
  mapper: CandidateScreeningAdminDetailMapper,
  options?: QueryHookOptions<UseGetCandidateScreeningData, UseGetCandidateScreeningVariables>
): QueryResult<UseGetCandidateScreeningData, UseGetCandidateScreeningVariables> {
  const queryBuilder: GraphqlBuilder<CandidateScreeningAdminDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('candidateScreening')
      .setReturnProperties(mapper)
      .declareVariables({
        candidateScreeningId: 'Long!',        
      })
      .setProperties(`candidateScreeningId: $candidateScreeningId`)
      .build(),
    options
  );
}

export function useLazyGetCandidateScreening(
  mapper: CandidateScreeningAdminDetailMapper,
  options?: QueryHookOptions<UseGetCandidateScreeningData, UseGetCandidateScreeningVariables>
): QueryTuple<UseGetCandidateScreeningData, UseGetCandidateScreeningVariables> {
  const queryBuilder: GraphqlBuilder<CandidateScreeningAdminDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('candidateScreening')
      .setReturnProperties(mapper)
      .declareVariables({
        candidateScreeningId: 'Long!',        
      })
      .setProperties(`candidateScreeningId: $candidateScreeningId`)
      .build(),
    options
  );
}


export function useGetComponentDetail(
  mapper: ComponentDetailMapper,
  options?: QueryHookOptions<UseGetComponentDetailData, UseGetComponentDetailVariables>
): QueryResult<UseGetComponentDetailData, UseGetComponentDetailVariables> {
  const queryBuilder: GraphqlBuilder<ComponentDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('componentDetail')
      .setReturnProperties(mapper)
      .declareVariables({
        componentId: 'Long!',        
      })
      .setProperties(`componentId: $componentId`)
      .build(),
    options
  );
}

export function useLazyGetComponentDetail(
  mapper: ComponentDetailMapper,
  options?: QueryHookOptions<UseGetComponentDetailData, UseGetComponentDetailVariables>
): QueryTuple<UseGetComponentDetailData, UseGetComponentDetailVariables> {
  const queryBuilder: GraphqlBuilder<ComponentDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('componentDetail')
      .setReturnProperties(mapper)
      .declareVariables({
        componentId: 'Long!',        
      })
      .setProperties(`componentId: $componentId`)
      .build(),
    options
  );
}


export function useGetComponents(
  mapper: PageableResponse__ComponentListDetailMapper,
  options?: QueryHookOptions<UseGetComponentsData, UseGetComponentsVariables>
): QueryResult<UseGetComponentsData, UseGetComponentsVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__ComponentListDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('components')
      .setReturnProperties(mapper)
      .declareVariables({
        filter: 'ComponentsFilterDto',
        paging: 'PageableInput!',        
      })
      .setProperties(`filter: $filter, paging: $paging`)
      .build(),
    options
  );
}

export function useLazyGetComponents(
  mapper: PageableResponse__ComponentListDetailMapper,
  options?: QueryHookOptions<UseGetComponentsData, UseGetComponentsVariables>
): QueryTuple<UseGetComponentsData, UseGetComponentsVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse__ComponentListDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('components')
      .setReturnProperties(mapper)
      .declareVariables({
        filter: 'ComponentsFilterDto',
        paging: 'PageableInput!',        
      })
      .setProperties(`filter: $filter, paging: $paging`)
      .build(),
    options
  );
}


export function useGetProcessDefinitionModel(
  mapper: ProcessDefinitionModelDtoMapper,
  options?: QueryHookOptions<UseGetProcessDefinitionModelData, UseGetProcessDefinitionModelVariables>
): QueryResult<UseGetProcessDefinitionModelData, UseGetProcessDefinitionModelVariables> {
  const queryBuilder: GraphqlBuilder<ProcessDefinitionModelDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('processDefinitionModel')
      .setReturnProperties(mapper)
      .declareVariables({
        processDefinition: 'String!',        
      })
      .setProperties(`processDefinition: $processDefinition`)
      .build(),
    options
  );
}

export function useLazyGetProcessDefinitionModel(
  mapper: ProcessDefinitionModelDtoMapper,
  options?: QueryHookOptions<UseGetProcessDefinitionModelData, UseGetProcessDefinitionModelVariables>
): QueryTuple<UseGetProcessDefinitionModelData, UseGetProcessDefinitionModelVariables> {
  const queryBuilder: GraphqlBuilder<ProcessDefinitionModelDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('processDefinitionModel')
      .setReturnProperties(mapper)
      .declareVariables({
        processDefinition: 'String!',        
      })
      .setProperties(`processDefinition: $processDefinition`)
      .build(),
    options
  );
}


export function useGetProcessVariables(
  mapper: ProcessVariablesDtoMapper,
  options?: QueryHookOptions<UseGetProcessVariablesData, UseGetProcessVariablesVariables>
): QueryResult<UseGetProcessVariablesData, UseGetProcessVariablesVariables> {
  const queryBuilder: GraphqlBuilder<ProcessVariablesDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('processVariables')
      .setReturnProperties(mapper)
      .declareVariables({
        componentId: 'Long!',        
      })
      .setProperties(`componentId: $componentId`)
      .build(),
    options
  );
}

export function useLazyGetProcessVariables(
  mapper: ProcessVariablesDtoMapper,
  options?: QueryHookOptions<UseGetProcessVariablesData, UseGetProcessVariablesVariables>
): QueryTuple<UseGetProcessVariablesData, UseGetProcessVariablesVariables> {
  const queryBuilder: GraphqlBuilder<ProcessVariablesDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('processVariables')
      .setReturnProperties(mapper)
      .declareVariables({
        componentId: 'Long!',        
      })
      .setProperties(`componentId: $componentId`)
      .build(),
    options
  );
}


export function useGetIntegrations(
  mapper: CamelIntegrationDtoMapper,
  options?: QueryHookOptions<UseGetIntegrationsData, UseGetIntegrationsVariables>
): QueryResult<UseGetIntegrationsData, UseGetIntegrationsVariables> {
  const queryBuilder: GraphqlBuilder<CamelIntegrationDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('integrations')
      .setReturnProperties(mapper)
      .declareVariables({
        componentId: 'Long!',        
      })
      .setProperties(`componentId: $componentId`)
      .build(),
    options
  );
}

export function useLazyGetIntegrations(
  mapper: CamelIntegrationDtoMapper,
  options?: QueryHookOptions<UseGetIntegrationsData, UseGetIntegrationsVariables>
): QueryTuple<UseGetIntegrationsData, UseGetIntegrationsVariables> {
  const queryBuilder: GraphqlBuilder<CamelIntegrationDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('integrations')
      .setReturnProperties(mapper)
      .declareVariables({
        componentId: 'Long!',        
      })
      .setProperties(`componentId: $componentId`)
      .build(),
    options
  );
}


export function useGetComponentNotes(
  mapper: ComponentNoteMapper,
  options?: QueryHookOptions<UseGetComponentNotesData, UseGetComponentNotesVariables>
): QueryResult<UseGetComponentNotesData, UseGetComponentNotesVariables> {
  const queryBuilder: GraphqlBuilder<ComponentNoteMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('componentNotes')
      .setReturnProperties(mapper)
      .declareVariables({
        componentId: 'Long',        
      })
      .setProperties(`componentId: $componentId`)
      .build(),
    options
  );
}

export function useLazyGetComponentNotes(
  mapper: ComponentNoteMapper,
  options?: QueryHookOptions<UseGetComponentNotesData, UseGetComponentNotesVariables>
): QueryTuple<UseGetComponentNotesData, UseGetComponentNotesVariables> {
  const queryBuilder: GraphqlBuilder<ComponentNoteMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('componentNotes')
      .setReturnProperties(mapper)
      .declareVariables({
        componentId: 'Long',        
      })
      .setProperties(`componentId: $componentId`)
      .build(),
    options
  );
}


export function useGetComponentLogs(
  mapper: ComponentLogDtoMapper,
  options?: QueryHookOptions<UseGetComponentLogsData, UseGetComponentLogsVariables>
): QueryResult<UseGetComponentLogsData, UseGetComponentLogsVariables> {
  const queryBuilder: GraphqlBuilder<ComponentLogDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('componentLogs')
      .setReturnProperties(mapper)
      .declareVariables({
        componentId: 'Long!',        
      })
      .setProperties(`componentId: $componentId`)
      .build(),
    options
  );
}

export function useLazyGetComponentLogs(
  mapper: ComponentLogDtoMapper,
  options?: QueryHookOptions<UseGetComponentLogsData, UseGetComponentLogsVariables>
): QueryTuple<UseGetComponentLogsData, UseGetComponentLogsVariables> {
  const queryBuilder: GraphqlBuilder<ComponentLogDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('componentLogs')
      .setReturnProperties(mapper)
      .declareVariables({
        componentId: 'Long!',        
      })
      .setProperties(`componentId: $componentId`)
      .build(),
    options
  );
}


export function useGetComponentLogVariables(
  mapper: ComponentLogVariablesDtoMapper,
  options?: QueryHookOptions<UseGetComponentLogVariablesData, UseGetComponentLogVariablesVariables>
): QueryResult<UseGetComponentLogVariablesData, UseGetComponentLogVariablesVariables> {
  const queryBuilder: GraphqlBuilder<ComponentLogVariablesDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('componentLogVariables')
      .setReturnProperties(mapper)
      .declareVariables({
        processInstanceLogId: 'Long!',        
      })
      .setProperties(`processInstanceLogId: $processInstanceLogId`)
      .build(),
    options
  );
}

export function useLazyGetComponentLogVariables(
  mapper: ComponentLogVariablesDtoMapper,
  options?: QueryHookOptions<UseGetComponentLogVariablesData, UseGetComponentLogVariablesVariables>
): QueryTuple<UseGetComponentLogVariablesData, UseGetComponentLogVariablesVariables> {
  const queryBuilder: GraphqlBuilder<ComponentLogVariablesDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('componentLogVariables')
      .setReturnProperties(mapper)
      .declareVariables({
        processInstanceLogId: 'Long!',        
      })
      .setProperties(`processInstanceLogId: $processInstanceLogId`)
      .build(),
    options
  );
}


export function useGetResourceDatabaseSections(
  mapper: ResourceDatabaseSectionDetailMapper,
  options?: QueryHookOptions<UseGetResourceDatabaseSectionsData, UseGetResourceDatabaseSectionsVariables>
): QueryResult<UseGetResourceDatabaseSectionsData, UseGetResourceDatabaseSectionsVariables> {
  const queryBuilder: GraphqlBuilder<ResourceDatabaseSectionDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('resourceDatabaseSections')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetResourceDatabaseSections(
  mapper: ResourceDatabaseSectionDetailMapper,
  options?: QueryHookOptions<UseGetResourceDatabaseSectionsData, UseGetResourceDatabaseSectionsVariables>
): QueryTuple<UseGetResourceDatabaseSectionsData, UseGetResourceDatabaseSectionsVariables> {
  const queryBuilder: GraphqlBuilder<ResourceDatabaseSectionDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('resourceDatabaseSections')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetResourceDatabaseItems(
  mapper: ResourceDatabaseItemMapper,
  options?: QueryHookOptions<UseGetResourceDatabaseItemsData, UseGetResourceDatabaseItemsVariables>
): QueryResult<UseGetResourceDatabaseItemsData, UseGetResourceDatabaseItemsVariables> {
  const queryBuilder: GraphqlBuilder<ResourceDatabaseItemMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('resourceDatabaseItems')
      .setReturnProperties(mapper)
      .declareVariables({
        sectionName: 'String!',
        countryId: 'Long',
        search: 'String',        
      })
      .setProperties(`sectionName: $sectionName, countryId: $countryId, search: $search`)
      .build(),
    options
  );
}

export function useLazyGetResourceDatabaseItems(
  mapper: ResourceDatabaseItemMapper,
  options?: QueryHookOptions<UseGetResourceDatabaseItemsData, UseGetResourceDatabaseItemsVariables>
): QueryTuple<UseGetResourceDatabaseItemsData, UseGetResourceDatabaseItemsVariables> {
  const queryBuilder: GraphqlBuilder<ResourceDatabaseItemMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('resourceDatabaseItems')
      .setReturnProperties(mapper)
      .declareVariables({
        sectionName: 'String!',
        countryId: 'Long',
        search: 'String',        
      })
      .setProperties(`sectionName: $sectionName, countryId: $countryId, search: $search`)
      .build(),
    options
  );
}


export function useGetGetResourceDatabaseItem(
  mapper: ResourceDatabaseItemMapper,
  options?: QueryHookOptions<UseGetGetResourceDatabaseItemData, UseGetGetResourceDatabaseItemVariables>
): QueryResult<UseGetGetResourceDatabaseItemData, UseGetGetResourceDatabaseItemVariables> {
  const queryBuilder: GraphqlBuilder<ResourceDatabaseItemMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('getResourceDatabaseItem')
      .setReturnProperties(mapper)
      .declareVariables({
        id: 'Long!',        
      })
      .setProperties(`id: $id`)
      .build(),
    options
  );
}

export function useLazyGetGetResourceDatabaseItem(
  mapper: ResourceDatabaseItemMapper,
  options?: QueryHookOptions<UseGetGetResourceDatabaseItemData, UseGetGetResourceDatabaseItemVariables>
): QueryTuple<UseGetGetResourceDatabaseItemData, UseGetGetResourceDatabaseItemVariables> {
  const queryBuilder: GraphqlBuilder<ResourceDatabaseItemMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('getResourceDatabaseItem')
      .setReturnProperties(mapper)
      .declareVariables({
        id: 'Long!',        
      })
      .setProperties(`id: $id`)
      .build(),
    options
  );
}


export function useGetItemDetail(
  mapper: ResourceDatabaseItemDetailMapper,
  options?: QueryHookOptions<UseGetItemDetailData, UseGetItemDetailVariables>
): QueryResult<UseGetItemDetailData, UseGetItemDetailVariables> {
  const queryBuilder: GraphqlBuilder<ResourceDatabaseItemDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('itemDetail')
      .setReturnProperties(mapper)
      .declareVariables({
        itemId: 'Long!',        
      })
      .setProperties(`itemId: $itemId`)
      .build(),
    options
  );
}

export function useLazyGetItemDetail(
  mapper: ResourceDatabaseItemDetailMapper,
  options?: QueryHookOptions<UseGetItemDetailData, UseGetItemDetailVariables>
): QueryTuple<UseGetItemDetailData, UseGetItemDetailVariables> {
  const queryBuilder: GraphqlBuilder<ResourceDatabaseItemDetailMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('itemDetail')
      .setReturnProperties(mapper)
      .declareVariables({
        itemId: 'Long!',        
      })
      .setProperties(`itemId: $itemId`)
      .build(),
    options
  );
}


export function useGetGetFields(
  mapper: FormFieldDtoMapper,
  options?: QueryHookOptions<UseGetGetFieldsData, UseGetGetFieldsVariables>
): QueryResult<UseGetGetFieldsData, UseGetGetFieldsVariables> {
  const queryBuilder: GraphqlBuilder<FormFieldDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('getFields')
      .setReturnProperties(mapper)
      .declareVariables({
        candidateScreeningDetailId: 'Long!',
        fieldIds: '[String]',        
      })
      .setProperties(`candidateScreeningDetailId: $candidateScreeningDetailId, fieldIds: $fieldIds`)
      .build(),
    options
  );
}

export function useLazyGetGetFields(
  mapper: FormFieldDtoMapper,
  options?: QueryHookOptions<UseGetGetFieldsData, UseGetGetFieldsVariables>
): QueryTuple<UseGetGetFieldsData, UseGetGetFieldsVariables> {
  const queryBuilder: GraphqlBuilder<FormFieldDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('getFields')
      .setReturnProperties(mapper)
      .declareVariables({
        candidateScreeningDetailId: 'Long!',
        fieldIds: '[String]',        
      })
      .setProperties(`candidateScreeningDetailId: $candidateScreeningDetailId, fieldIds: $fieldIds`)
      .build(),
    options
  );
}


export function useGetCandidateScreeningCompletedDataGet(
  mapper: CandidateScreeningCompletedDataMapper,
  options?: QueryHookOptions<UseGetCandidateScreeningCompletedDataGetData, UseGetCandidateScreeningCompletedDataGetVariables>
): QueryResult<UseGetCandidateScreeningCompletedDataGetData, UseGetCandidateScreeningCompletedDataGetVariables> {
  const queryBuilder: GraphqlBuilder<CandidateScreeningCompletedDataMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('candidateScreeningCompletedDataGet')
      .setReturnProperties(mapper)
      .declareVariables({
        candidateScreeningId: 'Long!',        
      })
      .setProperties(`candidateScreeningId: $candidateScreeningId`)
      .build(),
    options
  );
}

export function useLazyGetCandidateScreeningCompletedDataGet(
  mapper: CandidateScreeningCompletedDataMapper,
  options?: QueryHookOptions<UseGetCandidateScreeningCompletedDataGetData, UseGetCandidateScreeningCompletedDataGetVariables>
): QueryTuple<UseGetCandidateScreeningCompletedDataGetData, UseGetCandidateScreeningCompletedDataGetVariables> {
  const queryBuilder: GraphqlBuilder<CandidateScreeningCompletedDataMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('candidateScreeningCompletedDataGet')
      .setReturnProperties(mapper)
      .declareVariables({
        candidateScreeningId: 'Long!',        
      })
      .setProperties(`candidateScreeningId: $candidateScreeningId`)
      .build(),
    options
  );
}


export function useGetFaqs(
  mapper: FAQMapper,
  options?: QueryHookOptions<UseGetFaqsData, UseGetFaqsVariables>
): QueryResult<UseGetFaqsData, UseGetFaqsVariables> {
  const queryBuilder: GraphqlBuilder<FAQMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('faqs')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetFaqs(
  mapper: FAQMapper,
  options?: QueryHookOptions<UseGetFaqsData, UseGetFaqsVariables>
): QueryTuple<UseGetFaqsData, UseGetFaqsVariables> {
  const queryBuilder: GraphqlBuilder<FAQMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('faqs')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetGetFilesInfo(
  mapper: FileInfoMapper,
  options?: QueryHookOptions<UseGetGetFilesInfoData, UseGetGetFilesInfoVariables>
): QueryResult<UseGetGetFilesInfoData, UseGetGetFilesInfoVariables> {
  const queryBuilder: GraphqlBuilder<FileInfoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('getFilesInfo')
      .setReturnProperties(mapper)
      .declareVariables({
        ids: '[Long]',        
      })
      .setProperties(`ids: $ids`)
      .build(),
    options
  );
}

export function useLazyGetGetFilesInfo(
  mapper: FileInfoMapper,
  options?: QueryHookOptions<UseGetGetFilesInfoData, UseGetGetFilesInfoVariables>
): QueryTuple<UseGetGetFilesInfoData, UseGetGetFilesInfoVariables> {
  const queryBuilder: GraphqlBuilder<FileInfoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('getFilesInfo')
      .setReturnProperties(mapper)
      .declareVariables({
        ids: '[Long]',        
      })
      .setProperties(`ids: $ids`)
      .build(),
    options
  );
}


export function useGetGetUploadedFilesInfo(
  mapper: FileInfoMapper,
  options?: QueryHookOptions<UseGetGetUploadedFilesInfoData, UseGetGetUploadedFilesInfoVariables>
): QueryResult<UseGetGetUploadedFilesInfoData, UseGetGetUploadedFilesInfoVariables> {
  const queryBuilder: GraphqlBuilder<FileInfoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('getUploadedFilesInfo')
      .setReturnProperties(mapper)
      .declareVariables({
        fileUploadId: 'String!',        
      })
      .setProperties(`fileUploadId: $fileUploadId`)
      .build(),
    options
  );
}

export function useLazyGetGetUploadedFilesInfo(
  mapper: FileInfoMapper,
  options?: QueryHookOptions<UseGetGetUploadedFilesInfoData, UseGetGetUploadedFilesInfoVariables>
): QueryTuple<UseGetGetUploadedFilesInfoData, UseGetGetUploadedFilesInfoVariables> {
  const queryBuilder: GraphqlBuilder<FileInfoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('getUploadedFilesInfo')
      .setReturnProperties(mapper)
      .declareVariables({
        fileUploadId: 'String!',        
      })
      .setProperties(`fileUploadId: $fileUploadId`)
      .build(),
    options
  );
}


