import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { H3, Note } from '@scaut-sro/meepo';
import { ListOfServicesProps } from './ListOfServices.model';
import { CountryProductsDto } from '../../build/generated-sources/dto/CountryProductsDto';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import ListOfServiceLoader from './ListOfServiceLoader';
import Translate from '../Translate/Translate';
import FormLocale from '../../core/localization/translations/Form.locale';

const ListOfServices: React.FunctionComponent<ListOfServicesProps> = (props) => {
  const {
    data, loading,
  } = props;
  const { language } = useGetUser();
  const openItems: number[] | undefined = data?.map((item, index) => index);

  return (
    <>
      { loading || !data ? (
        <ListOfServiceLoader />
      ) : (
        <Accordion defaultIndex={openItems} allowMultiple>
          {data.map((countryCatalogue: CountryProductsDto) => (
            <AccordionItem key={`catalogue_${countryCatalogue.country?.translation?.localizationStrings?.[language]}`}>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <H3 mt={3} mb={3}>{countryCatalogue.country?.translation?.localizationStrings?.[language]}</H3>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel p={6}>
                {countryCatalogue.screeningPackages?.map((screeningPackage) => (
                  <React.Fragment key={screeningPackage.id}>
                    <Box mb={3}>
                      <Flex>
                        <Text>{screeningPackage.name?.localizationStrings?.[language]}</Text>
                        <Spacer />
                        <Box ml={3}>
                          {`${screeningPackage.price} `}
                          <Translate label={FormLocale.CURRENCY} />
                        </Box>
                      </Flex>
                      {screeningPackage.screeningDefinitions?.map((screeningDefinition) => (
                        <Flex ml={6} key={screeningDefinition.id}>
                          <Box><Note>{screeningDefinition.name?.localizationStrings?.[language]}</Note></Box>
                        </Flex>
                      ))}
                    </Box>
                  </React.Fragment>
                ))}
                {countryCatalogue.screeningDefinitions?.map((screeningDefinition) => (
                  <React.Fragment key={screeningDefinition.id}>
                    <Flex mb={2}>
                      <Box><Text>{screeningDefinition.name?.localizationStrings?.[language]}</Text></Box>
                      <Spacer />
                      <Box ml={3}>
                        {`${screeningDefinition.price} `}
                        <Translate label={FormLocale.CURRENCY} />
                      </Box>
                    </Flex>
                  </React.Fragment>
                ))}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      )}
    </>
  );
};

export default ListOfServices;
