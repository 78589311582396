import React, { useCallback, useMemo } from 'react';
import {
  MeepoTable, SectionContainer,
} from '@scaut-sro/meepo';
import { useHistory } from 'react-router-dom';
import {
  Box, Button, Flex, Text,
} from '@chakra-ui/react';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import { ResourceDatabaseItemsData, ResourceDatabaseSectionDetailData } from '../ResourceDatabase.model';
import Translate from '../../../components/Translate/Translate';
import { translate } from '../../../core/localization/LocaleUtils';
import TableLocale from '../../../core/localization/translations/Table.locale';
import ResourceDatabaseLocale from '../ResourceDatabase.locale';
import ItemDetailModal from '../ItemDetailModal';
import { ResourceDatabaseItem } from '../../../build/generated-sources/dto/ResourceDatabaseItem';

const DatabaseSectionDetail: React.FunctionComponent<ResourceDatabaseSectionDetailData> = (props) => {
  const { sectionDetail } = props;
  const history = useHistory();
  const { language } = useGetUser();

  const openItemDetail = useCallback((itemId?: number) => {
    if (itemId) {
      history.push(`/database/item/${itemId}`);
    } else {
      history.push('/404');
    }
  }, [history]);

  const tableData: ResourceDatabaseItemsData[] = useMemo(() => {
    if (sectionDetail && sectionDetail.section && sectionDetail.section.resourceDatabaseItems) {
      return sectionDetail.section.resourceDatabaseItems.map((item: ResourceDatabaseItem) => ({
        id: item.id || '-undefined-',
        name: item.name,
        country: item?.enumeration?.translation?.localizationStrings?.[language],
        buttons: (
          <Button
            variant="link"
            w="100%"
            onClick={() => openItemDetail(item?.id)}
          >
            <Translate label={ResourceDatabaseLocale.TABLE_DETAIL_GO_TO_DETAIL} />
          </Button>
        ),
      }));
    }
    return [];
  }, [sectionDetail, openItemDetail, language]);

  return (
    <>
      <SectionContainer>
        <Flex justifyContent="flex-end">
          <ItemDetailModal sectionDetail={sectionDetail} />
        </Flex>
        <Box overflowX="auto">
          <Box minW="600px">
            <MeepoTable
              columns={[
                { id: 'name', label: translate(ResourceDatabaseLocale.NAME, language) },
                { id: 'country', label: translate(ResourceDatabaseLocale.COUNTRY, language) },
                { id: 'buttons', boxProps: { w: '150px' } },
              ]}
              data={tableData}
              noData={<Text><Translate label={TableLocale.NO_DATA} /></Text>}
            />
          </Box>
        </Box>
      </SectionContainer>
    </>
  );
};

export default DatabaseSectionDetail;
