import React, {
  useEffect, useMemo, useState,
} from 'react';
import {
  MeepoTable, mergeFilters, SectionContainer, Toast,
} from '@scaut-sro/meepo';
import {
  Box, Button, Checkbox, Text,
} from '@chakra-ui/react';
import { useRouteMatch } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import {
  ComponentsTableData, TasksAllFilterType, ComponentsTableProps, TasksTableFilterType, ViewType, TasksRouteFilter,
} from './Tasks.model';
import TasksTableFilter from './TasksTableFilter';
import { useGetComponents } from '../../build/generated-sources/service/QueryService';
import { translate } from '../../core/localization/LocaleUtils';
import TasksLocale from './Tasks.locale';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import TableLocale from '../../core/localization/translations/Table.locale';
import Translate from '../../components/Translate/Translate';
import { Direction } from '../../build/generated-sources/enum/Direction';
import { ComponentDetail } from '../../build/generated-sources/dto/ComponentDetail';
import { StateBadge } from '../../components/StateBadge/StateBadge';
import { ResultBadge } from '../../components/ResultBadge/ResultBadge';
import TasksBulkChangeModal from './TasksBulkChangeModal';
import { State } from '../../build/generated-sources/enum/State';
import { AssigneeType } from '../../build/generated-sources/enum/AssigneeType';
import ScautAdminRoles from '../../core/auth/ScautAdminRoles';

export function getComponentsTableData(
  viewType: ViewType,
  openCandidateScreeningDetail: (componentDefinitionId?: number) => void,
  componentIds: Array<number>, setComponentIds: React.Dispatch<React.SetStateAction<Array<number>>>,
  data: ComponentDetail[] | undefined,
): ComponentsTableData[] {
  const fillComponentIds = (componentId: number, e: any) => {
    const newComponentIds = componentIds;
    if (e.target.checked && !newComponentIds.find((id) => id === componentId)) {
      newComponentIds.push(componentId);
    } else if (!e.target.checked && newComponentIds.find((id) => id === componentId)) {
      const index = newComponentIds.indexOf(componentId);
      newComponentIds.splice(index, 1);
    }
    setComponentIds(newComponentIds.concat());
  };

  if (data) {
    return data.map((componentDetail) => ({
      checkbox: <Checkbox
        onChange={(e) => {
          if (componentDetail.component?.id) {
            fillComponentIds(componentDetail.component.id, e);
          }
        }}
      />,
      id: componentDetail?.component?.id || '-undefined-',
      name: componentDetail.component?.componentDefinition?.internalName,
      candidate: `${componentDetail.candidateScreening?.candidateIdentity?.firstName}
       ${componentDetail.candidateScreening?.candidateIdentity?.lastName}`,
      orderId: componentDetail.orderId,
      state: <StateBadge state={componentDetail.component?.state || undefined} />,
      result: <ResultBadge result={componentDetail?.component?.result || undefined} />,
      assignee: componentDetail.component?.assignee
        ? `${componentDetail.component?.assignee?.firstName} ${componentDetail.component?.assignee?.lastName}` : '',
      buttons: (
        <Button
          variant="link"
          w="100%"
          onClick={() => openCandidateScreeningDetail(viewType === ViewType.CANDIDATE_SCREENING
            ? componentDetail.candidateScreening?.id : componentDetail.component?.id)}
        >
          <Translate label={TasksLocale.TABLE_DETAIL_GO_TO_DETAIL} />
        </Button>
      ),
    }));
  }
  return [];
}

const TasksTable: React.FunctionComponent<ComponentsTableProps> = (props) => {
  const { params } = useRouteMatch<{ filter: TasksRouteFilter }>();
  const { keycloak } = useKeycloak();
  const isSysAdmin = keycloak.hasResourceRole(ScautAdminRoles.SYSADMIN, 'scaut-admin-app');
  const isManager = keycloak.hasResourceRole(ScautAdminRoles.MANAGER, 'scaut-admin-app');

  const filters: TasksTableFilterType = useMemo(() => {
    if (params.filter) {
      if (params.filter === 'open') {
        return {
          states: [
            State.NOT_STARTED,
            State.STARTED,
            State.WAITING_FOR_CANDIDATE,
            State.WAITING_FOR_CLIENT,
            State.WAITING_FOR_ADMIN,
            State.WAITING_FOR_POA,
            State.CANNOT_COMPLETE,
          ],
        };
      } if (params.filter === 'assigned-to-me') {
        return {
          states: [
            State.NOT_STARTED,
            State.STARTED,
            State.WAITING_FOR_CANDIDATE,
            State.WAITING_FOR_CLIENT,
            State.WAITING_FOR_ADMIN,
            State.WAITING_FOR_POA,
            State.CANNOT_COMPLETE,
          ],
        };
      } if (params.filter === 'unassigned') {
        return {
          states: [
            State.NOT_STARTED,
            State.STARTED,
            State.WAITING_FOR_CANDIDATE,
            State.WAITING_FOR_CLIENT,
            State.WAITING_FOR_ADMIN,
            State.WAITING_FOR_POA,
            State.CANNOT_COMPLETE,
          ],
        };
      }
    }
    return {
      states: [],
    };
  }, [params.filter]);

  const [tableFilter, setTableFilter] = useState<TasksTableFilterType>({});
  const [allFilters, setAllFilters] = useState<TasksAllFilterType>({});
  const {
    openDetail, detailColumnTitle, viewType,
  } = props;
  const { language, userProfile } = useGetUser();
  const [componentIds, setComponentIds] = useState<Array<number>>([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [page, setPage] = useState<number>(0);
  const [list, setList] = useState<ComponentsTableData[]>([]);
  const [sorting, setSorting] = useState<{ sortBy?: string, sortDirection?: Direction }>({});
  const size = 100;

  useEffect(() => {
    if (componentIds.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [componentIds, setIsDisabled]);

  const { loading, refetch } = useGetComponents(
    {
      content: {
        component: {
          id: true,
          state: true,
          result: true,
          assignee: {
            firstName: true,
            lastName: true,
          },
          componentDefinition: {
            internalName: true,
          },
        },
        candidateScreening: {
          id: true,
          candidateIdentity: {
            firstName: true,
            lastName: true,
          },
        },
        orderId: true,
      },
    }, {
      onError: () => {
        Toast({
          title: translate(TasksLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      },
      variables: {
        filter: {
          states: allFilters.states && allFilters.states.length ? allFilters.states : undefined,
          search: allFilters.fulltext && allFilters.fulltext.length ? allFilters.fulltext : undefined,
          orderId: allFilters.orderId && allFilters.orderId ? allFilters.orderId : undefined,
          client: allFilters.client && allFilters.client.length ? allFilters.client : undefined,
          candidate: allFilters.candidate && allFilters.candidate.length ? allFilters.candidate : undefined,
          assigneeIds: allFilters.assigneeIds && allFilters.assigneeIds.length
            ? allFilters.assigneeIds : undefined,
          assigneeType: allFilters.assigneeType ? allFilters.assigneeType : undefined,
        },
        paging: {
          page,
          size,
          sortBy: sorting.sortBy,
          sortDirection: sorting.sortDirection,
        },
      },
      fetchPolicy: 'cache-and-network',
      pollInterval: 5000,
      onCompleted: (response) => {
        const onCompleteData = getComponentsTableData(viewType, openDetail, componentIds, setComponentIds,
          response.components.content);
        if (page === 0) {
          setList(onCompleteData);
        } else {
          setList((prev) => [...prev, ...onCompleteData]);
        }
      },
    },
  );

  useMemo(() => {
    const newTableFilter: TasksAllFilterType = {
      ...tableFilter,
    };
    if (params.filter && params.filter === 'assigned-to-me') {
      newTableFilter.assigneeIds = [userProfile?.id || -1];
      newTableFilter.assigneeType = undefined;
    } else if (params.filter && params.filter === 'unassigned') {
      newTableFilter.assigneeIds = [];
      newTableFilter.assigneeType = AssigneeType.NONE;
    } else {
      newTableFilter.assigneeType = undefined;
    }
    const newAllFilters: TasksAllFilterType = mergeFilters(newTableFilter, filters) as TasksAllFilterType;
    setAllFilters(newAllFilters);
    setPage(0);
  }, [filters, tableFilter, params.filter, userProfile?.id]);

  const refreshTable = () => {
    setList([]);
    setIsDisabled(true);
    refetch();
  };

  return (
    <SectionContainer>
      <Box mb={20}>
        <TasksTableFilter
          routeFilter={params.filter}
          filter={tableFilter}
          onFilterChange={(newTableFilter) => {
            setTableFilter(newTableFilter);
          }}
        />
      </Box>
      <Box overflowX="auto">
        <Box minW="500px">
          {isManager || isSysAdmin ? (
            <Box
              display="flex"
              justifyContent="flex-end"
              mb={4}
            >
              <TasksBulkChangeModal
                isDisabled={isDisabled}
                componentIds={componentIds}
                refreshTable={refreshTable}
              />
            </Box>
          ) : undefined }
          <MeepoTable
            columns={[
              { id: 'checkbox' },
              { id: 'candidate', label: translate(TasksLocale.CANDIDATE, language) },
              { id: 'name', label: translate(TasksLocale.LABEL, language) },
              { id: 'orderId', label: translate(TasksLocale.ORDER_ID, language) },
              { id: 'assignee', label: translate(TasksLocale.ASSIGNEE, language), minW: '180px' },
              {
                id: 'state',
                label: translate(TasksLocale.STATE, language),
                alignLabel: 'center',
                boxProps: {
                  w: '220px',
                },
              },
              {
                id: 'result',
                label: translate(TasksLocale.RESULT, language),
                alignLabel: 'center',
                boxProps: {
                  w: '220px',
                },
              },
              {
                id: 'buttons',
                label: translate(detailColumnTitle, language),
                minW: '150px',
                boxProps: { w: '200px' },
                alignLabel: 'center',
              },
            ]}
            data={list}
            alwaysOpen
            isLoading={loading}
            noData={<Text><Translate label={TableLocale.NO_DATA} /></Text>}
            setPage={() => {
              setPage((prev) => prev + 1);
            }}
            onSort={(newSorting) => {
              const direction: Direction | undefined = newSorting.type === 'asc'
                ? Direction.ASC
                : newSorting.type === 'desc'
                  ? Direction.DESC
                  : undefined;
              setSorting({
                sortBy: newSorting.column,
                sortDirection: direction,
              });
            }}
            activeSorting={{
              column: sorting.sortBy,
              type: sorting.sortDirection === Direction.ASC
                ? 'asc'
                : sorting.sortDirection === Direction.DESC
                  ? 'desc'
                  : 'none',
            }}
          />
        </Box>
      </Box>
    </SectionContainer>
  );
};

export default TasksTable;
