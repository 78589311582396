import React, {
  ChangeEvent, useEffect, useMemo, useState,
} from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  SimpleGrid,
  Switch,
  Text,
  Select as Select2,
  VStack,
} from '@chakra-ui/react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  getOption, H4, MeepoTable, Toast,
} from '@scaut-sro/meepo';
import { useHistory } from 'react-router-dom';
import { Select } from 'chakra-react-select';
import { useApolloClient } from '@apollo/client';
import { format } from 'react-string-format';
import { CatalogueComponentDetailProps, ComponentDefinitionFormAttr } from '../CatalogueComponents.model';
import Translate from '../../../../components/Translate/Translate';
import { translate } from '../../../../core/localization/LocaleUtils';
import { useGetUser } from '../../../../core/store/reducers/UserSettingsReducer';
import { useComponentDefinition } from '../../../../build/generated-sources/service/MutationService';
import CatalogueComponentsLocale from '../CatalogueComponents.locale';
import { Country } from '../../../../build/generated-sources/dto/Country';
import {
  useGetCountries, useGetPoaReports,
  useGetProcessDefinitions,
  useLazyGetProcessDefinitionModel,
} from '../../../../build/generated-sources/service/QueryService';
import Workflow from '../../../../components/Workflow/Workflow';
import FormLocale from '../../../../core/localization/translations/Form.locale';
import { PowerOfAttorney } from '../../../../build/generated-sources/enum/PowerOfAttorney';
import { secondsToTime } from '../../../../core/date/showDate';
import { Authorization } from '../../../../build/generated-sources/enum/Authorization';
import TableLocale from '../../../../core/localization/translations/Table.locale';
import TranslationChooseModal from '../../../Translations/TranslationChooseModal/TranslationChooseModal';
import { Translation } from '../../../../build/generated-sources/dto/Translation';
import { Language } from '../../../../build/generated-sources/enum/Language';
import { JasperReport } from '../../../../build/generated-sources/dto/JasperReport';
import { UseComponentDefinitionVariables } from '../../../../build/generated-sources/service/MutationServiceModel';
import { getComponentDefScreeningDefs } from '../../../../core/service/queries/ScreeningCatalogueService';

const createComponentDefinitionMapper = {
  id: true,
  internalName: true,
  processDefinition: true,
  averageRuntime: true,
  internalExpenses: true,
  externalExpenses: true,
  fees: true,
  requiresPowerOfAttorney: true,
  requiresAuthorization: true,
  poaAuthorityTexts: {
    id: true,
  },
  authorizationTexts: {
    id: true,
  },
  dynamicFields: {
    id: true,
  },
  performableAsGroup: true,
};

const CatalogueComponentDetailForm: React.FunctionComponent<CatalogueComponentDetailProps> = (props) => {
  const {
    componentDefinition, loadingSetter, formRef, cancelChangesTrigger,
  } = props;
  const history = useHistory();
  const { language } = useGetUser();
  const client = useApolloClient();
  const [countryList, setCountryList] = useState<Country[]>([]);
  const [processDefinitionList, setProcessDefinitionList] = useState<string[]>([]);
  const [processDefinitionId, setProcessDefinitionId] = useState<string | undefined>(undefined);
  const [poaTexts, setPoaTexts] = useState<number[]>([]);
  const [authorizationTexts, setAuthorizationTexts] = useState<number[]>([]);
  const [dynamicFields, setDynamicFields] = useState<number[]>([]);
  const [powerOfAttorneyValue, setPowerOfAttorneyValue] = useState<PowerOfAttorney>(
    componentDefinition?.requiresPowerOfAttorney || PowerOfAttorney.NO,
  );
  const [jasperReportLanguage, setPoaJasperReportLanguage] = useState<string | undefined>(
    componentDefinition?.poaJasperReportLanguage,
  );
  const [poaReport, setPoaReport] = useState<string>(componentDefinition?.poaJasperReport as string);
  const [poaReports, setPoaReports] = useState<JasperReport[]>([]);

  useGetPoaReports({
    name: true,
  }, {
    onCompleted: (data) => {
      setPoaReports(data.poaReports);
    },
  });

  const [authorizationValue, setAuthorizationValue] = useState<Authorization>(
    componentDefinition?.requiresAuthorization || Authorization.NO,
  );

  const requiresPoaOptions = useMemo(() => [
    { value: PowerOfAttorney.YES, label: translate(CatalogueComponentsLocale.POWER_OF_ATTORNEY_YES, language) },
    { value: PowerOfAttorney.NO, label: translate(CatalogueComponentsLocale.POWER_OF_ATTORNEY_NO, language) },
    {
      value: PowerOfAttorney.CONDITIONAL,
      label: translate(CatalogueComponentsLocale.POWER_OF_ATTORNEY_CONDITIONAL, language),
    },
  ], [language]);

  const requiresAuthorizationOptions = useMemo(() => [
    { value: Authorization.YES, label: translate(CatalogueComponentsLocale.POWER_OF_ATTORNEY_YES, language) },
    { value: Authorization.NO, label: translate(CatalogueComponentsLocale.POWER_OF_ATTORNEY_NO, language) },
  ], [language]);

  const defaultValues: Partial<ComponentDefinitionFormAttr> = useMemo(() => ({
    id: componentDefinition?.id,
    internalName: componentDefinition?.internalName,
    active: componentDefinition ? componentDefinition.active : true,
    requiresPowerOfAttorney: componentDefinition?.requiresPowerOfAttorney
      ? getOption(componentDefinition?.requiresPowerOfAttorney, requiresPoaOptions)
      : undefined,
    requiresAuthorization: componentDefinition?.requiresAuthorization
      ? getOption(componentDefinition?.requiresAuthorization, requiresAuthorizationOptions)
      : undefined,
    performableAsGroup: componentDefinition?.performableAsGroup,
    processDefinition: componentDefinition?.processDefinition
      ? { value: componentDefinition?.processDefinition, label: componentDefinition?.processDefinition }
      : undefined,
    internalExpenses: componentDefinition?.internalExpenses,
    externalExpenses: componentDefinition?.externalExpenses,
    fees: componentDefinition?.fees,
    averageRuntime: componentDefinition?.averageRuntime
      ? secondsToTime(componentDefinition?.averageRuntime) : undefined,
    countryId: componentDefinition?.country?.id ? {
      value: componentDefinition?.country?.id || -1,
      label: componentDefinition?.country?.translation?.localizationStrings?.[language] || '',
    } : undefined,

  }), [componentDefinition, requiresPoaOptions, requiresAuthorizationOptions, language]);

  useEffect(() => {
    setProcessDefinitionId(componentDefinition?.processDefinition);
  }, [componentDefinition?.processDefinition, setProcessDefinitionId]);

  const {
    handleSubmit, register, formState: { errors }, control, setValue, reset,
  } = useForm<Partial<ComponentDefinitionFormAttr>>({
    defaultValues,
  });

  const countryOptions = useMemo(() => (countryList.map((c) => ({
    value: c.id,
    label: c?.translation?.localizationStrings?.[language],
  }))), [countryList, language]);

  useEffect(() => {
    reset(defaultValues);
    setProcessDefinitionId(componentDefinition?.processDefinition);
  }, [cancelChangesTrigger, reset, defaultValues, setValue, componentDefinition]);

  const [process, setProcess] = useState<string | undefined>(undefined);

  const [getLazyProcessDefinition] = useLazyGetProcessDefinitionModel({
    processDefinitionModel: true,
  }, {
    onCompleted: (response) => {
      setProcess(response.processDefinitionModel.processDefinitionModel);
    },
    // TODO: for some reason cach-first fetch policy doesn't work well.
    //  It doesn't fetch right process when all processes are cached.
    //  I changed fetchPolicy to 'cache-and-network' as a workaround but it's not ideal.
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (processDefinitionId) {
      getLazyProcessDefinition({
        variables: {
          processDefinition: processDefinitionId,
        },
      });
    }
  }, [getLazyProcessDefinition, processDefinitionId]);

  useGetCountries(
    {
      id: true,
      translation: {
        id: true,
        localizationStrings: true,
      },
    }, {
      onError: () => {
        Toast({
          title: translate(CatalogueComponentsLocale.ERROR_GETTING_COUNTRIES, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setCountryList(response.countries);
      },
    },
  );

  useGetProcessDefinitions(
    {
      names: true,
    }, {
      onError: () => {
        Toast({
          title: translate(CatalogueComponentsLocale.ERROR_GETTING_PROCESS_DEFINITIONS, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setProcessDefinitionList(response?.processDefinitions?.names || []);
      },
    },
  );

  const processDefinitionOptions = useMemo(() => processDefinitionList.map((p) => ({
    value: p,
    label: p,
  })), [processDefinitionList]);

  const [mutateCreateComponentDefinition,
    optionsMutationComponentDefinition] = useComponentDefinition(createComponentDefinitionMapper, {
    onCompleted: (response) => {
      Toast({
        title: translate(FormLocale.SUCCESSFULLY_SAVED, language),
        status: 'success',
      });
      history.push(`/catalogue/component/${response.componentDefinition.id}`);
    },
    onError: () => {
      Toast({
        title: translate(CatalogueComponentsLocale.ERROR_GETTING_DATA, language),
        status: 'error',
      });
    },
  });

  useEffect(() => {
    if (loadingSetter) loadingSetter(optionsMutationComponentDefinition.loading);
  }, [loadingSetter, optionsMutationComponentDefinition]);

  const handleComponentDefinitionFormSubmit: SubmitHandler<ComponentDefinitionFormAttr> = (values) => {
    const variables: UseComponentDefinitionVariables = {
      componentDefinition: {
        id: componentDefinition?.id,
        internalName: values.internalName,
        active: values.active,
        countryId: values.countryId?.value,
        processDefinition: values.processDefinition.value,
        requiresPowerOfAttorney: values.requiresPowerOfAttorney.value,
        requiresAuthorization: values.requiresAuthorization.value,
        poaJasperReport: poaReport,
        poaJasperReportLanguage: jasperReportLanguage ? jasperReportLanguage as Language : undefined,
        authorizationTexts,
        poaAuthorityTexts: poaTexts,
        dynamicFields,
        performableAsGroup: values.performableAsGroup,
        internalExpenses: values.internalExpenses ? values.internalExpenses : undefined,
        externalExpenses: values.externalExpenses ? values.externalExpenses : undefined,
        fees: values.fees ? values.fees : undefined,
      },
    };

    if (componentDefinition && componentDefinition.active !== values.active) {
      getComponentDefScreeningDefs(client, {
        id: true,
        internalName: true,
      }, {
        componentDefinitionId: componentDefinition.id || -1,
      }).then((response) => {
        if (response.data.componentDefinitionScreeningDefinitions.length > 0) {
          const ret = response.data.componentDefinitionScreeningDefinitions.map((x) => x.internalName).join(', ');
          const errorMessage = format(translate(CatalogueComponentsLocale.ERROR_COMPONENT_DEFINITION_SAVE, language),
            ret);
          Toast({
            title: translate(CatalogueComponentsLocale.ERROR_COMPONENT_DEFINITION_SAVE_TITLE, language),
            description: errorMessage,
            status: 'error',
          });
        } else {
          mutateCreateComponentDefinition({ variables });
        }
      });
    } else {
      mutateCreateComponentDefinition({ variables });
    }
  };

  useEffect(() => {
    if (componentDefinition?.poaAuthorityTexts) {
      setPoaTexts(componentDefinition?.poaAuthorityTexts.map((translation) => translation.id as number));
    }
    if (componentDefinition?.authorizationTexts) {
      setAuthorizationTexts(componentDefinition?.authorizationTexts
        .map((translation) => translation.id as number));
    }
    if (componentDefinition?.dynamicFields) {
      setDynamicFields(componentDefinition?.dynamicFields
        .map((translation) => translation.id as number));
    }
  }, [componentDefinition]);

  const addEmptyPoaText = () => {
    poaTexts.push(-1);
    setPoaTexts([...poaTexts]);
  };

  const removePoaText = (index: number) => {
    poaTexts.splice(index, 1);
    setPoaTexts([...poaTexts]);
  };

  const modifyPoaText = (translation: Translation, index: number) => {
    const newList = [...poaTexts];
    newList[index] = translation.id as number;
    setPoaTexts(newList);
  };

  const addEmptyAuthorizationText = () => {
    authorizationTexts.push(-1);
    setAuthorizationTexts([...authorizationTexts]);
  };

  const removeAuthorizationText = (index: number) => {
    authorizationTexts.splice(index, 1);
    setAuthorizationTexts([...authorizationTexts]);
  };

  const modifyAuthorizationText = (translation: Translation, index: number) => {
    const newList = [...authorizationTexts];
    newList[index] = translation.id as number;
    setAuthorizationTexts(newList);
  };

  const addEmptyDynamicField = () => {
    dynamicFields.push(-1);
    setDynamicFields([...dynamicFields]);
  };

  const removeDynamicField = (index: number) => {
    dynamicFields.splice(index, 1);
    setDynamicFields([...dynamicFields]);
  };

  const modifyDynamicField = (translation: Translation, index: number) => {
    const newList = [...dynamicFields];
    newList[index] = translation.id as number;
    setDynamicFields(newList);
  };

  // @ts-ignore
  return (
    <>
      <form
        name="componentDefinitionForm"
        onSubmit={handleSubmit(handleComponentDefinitionFormSubmit)}
        ref={formRef}
      >
        <SimpleGrid columns={[1, 1, 1, 2, 2, 2]} spacing={6}>
          <Box>
            <SimpleGrid columns={[1, 1, 1, 2, 2]} spacing={6}>
              <FormControl isRequired isInvalid={!!errors.internalName}>
                <FormLabel htmlFor="internalName">
                  <Translate label={CatalogueComponentsLocale.INTERNAL_NAME} />
                </FormLabel>
                <Input
                  placeholder={translate(CatalogueComponentsLocale.INTERNAL_NAME, language)}
                  {...register('internalName', {
                    required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                  })}
                />
                <FormErrorMessage>
                  {errors.internalName && errors.internalName.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={!!errors.processDefinition}>
                <FormLabel htmlFor="processDefinition">
                  <Translate label={CatalogueComponentsLocale.PROCESS_DEFINITION} />
                </FormLabel>
                <Controller
                  name="processDefinition"
                  control={control}
                  render={({ field }) => (
                    <Select
                      hideSelectedOptions={false}
                      selectedOptionStyle="check"
                      {...field}
                      options={processDefinitionOptions}
                      onChange={(selected: { value: string, label: string }) => {
                        setValue('processDefinition', selected);
                        setProcessDefinitionId(selected.value);
                      }}
                    />
                  )}
                  rules={{
                    required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                  }}
                />
                <FormErrorMessage>
                  {errors.processDefinition && errors.processDefinition.value && errors.processDefinition.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={!!errors.requiresPowerOfAttorney}>
                <FormLabel htmlFor="requiresPowerOfAttorney">
                  <Translate label={CatalogueComponentsLocale.REQUIRES_POWER_OF_ATTORNEY} />
                </FormLabel>
                <Controller
                  name="requiresPowerOfAttorney"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={requiresPoaOptions}
                      hideSelectedOptions={false}
                      selectedOptionStyle="check"
                      onChange={(selected: { value: PowerOfAttorney, label: string }) => {
                        setValue('requiresPowerOfAttorney', selected);
                        setPowerOfAttorneyValue(selected.value);
                      }}
                    />
                  )}
                  rules={{
                    required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                  }}
                />
                <FormErrorMessage>
                  {errors.requiresPowerOfAttorney && errors.requiresPowerOfAttorney.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={!!errors.requiresAuthorization}>
                <FormLabel htmlFor="requiresAuthorization">
                  <Translate label={CatalogueComponentsLocale.REQUIRES_AUTHORIZATION} />
                </FormLabel>
                <Controller
                  name="requiresAuthorization"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={requiresAuthorizationOptions}
                      hideSelectedOptions={false}
                      selectedOptionStyle="check"
                      onChange={(selected: { value: Authorization, label: string }) => {
                        setValue('requiresAuthorization', selected);
                        setAuthorizationValue(selected.value);
                      }}
                    />
                  )}
                  rules={{
                    required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                  }}
                />
                <FormErrorMessage>
                  {errors.requiresAuthorization && errors.requiresAuthorization.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isRequired isInvalid={!!errors.countryId}>
                <FormLabel htmlFor="countryId">
                  <Translate label={CatalogueComponentsLocale.COUNTRY} />
                </FormLabel>
                <Controller
                  name="countryId"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={countryOptions}
                      hideSelectedOptions={false}
                      selectedOptionStyle="check"
                    />
                  )}
                  rules={{
                    required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                  }}
                />
                <FormErrorMessage>
                  {errors.countryId && errors.countryId.message}
                </FormErrorMessage>
              </FormControl>

            </SimpleGrid>
            <SimpleGrid columns={[1, 1, 1, 2, 2, 3]} spacing={6} mt={8}>

              <FormControl>
                <FormLabel htmlFor="internalExpenses">
                  <Translate label={CatalogueComponentsLocale.INTERNAL_EXPENSES} />
                </FormLabel>
                <InputGroup>
                  <Input
                    type="number"
                    placeholder={translate(CatalogueComponentsLocale.INTERNAL_EXPENSES, language)}
                    {...register('internalExpenses')}
                  />
                  <InputRightAddon>EUR</InputRightAddon>
                </InputGroup>
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="externalExpenses">
                  <Translate label={CatalogueComponentsLocale.EXTERNAL_EXPENSES} />
                </FormLabel>
                <InputGroup>
                  <Input
                    type="number"
                    placeholder={translate(CatalogueComponentsLocale.EXTERNAL_EXPENSES, language)}
                    {...register('externalExpenses')}
                  />
                  <InputRightAddon>EUR</InputRightAddon>
                </InputGroup>
              </FormControl>

              <FormControl>
                <Flex>
                  <FormLabel htmlFor="fees">
                    <Translate label={CatalogueComponentsLocale.FEES} />
                  </FormLabel>
                </Flex>
                <InputGroup>
                  <Input
                    type="number"
                    placeholder={translate(CatalogueComponentsLocale.FEES, language)}
                    {...register('fees')}
                  />
                  <InputRightAddon>EUR</InputRightAddon>
                </InputGroup>
              </FormControl>

              {componentDefinition?.id ? (
                <FormControl>
                  <Flex>
                    <FormLabel htmlFor="averageRuntime">
                      <Translate label={CatalogueComponentsLocale.AVERAGE_RUNTIME} />
                    </FormLabel>
                  </Flex>
                  <InputGroup>
                    <Input
                      type="string"
                      readOnly
                      {...register('averageRuntime')}
                    />
                  </InputGroup>
                </FormControl>
              ) : undefined }

            </SimpleGrid>
            <VStack mt={8} spacing={6}>
              <FormControl display="flex" justifyContent="space-between">
                <label htmlFor="performableAsGroup">{translate(CatalogueComponentsLocale.PERFORMABLE_AS_GROUP, language)}</label>
                <Controller
                  control={control}
                  name="performableAsGroup"
                  render={({ field }) => (
                    <Switch
                      onChange={(e) => { field.onChange(e.target.checked); }}
                      isChecked={field.value}
                    />
                  )}
                />
              </FormControl>
            </VStack>
            <VStack mt={8} spacing={6}>
              <FormControl
                display="flex"
                justifyContent="space-between"
              >
                <label htmlFor="active">{translate(CatalogueComponentsLocale.ACTIVE, language)}</label>
                <Controller
                  control={control}
                  name="active"
                  render={({ field }) => (
                    <Switch
                      onChange={(e) => { field.onChange(e.target.checked); }}
                      isChecked={field.value}
                    />
                  )}
                />
              </FormControl>
            </VStack>

            <Box
              border="solid 1px"
              borderRadius="10px"
              borderColor="grey"
              padding="20px"
              margin="20px"
              marginTop="30px"
            >
              <H4><Translate label={CatalogueComponentsLocale.DYNAMIC_FIELDS} /></H4>
              <Button margin="10px" onClick={addEmptyDynamicField}>
                <Translate label={CatalogueComponentsLocale.ADD_FIELD} />
              </Button>
              <MeepoTable
                columns={[
                  { id: 'translation' },
                  { id: 'remove' },
                ]}
                data={dynamicFields.map((dynamicField, index) => ({
                  id: index,
                  dynamicField,
                  index,
                  remove: (
                    <Button
                      colorScheme="red"
                      variant="outline"
                      onClick={() => removeDynamicField(index)}
                    >
                      <Translate label={CatalogueComponentsLocale.REMOVE_FIELD} />
                    </Button>
                  ),
                  translation: (
                    <TranslationChooseModal
                      id={`${index}`}
                      translationId={dynamicField === -1 ? undefined : dynamicField}
                      setValue={(translation) => modifyDynamicField(translation, index)}
                    />
                  ),
                }))}
                noData={<Text><Translate label={TableLocale.NO_DATA} /></Text>}
              />
            </Box>

            {
              powerOfAttorneyValue
              && (powerOfAttorneyValue === PowerOfAttorney.CONDITIONAL || powerOfAttorneyValue === PowerOfAttorney.YES)
                ? (
                  <>
                    <Box
                      border="solid 1px"
                      borderRadius="10px"
                      borderColor="grey"
                      padding="20px"
                      margin="20px"
                    >
                      <H4><Translate label={CatalogueComponentsLocale.POA_TEXTS} /></H4>
                      <Button margin="10px" onClick={addEmptyPoaText}>
                        <Translate label={CatalogueComponentsLocale.ADD_TEXT} />
                      </Button>
                      <MeepoTable
                        columns={[
                          { id: 'translation' },
                          { id: 'remove' },
                        ]}
                        data={poaTexts.map((poaText, index) => ({
                          id: index,
                          poaText,
                          index,
                          remove: (
                            <Button colorScheme="red" variant="outline" onClick={() => removePoaText(index)}>
                              <Translate label={CatalogueComponentsLocale.REMOVE_TEXT} />
                            </Button>
                          ),
                          translation: (
                            <TranslationChooseModal
                              id={`${index}`}
                              translationId={poaText === -1 ? undefined : poaText}
                              setValue={(translation) => modifyPoaText(translation, index)}
                            />
                          ),
                        }))}
                        noData={<Text><Translate label={TableLocale.NO_DATA} /></Text>}
                      />
                      <Box pt="15px">
                        <H4><Translate label={CatalogueComponentsLocale.POA_SETTINGS} /></H4>
                        <Box m="15px">
                          <Box>
                            <FormLabel htmlFor="poaLanguage">
                              <Translate label={CatalogueComponentsLocale.POA_LANGUAGE} />
                            </FormLabel>
                            <Select2
                              onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                                setPoaJasperReportLanguage(event.target.value);
                              }}
                              value={jasperReportLanguage}
                            >
                              <option value="">- Select value -</option>
                              {
                                Object.keys(Language).map((enumPoaLanguage) => (
                                  <option key={enumPoaLanguage} value={enumPoaLanguage}>
                                    {enumPoaLanguage}
                                  </option>
                                ))
                              }
                            </Select2>
                          </Box>
                          <Box>
                            <FormLabel htmlFor="poaReport">
                              <Translate label={CatalogueComponentsLocale.POA_REPORT} />
                            </FormLabel>
                            <Select2
                              onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                                setPoaReport(event.target.value);
                              }}
                              value={poaReport}
                            >
                              <option value="">- Select value -</option>
                              {
                                poaReports.map((jasperReport) => (
                                  <option key={jasperReport.name} value={jasperReport.name}>
                                    {jasperReport.name}
                                  </option>
                                ))
                              }
                            </Select2>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </>
                ) : (<></>)
            }

            {
              authorizationValue
              && (authorizationValue === Authorization.YES) ? (
                <>
                  <Box
                    border="solid 1px"
                    borderRadius="10px"
                    borderColor="grey"
                    padding="20px"
                    margin="20px"
                  >
                    <H4><Translate label={CatalogueComponentsLocale.AUTHORIZATION_TEXTS} /></H4>
                    <Button margin="10px" onClick={addEmptyAuthorizationText}>
                      <Translate label={CatalogueComponentsLocale.ADD_TEXT} />
                    </Button>
                    <MeepoTable
                      columns={[
                        { id: 'translation' },
                        { id: 'remove' },
                      ]}
                      data={authorizationTexts.map((authorizationText, index) => ({
                        id: index,
                        authorizationText,
                        index,
                        remove: (
                          <Button
                            colorScheme="red"
                            variant="outline"
                            onClick={() => removeAuthorizationText(index)}
                          >
                            <Translate label={CatalogueComponentsLocale.REMOVE_TEXT} />
                          </Button>
                        ),
                        translation: (
                          <TranslationChooseModal
                            id={`${index}`}
                            translationId={authorizationText === -1 ? undefined : authorizationText}
                            setValue={(translation) => modifyAuthorizationText(translation, index)}
                          />
                        ),
                      }))}
                      noData={<Text><Translate label={TableLocale.NO_DATA} /></Text>}
                    />
                  </Box>
                </>
                ) : (<></>)
            }
          </Box>

          <Workflow diagram={process} />
        </SimpleGrid>
      </form>
    </>
  );
};

export default CatalogueComponentDetailForm;
