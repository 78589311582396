import { Translation } from '../Localization.model';

class FormLocale {
  public static CONFIRM: Translation = { EN: 'Confirm changes', CZ: 'Potvrdit změny' };
  public static CHOOSE_TRANSLATION: Translation = { EN: 'Choose this translation', CZ: 'Vybrat překlad' };
  public static INVALID_CHARACTERS: Translation = { EN: 'Invalid characters', CZ: 'Neplatné znaky' };
  public static ERROR: Translation = { EN: 'Error', CZ: 'Chyba' };
  public static SUCCESS: Translation = { EN: 'Success', CZ: 'Úspěch' };
  public static CONTINUE: Translation = { EN: 'Continue', CZ: 'Pokračovat' };
  public static BACK: Translation = { EN: 'Back', CZ: 'Zpět' };
  public static PAY_ORDER: Translation = { EN: 'Pay order', CZ: 'Zaplatit objednávku' };
  public static NO_VALUE: Translation = { EN: '- No value -', CZ: '- Žádná hodnota -' };
  public static SEND: Translation = { EN: 'Send', CZ: 'Odeslat' };

  public static ERROR_MANDATORY_FIELD: Translation = { EN: 'Mandatory attribute', CZ: 'Povinný atribut' };
  public static ERROR_INVALID_CHARACTERS: Translation = { EN: 'Invalid characters', CZ: 'Neplatné znaky' };
  public static ERROR_INVALID_EMAIL: Translation = { EN: 'Invalid email', CZ: 'Email není validní' };
  public static ERROR_PHONE_MAX_LENGTH: Translation = {
    EN: 'Phone has to have less than 15 numbers', CZ: 'Telefon musí mít méně než 15 číslic',
  };
  public static ERROR_PHONE_MIN_LENGTH: Translation = {
    EN: 'Phone has to have more than 8 numbers', CZ: 'Telefon musí mít více jak 8 číslic',
  };
  public static ERROR_INVALID_FORMAT: Translation = { EN: 'Invalid format', CZ: 'Neplatný formát' };
  public static ERROR_INVALID_NATIONAL_ID_NUMBER: Translation = {
    EN: 'Invalid National ID number',
    CZ: 'Neplatné rodné číslo',
  };
  public static CANCEL: Translation = { EN: 'Cancel', CZ: 'Zrušit' };
  public static NEW: Translation = { EN: 'New', CZ: 'Nová' };
  public static EDIT: Translation = { EN: 'Edit', CZ: 'Upravit' };
  public static SAVE: Translation = { EN: 'Save', CZ: 'Uložit' };
  public static DELETE: Translation = { EN: 'Delete', CZ: 'Smazat' };
  public static DOWNLOAD: Translation = { EN: 'Download', CZ: 'Stáhnout' };
  public static YES: Translation = { EN: 'Yes', CZ: 'Ano' };
  public static NO: Translation = { EN: 'No', CZ: 'Ne' };
  public static CURRENCY: Translation = { EN: 'CZK', CZ: 'Kč' };
  public static SUCCESSFULLY_SAVED: Translation = {
    EN: 'Successfully saved',
    CZ: 'Úspěšně uloženo',
  };

  public static AVATAR_UPLOAD: Translation =
  {
    EN: 'Upload your avatar image as PNG or JPG. Maximum size of the image is 10MB',
    CZ: 'Nahrajte svůj avatar obrázek jako PNG or JPG. Maximální velikost obrázku je 10MB',
  };
}

export default FormLocale;
