import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, FormControl, FormErrorMessage, FormLabel, GridItem, SimpleGrid,
} from '@chakra-ui/react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { getOption, Toast } from '@scaut-sro/meepo';
import { Select } from 'chakra-react-select';
import { translate } from '../../core/localization/LocaleUtils';
import FormLocale from '../../core/localization/translations/Form.locale';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { useEnumerationCreateOrEdit } from '../../build/generated-sources/service/MutationService';
import { EnumerationsDetailProps, EnumerationsFormAttr } from './Enumeratons.model';
import { EnumerationType } from '../../build/generated-sources/enum/EnumerationType';
import EnumerationsLocale from './Enumerations.locale';
import Translate from '../../components/Translate/Translate';
import TranslationChooseModal from '../Translations/TranslationChooseModal/TranslationChooseModal';
import { Translation } from '../../build/generated-sources/dto/Translation';
import CountryAlphaCode from './CountriesAlphaCode';
import { handleApolloErrors } from '../../core/error/ScautErrorHandler';

const createEnumerationsMapper = {
  id: true,
};

const EnumerationsDetailForm: React.FunctionComponent<EnumerationsDetailProps> = (props) => {
  const {
    enumeration, loadingSetter, formRef, cancelChangesTrigger, refreshTable,
  } = props;
  const [enumerationType, setEnumerationType] = useState(enumeration?.type);
  const { language } = useGetUser();

  const typeOptions = useMemo(() => ([
    { value: EnumerationType.COUNTRY, label: translate(EnumerationsLocale.COUNTRY, language) },
    { value: EnumerationType.SURVEY, label: translate(EnumerationsLocale.SURVEY, language) },
  ]), [language]);

  const defaultValues: Partial<EnumerationsFormAttr> = useMemo(() => ({
    id: enumeration?.id,
    enumerationType: enumeration?.type
      ? getOption(enumeration?.type, typeOptions)
      : undefined,
    value: {
      value: enumeration?.value || '',
      label: enumeration?.value || '',
    },
    translationId: enumeration?.translation?.id || -1,
  }), [enumeration, typeOptions]);

  const {
    handleSubmit, setValue, reset, formState: { errors }, control, getValues, register,
  } = useForm<Partial<EnumerationsFormAttr>>({
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [cancelChangesTrigger, reset, setValue, defaultValues]);

  const [mutateEnumeration,
    optionsMutationEnumeration] = useEnumerationCreateOrEdit(createEnumerationsMapper, {
    onCompleted: () => {
      Toast({
        title: translate(FormLocale.SUCCESSFULLY_SAVED, language),
        status: 'success',
      });

      if (refreshTable) {
        refreshTable();
      }
    },
    onError: (error) => {
      handleApolloErrors(error, language);
    },
  });

  useEffect(() => {
    if (loadingSetter) loadingSetter(optionsMutationEnumeration.loading);
  }, [loadingSetter, optionsMutationEnumeration]);

  const handleEnumerationsFormSubmit: SubmitHandler<EnumerationsFormAttr> = (values) => {
    mutateEnumeration({
      variables: {
        enumerationId: enumeration?.id,
        enumeration: {
          value: values.value?.value,
          type: values.enumerationType?.value,
          translationId: values?.translationId,
        },
      },
    });
  };

  const setTranslationId = (translation: Translation) => {
    setValue('translationId', translation.id);
  };

  return (
    <>
      <form
        name="enumerationsDetailForm"
        onSubmit={handleSubmit(handleEnumerationsFormSubmit)}
        ref={formRef}
      >
        <SimpleGrid columns={[1, 1, 1, 3, 3, 3]} spacing={6} mb={7}>
          <GridItem colSpan={[1, 1, 1, 2, 2, 2]}>
            <FormControl mb={6} isInvalid={!!errors.translationId}>
              <FormLabel htmlFor="translationId">
                <Translate label={EnumerationsLocale.TRANSLATION_NAME} />
              </FormLabel>
              <TranslationChooseModal
                translationId={getValues('translationId')}
                setValue={setTranslationId}
                type="string"
                {...register('translationId', {
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                })}
              />
            </FormControl>
            <Box>
              <FormControl mb={6}>
                <FormLabel htmlFor="enumerationType">
                  <Translate label={EnumerationsLocale.TYPE} />
                </FormLabel>
                <Controller
                  name="enumerationType"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={typeOptions}
                      hideSelectedOptions={false}
                      selectedOptionStyle="check"
                      onChange={(value: any) => {
                        setEnumerationType(value.value);
                        setValue('enumerationType', value);
                      }}
                    />
                  )}
                />
              </FormControl>
            </Box>
            {enumerationType === 'COUNTRY' ? (
              <Box>
                <FormControl mb={6} isInvalid={!!errors.value}>
                  <FormLabel htmlFor="value">
                    <Translate label={EnumerationsLocale.COUNTRY_CODE} />
                  </FormLabel>

                  <Controller
                    name="value"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={CountryAlphaCode.map((country) => (
                          { label: country.code, value: country.code }
                        ))}
                        onChange={(value: any) => {
                          setValue('value', value);
                        }}
                        hideSelectedOptions={false}
                        selectedOptionStyle="check"
                      />
                    )}
                    rules={{
                      required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                    }}
                  />
                  <FormErrorMessage>
                    {errors.value && errors.value.value && errors.value.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>
            ) : undefined}
          </GridItem>
        </SimpleGrid>
      </form>
    </>
  );
};
export default EnumerationsDetailForm;
