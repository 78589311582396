import { Translation } from '../../../../core/localization/Localization.model';

class ClientInvoicesLocale {
  public static INVOICE_STATE_OPEN: Translation = { EN: 'To be paid', CZ: 'K zaplacení' };
  public static INVOICE_STATE_PAID: Translation = { EN: 'Paid', CZ: 'Zaplaceno' };
  public static ISSUE_DATE: Translation = { EN: 'Issue date', CZ: 'Vystaveno' };
  public static DUE_DATE: Translation = { EN: 'Due date', CZ: 'Splatnost' };
  public static PRICE: Translation = { EN: 'Price', CZ: 'Cena' };
  public static STATE: Translation = { EN: 'State', CZ: 'Stav' };
  public static INVOICE_DETAIL: Translation = { EN: 'Invoice', CZ: 'Faktura' };
  public static GO_TO_DETAIL: Translation = { EN: 'Go to detail', CZ: 'Přejít na detail' };
  public static STATES: Translation = { EN: 'States', CZ: 'Stavy' };
  public static PAY_INVOICE: Translation = { EN: 'Pay invoice', CZ: 'Zaplatit fakturu' };
  public static EMAIL_NOTIFICATION_SENT_AT: Translation = { EN: 'Email notification sent', CZ: 'Emailová notifikace odeslaná' };
  public static RESEND_EMAIL: Translation = { EN: 'Resend email', CZ: 'Znovu odeslat email' };
  public static ERROR_GETTING_DATA: Translation = {
    EN: 'There was a problem getting invoices',
    CZ: 'Při získávání faktur došlo k problému',
  };
}

export default ClientInvoicesLocale;
