import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  ApolloClient, ApolloLink, ApolloProvider, createHttpLink, InMemoryCache,
} from '@apollo/client';
import { withScalars } from 'apollo-link-scalars';
import { KeycloakInstance } from 'keycloak-js';
import TagManager from 'react-gtm-module';
import { setContext } from '@apollo/client/link/context';
import debounce from 'lodash.debounce';
import keycloakClient from './keycloak';
import ScautAdminTheme from './theme/ScautAdminTheme';
import AppStore from './core/store/DefaultStore';
import { useUserSettingsReduce } from './core/store/reducers/UserSettingsReducer';
import AppLayout from './components/AppLayout/AppLayout';
import LoadingScreen from './views/LoadingScreen/LoadingScreen';
import { schema } from './build/generated-sources/scalars/ScalarConstants';
import { useGetUserProfile } from './build/generated-sources/service/QueryService';
import Registration from './views/Registration/Registration';
import UserSettings from './views/UserSettings/UserSettings';
import Overview from './views/Overview/Overview';
import NoMatch from './views/404/404';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import ScautAdminRoles from './core/auth/ScautAdminRoles';
import { Language } from './build/generated-sources/enum/Language';
import Checks from './views/Checks/Checks';
import MultipleCheckDetail from './views/MultipleCheckDetail/MultipleCheckDetail';
import CandidateScreeningDetail from './views/CandidateScreeningDetail/CandidateScreeningDetail';
import OrderDetail from './views/OrderDetail/OrderDetail';
import CataloguePackages from './views/Catalogue/CataloguePackages/CataloguePackages';
import CatalogueScreenings from './views/Catalogue/CatalogueScreenings/CatalogueScreenings';
import CatalogueComponents from './views/Catalogue/CatalogueComponents/CatalogueComponents';
import CatalogueComponentDetail
  from './views/Catalogue/CatalogueComponents/CatalogueComponentDetail/CatalogueComponentDetail';
import CatalogueScreeningDetail
  from './views/Catalogue/CatalogueScreenings/CatalogueScreeningDetail/CatalogueScreeningDetail';
import CataloguePackageDetail from './views/Catalogue/CataloguePackages/CataloguePackageDetail/CataloguePackageDetail';
import Tasks from './views/Tasks/Tasks';
import ResourceDatabase from './views/ResourceDatabase/ResourceDatabase';
import ItemDetail from './views/ResourceDatabase/Detail/ItemDetail';
import Users from './views/Users/Users';
import Clients from './views/Clients/Clients';
import UserDetail from './views/Users/UserDetail/UserDetail';
import ClientUserDetail from './views/Clients/ClientDetail/ClientUsersDetail/ClientUserDetail';
import ClientDetail from './views/Clients/ClientDetail/ClientDetail/ClientDetail';
import Translations from './views/Translations/Translations';
import Countries from './views/Countries/Countries';
import CountriesDetail from './views/Countries/CountriesDetail/CountriesDetail';
import Faq from './views/Faq/Faq';
import Components from './views/Tasks/Components/Components';
import ComponentDetail from './views/Tasks/Components/ComponentDetail';
import ClientInvoiceDetail from './views/Clients/ClientDetail/ClientInvoices/ClientInvoiceDetail';
import Enumerations from './views/Enumerations/Enumerations';
import { ProcessUpload } from './views/ProcessUpload/ProcessUpload';
import Invoices from './views/Invoices/Invoices';

const Root: React.FunctionComponent = ({ children }) => {
  const { setUserProfile, setLanguage } = useUserSettingsReduce();
  const { loading } = useGetUserProfile({
    id: true,
    avatarThumbnail: true,
    firstName: true,
    lastName: true,
    email: true,
    avatar: true,
    userPreferences: {
      language: true,
    },
  }, {
    onCompleted: (data) => {
      setUserProfile(data.userProfile);
      const lang: Language = data.userProfile.userPreferences?.language || Language.EN;
      setLanguage(lang);
    },
  });

  return (
    <>
      {loading ? <LoadingScreen /> : <AppLayout>{ children }</AppLayout>}
    </>
  );
};

interface ConnectionWrapperProps {
  keycloak?: KeycloakInstance,
  initialized?: boolean
}

const ConnectionWrapper: React.FunctionComponent<ConnectionWrapperProps> = (
  {
    children,
    keycloak,
    initialized,
  },
) => {
  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API_URL}/graphql`,
  });

  const logoutDebounce = keycloak ? debounce(() => {
    keycloak.logout({
      redirectUri: `${window.location.protocol}//${window.location.host}`,
    });
  }, 3600000) : undefined;

  const authLink = setContext(async (_, { headers }) => {
    try {
      await keycloak?.updateToken(1);
      if (logoutDebounce) {
        logoutDebounce();
      }
      const authorizationHeader = initialized ? { authorization: `Bearer ${keycloak?.token}` } : {};
      return {
        headers: {
          ...headers,
          ...authorizationHeader,
        },
      };
    } catch (error) {
      keycloak?.logout();
      return undefined;
    }
  });

  const link = ApolloLink.from([
    withScalars({ schema }),
    authLink,
    httpLink,
  ].concat());

  const client = new ApolloClient({
    uri: `${process.env.REACT_APP_API_URL}/graphql`,
    cache: new InMemoryCache(),
    link,
  });

  return (
    <ApolloProvider client={client}>
      { children }
    </ApolloProvider>
  );
};

ConnectionWrapper.defaultProps = {
  initialized: false,
  keycloak: undefined,
};

const PublicApp: React.FunctionComponent = ({
  children,
}) => (
  <ConnectionWrapper>
    {children}
  </ConnectionWrapper>
);

const AuthorizedConnectionWrapper: React.FunctionComponent = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();

  return (
    <>
      { initialized ? (
        <ConnectionWrapper initialized={initialized} keycloak={keycloak}>
          { children }
        </ConnectionWrapper>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
};

const PrivateApp: React.FunctionComponent = ({ children }) => (
  <ReactKeycloakProvider
    authClient={keycloakClient}
    autoRefreshToken={false}
    initOptions={{ onLoad: 'login-required' }}
  >
    <AuthorizedConnectionWrapper>
      <Root>
        { children }
      </Root>
    </AuthorizedConnectionWrapper>
  </ReactKeycloakProvider>
);

function App() {
  React.useEffect(() => {
    TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_TAG || '' });
  }, []);

  return (
    <Provider store={AppStore}>
      <ChakraProvider theme={ScautAdminTheme}>
        <Router>
          <Switch>
            <Route path="/registration">
              <PublicApp>
                <Registration />
              </PublicApp>
            </Route>
            <Route path="*">
              <PrivateApp>
                <Switch>
                  <PrivateRoute path="/catalogue/packages" requiredRoles={[ScautAdminRoles.MANAGER]}>
                    <CataloguePackages />
                  </PrivateRoute>
                  <PrivateRoute path="/catalogue/package/:id" requiredRoles={[ScautAdminRoles.MANAGER]}>
                    <CataloguePackageDetail />
                  </PrivateRoute>
                  <PrivateRoute path="/catalogue/screenings" requiredRoles={[ScautAdminRoles.MANAGER]}>
                    <CatalogueScreenings />
                  </PrivateRoute>
                  <PrivateRoute path="/catalogue/screening/:id" requiredRoles={[ScautAdminRoles.MANAGER]}>
                    <CatalogueScreeningDetail />
                  </PrivateRoute>
                  <PrivateRoute path="/catalogue/components" requiredRoles={[ScautAdminRoles.MANAGER]}>
                    <CatalogueComponents />
                  </PrivateRoute>
                  <PrivateRoute path="/catalogue/component/:id" requiredRoles={[ScautAdminRoles.MANAGER]}>
                    <CatalogueComponentDetail />
                  </PrivateRoute>
                  <PrivateRoute path="/user-settings" requiredRoles={[ScautAdminRoles.OPERATOR]}>
                    <UserSettings />
                  </PrivateRoute>
                  <PrivateRoute path="/checks/:filter" requiredRoles={[ScautAdminRoles.OPERATOR]}>
                    <Checks />
                  </PrivateRoute>
                  <PrivateRoute path="/checks/open" requiredRoles={[ScautAdminRoles.MANAGER]}>
                    <Checks />
                  </PrivateRoute>
                  <PrivateRoute path="/checks/assigned-to-me" requiredRoles={[ScautAdminRoles.OPERATOR]}>
                    <Checks />
                  </PrivateRoute>
                  <PrivateRoute path="/checks/unassigned" requiredRoles={[ScautAdminRoles.MANAGER]}>
                    <Checks />
                  </PrivateRoute>
                  <PrivateRoute path="/checks" requiredRoles={[ScautAdminRoles.OPERATOR]}>
                    <Checks />
                  </PrivateRoute>
                  <PrivateRoute path="/users" requiredRoles={[ScautAdminRoles.SYSADMIN]}>
                    <Users />
                  </PrivateRoute>
                  <PrivateRoute path="/countries" requiredRoles={[ScautAdminRoles.MANAGER]}>
                    <Countries />
                  </PrivateRoute>
                  <PrivateRoute path="/country/:id" requiredRoles={[ScautAdminRoles.MANAGER]}>
                    <CountriesDetail />
                  </PrivateRoute>
                  <PrivateRoute path="/translations" requiredRoles={[ScautAdminRoles.MANAGER]}>
                    <Translations />
                  </PrivateRoute>
                  <PrivateRoute path="/clients" requiredRoles={[ScautAdminRoles.MANAGER]}>
                    <Clients />
                  </PrivateRoute>
                  <PrivateRoute path="/faq" requiredRoles={[ScautAdminRoles.MANAGER]}>
                    <Faq />
                  </PrivateRoute>
                  <PrivateRoute path="/process-upload" requiredRoles={[ScautAdminRoles.SYSADMIN]}>
                    <ProcessUpload />
                  </PrivateRoute>
                  <PrivateRoute path="/enumerations" requiredRoles={[ScautAdminRoles.MANAGER]}>
                    <Enumerations />
                  </PrivateRoute>
                  <PrivateRoute path="/user/:id" requiredRoles={[ScautAdminRoles.SYSADMIN]}>
                    <UserDetail />
                  </PrivateRoute>
                  <PrivateRoute path="/client/:id" requiredRoles={[ScautAdminRoles.MANAGER]}>
                    <ClientDetail />
                  </PrivateRoute>
                  <PrivateRoute path="/client-user/:id" requiredRoles={[ScautAdminRoles.MANAGER]}>
                    <ClientUserDetail />
                  </PrivateRoute>
                  <PrivateRoute path="/invoice/:id" requiredRoles={[ScautAdminRoles.MANAGER]}>
                    <ClientInvoiceDetail />
                  </PrivateRoute>
                  <PrivateRoute path="/tasks/:filter" requiredRoles={[ScautAdminRoles.OPERATOR]}>
                    <Tasks />
                  </PrivateRoute>
                  <PrivateRoute path="/tasks/open" requiredRoles={[ScautAdminRoles.MANAGER]}>
                    <Tasks />
                  </PrivateRoute>
                  <PrivateRoute path="/tasks/assigned-to-me" requiredRoles={[ScautAdminRoles.OPERATOR]}>
                    <Tasks />
                  </PrivateRoute>
                  <PrivateRoute path="/tasks/unassigned" requiredRoles={[ScautAdminRoles.MANAGER]}>
                    <Tasks />
                  </PrivateRoute>
                  <PrivateRoute path="/components" requiredRoles={[ScautAdminRoles.SYSADMIN]}>
                    <Components />
                  </PrivateRoute>
                  <PrivateRoute path="/component/:id" requiredRoles={[ScautAdminRoles.SYSADMIN]}>
                    <ComponentDetail />
                  </PrivateRoute>
                  <PrivateRoute exact path="/order/:id" requiredRoles={[ScautAdminRoles.OPERATOR]}>
                    <OrderDetail />
                  </PrivateRoute>
                  <PrivateRoute exact path="/multicheck/:id" requiredRoles={[ScautAdminRoles.OPERATOR]}>
                    <MultipleCheckDetail />
                  </PrivateRoute>
                  <PrivateRoute exact path="/screening/:id" requiredRoles={[ScautAdminRoles.OPERATOR]}>
                    <CandidateScreeningDetail />
                  </PrivateRoute>
                  <PrivateRoute exact path="/database" requiredRoles={[ScautAdminRoles.OPERATOR]}>
                    <ResourceDatabase />
                  </PrivateRoute>
                  <PrivateRoute exact path="/database/item/:id" requiredRoles={[ScautAdminRoles.OPERATOR]}>
                    <ItemDetail />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/all-invoices"
                    requiredRoles={[ScautAdminRoles.MANAGER, ScautAdminRoles.ACCOUNTANT]}
                  >
                    <Invoices />
                  </PrivateRoute>
                  <PrivateRoute exact path="/" requiredRoles={[ScautAdminRoles.OPERATOR]}>
                    <Overview />
                  </PrivateRoute>
                  <PrivateRoute path="*" requiredRoles={[ScautAdminRoles.OPERATOR]}>
                    <NoMatch />
                  </PrivateRoute>
                </Switch>
              </PrivateApp>
            </Route>
          </Switch>
        </Router>
      </ChakraProvider>
    </Provider>
  );
}

export default App;
