import React, { useEffect, useMemo } from 'react';
import {
  FormControl, FormErrorMessage, FormLabel, Input,
} from '@chakra-ui/react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Toast, Translate, translate } from '@scaut-sro/meepo';
import { Select } from 'chakra-react-select';
import { useGetUser } from '../../../../../core/store/reducers/UserSettingsReducer';
import ClientsLocale from '../../../Clients.locale';
import { usePayInvoice } from '../../../../../build/generated-sources/service/MutationService';
import FormLocale from '../../../../../core/localization/translations/Form.locale';
import { PaymentType } from '../../../../../build/generated-sources/enum/PaymentType';
import { ClientPayInvoiceAttr, ClientPayInvoiceProps } from '../ClientInvoices.model';

const PayInvoiceForm: React.FunctionComponent<ClientPayInvoiceProps> = (props) => {
  const {
    loadingSetter, formRef, invoiceId, refreshData,
  } = props;
  const { language } = useGetUser();

  const {
    handleSubmit, formState: { errors }, register, control,
  } = useForm<Partial<ClientPayInvoiceAttr>>();

  const paymentTypeOptionsList = useMemo(() => ([
    { value: 'CASH', label: translate(ClientsLocale.CASH, language) },
    { value: 'CHECK', label: translate(ClientsLocale.CHECK, language) },
    { value: 'OTHER', label: translate(ClientsLocale.OTHER, language) },
  ]), [language]);

  const [mutatePayInvoice,
    mutatePayInvoiceOptions] = usePayInvoice({
    success: true,
  }, {
    onCompleted: () => {
      Toast({
        title: translate(FormLocale.SUCCESSFULLY_SAVED, language),
        status: 'success',
      });
      refreshData();
    },
    onError: () => {
      Toast({
        title: translate(ClientsLocale.ERROR_SAVING_DATA, language),
        status: 'error',
      });
    },
  });

  useEffect(() => {
    if (loadingSetter) loadingSetter(mutatePayInvoiceOptions.loading);
  }, [loadingSetter, mutatePayInvoiceOptions]);

  const handlePayInvoiceFormSubmit: SubmitHandler<ClientPayInvoiceAttr> = (values) => {
    mutatePayInvoice({
      variables: {
        invoiceId: invoiceId || -1,
        paymentType: values.paymentType?.value as PaymentType,
        note: values.note,
      },
    });
  };

  return (
    <>
      <form
        name="clientPayInvoiceForm"
        onSubmit={handleSubmit(handlePayInvoiceFormSubmit)}
        ref={formRef}
      >
        <FormControl>
          <FormLabel><Translate label={ClientsLocale.PAYMENT_TYPE} /></FormLabel>
          <Controller
            name="paymentType"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={paymentTypeOptionsList}
                hideSelectedOptions={false}
                selectedOptionStyle="check"
              />
            )}
          />
        </FormControl>
        <FormControl isInvalid={!!errors.note}>
          <FormLabel htmlFor="note">
            <Translate label={ClientsLocale.NOTE} />
          </FormLabel>
          <Input
            placeholder={translate(ClientsLocale.NOTE, language)}
            {...register('note', {
              required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
            })}
          />
          <FormErrorMessage>
            {errors.note && errors.note.message}
          </FormErrorMessage>
        </FormControl>
      </form>
    </>
  );
};
export default PayInvoiceForm;
