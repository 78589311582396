import React, { useMemo, useState } from 'react';
import {
  H1, MeepoTable, SectionContainer, Toast,
} from '@scaut-sro/meepo';
import { useHistory } from 'react-router-dom';
import {
  Box, Button, Checkbox,
  Flex,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { PageInfo } from '@scaut-sro/meepo/lib/components/MeepoTable/MeepoTable.model';
import { useGetComponentDefinitions } from '../../../build/generated-sources/service/QueryService';
import { Direction } from '../../../build/generated-sources/enum/Direction';
import {
  CatalogueComponentsAllFilterType, CatalogueComponentsProps,
  CatalogueComponentsTableData,
  CatalogueComponentsTableFilterType,
} from './CatalogueComponents.model';
import { ComponentDefinition } from '../../../build/generated-sources/dto/ComponentDefinition';
import CatalogueComponentsTableFilter from './CatalogueComponentsTableFilter';
import { translate } from '../../../core/localization/LocaleUtils';
import CatalogueComponentsLocale from './CatalogueComponents.locale';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import TableLocale from '../../../core/localization/translations/Table.locale';
import Translate from '../../../components/Translate/Translate';
import DetailHeader from '../../../components/DetailHeader/DetailHeader';
import CatalogueComponentsModal from './CatalogueComponentsModal';
import { Language } from '../../../build/generated-sources/enum/Language';
import { secondsToTime } from '../../../core/date/showDate';

export function getCatalogueComponentsTableData(openComponentDetail: (componentDefinitionId?: number) => void, language: Language,
  data: ComponentDefinition[] | undefined): CatalogueComponentsTableData[] {
  if (data) {
    return data.map((componentDefinition) => ({
      id: componentDefinition.id || '-undefined-',
      internalName: componentDefinition.internalName,
      active: <Checkbox isChecked={componentDefinition.active} isDisabled />,
      processDefinition: componentDefinition.processDefinition,
      averageRuntime: componentDefinition.averageRuntime ? secondsToTime(componentDefinition.averageRuntime) : undefined,
      internalExpenses: componentDefinition.internalExpenses,
      externalExpenses: componentDefinition.externalExpenses,
      fees: componentDefinition.fees,
      requiresPowerOfAttorney: componentDefinition.requiresPowerOfAttorney,
      performableAsGroup: componentDefinition.performableAsGroup,
      country: componentDefinition.country?.translation?.localizationStrings?.[language],
      buttons: (
        <Button
          variant="link"
          w="100%"
          onClick={() => openComponentDetail(componentDefinition?.id)}
        >
          <Translate label={CatalogueComponentsLocale.TABLE_DETAIL_GO_TO_DETAIL} />
        </Button>
      ),
    }));
  }
  return [];
}

const CatalogueComponents: React.FunctionComponent<CatalogueComponentsProps> = (props) => {
  const [tableFilter, setTableFilter] = useState<CatalogueComponentsTableFilterType>({});
  const [allFilters, setAllFilter] = useState<CatalogueComponentsAllFilterType>({});
  const { filters } = props;
  const [list, setList] = useState<CatalogueComponentsTableData[]>([]);
  const history = useHistory();
  const { language } = useGetUser();
  const [sorting, setSorting] = useState<{ sortBy?: string, sortDirection?: Direction }>({});
  const [pageInfo, setPageInfo] = useState<PageInfo>({ pageNumber: 0, pageSize: 20, totalPages: 0 });

  const openComponentDetail = (componentDefinitionId?: number) => {
    if (componentDefinitionId) {
      history.push(`/catalogue/component/${componentDefinitionId}`);
    } else {
      history.push('/404');
    }
  };

  const { loading } = useGetComponentDefinitions(
    {
      pageInfo: {
        pageSize: true,
        pageNumber: true,
        numberOfElements: true,
        totalElements: true,
        totalPages: true,
      },
      content: {
        id: true,
        internalName: true,
        active: true,
        processDefinition: true,
        averageRuntime: true,
        internalExpenses: true,
        externalExpenses: true,
        fees: true,
        requiresPowerOfAttorney: true,
        performableAsGroup: true,
        country: {
          translation: { localizationStrings: true },
        },
      },
    }, {
      onError: () => {
        Toast({
          title: translate(CatalogueComponentsLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      },
      variables: {
        paging: {
          page: pageInfo.pageNumber,
          size: pageInfo.pageSize,
          sortBy: sorting.sortBy,
          sortDirection: sorting.sortDirection,
        },
        filter: {
          search: allFilters.fulltext && allFilters.fulltext.length ? allFilters.fulltext : undefined,
          countryIds: allFilters.countryIds && allFilters.countryIds.length ? allFilters.countryIds : undefined,
        },
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        const onCompleteData = getCatalogueComponentsTableData(openComponentDetail, language,
          response.componentDefinitions.content);
        setList(onCompleteData);
        setPageInfo({
          pageSize: response.componentDefinitions.pageInfo?.pageSize,
          pageNumber: response.componentDefinitions.pageInfo?.pageNumber || 0,
          totalPages: response.componentDefinitions.pageInfo?.totalPages || 0,
          numberOfElements: response.componentDefinitions.pageInfo?.numberOfElements,
          totalElements: response.componentDefinitions.pageInfo?.totalElements,
        });
      },
    },
  );

  useMemo(() => {
    const newAllFilters: CatalogueComponentsAllFilterType = {
      ...filters,
      ...tableFilter,
    };
    setAllFilter(newAllFilters);
  }, [filters, tableFilter]);

  return (
    <>
      <SectionContainer>
        <DetailHeader>
          <H1><Translate label={CatalogueComponentsLocale.CATALOGUE_COMPONENTS} /></H1>
        </DetailHeader>
        <Box mb={20}>
          <CatalogueComponentsTableFilter
            filter={tableFilter}
            onFilterChange={(newTableFilter) => {
              setTableFilter(newTableFilter);
            }}
          />
          <Spacer />
          <Flex mt={6} justifyContent="flex-end">
            <CatalogueComponentsModal />
          </Flex>

        </Box>
        <Box overflowX="auto">
          <Box minW="600px">
            <MeepoTable
              columns={[
                { id: 'internalName', label: translate(CatalogueComponentsLocale.INTERNAL_NAME, language), minW: '200px' },
                {
                  id: 'active',
                  label: translate(CatalogueComponentsLocale.ACTIVE, language),
                  alignData: 'center',
                  alignLabel: 'center',
                },
                {
                  id: 'averageRuntime',
                  label: translate(CatalogueComponentsLocale.AVERAGE_RUNTIME, language),
                  minW: '100px',
                },
                { id: 'country', label: translate(CatalogueComponentsLocale.COUNTRY, language), boxProps: { w: '180px' } },
                {
                  id: 'buttons',
                  label: translate(CatalogueComponentsLocale.COMPONENT_DETAIL, language),
                  minW: '150px',
                  boxProps: { w: '200px' },
                  alignLabel: 'center',
                },
              ]}
              data={list}
              isLoading={loading}
              noData={<Text><Translate label={TableLocale.NO_DATA} /></Text>}
              pageInfo={pageInfo}
              setPage={(newPageNumber, newPageSize) => {
                setPageInfo({
                  ...pageInfo,
                  pageNumber: newPageNumber,
                  pageSize: newPageSize,
                });
              }}
              onSort={(newSorting) => {
                const direction: Direction | undefined = newSorting.type === 'asc'
                  ? Direction.ASC
                  : newSorting.type === 'desc'
                    ? Direction.DESC
                    : undefined;
                setSorting({
                  sortBy: newSorting.column,
                  sortDirection: direction,
                });
              }}
              activeSorting={{
                column: sorting.sortBy,
                type: sorting.sortDirection === Direction.ASC
                  ? 'asc'
                  : sorting.sortDirection === Direction.DESC
                    ? 'desc'
                    : 'none',
              }}
            />
          </Box>
        </Box>
      </SectionContainer>
    </>
  );
};

export default CatalogueComponents;
