import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  FilterButton, InfoContainer, Translate,
} from '@scaut-sro/meepo';
import {
  Box,
  Button,
  Collapse,
  Flex, FormControl, FormLabel, Input,
  SimpleGrid,
  Select as Select2,
} from '@chakra-ui/react';
import { FiFilter, FiX } from 'react-icons/fi';
import { Select } from 'chakra-react-select';
import { InvoicesTableFilterProps, InvoicesTableFilterType } from './Invoices.model';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../core/localization/LocaleUtils';
import InvoicesLocale from './Invoices.locale';
import { Client } from '../../build/generated-sources/dto/Client';

const InvoicesTableFilter: React.FunctionComponent<InvoicesTableFilterProps> = (props) => {
  const { filter, onFilterChange, clientList } = props;
  const { language } = useGetUser();
  const [states, setStates] = useState<{ value: string, label: string }[]>([]);
  const [clients, setClients] = useState<string>('');
  const {
    handleSubmit, register, reset, setValue,
  } = useForm();
  const [openFilters, setOpenFilters] = useState<boolean>(false);

  const handleFilterSubmit: SubmitHandler<InvoicesTableFilterType> = (values) => {
    onFilterChange(values);
    setClients(values.clients?.toString() || '');
  };

  const handleFilterCancel = () => {
    handleFilterSubmit({});
  };

  const activeFilters: boolean = useMemo(() => {
    const filteredObjKeys = Object.keys(filter).filter((key) => (filter[key] && filter[key].length));
    return !!filteredObjKeys.length;
  }, [filter]);

  const invoiceStateOptions = useMemo(() => ([
    { value: 'OPEN', label: translate(InvoicesLocale.INVOICE_STATE_OPEN, language) },
    { value: 'PAID', label: translate(InvoicesLocale.INVOICE_STATE_PAID, language) },
  ]), [language]);

  useEffect(() => {
    register('states');
  }, [register]);

  useEffect(() => {
    reset({
      issueDateFrom: filter.issueDateFrom,
      issueDateTo: filter.issueDateTo,
      dueDateFrom: filter.dueDateFrom,
      dueDateTo: filter.dueDateTo,
      states: filter.states,
      clients: filter.clientId,
    });
    setStates(filter.states?.map((rec) => invoiceStateOptions.filter((option) => option.value === rec as string)[0]) || []);
  }, [filter, reset, invoiceStateOptions]);

  return (
    <Box>
      <form
        name="ClientInvoicesTableFilter"
        onSubmit={handleSubmit((values) => {
          handleFilterSubmit(values);
        })}
      >
        <Flex justifyContent="flex-end">
          <Box ml={3}>
            <FilterButton
              activeFilters={activeFilters}
              openFilters={() => { setOpenFilters(!openFilters); }}
              cancelFilters={handleFilterCancel}
            />
          </Box>
        </Flex>
        <Collapse in={openFilters} animateOpacity>
          <Box mt={3}>

            <InfoContainer>

              <SimpleGrid gap={6} columns={[1, 1, 2, 2, 2, 3]}>
                <FormControl>
                  <FormLabel fontSize="sm"><Translate label={InvoicesLocale.ISSUE_DATE} /></FormLabel>
                  <Flex alignItems="center">
                    <Input
                      size="sm"
                      type="date"
                      {...register('issueDateFrom')}
                    />
                    <Box ml={1} mr={1}>-</Box>
                    <Input
                      size="sm"
                      type="date"
                      {...register('issueDateTo')}
                    />
                  </Flex>
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="sm"><Translate label={InvoicesLocale.DUE_DATE} /></FormLabel>
                  <Flex alignItems="center">
                    <Input
                      size="sm"
                      type="date"
                      {...register('dueDateFrom')}
                    />
                    <Box ml={1} mr={1}>-</Box>
                    <Input
                      size="sm"
                      type="date"
                      {...register('dueDateTo')}
                    />
                  </Flex>
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="sm"><Translate label={InvoicesLocale.STATES} /></FormLabel>
                  <Select
                    size="sm"
                    name="states"
                    isMulti
                    closeMenuOnSelect={false}
                    value={states}
                    options={invoiceStateOptions}
                    onChange={(values: { value: string, label: string }[]) => {
                      setValue('states', values.map((value) => value.value));
                      setStates(values);
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="sm"><Translate label={InvoicesLocale.CLIENTS} /></FormLabel>
                  <Select2
                    size="sm"
                    {...register('clients')}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setValue('clients', e.currentTarget.value);
                      setClients(e.currentTarget.value);
                    }}
                    value={clients}
                  >
                    <option value="">- Select value -</option>
                    {
                      clientList.map((values: Client) => <option key={values.name} value={values.id}>{values.name}</option>)
                    }
                  </Select2>
                </FormControl>
              </SimpleGrid>

              <Flex mt={6} justifyContent="flex-end">
                <Button
                  size="sm"
                  colorScheme="red"
                  isDisabled={!activeFilters}
                  onClick={handleFilterCancel}
                  variant="ghost"
                  leftIcon={<FiX />}
                >
                  Cancel filter
                </Button>
                <Button
                  size="sm"
                  ml={3}
                  leftIcon={<FiFilter />}
                  type="submit"
                >
                  Filter
                </Button>
              </Flex>
            </InfoContainer>
          </Box>
        </Collapse>
      </form>
    </Box>
  );
};

export default InvoicesTableFilter;
