import React, { useMemo, useState } from 'react';
import {
  Language,
  MeepoTable, SectionContainer, Toast, Translate,
} from '@scaut-sro/meepo';
import {
  Box, Button, Flex, Text,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { PageInfo } from '@scaut-sro/meepo/lib/components/MeepoTable/MeepoTable.model';
import ShowDate from '../../../../components/ShowDate/ShowDate';
import { useGetPrimaryOrders } from '../../../../build/generated-sources/service/QueryService';
import { Direction } from '../../../../build/generated-sources/enum/Direction';
import { useGetUser } from '../../../../core/store/reducers/UserSettingsReducer';
import ClientsLocale from '../../Clients.locale';
import { translate } from '../../../../core/localization/LocaleUtils';
import TableLocale from '../../../../core/localization/translations/Table.locale';
import { ClientOrdersProps, ClientOrdersTableData } from './ClientOrders.model';
import { PrimaryOrderDetail } from '../../../../build/generated-sources/dto/PrimaryOrderDetail';
import { OrderType } from '../../../../build/generated-sources/enum/OrderType';
import UserIcon from '../../../Checks/UserIcon';
import { ResultBadge } from '../../../../components/ResultBadge/ResultBadge';
import { StateBadge } from '../../../../components/StateBadge/StateBadge';
import ChecksLocale from '../../../Checks/Checks.locale';
import { CandidateScreening } from '../../../../build/generated-sources/dto/CandidateScreening';
import { OrderState } from '../../../../build/generated-sources/enum/OrderState';
import { ChecksAllFilterType, ChecksTableFilterType } from '../../../Checks/Checks.model';
import ChecksTableFilter from '../../../Checks/ChecksTableFilter';

export function getOrdersTableData(toDetail: (id: number, route: string) => void, language: Language,
  data: PrimaryOrderDetail[] | undefined): ClientOrdersTableData[] {
  if (data) {
    return data.map((dto) => {
      const firstCandidateScreening: CandidateScreening | undefined = dto.order?.subOrders?.[0].candidateScreenings?.[0];
      return {
        id: dto.order?.id || '-undefined-',
        type: <UserIcon type={dto.order?.type || OrderType.SINGLE} />,
        name: dto.order?.name,
        clientOrderCustomId: dto.order?.clientOrderCustomId,
        created: <ShowDate date={dto.order?.created} time />,
        orderedBy: `${dto.order?.clientUser?.firstName} ${dto.order?.clientUser?.lastName}`,
        result: <ResultBadge result={dto.order?.result || undefined} />,
        orderState: <StateBadge state={dto.order?.orderState || undefined} />,
        accountManager: dto?.accountManager?.firstName && dto?.accountManager?.lastName
          ? `${dto?.accountManager?.firstName} ${dto?.accountManager?.lastName}` : '',
        buttons: (
          <Flex justifyContent="center">
            <Button
              variant="ghost"
              size="sm"
              bg="#E5EFFE"
              onClick={() => {
                toDetail(
                  dto.order?.orderState === OrderState.DRAFT ? dto.order?.id || -1
                    : (dto.order?.type === OrderType.SINGLE ? firstCandidateScreening?.id || -1
                      : dto.order?.id || -1),
                  dto.order?.orderState === OrderState.DRAFT ? 'order'
                    : (dto.order?.type === OrderType.SINGLE ? 'screening' : 'multicheck'),
                );
              }}
            >
              Detail
            </Button>
          </Flex>
        ),
      };
    });
  }
  return [];
}

const ClientOrders:React.FunctionComponent<ClientOrdersProps> = (props) => {
  const [tableFilter, setTableFilter] = useState< ChecksTableFilterType>({});
  const [allFilters, setAllFilter] = useState< ChecksTableFilterType>({});
  const { clientId, filters } = props;
  const [list, setList] = useState<ClientOrdersTableData[]>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>({ pageNumber: 0, pageSize: 20, totalPages: 0 });
  const [sorting, setSorting] = useState<{ sortBy?: string, sortDirection?: Direction }>({});
  const history = useHistory();
  const { language } = useGetUser();

  const routeToDetail = (id: number, route: string) => {
    history.push(`/${route}/${id}`);
  };

  const { loading } = useGetPrimaryOrders({
    pageInfo: {
      pageSize: true,
      pageNumber: true,
      numberOfElements: true,
      totalElements: true,
      totalPages: true,
    },
    content: {
      order: {
        id: true,
        type: true,
        name: true,
        clientOrderCustomId: true,
        created: true,
        result: true,
        orderState: true,
        subOrders: {
          candidateScreenings: {
            id: true,
          },
        },
      },
      accountManager: {
        firstName: true,
        lastName: true,
      },
    },
  }, {
    onCompleted: (response) => {
      const onCompleteData = getOrdersTableData(routeToDetail, language, response.primaryOrders.content);
      if (response.primaryOrders === null) {
        Toast({
          title: translate(ClientsLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      }
      setList(onCompleteData);
      setPageInfo({
        pageSize: response.primaryOrders.pageInfo?.pageSize,
        pageNumber: response.primaryOrders.pageInfo?.pageNumber || 0,
        totalPages: response.primaryOrders.pageInfo?.totalPages || 0,
        numberOfElements: response.primaryOrders.pageInfo?.numberOfElements,
        totalElements: response.primaryOrders.pageInfo?.totalElements,
      });
    },
    onError: () => {
      Toast({
        title: translate(ClientsLocale.ERROR_GETTING_DATA, language),
        status: 'error',
      });
    },
    variables: {
      filter: {
        search: allFilters.fulltext && allFilters.fulltext.length ? allFilters.fulltext : undefined,
        name: allFilters.name && allFilters.name.length ? allFilters.name : undefined,
        clientOrderCustomId: allFilters.clientOrderCustomId && allFilters.clientOrderCustomId.length
          ? allFilters.clientOrderCustomId : undefined,
        createdFrom: allFilters.orderedFrom && allFilters.orderedFrom.length
          ? allFilters.orderedFrom : undefined,
        createdTo: allFilters.orderedTo && allFilters.orderedTo.length ? allFilters.orderedTo : undefined,
        results: allFilters.result && allFilters.result.length ? allFilters.result : undefined,
        states: allFilters.orderState && allFilters.orderState.length ? allFilters.orderState : undefined,
        types: allFilters.type && allFilters.type.length ? allFilters.type : undefined,
        clientIds: [clientId],
      },
      paging: {
        page: pageInfo.pageNumber,
        size: pageInfo.pageSize,
        sortBy: sorting.sortBy,
        sortDirection: sorting.sortDirection,
      },
    },
  });

  useMemo(() => {
    const newAllFilters: ChecksAllFilterType = {
      ...filters,
      ...tableFilter,
    };
    setAllFilter(newAllFilters);
  }, [filters, tableFilter]);

  return (
    <>
      <SectionContainer>
        <Box mb={10}>
          <ChecksTableFilter
            filter={tableFilter}
            onFilterChange={(newTableFilter) => {
              setTableFilter(newTableFilter);
            }}
          />
        </Box>
      </SectionContainer>
      <Box minW="600px">
        <MeepoTable
          columns={[
            { id: 'type' },
            { id: 'name', label: translate(ChecksLocale.LABEL, language) },
            { id: 'clientOrderCustomId', label: translate(ChecksLocale.CUSTOM_ID, language) },
            { id: 'created', label: translate(ChecksLocale.ORDER_CREATED, language) },
            { id: 'accountManager', label: translate(ChecksLocale.ACCOUNT_MANAGER, language), minW: '180px' },
            {
              id: 'result',
              label: translate(ChecksLocale.ORDER_RESULT, language),
              boxProps: {
                w: '220px',
              },
            },
            {
              id: 'orderState',
              label: translate(ChecksLocale.ORDER_STATE, language),
              boxProps: {
                w: '220px',
              },
            },
            {
              id: 'buttons',
              boxProps: {
                w: 24,
              },
            },
          ]}
          data={list}
          isLoading={loading}
          noData={<Text><Translate label={TableLocale.NO_DATA} /></Text>}
          pageInfo={pageInfo}
          setPage={(newPageNumber, newPageSize) => {
            setPageInfo({
              ...pageInfo,
              pageNumber: newPageNumber,
              pageSize: newPageSize,
            });
          }}
          onSort={(newSorting) => {
            const direction: Direction | undefined = newSorting.type === 'asc'
              ? Direction.ASC
              : newSorting.type === 'desc'
                ? Direction.DESC
                : undefined;
            setSorting({
              sortBy: newSorting.column,
              sortDirection: direction,
            });
          }}
          activeSorting={{
            column: sorting.sortBy,
            type: sorting.sortDirection === Direction.ASC
              ? 'asc'
              : sorting.sortDirection === Direction.DESC
                ? 'desc'
                : 'none',
          }}
        />
      </Box>
    </>
  );
};

export default ClientOrders;
