import { Translation } from '../../../../../core/localization/Localization.model';

class ClientUserRegistrationLocale {
  public static USER_GO_TO_DETAIL: Translation = { EN: 'Go to detail', CZ: 'Přejít na detail' };
  public static USER_DETAIL: Translation = { EN: 'User detail', CZ: 'Seznam detail' };
  public static USERS: Translation = { EN: 'Users', CZ: 'Uživatelé' };
  public static NAME: Translation = { EN: 'Name', CZ: 'Název' };
  public static FIRST_NAME: Translation = { EN: 'First name', CZ: 'Jméno' };
  public static LAST_NAME: Translation = { EN: 'Last name', CZ: 'Příjmení' };
  public static YES: Translation = { EN: 'Yes', CZ: 'Ano' };
  public static NO: Translation = { EN: 'No', CZ: 'Ne' };
  public static EMAIL: Translation = { EN: 'Email', CZ: 'Email' };
  public static POSITION: Translation = { EN: 'Job position', CZ: 'Pracovní pozice' };
  public static PHONE: Translation = { EN: 'Phone', CZ: 'Telefon' };
  public static ACTIVE: Translation = { EN: 'Active', CZ: 'Aktivní' };
  public static LAST_ACTIVE: Translation = { EN: 'Last activity', CZ: 'Naposledy aktivní' };
  public static USER_ROLE: Translation = { EN: 'Role', CZ: 'Role' };
  public static TEAMS: Translation = { EN: 'Teams', CZ: 'Týmy' };
  public static INVITE_SENT: Translation = { EN: 'Invite sent', CZ: 'Pozvánka odeslána' };
  public static USER_INVITATION: Translation = { EN: 'User invitation', CZ: 'Pozvánka uživatele' };
  public static PROBLEM_INVITING_CLIENT: Translation = {
    EN: 'There was a problem inviting user',
    CZ: 'Došlo k problému s pozváním uživatele',
  };
  public static ERROR_GETTING_ROLES: Translation = {
    EN: 'There was a problem getting roles.',
    CZ: 'Problém při načitání rolí.',
  };
  public static ERROR_GETTING_TEAMS: Translation = {
    EN: 'There was a problem getting teams.',
    CZ: 'Problém při načitání týmů.',
  };
  public static CLIENT_DELETED: Translation = {
    EN: 'client deleted',
    CZ: 'Klient smazán',
  };
  public static ARE_YOU_SURE: Translation = {
    EN: 'Are you sure you want to delete user',
    CZ: 'Jste si jisti, že chcete smazat uživatele',
  };
  public static CLIENT_REGISTRATION: Translation = { EN: 'Client registration', CZ: 'Registrace klienta' };
  public static INVITE: Translation = { EN: 'Invite', CZ: 'Pozvat' };
  public static EMAIL_ERROR_INVALID: Translation = { EN: 'Invalid email', CZ: 'Email není validní' };
}
export default ClientUserRegistrationLocale;
