import { Translation } from '../../core/localization/Localization.model';

class FaqLocale {
  public static MODAL_FAQ_HEADER: Translation = { EN: 'Frequently asked questions', CZ: 'Nejčastěji pokládané otázky' };
  public static ERROR_GETTING_FAQ: Translation = {
    EN: 'There was a problem getting faq information',
    CZ: 'Problém při načítání faq údajů',
  };
  public static NEW: Translation = { EN: 'New', CZ: 'Nová' };
  public static MODAL_CREATE_QUESTION_TITLE: Translation = { EN: 'New FAQ', CZ: 'Nová FAQ' };
  public static MODAL_EDIT_QUESTION_TITLE: Translation = { EN: 'Edit FAQ', CZ: 'Upravit FAQ' };
  public static FAQ_DETAIL: Translation = { EN: 'FAQ Detail', CZ: 'FAQ detail' };
  public static GO_TO_DETAIL: Translation = { EN: 'Go to detail', CZ: 'Přejít na detail' };
  public static TRANSLATION_NAME_QUESTION: Translation = { EN: 'Question localized name', CZ: 'Lokalizované jméno otázky' };
  public static TRANSLATION_NAME_ANSWER: Translation = { EN: 'Answer localized name', CZ: 'Lokalizované jméno odpovědi' };
  public static DELETE: Translation = { EN: 'Delete', CZ: 'Smazat' };
  public static CZECH: Translation = { EN: 'Czech', CZ: 'Česky' };
  public static ENGLISH: Translation = { EN: 'English', CZ: 'Anglicky' };
  public static QUESTION: Translation = { EN: 'Question', CZ: 'Otázka' };
  public static ANSWER: Translation = { EN: 'Answer', CZ: 'Odpověď' };
  public static YES: Translation = { EN: 'Yes', CZ: 'Ano' };
  public static NO: Translation = { EN: 'No', CZ: 'Ne' };
  public static ERROR_GETTING_TRANSLATIONS: Translation = {
    EN: 'There was a problem getting translations.',
    CZ: 'Problém při načitání překladů',
  };
  public static ARE_YOU_SURE: Translation = {
    EN: 'Are you sure you want to delete question',
    CZ: 'Opravdu chcete smazat otázku',
  };
  public static FAQ_DELETED: Translation = {
    EN: 'Question deleted',
    CZ: 'Otázka smazána',
  };
  public static ERROR_DELETING_QUESTION: Translation = {
    EN: 'There was a problem deleting question.',
    CZ: 'Při mazání otázky došlo k problému',
  };
  public static ERROR_SAVING_FAQ: Translation = {
    EN: 'There was a problem saving faq information',
    CZ: 'Při ukládání často kladených informací došlo k problému',
  };
}

export default FaqLocale;
