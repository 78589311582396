import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Input,
  SimpleGrid,
  Spacer,
} from '@chakra-ui/react';
import {
  Controller, FormProvider, SubmitHandler, useForm,
} from 'react-hook-form';
import { InfoContainer, Toast } from '@scaut-sro/meepo';
import { useHistory } from 'react-router-dom';
import { Select } from 'chakra-react-select';
import { ItemDetailProps, ItemDetailViewAttr } from './ResourceDatabase.model';
import { useResourceDatabaseItem } from '../../build/generated-sources/service/MutationService';
import ResourceDatabaseLocale from './ResourceDatabase.locale';
import Questionnaire from '../../components/Questionnaire/Questionnaire';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { Country } from '../../build/generated-sources/dto/Country';
import { useGetEnumerations } from '../../build/generated-sources/service/QueryService';
import CatalogueComponentsLocale from '../Catalogue/CatalogueComponents/CatalogueComponents.locale';
import { translate } from '../../core/localization/LocaleUtils';
import Translate from '../../components/Translate/Translate';
import FormLocale from '../../core/localization/translations/Form.locale';
import { EnumerationType } from '../../build/generated-sources/enum/EnumerationType';

const DatabaseItemDetail: React.FunctionComponent<ItemDetailProps> = (props) => {
  const {
    itemDetail, sectionDetail, cancelChangesTrigger,
  } = props;
  const history = useHistory();
  const { language } = useGetUser();
  const [countryList, setCountryList] = useState<Country[]>([]);

  const defaultValues: Partial<ItemDetailViewAttr> = useMemo(() => ({
    id: itemDetail?.item?.id,
    name: itemDetail?.item?.name,
    countryId: itemDetail?.item?.enumeration?.id
      ? {
        value: itemDetail?.item.enumeration.id,
        label: itemDetail?.item.enumeration.translation?.localizationStrings?.[language] || '',
      }
      : undefined,
    form: itemDetail ? itemDetail.form : sectionDetail?.form,
  }), [itemDetail, sectionDetail, language]);

  const useFormMethods = useForm<Partial<ItemDetailViewAttr>>({
    defaultValues,
  });

  const countryOptions = useMemo(() => (countryList.map((c) => ({
    value: c.id,
    label: c?.translation?.localizationStrings?.[language],
  }))), [countryList, language]);

  const countryId = useMemo(() => (countryOptions.find(
    (country) => country.value === itemDetail?.item?.enumeration?.id,
  )), [countryOptions, itemDetail]);

  const form = useMemo(() => {
    let myForm;
    if (itemDetail?.form) {
      myForm = itemDetail.form;
    } else if (!itemDetail?.form && sectionDetail?.form) {
      myForm = sectionDetail.form;
    } else {
      myForm = new Map<string, string>();
    }
    return (myForm);
  }, [itemDetail, sectionDetail]);

  useEffect(() => {
    useFormMethods.reset(defaultValues);
    useFormMethods.setValue('countryId', countryId as { value: number, label: string });
  }, [cancelChangesTrigger, useFormMethods, defaultValues, countryId]);

  useGetEnumerations(
    {
      id: true,
      translation: {
        id: true,
        localizationStrings: true,
      },
    }, {
      variables: {
        enumerationType: EnumerationType.COUNTRY,
      },
      onError: () => {
        Toast({
          title: translate(CatalogueComponentsLocale.ERROR_GETTING_COUNTRIES, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setCountryList(response.enumerations);
      },
    },
  );

  const [mutateSaveItem] = useResourceDatabaseItem({
    id: true,
  },
  {
    onCompleted: (response) => {
      Toast({
        title: translate(FormLocale.SUCCESSFULLY_SAVED, language),
        status: 'success',
      });
      history.push(`/database/item/${response.resourceDatabaseItem.id}`);
    },
    onError: () => {
      Toast({
        title: translate(CatalogueComponentsLocale.ERROR_GETTING_COUNTRIES, language),
        status: 'error',
      });
    },
  });

  const handleScreeningFormSubmit: SubmitHandler<ItemDetailViewAttr> = (values) => {
    mutateSaveItem({
      variables: {
        sectionId: itemDetail?.sectionId || sectionDetail?.section?.id || -1,
        item: {
          id: itemDetail?.item?.id,
          name: values.name,
          enumerationId: values.countryId?.value,
          resourceDatabaseFields: values,
        },
      },
    });
  };

  // @ts-ignore
  return (
    <Box p={4}>
      <InfoContainer>
        <FormProvider {...useFormMethods}>
          <form onSubmit={useFormMethods.handleSubmit(handleScreeningFormSubmit)}>
            <SimpleGrid columns={[1, 1, 1, 3, 3, 3]} spacing={6}>

              <GridItem colSpan={[1, 1, 1, 2, 2, 2]}>
                <FormControl isInvalid={!!useFormMethods.formState.errors.name}>
                  <FormLabel htmlFor="name">
                    <Translate label={ResourceDatabaseLocale.NAME} />
                  </FormLabel>
                  <Input
                    placeholder={translate(ResourceDatabaseLocale.NAME, language)}
                    {...useFormMethods.register('name', {
                      required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                    })}
                  />
                  <FormErrorMessage>
                    {
                              // TODO:
                              // @ts-ignore
                      useFormMethods.formState.errors.name && useFormMethods.formState.errors.name.message
                            }
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!useFormMethods.formState.errors.countryId}>
                  <FormLabel htmlFor="countryId">
                    <Translate label={ResourceDatabaseLocale.COUNTRY} />
                  </FormLabel>
                  <Controller
                    name="countryId"
                    control={useFormMethods.control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={countryOptions}
                        hideSelectedOptions={false}
                        selectedOptionStyle="check"
                      />
                    )}
                    rules={{
                      required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                    }}
                  />
                  <FormErrorMessage>
                    {
                              // TODO:
                              // @ts-ignore
                      useFormMethods.formState.errors.countryId && useFormMethods.formState.errors.countryId.message
                            }
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
            </SimpleGrid>
            <Divider mt={4} mb={4} />
            <Questionnaire
              definition={form}
            />
            <Flex>
              <Spacer />
              <Button mt={6} type="submit">
                <Translate label={FormLocale.SEND} />
              </Button>
            </Flex>
          </form>
        </FormProvider>
      </InfoContainer>
    </Box>
  );
};

export default DatabaseItemDetail;
