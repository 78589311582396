import React, { useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { H1, SectionContainer, Toast } from '@scaut-sro/meepo';
import {
  Button, ButtonGroup, HStack, Spacer,
} from '@chakra-ui/react';
import {
  useLazyGetComponentDefinitionDetail,
} from '../../../../build/generated-sources/service/QueryService';
import CatalogueComponentDetailForm from './CatalogueComponentDetailForm';
import { ComponentDefinition } from '../../../../build/generated-sources/dto/ComponentDefinition';
import DetailHeader from '../../../../components/DetailHeader/DetailHeader';
import Translate from '../../../../components/Translate/Translate';
import CatalogueComponentsLocale from '../CatalogueComponents.locale';
import LoadingScreen from '../../../LoadingScreen/LoadingScreen';
import FormLocale from '../../../../core/localization/translations/Form.locale';
import { translate } from '../../../../core/localization/LocaleUtils';
import { useGetUser } from '../../../../core/store/reducers/UserSettingsReducer';

const CatalogueComponentDetail: React.FunctionComponent = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const componentDefinitionId = Number.isNaN(+params.id);
  const [data, setData] = useState<ComponentDefinition | undefined>(undefined);
  const [formIsLoading, setFormIsLoading] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [resetForm, setResetForm] = useState<number>(0);
  const { language } = useGetUser();

  const [componentDefinitionHandle] = useLazyGetComponentDefinitionDetail(
    {
      id: true,
      internalName: true,
      active: true,
      country: {
        id: true,
        translation: {
          id: true,
          localizationStrings: true,
        },
      },
      fees: true,
      internalExpenses: true,
      externalExpenses: true,
      processDefinition: true,
      requiresPowerOfAttorney: true,
      requiresAuthorization: true,
      poaJasperReportLanguage: true,
      poaJasperReport: true,
      poaAuthorityTexts: {
        id: true,
      },
      authorizationTexts: {
        id: true,
      },
      dynamicFields: {
        id: true,
      },
      performableAsGroup: true,
      averageRuntime: true,
    },
    {
      onCompleted: (response) => {
        if (response.componentDefinitionDetail === null) {
          Toast({
            title: translate(CatalogueComponentsLocale.ERROR_GETTING_DATA, language),
            status: 'error',
          });
        }
        setData(response.componentDefinitionDetail);
      },
      onError: () => {
        Toast({
          title: translate(CatalogueComponentsLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      },
      variables: {
        componentDefinitionId: +params.id,
      },
    },
  );

  useEffect(() => {
    if (!componentDefinitionId) {
      componentDefinitionHandle({
        variables: {
          componentDefinitionId: +params.id,
        },
      });
    }
  }, [componentDefinitionHandle, params.id, componentDefinitionId]);

  return (
    <>
      {!data ? <LoadingScreen /> : (
        <>
          <SectionContainer>
            <DetailHeader>
              <H1><Translate label={CatalogueComponentsLocale.COMPONENT_DETAIL} /></H1>
            </DetailHeader>
            <CatalogueComponentDetailForm
              componentDefinition={data}
              loadingSetter={(isLoading) => { setFormIsLoading(isLoading); }}
              formRef={formRef}
              cancelChangesTrigger={resetForm}
            />
            <HStack mt={6}>
              <Spacer />
              <ButtonGroup>
                <Button
                  onClick={() => {
                    if (formRef) {
                      formRef.current?.dispatchEvent(new Event('submit', {
                        cancelable: true,
                        bubbles: true,
                      }));
                    }
                  }}
                  isLoading={formIsLoading}
                >
                  <Translate label={FormLocale.SAVE} />
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="red"
                  isDisabled={formIsLoading}
                  onClick={() => {
                    setResetForm(resetForm + 1);
                  }}
                >
                  <Translate label={FormLocale.CANCEL} />
                </Button>
              </ButtonGroup>
            </HStack>
          </SectionContainer>
        </>
      )}
    </>
  );
};

export default CatalogueComponentDetail;
