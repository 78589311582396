import React, { useEffect, useState } from 'react';
import {
  Box, Button, Flex, Text,
} from '@chakra-ui/react';
import { MeepoTable, Toast } from '@scaut-sro/meepo';
import { useHistory } from 'react-router-dom';
import { PageInfo } from '@scaut-sro/meepo/lib/components/MeepoTable/MeepoTable.model';
import { ChecksAllFilterType, ChecksTableData } from '../Checks/Checks.model';
import { CandidateScreening } from '../../build/generated-sources/dto/CandidateScreening';
import { OrderType } from '../../build/generated-sources/enum/OrderType';
import SingleCheckDetail from '../Checks/SingleCheckDetail';
import MultipleCheckDetail from '../Checks/MultipleCheckDetail';
import UnfinishedOrderCheckDetail from '../Checks/UnfinishedOrderCheckDetail';
import UserIcon from '../Checks/UserIcon';
import ShowDate from '../../components/ShowDate/ShowDate';
import {
  LastChecksProps,
  LastChecksTableFilterType,
} from './OverviewLastChecks.model';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { useGetPrimaryOrders } from '../../build/generated-sources/service/QueryService';
import { translate } from '../../core/localization/LocaleUtils';
import ChecksLocale from '../Checks/Checks.locale';
import Translate from '../../components/Translate/Translate';
import TableLocale from '../../core/localization/translations/Table.locale';
import OverviewLastChecksLocale from './OverviewLastChecks.locale';
import { PrimaryOrderDto } from '../../build/generated-sources/dto/PrimaryOrderDto';
import { ResultBadge } from '../../components/ResultBadge/ResultBadge';
import { StateBadge } from '../../components/StateBadge/StateBadge';
import { Direction } from '../../build/generated-sources/enum/Direction';

export function getChecksTableData(
  data: PrimaryOrderDto[] | undefined,
  toDetail: (id: number, route: string) => void,
): ChecksTableData[] {
  if (data) {
    return data.map((dto) => {
      const firstCandidateScreening: CandidateScreening | undefined = dto.order?.subOrders?.[0].candidateScreenings?.[0];
      return {
        key: dto.order?.id || '-undefined-',
        id: dto.order?.id || '-undefined-',
        detail: firstCandidateScreening ? (
          dto.order?.type === OrderType.SINGLE ? (
            <SingleCheckDetail
              candidateScreening={firstCandidateScreening}
              orderId={dto.order?.id}
              anonymizationDate={dto.anonymizationDate}
            />
          ) : (
            <MultipleCheckDetail
              order={dto.order}
              candidateScreening={firstCandidateScreening}
              anonymizationDate={dto.anonymizationDate}
            />
          )
        ) : (
          <UnfinishedOrderCheckDetail order={dto.order} />
        ),
        type: <UserIcon type={dto.order?.type || OrderType.SINGLE} />,
        name: dto.order?.name,
        clientOrderCustomId: dto.order?.clientOrderCustomId,
        created: <ShowDate date={dto.order?.created} time />,
        orderedBy: `${dto.order?.clientUser?.firstName} ${dto.order?.clientUser?.lastName}`,
        result: <ResultBadge result={dto.order?.result || undefined} />,
        orderState: <StateBadge state={dto.order?.orderState || undefined} />,
        buttons: (
          <Flex justifyContent="center">
            <Button
              variant="ghost"
              size="sm"
              bg="#E5EFFE"
              onClick={() => toDetail(
                dto.order?.type === OrderType.SINGLE
                  ? firstCandidateScreening?.id || -1
                  : dto.order?.id || -1,
                dto.order?.type === OrderType.SINGLE
                  ? 'screening'
                  : 'multicheck',
              )}
            >
              Detail
            </Button>
          </Flex>
        ),
      };
    });
  }
  return [];
}

const OverviewLastChecks: React.FunctionComponent<LastChecksProps> = (
  props,
) => {
  const { type, filters } = props;
  const { language } = useGetUser();
  const [pageInfo, setPageInfo] = useState<PageInfo>({
    pageNumber: 0,
    pageSize: 10,
    totalPages: 0,
  });
  const [sorting, setSorting] = useState<{
    sortBy?: string;
    sortDirection?: Direction;
  }>({});
  const [list, setList] = useState<ChecksTableData[]>([]);
  const [allFilters, setAllFilter] = useState<LastChecksTableFilterType>({});
  const history = useHistory();

  const routeToDetail = (id: number, route: string) => {
    history.push(`/${route}/${id}`);
  };

  useEffect(() => {
    const newAllFilters: ChecksAllFilterType = {};
    setAllFilter(newAllFilters);
  }, []);

  const { loading } = useGetPrimaryOrders(
    {
      pageInfo: {
        pageSize: true,
        pageNumber: true,
        numberOfElements: true,
        totalElements: true,
        totalPages: true,
      },
      content: {
        anonymizationDate: true,
        order: {
          id: true,
          name: true,
          type: true,
          clientUser: {
            firstName: true,
            lastName: true,
          },
          processStarted: true,
          processCompleted: true,
          clientOrderCustomId: true,
          orderState: true,
          result: true,
          created: true,
          subOrders: {
            candidateScreenings: {
              id: true,
              processStarted: true,
              processCompleted: true,
              result: true,
              screenings: {
                id: true,
                result: true,
                screeningDefinition: {
                  id: true,
                  name: {
                    localizationStrings: true,
                  },
                },
              },
            },
          },
        },
      },
    },
    {
      onError: () => {
        Toast({
          title: translate(
            OverviewLastChecksLocale.ERROR_GETTING_ORDERS,
            language,
          ),
          status: 'error',
        });
      },
      variables: {
        paging: {
          page: pageInfo.pageNumber,
          size: pageInfo.pageSize,
          sortBy: sorting.sortBy,
          sortDirection: sorting.sortDirection,
        },
        filter: {
          types: type
            ? [type]
            : allFilters.type && allFilters.type.length
              ? allFilters.type
              : undefined,
          ...filters,
        },
      },
      fetchPolicy: 'cache-and-network',
      pollInterval: 5000,
      onCompleted: (response) => {
        const onCompleteData = getChecksTableData(
          response.primaryOrders.content,
          routeToDetail,
        );
        setList(onCompleteData);
        setPageInfo({
          pageSize: response.primaryOrders.pageInfo?.pageSize,
          pageNumber: response.primaryOrders.pageInfo?.pageNumber || 0,
          totalPages: response.primaryOrders.pageInfo?.totalPages || 0,
          numberOfElements: response.primaryOrders.pageInfo?.numberOfElements,
          totalElements: response.primaryOrders.pageInfo?.totalElements,
        });
      },
    },
  );

  return (
    <>
      <Box overflowX="auto">
        <Box minW="500px">
          <MeepoTable
            columns={[
              {
                id: 'type',
                boxProps: {
                  w: '70px',
                },
              },
              { id: 'name', label: translate(ChecksLocale.LABEL, language) },
              {
                id: 'created',
                label: translate(ChecksLocale.ORDER_CREATED, language),
                boxProps: {
                  w: '140px',
                },
              },
              {
                id: 'orderState',
                label: translate(ChecksLocale.ORDER_STATE, language),
                boxProps: {
                  w: '140px',
                },
              },
              {
                id: 'result',
                label: translate(ChecksLocale.ORDER_RESULT, language),
                boxProps: {
                  w: 24,
                },
              },
              {
                id: 'buttons',
                boxProps: {
                  w: 24,
                },
              },
            ]}
            data={list}
            isLoading={loading}
            noData={(
              <Text>
                <Translate label={TableLocale.NO_DATA} />
              </Text>
            )}
            pageInfo={pageInfo}
            setPage={(newPageNumber, newPageSize) => {
              setPageInfo({
                ...pageInfo,
                pageNumber: newPageNumber,
                pageSize: newPageSize,
              });
            }}
            onSort={(newSorting) => {
              const direction: Direction | undefined = newSorting.type === 'asc'
                ? Direction.ASC
                : newSorting.type === 'desc'
                  ? Direction.DESC
                  : undefined;
              setSorting({
                sortBy: newSorting.column,
                sortDirection: direction,
              });
            }}
            activeSorting={{
              column: sorting.sortBy,
              type:
                sorting.sortDirection === Direction.ASC
                  ? 'asc'
                  : sorting.sortDirection === Direction.DESC
                    ? 'desc'
                    : 'none',
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default OverviewLastChecks;
