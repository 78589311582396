import React, { useEffect, useMemo, useState } from 'react';
import {
  H1, H2, MeepoTag, SectionContainer, TagArea, Toast,
} from '@scaut-sro/meepo';
import {
  Box, Flex, Heading, Icon, VStack,
} from '@chakra-ui/react';
import { useRouteMatch } from 'react-router-dom';
import { IoReceiptOutline, IoShapesOutline } from 'react-icons/io5';
import { AiOutlineNumber, AiOutlineTeam } from 'react-icons/ai';
import { OrderDetailTablesData } from './OrderDetail.model';
import Translate from '../../components/Translate/Translate';
import OrderDetailLocale from './OrderDetail.locale';
import OrderDetailCandidateTable from './OrderDetailCandidateTable';
import TotalPrice from '../../components/TotalPrice/TotalPrice';
import { useLazyGetPrimaryOrderDetail } from '../../build/generated-sources/service/QueryService';
import ListOfServices from '../../components/ListOfServices/ListOfServices';
import { CandidateIdentity } from '../../build/generated-sources/dto/CandidateIdentity';
import { CandidateScreening } from '../../build/generated-sources/dto/CandidateScreening';
import { PrimaryOrderDetail } from '../../build/generated-sources/dto/PrimaryOrderDetail';
import { OrderState } from '../../build/generated-sources/enum/OrderState';
import Confirmation from '../NewCheck/Confirmation/Confirmation';
import { OrderType } from '../../build/generated-sources/enum/OrderType';
import { translate } from '../../core/localization/LocaleUtils';
import ChecksLocale from '../Checks/Checks.locale';
import NewCheckLocale from '../NewCheck/NewCheck.locale';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import DetailHeader from '../../components/DetailHeader/DetailHeader';

const OrderDetail: React.FunctionComponent = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const primaryOrderId = Number.isNaN(+params.id);
  const [data, setData] = useState<PrimaryOrderDetail | undefined>();
  const { language } = useGetUser();

  const [orderDetailHandle, { loading }] = useLazyGetPrimaryOrderDetail(
    {
      order: {
        id: true,
        name: true,
        clientOrderCustomId: true,
        type: true,
        price: true,
        team: {
          name: true,
        },
        orderState: true,
        result: true,
      },
      suborderProducts: {
        subOrder: {
          candidateScreenings: {
            id: true,
            state: true,
            result: true,
            candidateIdentity: {
              firstName: true,
              lastName: true,
              dateOfBirth: true,
              email: true,
            },
            screenings: {
              screeningDefinition: {
                id: true,
                name: { localizationStrings: true },
                description: { localizationStrings: true },
              },
            },
          },
          candidateIdentities: {
            firstName: true,
            lastName: true,
            dateOfBirth: true,
          },
        },
        products: {
          screeningDefinitions: {
            id: true,
            name: { localizationStrings: true },
            description: { localizationStrings: true },
            price: true,
          },
          screeningPackages: {
            id: true,
            name: { localizationStrings: true },
            description: { localizationStrings: true },
            price: true,
            screeningDefinitions: {
              id: true,
              name: { localizationStrings: true },
              description: { localizationStrings: true },
            },
          },
          country: {
            translation: { localizationStrings: true },
          },
        },
      },
    }, {
      onCompleted: (response) => {
        setData(response.primaryOrderDetail);
      },
      onError: () => {
        Toast({
          title: translate(OrderDetailLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      },
      variables: {
        primaryOrderId: +params.id,
      },
    },
  );

  useEffect(() => {
    if (!primaryOrderId) {
      orderDetailHandle({
        variables: {
          primaryOrderId: +params.id,
        },
      });
    }
  }, [primaryOrderId, params.id, orderDetailHandle]);

  const orderDetailTablesData: OrderDetailTablesData = useMemo(() => {
    if (data) {
      const candidateScreenings: CandidateScreening[] = [];
      const candidates: CandidateIdentity[] = [];
      data.suborderProducts?.forEach((subOrderProductsDto) => {
        subOrderProductsDto.subOrder?.candidateScreenings?.forEach((candidateScreening) => {
          candidateScreenings.push(candidateScreening);
        });
        subOrderProductsDto.subOrder?.candidateIdentities?.forEach((candidate) => {
          candidates.push({
            firstName: candidate.firstName,
            lastName: candidate.lastName,
            dateOfBirth: candidate.dateOfBirth,
            email: candidate.email,
          });
        });
      });
      return {
        candidateScreenings,
        candidates,
      };
    }
    return {};
  }, [data]);

  return (
    <>
      <SectionContainer>
        { data?.order?.orderState === OrderState.DRAFT ? (
          <Confirmation
            orderData={data}
            candidates={orderDetailTablesData.candidates || []}
            paymentCallback={() => {
              orderDetailHandle({
                variables: {
                  primaryOrderId: +params.id,
                },
              });
            }}
          />
        ) : (
          <>
            <DetailHeader>
              <H1 m={0} mr={6}><Translate label={OrderDetailLocale.ORDER_RECAP} /></H1>
            </DetailHeader>
            <Box mb={8}>
              <Flex alignItems="center">
                <Icon as={IoReceiptOutline} fontSize={50} />
                <Box ml={4}>
                  <Heading size="lg" color="primaryBase" mb={3}>{data?.order?.name}</Heading>
                  <TagArea>
                    { data?.order?.type ? (
                      <MeepoTag
                        leftIcon={IoShapesOutline}
                        label={
                                      data?.order?.type === OrderType.MULTIPLE
                                        ? translate(ChecksLocale.MULTIPLE_PEOPLE, language)
                                        : translate(ChecksLocale.SINGLE_CHECK, language)
                                    }
                        tooltip={translate(NewCheckLocale.TYPE, language)}
                      />
                    )
                      : undefined }
                    {data?.order?.team?.name ? (
                      <MeepoTag
                        leftIcon={AiOutlineTeam}
                        label={data?.order?.team?.name}
                        tooltip={translate(NewCheckLocale.TEAM, language)}
                      />
                    ) : undefined}
                    {data?.order?.clientOrderCustomId ? (
                      <MeepoTag
                        leftIcon={AiOutlineNumber}
                        label={data?.order?.clientOrderCustomId}
                        tooltip={translate(NewCheckLocale.ORDER_CUSTOM_ID, language)}
                      />
                    ) : undefined}
                  </TagArea>
                </Box>
              </Flex>
            </Box>
            <VStack
              spacing={10}
              align="stretch"
            >
              <Box>
                <H2 mt={8} mb={6}><Translate label={OrderDetailLocale.SCREENINGS} /></H2>
                {data?.suborderProducts ? (
                  <ListOfServices
                    data={data?.suborderProducts[0].products as []}
                  />
                ) : undefined}
              </Box>
              <Box>
                <H2 mt={8} mb={6}><Translate label={OrderDetailLocale.CANDIDATES} /></H2>
                <Box overflowX="auto">
                  <OrderDetailCandidateTable
                    data={orderDetailTablesData.candidates || []}
                    isLoading={loading}
                  />
                </Box>
              </Box>
              <TotalPrice price={data?.order?.price as number} />
            </VStack>
          </>
        )}
      </SectionContainer>
    </>
  );
};

export default OrderDetail;
