import React, { useEffect, useState, useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  H1, SectionContainer, Toast,
} from '@scaut-sro/meepo';
import {
  Box,
  Button,
  Flex,
  Heading,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  useDisclosure,
  Input,
  FormLabel,
  FormControl,
} from '@chakra-ui/react';
import {
  useGetAdminUsers,
  useLazyGetComponentDetail,
} from '../../../build/generated-sources/service/QueryService';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import DetailHeader from '../../../components/DetailHeader/DetailHeader';
import Translate from '../../../components/Translate/Translate';
import TasksLocale from '../Tasks.locale';
import {
  useAssignComponent, useReplaceComponentProcess,
} from '../../../build/generated-sources/service/MutationService';
import { handleApolloErrors } from '../../../core/error/ScautErrorHandler';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../../core/localization/LocaleUtils';
import { StateBadge } from '../../../components/StateBadge/StateBadge';
import { ComponentDetail } from '../../../build/generated-sources/dto/ComponentDetail';
import { TaskDto } from '../../../build/generated-sources/dto/TaskDto';
import { State } from '../../../build/generated-sources/enum/State';
import { ResultBadge } from '../../../components/ResultBadge/ResultBadge';
import { TasksTableData } from './Components.model';
import TasksDetail from './TasksDetail';
import IntegrationDetail from './IntegrationDetail';
import ComponentLog from './Logs/ComponentLog';
import Notes from '../Notes';

export function getTasksTableData(
  data: TaskDto[],
): TasksTableData[] {
  if (data) {
    return data.map((task) => ({
      id: task.id || '-undefined-',
      internalName: task.internalName,
      description: task.description,
      state: <StateBadge state={task.state as string} />,
    }));
  }
  return [];
}

const ComponentDetailView: React.FunctionComponent = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const componentId = Number.isNaN(+params.id);
  const { language } = useGetUser();
  const [data, setData] = useState<ComponentDetail | undefined>(undefined);
  const [assignee, setAssignee] = useState<number | string | undefined>('');
  const [list, setList] = useState<TasksTableData[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const inputIdRef = useRef<HTMLInputElement>(null);

  const [mutateAssignee, mutateAssigneeOptions] = useAssignComponent(
    {
      success: true,
    },
    {
      onCompleted: () => {
        Toast({
          title: translate(TasksLocale.SUCCESSFULLY_ASSIGNED, language),
          status: 'success',
        });
      },
      onError: (error) => {
        handleApolloErrors(error, language);
      },
    },
  );

  const [componentHandle, { loading }] = useLazyGetComponentDetail(
    {
      component: {
        id: true,
        state: true,
        result: true,
        assignee: {
          id: true,
          firstName: true,
          lastName: true,
        },
        componentDefinition: {
          internalName: true,
          processDefinition: true,
        },
      },
      tasks: {
        id: true,
        internalName: true,
        description: true,
        state: true,
      },
      processDefinitionModel: {
        processDefinitionModel: true,
        activeSteps: {
          stepName: true,
          stepType: true,
        },
      },
    },
    {
      onCompleted: (response) => {
        if (response.componentDetail === null) {
          Toast({
            title: translate(TasksLocale.ERROR_GETTING_DATA, language),
            status: 'error',
          });
        }
        const onCompleteData = getTasksTableData(
          response.componentDetail.tasks || [],
        );
        setList(onCompleteData);
        setData(response.componentDetail);
        setAssignee(response.componentDetail.component?.assignee?.id);
      },
      onError: () => {
        Toast({
          title: translate(TasksLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      },
      variables: {
        componentId: +params.id,
      },
    },
  );

  useEffect(() => {
    if (!componentId) {
      componentHandle({
        variables: {
          componentId: +params.id,
        },
      });
    }
  }, [componentHandle, params.id, componentId]);

  const allUsers = useGetAdminUsers({
    id: true,
    firstName: true,
    lastName: true,
  });

  const assignAdminUser = (assignee1: string) => {
    mutateAssignee({
      variables: {
        adminUserId: parseInt(assignee1, 10),
        componentId: +params.id || -1,
      },
    });
  };

  const [replaceComponent] = useReplaceComponentProcess(
    {
      success: true,
    },
    {
      onCompleted: () => {
        Toast({
          title: translate(TasksLocale.SUCCESSFULLY_ASSIGNED, language),
          status: 'success',
        });
      },
      onError: (error) => {
        handleApolloErrors(error, language);
      },
    },
  );

  const doReplaceComponent = () => {
    if (inputIdRef.current?.value) {
      replaceComponent({
        variables: {
          componentId: +params.id,
          replaceProcess: {
            startInActivityId: inputIdRef.current?.value.toString(),
          },
        },
      });
    } else {
      replaceComponent({
        variables: {
          componentId: +params.id,
        },
      });
    }
    onClose();
  };

  return (
    <>
      {!data ? <LoadingScreen /> : (
        <>
          <SectionContainer>
            <Box overflowX="auto">
              <Box>
                <DetailHeader>
                  <Box ml={4}>
                    <Box ml={4}>
                      <H1>
                        {
                                `${data?.component?.componentDefinition?.internalName} `
                                || 'Loading'
                            }
                      </H1>
                    </Box>
                    <Box ml={4}>
                      <Heading size="md" color="primaryBase" mb={3}>
                        {
                                `${data?.component?.componentDefinition?.processDefinition} `
                                || 'Loading'
                            }
                      </Heading>
                    </Box>
                  </Box>
                  <Flex justifyContent={['flex-start', 'flex-start', 'flex-start', 'flex-end']}>
                    <Flex alignItems="center" w="200px" minW="fit-content">
                      <Box w="100%">
                        <StateBadge
                          state={data?.component?.state}
                          size="lg"
                        />
                        <ResultBadge
                          result={data?.component?.result}
                          size="lg"
                        />
                        <FormControl mt={3}>
                          <FormLabel><Translate label={TasksLocale.ASSIGNEE} /></FormLabel>
                          <Select
                            onChange={(event) => {
                              setAssignee(event.target.value);
                              assignAdminUser(event.target.value);
                            }}
                            value={assignee}
                            isDisabled={data.component?.state === State.FINISHED || mutateAssigneeOptions.loading}
                          >
                            <option value="">- Select value -</option>
                            { allUsers.data?.adminUsers.map((userData) => (
                              <option key={userData.id} value={userData.id}>
                                {`${userData.firstName} ${userData.lastName}`}
                              </option>
                            )) }
                          </Select>
                          {/*                          <HStack justifyContent="right">
                            <Button mt={5} variant="outline" color="primaryBase">
                              button
                            </Button>
                          </HStack> */}
                        </FormControl>
                      </Box>
                    </Flex>
                  </Flex>
                </DetailHeader>

                <Tabs size="lg">
                  <TabList>
                    <Tab><Translate label={TasksLocale.TASKS} /></Tab>
                    <Tab><Translate label={TasksLocale.INTEGRATION} /></Tab>
                    <Tab><Translate label={TasksLocale.NOTES} /></Tab>
                    <Tab><Translate label={TasksLocale.LOGS} /></Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <TasksDetail
                        tasks={list}
                        loading={loading}
                        processDefinitionModel={data.processDefinitionModel}
                      />
                      <Flex justifyContent="end">
                        <Button onClick={onOpen} mt="3">
                          <Translate label={TasksLocale.RESTART} />
                        </Button>
                      </Flex>
                      <Modal isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent p="3">
                          <ModalHeader m="auto" pb="2">You sure?</ModalHeader>
                          <FormControl>
                            <FormLabel m="0" pb="4" fontSize="14" fontWeight="bold">
                              <Translate label={TasksLocale.ACTIVITY_ID} />
                              :
                              <Input type="text" ref={inputIdRef} mt="2" placeholder="***" />
                            </FormLabel>
                          </FormControl>
                          <Flex justifyContent="right">
                            <Button colorScheme="blue" mr={3} onClick={doReplaceComponent}>
                              <Translate label={TasksLocale.SAVE} />
                            </Button>
                            <Button colorScheme="red" onClick={onClose}>
                              <Translate label={TasksLocale.CANCEL} />
                            </Button>
                          </Flex>
                        </ModalContent>
                      </Modal>
                    </TabPanel>
                    <TabPanel>
                      <IntegrationDetail componentId={data.component?.id} componentState={data.component?.state} />
                    </TabPanel>
                    <TabPanel>
                      <Notes />
                    </TabPanel>
                    <TabPanel>
                      <ComponentLog />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </Box>
          </SectionContainer>
        </>
      )}
    </>
  );
};

export default ComponentDetailView;
