import React, { useEffect, useMemo, useState } from 'react';
import {
  FormControl, FormErrorMessage, FormLabel, GridItem, Input, SimpleGrid,
} from '@chakra-ui/react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Select } from 'chakra-react-select';

import { Toast } from '@scaut-sro/meepo';
import { translate } from '../../../../../core/localization/LocaleUtils';
import FormLocale from '../../../../../core/localization/translations/Form.locale';
import { useGetUser } from '../../../../../core/store/reducers/UserSettingsReducer';
import Translate from '../../../../../components/Translate/Translate';
import { useClientUserCreate } from '../../../../../build/generated-sources/service/MutationService';
import { TeamDto } from '../../../../../build/generated-sources/dto/TeamDto';
import { KeycloakRoleDto } from '../../../../../build/generated-sources/dto/KeycloakRoleDto';
import {
  useGetClientRoles,
  useGetTeams,
} from '../../../../../build/generated-sources/service/QueryService';
import { ClientUserRegistrationFormAttr, ClientUserRegistrationFormProps } from './ClientUserRegistrationForm.model';
import ClientUserRegistrationLocale from './ClientUserRegistration.locale';
import { handleApolloErrors } from '../../../../../core/error/ScautErrorHandler';

const clientsRegistrationMapper = {
  id: true,
};

const ClientUserRegistrationForm: React.FunctionComponent<ClientUserRegistrationFormProps> = (props) => {
  const {
    client, formRef, loadingSetter, cancelChangesTrigger,
  } = props;
  const { params } = useRouteMatch<{ id: string }>();
  const clientUserId = Number(params.id);
  const history = useHistory();
  const { language } = useGetUser();
  const [roleList, setRoleList] = useState<KeycloakRoleDto[]>([]);
  const [teamList, setTeamList] = useState<TeamDto[]>([]);
  const defaultValues: Partial<ClientUserRegistrationFormAttr> = useMemo(() => ({
    email: client?.email,
    role: {
      value: client?.role || 'regular',
      label: client?.role || 'regular',
    },
    teams: client?.teams?.map((team) => ({
      value: team.id || -1,
      label: team.name || '',
    })),
  }), [client]);

  const {
    handleSubmit, register, setValue, reset, formState: { errors }, control,
  } = useForm<Partial<ClientUserRegistrationFormAttr>>({
    defaultValues,
  });

  const roleOptions = useMemo(() => roleList.map((c) => ({
    value: c.id,
    label: c.name,
  })), [roleList]);

  const roleOption = useMemo(() => (roleOptions.find(
    (role) => role.value === client?.role,
  )), [roleOptions, client]);

  const teamOptions = useMemo(() => teamList.map((team) => ({
    value: team.id,
    label: team.name,
  })), [teamList]);

  useGetClientRoles(
    {
      id: true,
      name: true,
    }, {
      onError: () => {
        Toast({
          title: translate(ClientUserRegistrationLocale.ERROR_GETTING_ROLES, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setRoleList(response.clientRoles);
      },
    },
  );
  useGetTeams(
    {
      id: true,
      name: true,
    }, {
      onError: () => {
        Toast({
          title: translate(ClientUserRegistrationLocale.ERROR_GETTING_TEAMS, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setTeamList(response.teams);
      },
    },
  );

  const teamOptionSelected = useMemo(() => (teamOptions.filter(
    (option) => {
      const isSelected = client?.teams?.find(
        (team) => team.id === option.value,
      );
      return !!isSelected;
    },
  )), [teamOptions, client]);

  useEffect(() => {
    reset(defaultValues);
    setValue('role', roleOption as { value: string, label: string });
    setValue('teams', teamOptionSelected as { value: number, label: string }[]);
  }, [
    cancelChangesTrigger, reset, setValue, defaultValues, roleOption, teamOptionSelected,
  ]);

  const [mutateClientUserCreate,
    mutateClientUserCreateOptions] = useClientUserCreate(clientsRegistrationMapper, {
    onCompleted: (response) => {
      Toast({
        title: translate(ClientUserRegistrationLocale.INVITE_SENT, language),
        status: 'success',
      });
      history.push(`/client-user/${response.clientUserCreate.id}`);
    },
    onError: (error) => {
      handleApolloErrors(error, language);
    },
  });

  useEffect(() => {
    if (loadingSetter) loadingSetter(mutateClientUserCreateOptions.loading);
  }, [loadingSetter, mutateClientUserCreateOptions]);

  const handleClientUserRegistrationFormSubmit: SubmitHandler<ClientUserRegistrationFormAttr> = (values) => {
    mutateClientUserCreate({
      variables: {
        clientId: clientUserId || -1,
        clientUser: {
          email: values.email,
          role: values?.role?.value,
          teamIds: values?.teams?.map((team) => team.value),
        },
      },
    });
  };

  return (
    <>
      <form
        name="clientUsersRegistrationForm"
        onSubmit={handleSubmit(handleClientUserRegistrationFormSubmit)}
        ref={formRef}
      >
        <SimpleGrid columns={[1, 1, 1, 3, 3, 3]} spacing={6} mb={7}>
          <GridItem colSpan={[1, 1, 1, 2, 2, 2]}>
            <FormControl mb={6} isInvalid={!!errors.email}>
              <FormLabel htmlFor="email">
                <Translate label={ClientUserRegistrationLocale.EMAIL} />
              </FormLabel>
              <Input
                placeholder={translate(ClientUserRegistrationLocale.EMAIL, language)}
                {...register('email', {
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                  pattern: {
                    // eslint-disable-next-line max-len
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: translate(ClientUserRegistrationLocale.EMAIL_ERROR_INVALID, language),
                  },
                })}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl mb={6} isInvalid={!!errors.role}>
              <FormLabel htmlFor="role">
                <Translate label={ClientUserRegistrationLocale.USER_ROLE} />
              </FormLabel>
              <Controller
                name="role"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={roleOptions}
                    hideSelectedOptions={false}
                    selectedOptionStyle="check"
                  />
                )}
                rules={{
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                }}
              />
            </FormControl>
            <FormControl mb={6} isInvalid={!!errors.teams}>
              <FormLabel htmlFor="teams">
                <Translate label={ClientUserRegistrationLocale.TEAMS} />
              </FormLabel>
              <Controller
                name="teams"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={teamOptions}
                    hideSelectedOptions={false}
                    selectedOptionStyle="check"
                    isMulti
                    closeMenuOnSelect={false}
                  />
                )}
                rules={{
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                }}
              />
            </FormControl>
          </GridItem>
        </SimpleGrid>
      </form>
    </>
  );
};
export default ClientUserRegistrationForm;
