import { format } from 'date-fns';
import { Language } from '../../build/generated-sources/enum/Language';
import { useGetUser } from '../store/reducers/UserSettingsReducer';

export type BenevolentDate = string | Date | undefined;

export function showDate(language: Language, date?: BenevolentDate): string | undefined {
  let dateObject;
  if (date) {
    if (typeof date === 'string') {
      dateObject = new Date(date);
    } else {
      dateObject = date;
    }
    if (language === Language.CZ) {
      return format(dateObject, 'd.M.yyyy');
    }
    return format(dateObject, 'd/M/yyyy');
  }
  return undefined;
}

export function showDateTime(language: Language, date?: BenevolentDate): string | undefined {
  let dateObject;
  if (date) {
    if (typeof date === 'string') {
      dateObject = new Date(date);
    } else {
      dateObject = date;
    }
    if (language === Language.CZ) {
      return format(dateObject, 'd.M.yyyy h:mm');
    }
    return format(dateObject, 'd/M/yyyy h:mm');
  }
  return undefined;
}

export function useShowDate(date?: BenevolentDate, time?: boolean) {
  const { language } = useGetUser();
  return time ? showDateTime(language, date) : showDate(language, date);
}

export function secondsToTime(seconds: number) {
  const h = Math.floor(seconds / 3600).toString().padStart(2, '0');
  const m = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
  const s = Math.floor(seconds % 60).toString().padStart(2, '0');

  return `${h}h ${m}m ${s}s`;
}
