import { Translation } from '../../core/localization/Localization.model';

class ResourceDatabaseLocale {
  public static ITEM_DETAIL: Translation = { EN: 'Item detail', CZ: 'Detail záznamu' };
  public static ITEMS: Translation = { EN: 'Items', CZ: 'Záznamy' };
  public static COUNTRY: Translation = { EN: 'Country', CZ: 'země' };
  public static NAME: Translation = { EN: 'Name', CZ: 'Jméno' };
  public static FIELD_NAME: Translation = { EN: 'Field name', CZ: 'Jméno pole' };
  public static FORM_INPUT_TYPE: Translation = { EN: 'Form input type', CZ: 'Typ pole' };
  public static IS_MANDATORY: Translation = { EN: 'Is mandatory', CZ: 'Je povinné' };
  public static IS_PRIVATE: Translation = { EN: 'Is private', CZ: 'Je privatní' };
  public static TABLE_DETAIL_GO_TO_DETAIL: Translation = { EN: 'Go to detail', CZ: 'Přejít na detail' };
  public static NO_FIELDS: Translation = {
    EN: 'This form requires no further information.',
    CZ: 'Tento formulář v tuto chvíli nevyžaduje žádné další informace.',
  };
  public static ERROR_GETTING_COUNTRIES: Translation = {
    EN: 'There was a problem getting countries.',
    CZ: 'Problém při načitání zemí',
  };
  public static ERROR_GETTING_DATA: Translation = {
    EN:
        'There was a problem getting data',
    CZ: 'Při získávání dat došlo k problému',
  };
}

export default ResourceDatabaseLocale;
