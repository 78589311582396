import {
  ConfirmationModal,
  H1, H3, SectionContainer, Toast,
} from '@scaut-sro/meepo';
import {
  Box,
  Button,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Spacer,
  ButtonGroup,
  HStack,
  Flex, Badge, useDisclosure,
} from '@chakra-ui/react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import React, { useRef, useState, useEffect } from 'react';
import Translate from '../../../../components/Translate/Translate';
import DetailHeader from '../../../../components/DetailHeader/DetailHeader';
import { translate } from '../../../../core/localization/LocaleUtils';
import { useGetUser } from '../../../../core/store/reducers/UserSettingsReducer';
import {
  useLazyGetClientDetail,
} from '../../../../build/generated-sources/service/QueryService';
import ClientsLocale from '../../Clients.locale';
import ClientDetailForm from './ClientDetailForm';
import FormLocale from '../../../../core/localization/translations/Form.locale';
import { Client } from '../../../../build/generated-sources/dto/Client';
import LoadingScreen from '../../../LoadingScreen/LoadingScreen';
import PrepaidBalance from '../../../../components/PrepaidBalance/PrepaidBalance';
import ClientAddPrepaymentModal from '../AddPrepaymentModal/ClientAddPrepaymentModal';
import { PaymentMethod } from '../../../../build/generated-sources/enum/PaymentMethod';
import { UseGetClientDetailData } from '../../../../build/generated-sources/service/QueryServiceModel';
import ClientUsers from '../ClientUsersDetail/ClientUsers';
import ClientInvoices from '../ClientInvoices/ClientInvoices';
import {
  useClientActivate,
  useClientDeactivate,
  useClientDelete,
} from '../../../../build/generated-sources/service/MutationService';
import ClientOrders from '../ClientOrders/ClientOrders';

const ClientDetail:React.FunctionComponent = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const clientId = Number.isNaN(+params.id);
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const { isOpen: isDeactivateOpen, onOpen: onDeactivateOpen, onClose: onDeactivateClose } = useDisclosure();
  const { isOpen: isActivateOpen, onOpen: onActivateOpen, onClose: onActivateClose } = useDisclosure();
  const formRef = useRef<HTMLFormElement>(null);
  const [formIsLoading, setFormIsLoading] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<number>(0);
  const [data, setData] = useState<Client | undefined>(undefined);
  const [allowedPaymentMethods, setAllowedPaymentMethods] = useState<PaymentMethod[] | []>([]);
  const [prepaidBalance, setPrepaidBalance] = useState<number | undefined>(0);
  const { language } = useGetUser();
  const history = useHistory();

  const onFetchCompleted = (response: UseGetClientDetailData) => {
    if (response.clientDetail === null) {
      Toast({
        title: translate(ClientsLocale.ERROR_GETTING_DATA, language),
        status: 'error',
      });
    }
    setData(response.clientDetail.client);
    setAllowedPaymentMethods(response.clientDetail.allowedPaymentMethods || []);
    setPrepaidBalance(response.clientDetail.prepaidBalance);
  };

  const [clientHandle, { refetch }] = useLazyGetClientDetail(
    {
      client: {
        id: true,
        name: true,
        email: true,
        phone: true,
        ico: true,
        dic: true,
        street: true,
        zipcode: true,
        city: true,
        country: {
          id: true,
          translation: {
            id: true,
            localizationStrings: true,
          },
        },
        active: true,
        enterprise: true,
        enabled2fa: true,
        screeningApprovals: true,
        anonymizeAfter: true,
        invoiceParts: true,
        allowPrepayment: true,
        communicationLanguage: true,
        supervisor: {
          id: true,
          firstName: true,
          lastName: true,
        },
        allowedCountries: {
          id: true,
          translation: {
            localizationStrings: true,
          },
        },
        defaultCountries: {
          id: true,
          translation: {
            localizationStrings: true,
          },
        },
        screeningPackages: {
          id: true,
          name: {
            localizationStrings: true,
          },
        },
      },
      prepaidBalance: true,
      allowedPaymentMethods: true,
    },
    {
      onCompleted: onFetchCompleted,
      onError: () => {
        Toast({
          title: translate(ClientsLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      },
      variables: {
        clientId: +params.id,
      },
    },
  );

  useEffect(() => {
    if (!clientId) {
      clientHandle({
        variables: {
          clientId: +params.id,
        },
      });
    }
  }, [clientHandle, params.id, clientId]);

  const refreshData = () => {
    if (refetch) {
      refetch().then((response) => onFetchCompleted(response.data));
    }
  };

  const [mutateDeleteClient] = useClientDelete({
    success: true,
  }, {
    onCompleted: () => {
      Toast({
        title: translate(FormLocale.SUCCESSFULLY_SAVED, language),
        status: 'success',
      });
      history.push('/clients');
    },
    onError: () => {
      Toast({
        title: translate(ClientsLocale.ERROR_GETTING_DATA, language),
        status: 'error',
      });
    },
  });

  const [mutateDeactivateClient] = useClientDeactivate({
    success: true,
  }, {
    onCompleted: () => {
      Toast({
        title: translate(FormLocale.SUCCESSFULLY_SAVED, language),
        status: 'success',
      });
      refreshData();
    },
    onError: () => {
      Toast({
        title: translate(ClientsLocale.ERROR_GETTING_DATA, language),
        status: 'error',
      });
    },
  });

  const [mutateActivateClient] = useClientActivate({
    success: true,
  }, {
    onCompleted: () => {
      Toast({
        title: translate(FormLocale.SUCCESSFULLY_SAVED, language),
        status: 'success',
      });
      refreshData();
    },
    onError: () => {
      Toast({
        title: translate(ClientsLocale.ERROR_GETTING_DATA, language),
        status: 'error',
      });
    },
  });

  const deleteClient = () => {
    mutateDeleteClient({
      variables: {
        clientId: +params.id,
      },
    });
    onDeleteClose();
  };

  const deactivateClient = () => {
    mutateDeactivateClient({
      variables: {
        clientId: +params.id,
      },
    });
    onDeactivateClose();
  };

  const activateClient = () => {
    mutateActivateClient({
      variables: {
        clientId: +params.id,
      },
    });
    onActivateClose();
  };

  return (
    <>
      <SectionContainer>
        <Box overflowX="auto">
          <Box>
            <DetailHeader>
              <Box w="100%">
                <H1>{data?.name}</H1>
                <H3><Translate label={ClientsLocale.CLIENT_DETAIL} /></H3>
                {data?.active ? (
                  <Badge colorScheme="green"><Translate label={ClientsLocale.ACTIVE} /></Badge>
                ) : (
                  <Badge colorScheme="red"><Translate label={ClientsLocale.DEACTIVATED} /></Badge>
                )}
              </Box>
              <Flex justifyContent={['flex-start', 'flex-start', 'flex-start', 'flex-end']}>
                <Flex alignItems="center" w="200px" minW="fit-content">
                  <Box w="100%">
                    <PrepaidBalance balance={prepaidBalance || 0} />
                  </Box>
                  <Box w="100%">
                    <ClientAddPrepaymentModal clientId={data?.id} refreshData={refreshData} />
                  </Box>
                </Flex>
              </Flex>
            </DetailHeader>
            <Tabs size="lg">
              <TabList>
                <Tab><Translate label={ClientsLocale.GENERAL} /></Tab>
                <Tab><Translate label={ClientsLocale.USERS} /></Tab>
                <Tab><Translate label={ClientsLocale.ORDERS} /></Tab>
                <Tab><Translate label={ClientsLocale.INVOICES} /></Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  {!data ? <LoadingScreen /> : (
                    <Box>
                      <ClientDetailForm
                        client={data}
                        allowedPaymentMethods={allowedPaymentMethods}
                        loadingSetter={(isLoading) => { setFormIsLoading(isLoading); }}
                        formRef={formRef}
                        cancelChangesTrigger={resetForm}
                      />
                    </Box>
                  )}
                  <HStack mt={6}>
                    <Button
                      colorScheme="red"
                      onClick={onDeleteOpen}
                    >
                      <Translate label={ClientsLocale.DELETE_CLIENT} />
                    </Button>
                    <ConfirmationModal
                      message={translate(ClientsLocale.DELETE_CLIENT_ARE_YOU_SURE, language)}
                      yes={translate(FormLocale.YES, language)}
                      no={translate(FormLocale.NO, language)}
                      name={`${data?.name?.split(' ').slice(0, 5).join(' ')}?`}
                      modalFunction={deleteClient}
                      isOpen={isDeleteOpen}
                      onClose={onDeleteClose}
                      onOpen={onDeleteOpen}
                    />
                    {data?.active ? (
                      <>
                        <Button
                          colorScheme="red"
                          onClick={onDeactivateOpen}
                        >
                          <Translate label={ClientsLocale.DEACTIVATE_CLIENT} />
                        </Button>
                        <ConfirmationModal
                          message={translate(ClientsLocale.DEACTIVATE_CLIENT_ARE_YOU_SURE, language)}
                          yes={translate(FormLocale.YES, language)}
                          no={translate(FormLocale.NO, language)}
                          name={`${data?.name?.split(' ').slice(0, 5).join(' ')}?`}
                          modalFunction={deactivateClient}
                          isOpen={isDeactivateOpen}
                          onClose={onDeactivateClose}
                          onOpen={onDeactivateOpen}
                        />
                      </>
                    ) : (
                      <>
                        <Button
                          colorScheme="green"
                          onClick={onActivateOpen}
                        >
                          <Translate label={ClientsLocale.ACTIVATE_CLIENT} />
                        </Button>
                        <ConfirmationModal
                          message={translate(ClientsLocale.ACTIVATE_CLIENT_ARE_YOU_SURE, language)}
                          yes={translate(FormLocale.YES, language)}
                          no={translate(FormLocale.NO, language)}
                          name={`${data?.name?.split(' ').slice(0, 5).join(' ')}?`}
                          modalFunction={activateClient}
                          isOpen={isActivateOpen}
                          onClose={onActivateClose}
                          onOpen={onActivateOpen}
                        />
                      </>

                    )}
                    <Spacer />
                    <ButtonGroup>
                      <Button
                        onClick={() => {
                          if (formRef) {
                            formRef.current?.dispatchEvent(new Event('submit', {
                              cancelable: true,
                              bubbles: true,
                            }));
                          }
                        }}
                        isLoading={formIsLoading}
                      >
                        <Translate label={FormLocale.SAVE} />
                      </Button>
                      <Button
                        variant="ghost"
                        colorScheme="red"
                        isDisabled={formIsLoading}
                        onClick={() => {
                          setResetForm(resetForm + 1);
                        }}
                      >
                        <Translate label={FormLocale.CANCEL} />
                      </Button>
                    </ButtonGroup>
                  </HStack>
                </TabPanel>
                <TabPanel>
                  <Box overflowX="auto">
                    <ClientUsers clientId={+params.id} />
                  </Box>
                </TabPanel>
                <TabPanel>
                  <ClientOrders clientId={+params.id} />
                </TabPanel>
                <TabPanel>
                  <Box overflowX="auto">
                    <ClientInvoices clientId={+params.id} />
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
      </SectionContainer>
    </>
  );
};

export default ClientDetail;
