export enum FormInputType {
  TEXT_INPUT = 'TEXT_INPUT',
  NUMBER_INPUT = 'NUMBER_INPUT',
  EMAIL_INPUT = 'EMAIL_INPUT',
  PHONE_INPUT = 'PHONE_INPUT',
  NATIONAL_ID_NUMBER_INPUT = 'NATIONAL_ID_NUMBER_INPUT',
  DATEPICKER = 'DATEPICKER',
  SWITCH = 'SWITCH',
  SWITCH_INLINE = 'SWITCH_INLINE',
  READ_ONLY = 'READ_ONLY',
  CHECKBOX = 'CHECKBOX',
  SELECT = 'SELECT',
  SELECT_RD = 'SELECT_RD',
  FILE = 'FILE',
  COUNTABLE_DYNAMIC_FIELD = 'COUNTABLE_DYNAMIC_FIELD',
}
