import { Translation } from '../../core/localization/Localization.model';

class OrderDetailLocale {
  public static ORDER: Translation = { EN: 'Order', CZ: 'Objednávka' };
  public static ORDER_DETAIL: Translation = { EN: 'Order detail', CZ: 'Detail objednávky' };
  public static CANDIDATE_SCREENINGS: Translation = { EN: 'Candidate screenings', CZ: 'Kontroly' };
  public static SCREENINGS: Translation = { EN: 'Screenings', CZ: 'Dílčí kontroly' };
  public static STATE: Translation = { EN: 'State', CZ: 'Status' };
  public static RESULT: Translation = { EN: 'Result', CZ: 'Výsledek' };
  public static CANDIDATES: Translation = { EN: 'Candidates', CZ: 'Kandidáti' };
  public static PRICE: Translation = { EN: 'Price', CZ: 'Cena' };
  public static FIRST_NAME: Translation = { EN: 'First name', CZ: 'Jméno' };
  public static LAST_NAME: Translation = { EN: 'Last name', CZ: 'Příjmení' };
  public static DATE_OF_BIRTH: Translation = { EN: 'Date of birth', CZ: 'Datum narození' };
  public static NAME: Translation = { EN: 'Name', CZ: 'Název' };
  public static DESCRIPTION: Translation = { EN: 'Description', CZ: 'Popis' };
  public static ORDER_RECAP: Translation = { EN: 'Order recapitulation', CZ: 'Rekapitulace objednávky' };
  public static ERROR_GETTING_DATA: Translation = {
    EN:
        'There was a problem getting data',
    CZ: 'Při získávání dat došlo k problému',
  };
}

export default OrderDetailLocale;
