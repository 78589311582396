import React, { useEffect } from 'react';
import {
  Button, FormControl, FormErrorMessage, FormLabel, Input, SimpleGrid,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Toast } from '@scaut-sro/meepo';
import Translate from '../../components/Translate/Translate';
import UserSettingsLocale from './UserSettings.locale';
import { translate } from '../../core/localization/LocaleUtils';
import FormLocale from '../../core/localization/translations/Form.locale';
import { useGetUser, useUserSettingsReduce } from '../../core/store/reducers/UserSettingsReducer';
import { useUpdateUserProfile } from '../../build/generated-sources/service/MutationService';
import { AdminUserGraphQLInput } from '../../build/generated-sources/dto/AdminUserGraphQLInput';
import { AdminUser } from '../../build/generated-sources/dto/AdminUser';
import { handleApolloErrors } from '../../core/error/ScautErrorHandler';
import {
  UseUpdateUserProfileData,
} from '../../build/generated-sources/service/MutationServiceModel';

const UserInfoForm: React.FunctionComponent = () => {
  const { language, userProfile } = useGetUser();
  const {
    handleSubmit, register, formState: { errors }, reset,
  } = useForm();
  const { setUserProfile } = useUserSettingsReduce();
  const onUpdateUserProfileComplete = (data: UseUpdateUserProfileData) => {
    if (data.updateUserProfile.email) {
      const newUserProfile: AdminUser = data.updateUserProfile;
      setUserProfile(newUserProfile, true);
      Toast({
        title: translate(UserSettingsLocale.PERSONAL_INFORMATION_SUCCESS, language),
        status: 'success',
      });
    }
  };
  const [mutateUserProfile, { loading }] = useUpdateUserProfile({
    firstName: true,
    email: true,
    lastName: true,
    avatar: true,
  }, {
    onCompleted: onUpdateUserProfileComplete,
    onError: (error) => {
      handleApolloErrors(error, language);
    },
  });

  type FormModel = AdminUserGraphQLInput;

  const handleUserInfoSubmit: SubmitHandler<FormModel> = (values) => {
    mutateUserProfile({
      variables: {
        adminUser: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
        },
      },
    });
  };

  useEffect(() => {
    reset(userProfile);
  }, [reset, userProfile]);
  return (
    <>

      <form name="userInfo" onSubmit={handleSubmit(handleUserInfoSubmit)}>
        <FormControl isInvalid={errors.avatar} />
        <SimpleGrid columns={[1, 2, 2, 2, 3]} spacing={6} mb={6}>
          <FormControl isInvalid={errors.firstName}>
            <FormLabel htmlFor="firstName"><Translate label={UserSettingsLocale.FIRST_NAME} /></FormLabel>
            <Input
              id="firstName"
              defaultValue={userProfile?.firstName}
              placeholder={translate(UserSettingsLocale.FIRST_NAME_PLACEHOLDER, language)}
              {...register('firstName', {
                required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
              })}
            />
            <FormErrorMessage>
              {errors.firstName && errors.firstName.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.lastName}>
            <FormLabel htmlFor="lastName"><Translate label={UserSettingsLocale.LAST_NAME} /></FormLabel>
            <Input
              id="lastName"
              defaultValue={userProfile?.lastName}
              placeholder={translate(UserSettingsLocale.LAST_NAME_PLACEHOLDER, language)}
              {...register('lastName', {
                required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
              })}
            />
            <FormErrorMessage>
              {errors.lastName && errors.lastName.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.email}>
            <FormLabel htmlFor="email"><Translate label={UserSettingsLocale.EMAIL} /></FormLabel>
            <Input
              id="email"
              defaultValue={userProfile?.email}
              placeholder={translate(UserSettingsLocale.EMAIL_PLACEHOLDER, language)}
              {...register('email', {
                required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                pattern: {
                  // eslint-disable-next-line max-len
                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: translate(UserSettingsLocale.EMAIL_ERROR_INVALID, language),
                },
              })}
            />
            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>
        </SimpleGrid>
        <Button mt={4} isLoading={loading} type="submit">
          <Translate label={FormLocale.CONFIRM} />
        </Button>
      </form>
    </>
  );
};

export default UserInfoForm;
