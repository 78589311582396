import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  ConfirmationModal,
  H1, SectionContainer, Toast,
} from '@scaut-sro/meepo';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import CountriesDetailForm from './CountriesDetailForm';
import DetailHeader from '../../../components/DetailHeader/DetailHeader';
import Translate from '../../../components/Translate/Translate';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import { useLazyGetCountryDetail } from '../../../build/generated-sources/service/QueryService';
import FormLocale from '../../../core/localization/translations/Form.locale';
import CountriesLocale from '../Countries.locale';
import { useCountryDelete } from '../../../build/generated-sources/service/MutationService';
import { translate } from '../../../core/localization/LocaleUtils';
import { useGetUser } from '../../../core/store/reducers/UserSettingsReducer';
import { Country } from '../../../build/generated-sources/dto/Country';

const CountriesDetail: React.FunctionComponent = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const countryId = Number.isNaN(+params.id);
  const history = useHistory();
  const { language } = useGetUser();
  const [data, setData] = useState<Country | undefined>(undefined);
  const [formIsLoading, setFormIsLoading] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [resetForm, setResetForm] = useState<number>(0);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [countryHandle] = useLazyGetCountryDetail(
    {
      id: true,
      active: true,
      visibleByDefault: true,
      language: true,
      poaJasperReport: true,
      enumeration: {
        id: true,
        value: true,
        translation: {
          id: true,
          name: true,
          localizationStrings: true,
        },
      },
      translation: {
        id: true,
        name: true,
        localizationStrings: true,
      },
    },
    {
      onCompleted: (response) => {
        if (response.countryDetail === null) {
          Toast({
            title: translate(CountriesLocale.ERROR_GETTING_DATA, language),
            status: 'error',
          });
        }
        setData(response.countryDetail);
      },
      onError: () => {
        Toast({
          title: translate(CountriesLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      },
      variables: {
        countryId: +params.id,
      },
    },
  );

  useEffect(() => {
    if (!countryId) {
      countryHandle({
        variables: {
          countryId: +params.id,
        },
      });
    }
  }, [countryHandle, params.id, countryId]);

  const handleBack = () => {
    history.goBack();
  };

  const [deleteCountry] = useCountryDelete({
    success: true,
  }, {
    onCompleted: () => {
      Toast({
        title: translate(CountriesLocale.COUNTRY_DELETED, language),
        status: 'success',
      });
      history.push('/countries');
    },
    onError: () => {
      Toast({
        title: translate(CountriesLocale.ERROR_DELETING_COUNTRY, language),
        status: 'error',
      });
    },
  });

  const deleteSelectedCountry = () => {
    deleteCountry({
      variables: {
        countryId: +params.id,
      },
    });
  };

  return (
    <>
      {!data ? <LoadingScreen /> : (
        <SectionContainer>
          <DetailHeader>
            <H1><Translate label={CountriesLocale.COUNTRY_DETAIL} /></H1>
          </DetailHeader>
          <HStack justifyContent="right">
            <Button
              variant="outline"
              colorScheme="red"
              onClick={onOpen}
              isDisabled={formIsLoading}
            >
              <Translate label={FormLocale.DELETE} />
            </Button>
          </HStack>
          <Box>
            <CountriesDetailForm
              country={data}
              loadingSetter={(isLoading) => { setFormIsLoading(isLoading); }}
              formRef={formRef}
              cancelChangesTrigger={resetForm}
            />
          </Box>
          <Flex mt={8}>
            <Button variant="ghost" onClick={handleBack} leftIcon={<ArrowBackIcon />}>
              <Translate label={FormLocale.BACK} />
            </Button>
            <Spacer />
            <ButtonGroup>
              <Button
                onClick={() => {
                  if (formRef) {
                    formRef.current?.dispatchEvent(new Event('submit', {
                      cancelable: true,
                      bubbles: true,
                    }));
                  }
                }}
                isLoading={formIsLoading}
              >
                <Translate label={FormLocale.SAVE} />
              </Button>
              <Button
                variant="ghost"
                colorScheme="red"
                isDisabled={formIsLoading}
                onClick={() => {
                  setResetForm(resetForm + 1);
                }}
              >
                <Translate label={FormLocale.CANCEL} />
              </Button>
            </ButtonGroup>
          </Flex>
          <ConfirmationModal
            message={translate(CountriesLocale.ARE_YOU_SURE, language)}
            yes={translate(CountriesLocale.YES, language)}
            no={translate(CountriesLocale.NO, language)}
            name={data.translation?.name}
            modalFunction={deleteSelectedCountry}
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
          />
        </SectionContainer>
      )}
    </>
  );
};

export default CountriesDetail;
