import { ApolloClient, ApolloQueryResult } from '@apollo/client';
import { GraphqlBuilder, GraphQLType } from '@scaut-sro/npm-core-generator';
import { UseGetGetFieldsData, UseGetGetFieldsVariables } from '../../../build/generated-sources/service/QueryServiceModel';
import { FormFieldDtoMapper } from '../../../build/generated-sources/dto/FormFieldDto';

function getCompletedDataFields(
  client: ApolloClient<any>,
  mapper: FormFieldDtoMapper,
  variables: UseGetGetFieldsVariables,
): Promise<ApolloQueryResult<UseGetGetFieldsData>> {
  const queryBuilder: GraphqlBuilder<FormFieldDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  const query = queryBuilder
    .setName('getFields')
    .setReturnProperties(mapper)
    .declareVariables({
      candidateScreeningDetailId: 'Long!',
      fieldIds: '[String]',
    })
    .setProperties('candidateScreeningDetailId: $candidateScreeningDetailId, fieldIds: $fieldIds')
    .build();

  return client.query<UseGetGetFieldsData, UseGetGetFieldsVariables>({
    query,
    variables,
  });
}

export default getCompletedDataFields;
