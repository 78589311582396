import React from 'react';
import { useHistory } from 'react-router-dom';
import TasksTable from '../TasksTable';
import TasksLocale from '../Tasks.locale';
import { ViewType } from '../Tasks.model';

const Components: React.FunctionComponent = () => {
  const history = useHistory();

  const openComponentDetail = (componentId?: number) => {
    if (componentId) {
      history.push(`/component/${componentId}`);
    } else {
      history.push('/404');
    }
  };

  return (
    <TasksTable
      viewType={ViewType.COMPONENT}
      openDetail={openComponentDetail}
      detailColumnTitle={TasksLocale.COMPONENT_DETAIL}
    />
  );
};

export default Components;
