import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, FormControl, FormErrorMessage, FormLabel, GridItem, Input,
  SimpleGrid, Switch,
} from '@chakra-ui/react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Toast } from '@scaut-sro/meepo';
import { useHistory } from 'react-router-dom';
import { Select } from 'chakra-react-select';
import { KeycloakRoleDto } from '../../../../build/generated-sources/dto/KeycloakRoleDto';
import UserSettingsLocale from '../../../UserSettings/UserSettings.locale';
import { showDateTime } from '../../../../core/date/showDate';
import { TeamDto } from '../../../../build/generated-sources/dto/TeamDto';
import { translate } from '../../../../core/localization/LocaleUtils';
import { useClientUser } from '../../../../build/generated-sources/service/MutationService';
import FormLocale from '../../../../core/localization/translations/Form.locale';
import UsersLocale from '../../../Users/Users.locale';
import { UserFormAttr } from '../../../Users/Users.model';
import { useGetClientRoles, useGetTeams } from '../../../../build/generated-sources/service/QueryService';
import Translate from '../../../../components/Translate/Translate';
import { useGetUser } from '../../../../core/store/reducers/UserSettingsReducer';
import ClientsLocale from '../../Clients.locale';
import { ClientUserDetailProps, ClientUserFormAttr } from './ClientUsers.model';
import { handleApolloErrors } from '../../../../core/error/ScautErrorHandler';

const createClientUserMapper = {
  id: true,
};

const ClientUserDetailForm: React.FunctionComponent<ClientUserDetailProps> = (props) => {
  const {
    clientUser, loadingSetter, formRef,
  } = props;
  const history = useHistory();
  const { language } = useGetUser();
  const [roleList, setRoleList] = useState<KeycloakRoleDto[]>([]);
  const [teamList, setTeamList] = useState<TeamDto[]>([]);
  const defaultValues: Partial<UserFormAttr> = useMemo(() => ({
    clientUserId: clientUser?.id,
    firstName: clientUser?.firstName,
    lastName: clientUser?.lastName,
    email: clientUser?.email,
    active: clientUser?.active,
    phone: clientUser?.phone,
    lastActive: clientUser?.lastActive,
    role: {
      value: clientUser.role || '',
      label: clientUser.role || '',
    },
    teams: clientUser?.teams?.map((team) => ({
      value: team.id || -1,
      label: team.name || '',
    })),
  }), [clientUser]);
  const {
    handleSubmit, register, setValue, reset, formState: { errors }, control,
  } = useForm<Partial<ClientUserFormAttr>>({
    defaultValues,
  });

  useGetClientRoles(
    {
      id: true,
      name: true,
    }, {
      onError: () => {
        Toast({
          title: translate(UsersLocale.ERROR_GETTING_ROLES, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setRoleList(response.clientRoles);
      },
    },
  );

  useGetTeams(
    {
      id: true,
      name: true,
    }, {
      variables: {
        clientId: clientUser.clientId as number,
      },
      onError: () => {
        Toast({
          title: translate(ClientsLocale.ERROR_GETTING_TEAMS, language),
          status: 'error',
        });
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (response) => {
        setTeamList(response.teams);
      },
    },
  );

  const roleOptions = useMemo(() => roleList.map((c) => ({
    value: c.id,
    label: c.name,
  })), [roleList]);

  const roleOption = useMemo(() => (roleOptions.find(
    (role) => role.value === clientUser.role,
  )), [roleOptions, clientUser]);

  const teamOptions = useMemo(() => teamList.map((team) => ({
    value: team.id,
    label: team.name,
  })), [teamList]);

  const teamOptionSelected = useMemo(() => (teamOptions.filter(
    (option) => {
      const isSelected = clientUser?.teams?.find(
        (team) => team.id === option.value,
      );
      return !!isSelected;
    },
  )), [teamOptions, clientUser]);

  useEffect(() => {
    reset(defaultValues);
    setValue('role', roleOption as { value: string, label: string });
    setValue('teams', teamOptionSelected as { value: number, label: string }[]);
  }, [
    reset, setValue, defaultValues, roleOption, teamOptionSelected,
  ]);

  const [mutateUser,
    optionsMutationUser] = useClientUser(createClientUserMapper, {
    onCompleted: (response) => {
      Toast({
        title: translate(FormLocale.SUCCESSFULLY_SAVED, language),
        status: 'success',
      });
      history.push(`/client-user/${response.clientUser.id}`);
    },
    onError: (error) => {
      handleApolloErrors(error, language);
    },
  });

  useEffect(() => {
    if (loadingSetter) loadingSetter(optionsMutationUser.loading);
  }, [loadingSetter, optionsMutationUser]);

  const handleUserFormSubmit: SubmitHandler<ClientUserFormAttr> = (values) => {
    mutateUser({
      variables: {
        clientUserId: clientUser?.id || -1,
        clientUser: {
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
          email: values.email,
          active: values.active,
          role: values?.role?.value,
          teamIds: values?.teams?.map((team) => team.value),
        },
      },
    });
  };

  return (
    <>
      <form
        name="userDetailForm"
        onSubmit={handleSubmit(handleUserFormSubmit)}
        ref={formRef}
      >
        <SimpleGrid columns={[1, 1, 1, 3, 3, 3]} spacing={6} mb={7}>
          <GridItem colSpan={[1, 1, 1, 2, 2, 2]}>
            <FormControl mb={6} isInvalid={!!errors.firstName}>
              <FormLabel htmlFor="firstName">
                <Translate label={ClientsLocale.FIRST_NAME} />
              </FormLabel>
              <Input
                placeholder={translate(ClientsLocale.FIRST_NAME, language)}
                {...register('firstName', {
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                })}
              />
              <FormErrorMessage>
                {errors.firstName && errors.firstName.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl mb={6} isInvalid={!!errors.lastName}>
              <FormLabel htmlFor="lastName">
                <Translate label={ClientsLocale.LAST_NAME} />
              </FormLabel>
              <Input
                placeholder={translate(ClientsLocale.LAST_NAME, language)}
                {...register('lastName', {
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                })}
              />
              <FormErrorMessage>
                {errors.lastName && errors.lastName.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl mb={6} isInvalid={!!errors.email}>
              <FormLabel htmlFor="email">
                <Translate label={ClientsLocale.EMAIL} />
              </FormLabel>
              <Input
                placeholder={translate(UsersLocale.EMAIL, language)}
                {...register('email', {
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                  pattern: {
                    // eslint-disable-next-line max-len
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: translate(UserSettingsLocale.EMAIL_ERROR_INVALID, language),
                  },
                })}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl mb={6} isInvalid={!!errors.phone}>
              <FormLabel htmlFor="phone">
                <Translate label={ClientsLocale.PHONE} />
              </FormLabel>
              <Input
                placeholder={translate(ClientsLocale.PHONE, language)}
                {...register('phone', {
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                  pattern: {
                    value: /^\+?[\d\s-]*$/,
                    message: translate(FormLocale.INVALID_CHARACTERS, language),
                  },
                })}
              />
              <FormErrorMessage>
                {errors.phone && errors.phone.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            {clientUser.id && (
              <Box>
                <FormControl
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderRadius="md"
                  backgroundColor="gray.100"
                  p="6px"
                  pl={3}
                  mt="30px"
                  mb={6}
                >
                  <label htmlFor="active">{translate(ClientsLocale.ACTIVE, language)}</label>
                  <Controller
                    control={control}
                    name="active"
                    render={({ field }) => (
                      <Switch
                        onChange={(e) => { field.onChange(e.target.checked); }}
                        isChecked={field.value}
                        size="lg"
                        colorScheme="green"
                      />
                    )}
                  />
                </FormControl>
              </Box>
            )}
            <FormControl mb={6} isInvalid={!!errors.role}>
              <FormLabel htmlFor="role">
                <Translate label={UsersLocale.USER_ROLE} />
              </FormLabel>
              <Controller
                name="role"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={roleOptions}
                    hideSelectedOptions={false}
                    selectedOptionStyle="check"
                  />
                )}
                rules={{
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                }}
              />
            </FormControl>
            <FormControl mb={6} isInvalid={!!errors.teams}>
              <FormLabel htmlFor="teams">
                <Translate label={ClientsLocale.TEAMS} />
              </FormLabel>
              <Controller
                name="teams"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={teamOptions}
                    hideSelectedOptions={false}
                    selectedOptionStyle="check"
                    isMulti
                    closeMenuOnSelect={false}
                  />
                )}
                rules={{
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                }}
              />
            </FormControl>
            <FormControl mb={6} isInvalid={!!errors.lastActive}>
              <FormLabel htmlFor="lastActive">
                <Translate label={ClientsLocale.LAST_ACTIVE} />
              </FormLabel>
              <Input
                disabled
                placeholder={translate(ClientsLocale.LAST_ACTIVE, language)}
                value={showDateTime(language, clientUser.lastActive)}
              />
            </FormControl>
          </GridItem>
        </SimpleGrid>
      </form>
    </>
  );
};
export default ClientUserDetailForm;
