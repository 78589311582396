import {
  Button, ButtonGroup, FormControl, FormLabel, HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent, ModalFooter,
  ModalHeader,
  ModalOverlay, SimpleGrid,
  Text, useDisclosure,
} from '@chakra-ui/react';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { Select } from 'chakra-react-select';
import { InfoContainer, Toast } from '@scaut-sro/meepo';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MdOutlineAssignmentInd } from 'react-icons/all';
import Translate from '../../components/Translate/Translate';
import FormLocale from '../../core/localization/translations/Form.locale';
import { TasksBulkChangeModalProps } from './Tasks.model';
import { useGetAdminUsers } from '../../build/generated-sources/service/QueryService';
import TasksLocale from './Tasks.locale';
import { useAssignComponents } from '../../build/generated-sources/service/MutationService';
import { translate } from '../../core/localization/LocaleUtils';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';

const createResultResponseMapper = {
  success: true,

};

const TasksBulkChangeModal: React.FunctionComponent<TasksBulkChangeModalProps> = (props) => {
  const {
    componentIds, isDisabled, loadingSetter, refreshTable,
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [assigneeId, setAssigneeId] = useState<{ value: number, label: string }>({ value: -1, label: '' });
  const [disabled, setDisabled] = useState(true);
  const { handleSubmit, register, setValue } = useForm();
  const { language } = useGetUser();
  const formRef = useRef<HTMLFormElement>(null);
  const { data } = useGetAdminUsers({
    id: true,
    firstName: true,
    lastName: true,
  });

  const assigneeIdsOptions = useMemo(() => (
    data?.adminUsers.map((adminUser) => ({
      value: adminUser.id,
      label: `${adminUser.firstName} ${adminUser.lastName}`,
    }))
  ), [data]);

  const [mutateAssignTasks,
    mutateAssignTasksOptions] = useAssignComponents(createResultResponseMapper, {
    onCompleted: () => {
      Toast({
        title: translate(TasksLocale.SUCCESSFULLY_ASSIGNED, language),
        status: 'success',
      });
      if (refreshTable) {
        onClose();
        refreshTable();
      }
    },
    onError: () => {
      Toast({
        title: translate(TasksLocale.ERROR_ASSIGNING_COMPONENT, language),
        status: 'error',
      });
    },
  });

  useEffect(() => {
    if (loadingSetter) loadingSetter(mutateAssignTasksOptions.loading);
  }, [loadingSetter, mutateAssignTasksOptions]);

  useEffect(() => {
    if (assigneeId.label !== '') {
      setDisabled(false);
    }
  }, [assigneeId]);

  const handleAssignSubmit : SubmitHandler<{ assigneeId: number }> = () => {
    mutateAssignTasks({
      variables: {
        componentIds,
        adminUserId: assigneeId.value,
      },
    });
  };

  return (
    <>
      <Button leftIcon={<MdOutlineAssignmentInd />} onClick={onOpen} isDisabled={isDisabled}>
        <Translate label={TasksLocale.ASSIGN} />
      </Button>
      <Modal closeOnOverlayClick={false} size="6xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text>
              <Translate label={TasksLocale.SET_ASSIGNEE} />
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form
              name="tasksBulkChangeModalForm"
              ref={formRef}
              onSubmit={handleSubmit(handleAssignSubmit)}
            >
              <InfoContainer>
                <SimpleGrid gap={6} columns={[1, 1, 2, 2, 2, 3]}>
                  <FormControl>
                    <FormLabel fontSize="sm"><Translate label={TasksLocale.ASSIGNEES} /></FormLabel>
                    <Select
                      size="sm"
                      closeMenuOnSelect
                      value={assigneeId}
                      {...register('assigneeId')}
                      options={assigneeIdsOptions}
                      onChange={(values: { value: number, label: string }) => {
                        setAssigneeId(values);
                        setValue('assigneeId', values.value);
                      }}
                    />
                  </FormControl>
                </SimpleGrid>
              </InfoContainer>
            </form>
          </ModalBody>
          <ModalFooter>
            <HStack>
              <ButtonGroup variant="outline" spacing="6" isDisabled={disabled}>
                <Button
                  type="submit"
                  onClick={() => {
                    if (formRef) {
                      formRef.current?.dispatchEvent(new Event('submit', {
                        cancelable: true,
                        bubbles: true,
                      }));
                    }
                  }}
                >
                  <Translate label={TasksLocale.CONFIRM} />
                </Button>
                <Button variant="ghost" colorScheme="red" onClick={onClose}>
                  <Translate label={FormLocale.CANCEL} />
                </Button>
              </ButtonGroup>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TasksBulkChangeModal;
