import React, {
  Dispatch, SetStateAction,
  useEffect, useMemo, useState,
} from 'react';
import {
  MeepoTable, mergeFilters, SectionContainer, Toast,
} from '@scaut-sro/meepo';
import { Box, Checkbox, Text } from '@chakra-ui/react';
import { useRouteMatch } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { PageInfo } from '@scaut-sro/meepo/lib/components/MeepoTable/MeepoTable.model';
import {
  ChecksAllFilterType, ChecksRouteFilter, ChecksTableData, ChecksTableFilterType,
} from './Checks.model';
import ChecksTableFilter from './ChecksTableFilter';
import { useGetPrimaryOrders } from '../../build/generated-sources/service/QueryService';
import { translate } from '../../core/localization/LocaleUtils';
import ChecksLocale from './Checks.locale';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import TableLocale from '../../core/localization/translations/Table.locale';
import Translate from '../../components/Translate/Translate';
import { OrderType } from '../../build/generated-sources/enum/OrderType';
import { ResultBadge } from '../../components/ResultBadge/ResultBadge';
import { StateBadge } from '../../components/StateBadge/StateBadge';
import SingleCheckDetail from './SingleCheckDetail';
import UserIcon from './UserIcon';
import ShowDate from '../../components/ShowDate/ShowDate';
import { CandidateScreening } from '../../build/generated-sources/dto/CandidateScreening';
import UnfinishedOrderCheckDetail from './UnfinishedOrderCheckDetail';
import MultipleCheckDetail from './MultipleCheckDetail';
import { Direction } from '../../build/generated-sources/enum/Direction';
import { PrimaryOrderDetail } from '../../build/generated-sources/dto/PrimaryOrderDetail';
import { ScreeningPackage } from '../../build/generated-sources/dto/ScreeningPackage';
import ChecksBulkChangeModal from './ChecksBulkChangeModal';
import { OrderState } from '../../build/generated-sources/enum/OrderState';
import { AssigneeType } from '../../build/generated-sources/enum/AssigneeType';
import ScautAdminRoles from '../../core/auth/ScautAdminRoles';

export function getChecksTableData(
  orderIds: Array<number>,
  setOrderIds: Dispatch<SetStateAction<Array<number>>>,
  data?: PrimaryOrderDetail[],
): ChecksTableData[] {
  const fillOrderIds = (orderId: number, e: any) => {
    const newOrderIds = orderIds;
    if (e.target.checked && !newOrderIds.find((id) => id === orderId)) {
      newOrderIds.push(orderId);
    } else if (!e.target.checked && newOrderIds.find((id) => id === orderId)) {
      const index = newOrderIds.indexOf(orderId);
      newOrderIds.splice(index, 1);
    }
    setOrderIds(newOrderIds.concat());
  };
  if (data) {
    return data.map((orderDetail) => {
      const firstCandidateScreening: CandidateScreening | undefined = orderDetail?.order?.subOrders?.[0].candidateScreenings?.[0];
      const screeningPackages: ScreeningPackage[] | undefined = orderDetail?.order?.subOrders?.[0].screeningPackages;
      return {
        checkbox: <Checkbox
          onChange={(e) => {
            if (orderDetail.order?.id) {
              fillOrderIds(orderDetail.order.id, e);
            }
          }}
        />,
        id: orderDetail?.order?.id || '-undefined-',
        detail: firstCandidateScreening
          ? orderDetail?.order?.type === OrderType.SINGLE
            ? (
              <SingleCheckDetail
                candidateScreening={firstCandidateScreening}
                orderId={orderDetail?.order?.id}
                screeningPackages={screeningPackages}
                anonymizationDate={orderDetail.anonymizationDate}
              />
            )
            : (
              <MultipleCheckDetail
                order={orderDetail?.order}
                candidateScreening={firstCandidateScreening}
                screeningPackages={screeningPackages}
                anonymizationDate={orderDetail.anonymizationDate}
              />
            )
          : <UnfinishedOrderCheckDetail order={orderDetail?.order} />,
        type: <UserIcon type={orderDetail?.order?.type || OrderType.SINGLE} />,
        name: orderDetail?.order?.name,
        clientOrderCustomId: orderDetail?.order?.clientOrderCustomId,
        created: <ShowDate date={orderDetail?.order?.created} time />,
        result: <ResultBadge result={orderDetail?.order?.result || undefined} />,
        orderState: <StateBadge state={orderDetail?.order?.orderState || undefined} />,
        accountManager: orderDetail?.accountManager?.firstName && orderDetail?.accountManager?.lastName
          ? `${orderDetail?.accountManager?.firstName} ${orderDetail?.accountManager?.lastName}` : '',
        client: orderDetail?.order?.client?.name,
      };
    });
  }
  return [];
}

const Checks: React.FunctionComponent = () => {
  const { params } = useRouteMatch<{ filter: ChecksRouteFilter }>();
  const { keycloak } = useKeycloak();
  const isSysAdmin = keycloak.hasResourceRole(ScautAdminRoles.SYSADMIN, 'scaut-admin-app');
  const isManager = keycloak.hasResourceRole(ScautAdminRoles.MANAGER, 'scaut-admin-app');

  const filters: ChecksTableFilterType = useMemo(() => {
    if (params.filter) {
      if (params.filter === 'open') {
        return {
          orderState: [
            OrderState.DRAFT,
            OrderState.WAITING_FOR_CLIENT,
            OrderState.WAITING_FOR_ADMIN,
            OrderState.WAITING_FOR_CANDIDATE,
            OrderState.WAITING_FOR_POA,
            OrderState.FOR_APPROVAL,
            OrderState.CANNOT_COMPLETE,
            OrderState.PROCESSING,
          ],
        };
      } if (params.filter === 'assigned-to-me') {
        return {
          orderState: [
            OrderState.DRAFT,
            OrderState.WAITING_FOR_CLIENT,
            OrderState.WAITING_FOR_ADMIN,
            OrderState.WAITING_FOR_CANDIDATE,
            OrderState.WAITING_FOR_POA,
            OrderState.FOR_APPROVAL,
            OrderState.CANNOT_COMPLETE,
            OrderState.PROCESSING,
          ],
        };
      } if (params.filter === 'unassigned') {
        return {
          orderState: [
            OrderState.DRAFT,
            OrderState.WAITING_FOR_CLIENT,
            OrderState.WAITING_FOR_ADMIN,
            OrderState.WAITING_FOR_CANDIDATE,
            OrderState.WAITING_FOR_POA,
            OrderState.FOR_APPROVAL,
            OrderState.CANNOT_COMPLETE,
            OrderState.PROCESSING,
          ],
        };
      }
    }
    return {
      orderState: [],
    };
  }, [params.filter]);

  const [tableFilter, setTableFilter] = useState<ChecksTableFilterType>({});
  const [allFilters, setAllFilter] = useState<ChecksAllFilterType>({});
  const [orderIds, setOrderIds] = useState<Array<number>>([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const { language, userProfile } = useGetUser();
  const [pageInfo, setPageInfo] = useState<PageInfo>({ pageNumber: 0, pageSize: 10, totalPages: 0 });
  const [sorting, setSorting] = useState<{ sortBy?: string, sortDirection?: Direction }>({});
  const [list, setList] = useState<ChecksTableData[]>([]);

  useEffect(() => {
    if (orderIds.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [orderIds, setIsDisabled]);

  const { loading, refetch } = useGetPrimaryOrders(
    {
      pageInfo: {
        pageSize: true,
        pageNumber: true,
        numberOfElements: true,
        totalElements: true,
        totalPages: true,
      },
      content: {
        anonymizationDate: true,
        accountManager: {
          firstName: true,
          lastName: true,
        },
        order: {
          id: true,
          name: true,
          type: true,
          processStarted: true,
          processCompleted: true,
          clientOrderCustomId: true,
          orderState: true,
          result: true,
          created: true,
          client: {
            name: true,
          },
          subOrders: {
            candidateScreenings: {
              id: true,
              processStarted: true,
              processCompleted: true,
              result: true,
              screenings: {
                id: true,
                result: true,
                screeningDefinition: {
                  id: true,
                  name: {
                    localizationStrings: true,
                  },
                },
              },
            },
            screeningPackages: {
              id: true,
              name: {
                localizationStrings: true,
              },
            },
          },

        },
      },
    }, {
      onError: () => {
        Toast({
          title: translate(ChecksLocale.ERROR_GETTING_DATA, language),
          status: 'error',
        });
      },
      variables: {
        paging: {
          page: pageInfo.pageNumber,
          size: pageInfo.pageSize,
          sortBy: sorting.sortBy,
          sortDirection: sorting.sortDirection,
        },
        filter: {
          search: allFilters.fulltext && allFilters.fulltext.length ? allFilters.fulltext : undefined,
          name: allFilters.name && allFilters.name.length ? allFilters.name : undefined,
          clientOrderCustomId: allFilters.clientOrderCustomId && allFilters.clientOrderCustomId.length
            ? allFilters.clientOrderCustomId : undefined,
          createdFrom: allFilters.orderedFrom && allFilters.orderedFrom.length
            ? allFilters.orderedFrom : undefined,
          createdTo: allFilters.orderedTo && allFilters.orderedTo.length ? allFilters.orderedTo : undefined,
          results: allFilters.result && allFilters.result.length ? allFilters.result : undefined,
          states: allFilters.orderState && allFilters.orderState.length ? allFilters.orderState : undefined,
          types: allFilters.type && allFilters.type.length ? allFilters.type : undefined,
          accountManagerIds: allFilters.accountManagerIds && allFilters.accountManagerIds.length
            ? allFilters.accountManagerIds : undefined,
          clientIds: allFilters.clientIds && allFilters.clientIds.length
            ? allFilters.clientIds : undefined,
          accountManagerType: allFilters.accountManagerType ? allFilters.accountManagerType : undefined,
        },
      },
      fetchPolicy: 'cache-and-network',
      pollInterval: 5000,
      onCompleted: (response) => {
        const onCompleteData = getChecksTableData(orderIds, setOrderIds, response.primaryOrders.content);
        setList(onCompleteData);
        setPageInfo({
          pageSize: response.primaryOrders.pageInfo?.pageSize,
          pageNumber: response.primaryOrders.pageInfo?.pageNumber || 0,
          totalPages: response.primaryOrders.pageInfo?.totalPages || 0,
          numberOfElements: response.primaryOrders.pageInfo?.numberOfElements,
          totalElements: response.primaryOrders.pageInfo?.totalElements,
        });
      },
    },
  );

  useMemo(() => {
    const newTableFilter = {
      ...tableFilter,
    };
    if (params.filter && params.filter === 'assigned-to-me') {
      newTableFilter.accountManagerIds = [userProfile?.id || -1];
      newTableFilter.accountManagerType = undefined;
    } else if (params.filter && params.filter === 'unassigned') {
      newTableFilter.accountManagerIds = [];
      newTableFilter.accountManagerType = AssigneeType.NONE;
    } else {
      newTableFilter.accountManagerType = undefined;
    }
    const newAllFilters: ChecksAllFilterType = mergeFilters(newTableFilter, filters) as ChecksAllFilterType;
    setAllFilter(newAllFilters);
  }, [filters, tableFilter, params.filter, userProfile?.id]);

  const refreshTable = () => {
    setList([]);
    setIsDisabled(true);
    refetch();
  };

  return (
    <SectionContainer>
      <Box mb={20}>
        <ChecksTableFilter
          routeFilter={params.filter}
          filter={tableFilter}
          onFilterChange={(newTableFilter) => {
            setTableFilter(newTableFilter);
          }}
        />
      </Box>
      <Box overflowX="auto">
        {isManager || isSysAdmin ? (
          <Box
            display="flex"
            justifyContent="flex-end"
            mb={4}
          >
            <ChecksBulkChangeModal
              isDisabled={isDisabled}
              primaryOrderIds={orderIds}
              refreshTable={refreshTable}
            />
          </Box>
        ) : undefined }
        <Box minW="500px">
          <MeepoTable
            columns={[
              { id: 'checkbox' },
              { id: 'type' },
              { id: 'name', label: translate(ChecksLocale.LABEL, language) },
              { id: 'clientOrderCustomId', label: translate(ChecksLocale.CUSTOM_ID, language) },
              { id: 'created', label: translate(ChecksLocale.ORDER_CREATED, language) },
              { id: 'accountManager', label: translate(ChecksLocale.ACCOUNT_MANAGER, language), minW: '180px' },
              { id: 'client', label: translate(ChecksLocale.CLIENT, language), minW: '180px' },
              {
                id: 'result',
                label: translate(ChecksLocale.ORDER_RESULT, language),
                boxProps: {
                  w: '220px',
                },
              },
              {
                id: 'orderState',
                label: translate(ChecksLocale.ORDER_STATE, language),
                boxProps: {
                  w: '220px',
                },
              },
            ]}
            data={list}
            isLoading={loading}
            noData={<Text><Translate label={TableLocale.NO_DATA} /></Text>}
            pageInfo={pageInfo}
            setPage={(newPageNumber, newPageSize) => {
              setPageInfo({
                ...pageInfo,
                pageNumber: newPageNumber,
                pageSize: newPageSize,
              });
            }}
            onSort={(newSorting) => {
              const direction: Direction | undefined = newSorting.type === 'asc'
                ? Direction.ASC
                : newSorting.type === 'desc'
                  ? Direction.DESC
                  : undefined;
              setSorting({
                sortBy: newSorting.column,
                sortDirection: direction,
              });
            }}
            activeSorting={{
              column: sorting.sortBy,
              type: sorting.sortDirection === Direction.ASC
                ? 'asc'
                : sorting.sortDirection === Direction.DESC
                  ? 'desc'
                  : 'none',
            }}
          />
        </Box>
      </Box>
    </SectionContainer>
  );
};

export default Checks;
