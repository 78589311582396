import React, { useState } from 'react';
import {
  Button,
  ButtonGroup,
  Flex,
  Text,
} from '@chakra-ui/react';
import { SectionContainer, Translate } from '@scaut-sro/meepo';
import OverviewLastChecksOptions from './OverviewLastChecksOptions';
import OverviewLastChecksLocale from './OverviewLastChecks.locale';
import { OrderTypeOptions } from './OverviewLastChecks.model';
import { PrimaryOrderOverviewFilter } from '../../build/generated-sources/dto/PrimaryOrderOverviewFilter';
import { LastRecordType } from '../../build/generated-sources/enum/LastRecordType';
import { OrderState } from '../../build/generated-sources/enum/OrderState';
import { Result } from '../../build/generated-sources/enum/Result';
import NotificationBoxes from '../../components/NotificationBox/NotificationBoxes';

const filters: PrimaryOrderOverviewFilter[] = [
  {
    filterId: 'Everything',
    filter: {},
    lastRecordType: LastRecordType.CREATED,
  },
  {
    filterId: 'Processed',
    filter: {
      states: [OrderState.FINISHED],
    },
    lastRecordType: LastRecordType.FINISHED,
  },
  {
    filterId: 'FoundResults',
    filter: {
      results: [Result.FOUND_RESULTS],
    },
    lastRecordType: LastRecordType.FINISHED,
  },
  {
    filterId: 'Clear',
    filter: {
      results: [Result.CLEAR],
    },
    lastRecordType: LastRecordType.FINISHED,
  },
];

const Overview: React.FunctionComponent = () => {
  const [orderType, setOrderType] = useState<OrderTypeOptions>('ALL');
  const [filter, setFilter] = useState<PrimaryOrderOverviewFilter>({
    filterId: 'Everything',
    filter: {},
    lastRecordType: LastRecordType.CREATED,
  });
  return (
    <SectionContainer>
      <Flex>
        <Text fontSize="2xl" fontWeight="semibold" mb={6} color="#002458">
          <Translate label={OverviewLastChecksLocale.OVERVIEW} />
        </Text>
        <ButtonGroup size="sm" ml={8} isAttached variant="outline">
          <Button
            bg={orderType === 'ALL' ? '#E6F0FE' : 'transparent'}
            color={orderType === 'ALL' ? '#0066F8' : '#5E6C7A'}
            mr="-px"
            height="32px"
            minWidth="70px"
            onClick={() => { setOrderType('ALL'); }}
          >
            <Translate label={OverviewLastChecksLocale.ALL} />
          </Button>
          <Button
            bg={orderType === 'SINGLE' ? '#E6F0FE' : 'transparent'}
            color={orderType === 'SINGLE' ? '#0066F8' : '#5E6C7A'}
            mr="-px"
            height="32px"
            onClick={() => { setOrderType('SINGLE'); }}
          >
            <Translate label={OverviewLastChecksLocale.SINGLE} />
          </Button>
          <Button
            bg={orderType === 'MULTIPLE' ? '#E6F0FE' : 'transparent'}
            color={orderType === 'MULTIPLE' ? '#0066F8' : '#5E6C7A'}
            mr="-px"
            height="32px"
            onClick={() => { setOrderType('MULTIPLE'); }}
          >
            <Translate label={OverviewLastChecksLocale.MULTIPLE} />
          </Button>
        </ButtonGroup>
      </Flex>
      <NotificationBoxes setFilter={(index) => { setFilter(filters[index]); }} filters={filters} />
      <OverviewLastChecksOptions type={orderType} filter={filter.filter} />
    </SectionContainer>
  );
};
export default Overview;
