import React from 'react';
import { SimpleGrid } from '@chakra-ui/react';

const DetailHeader: React.FunctionComponent = ({ children }) => (
  <SimpleGrid columns={[1, 1, 1, 2, 2, 2]} mb={10} gap={8} rowGap={18}>
    { children }
  </SimpleGrid>
);

export default DetailHeader;
