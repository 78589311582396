import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FilterButton, InfoContainer } from '@scaut-sro/meepo';
import {
  Box, Button,
  Collapse,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { Select } from 'chakra-react-select';
import { FiFilter, FiX } from 'react-icons/fi';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { translate } from '../../core/localization/LocaleUtils';
import { EnumerationsTableFilterProps, EnumerationsTableFilterType } from './Enumeratons.model';
import { EnumerationType } from '../../build/generated-sources/enum/EnumerationType';
import EnumerationsLocale from './Enumerations.locale';

const EnumerationsTableFilter: React.FunctionComponent<EnumerationsTableFilterProps> = (props) => {
  const { filter, onFilterChange } = props;
  const { language } = useGetUser();
  const {
    handleSubmit, register, reset, setValue,
  } = useForm();
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [enumerationType, setEnumerationType] = useState<{ value?: string, label?: string }[]>([]);

  const handleFilterSubmit: SubmitHandler<EnumerationsTableFilterType> = (values) => {
    onFilterChange(values);
  };

  const handleFilterCancel = () => {
    handleFilterSubmit({});
    setEnumerationType([]);
  };

  const typeOptions = useMemo(() => ([
    { value: EnumerationType.COUNTRY, label: translate(EnumerationsLocale.COUNTRY, language) },
    { value: EnumerationType.SURVEY, label: translate(EnumerationsLocale.SURVEY, language) },
  ]), [language]);

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmit(handleFilterSubmit);
    }
  };

  const activeFilters: boolean = useMemo(() => {
    const filteredObjKeys = Object.keys(filter).filter((key) => filter[key] && filter[key].length);
    return !!filteredObjKeys.length;
  }, [filter]);

  useEffect(() => {
    reset({
      fulltext: filter.fulltext,
      type: filter.type,
    });
  }, [filter, reset]);
  return (
    <Box>
      <form
        name="enumerationsFilterTableFilter"
        onSubmit={handleSubmit((values) => {
          handleFilterSubmit(values);
        })}
      >
        <Flex>
          <InputGroup zIndex={0}>
            <InputLeftElement>
              <SearchIcon />
            </InputLeftElement>
            <Input
              id="fulltext"
              variant="filled"
              onKeyUp={handleKeyUp}
              {...register('fulltext')}
            />
          </InputGroup>
          <Box ml={3}>
            <FilterButton
              activeFilters={activeFilters}
              openFilters={() => { setOpenFilters(!openFilters); }}
              cancelFilters={handleFilterCancel}
            />
          </Box>
        </Flex>
        <Collapse in={openFilters} animateOpacity>
          <Box mt={3}>

            <InfoContainer>

              <SimpleGrid gap={6} columns={[1, 1, 2, 2, 2, 3]}>

                <FormControl mb={6}>
                  <FormLabel htmlFor="enumerationType">
                    Type
                  </FormLabel>
                  <Select
                    name="type"
                    isMulti
                    options={typeOptions}
                    value={enumerationType}
                    hideSelectedOptions={false}
                    selectedOptionStyle="check"
                    onChange={(values: { value: string, label: string }[]) => {
                      setEnumerationType(values);
                      setValue('type', values.map((value: any) => value.value));
                    }}
                    size="sm"
                  />
                </FormControl>
              </SimpleGrid>

              <Flex mt={6} justifyContent="flex-end">
                <Button
                  size="sm"
                  colorScheme="red"
                  isDisabled={!activeFilters}
                  onClick={handleFilterCancel}
                  variant="ghost"
                  type="reset"
                  leftIcon={<FiX />}
                >
                  Cancel filter
                </Button>
                <Button
                  size="sm"
                  ml={3}
                  leftIcon={<FiFilter />}
                  type="submit"
                >
                  Filter
                </Button>
              </Flex>
            </InfoContainer>
          </Box>
        </Collapse>
      </form>
    </Box>
  );
};

export default EnumerationsTableFilter;
