import React, { useMemo, useState } from 'react';
import {
  Box, Button, Collapse,
  Flex, FormControl, GridItem, HStack, SimpleGrid, Switch,
  Text, Textarea, useDisclosure,
} from '@chakra-ui/react';
import {
  ViewIcon, ViewOffIcon,
} from '@chakra-ui/icons';
import { ConfirmationModal, Toast } from '@scaut-sro/meepo';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { translate } from '../../core/localization/LocaleUtils';
import TasksLocale from './Tasks.locale';
import Translate from '../../components/Translate/Translate';
import { NoteDetailProps, NotesFormAttr } from './Tasks.model';
import { useGetUser } from '../../core/store/reducers/UserSettingsReducer';
import { useComponentNoteDelete } from '../../build/generated-sources/service/MutationService';
import FormLocale from '../../core/localization/translations/Form.locale';
import ShowDate from '../../components/ShowDate/ShowDate';

const Note: React.FunctionComponent<NoteDetailProps> = (props) => {
  const { note, handleNoteSubmit, refreshData } = props;
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const { language } = useGetUser();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const { params } = useRouteMatch<{ id: string }>();

  const handleEdit = () => {
    setEdit(true);
  };
  const handleCancelEdit = () => {
    setEdit(false);
  };

  const defaultValues: Partial<NotesFormAttr> = useMemo(() => ({
    componentNoteId: note?.id,
    componentId: +params.id,
    text: note?.text || '',
    visibility: (note?.visibility === 'PRIVATE' && false) || (note?.visibility === 'PUBLIC' && true),
  }), [params.id, note]);

  const {
    formState: { errors }, register, getValues, control,
  } = useForm<Partial<NotesFormAttr>>({ defaultValues });

  const [mutateDeleteNote] = useComponentNoteDelete({
    success: true,
  }, {
    onCompleted: () => {
      Toast({
        title: translate(TasksLocale.NOTE_DELETED, language),
        status: 'success',
      });
      setEdit(false);
      onDeleteClose();
      if (refreshData) {
        refreshData();
      }
    },
    onError: () => {
      history.push('/404');
    },
  });

  const deleteNote = () => {
    mutateDeleteNote({
      variables: {
        componentNoteId: note.id || -1,
      },
    });
  };

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      p={[0, 5, 5, 5]}
      mb={4}
      boxShadow="base"
      key={note.id}
      backgroundColor={edit ? ('#ffffff') : (note?.visibility === 'PUBLIC' ? 'red.50' : 'green.50')}
      minW="400px"
      overflow="auto"
    >
      <Flex justifyContent="space-between">
        <Box>
          <Text fontSize="xs" color="gray.400">
            <Translate label={TasksLocale.NOTE_CREATED} />
            {note.createdBy}
            {' '}
            <ShowDate date={note.created} time />
          </Text>
          <Text fontSize="xs" color="gray.400" pb={3}>
            <Translate label={TasksLocale.NOTE_UPDATED} />
            {note.updatedBy}
            {' '}
            <ShowDate date={note.updated} time />
          </Text>
        </Box>
        <Box>
          <Text fontSize="m" color="gray.400">
            {note?.visibility === 'PUBLIC' ? <ViewIcon color="red.400" /> : <ViewOffIcon color="green.400" />}
          </Text>
        </Box>
      </Flex>
      {!edit && note.text && note?.text?.length <= 200 && (
      <Box>
        <Text size="s">
          {note.text}
        </Text>
      </Box>
      )}
      {!edit && note.text && note?.text?.length > 200 && (
      <>
        <Collapse in={open} startingHeight={50}>
          <Text fontSize="s">{note.text}</Text>
        </Collapse>
        <Button variant="ghost" size="sm" mt="1rem" onClick={() => setOpen(!open)}>
          {translate(TasksLocale.SHOW, language)}
          {' '}
          {translate(open ? TasksLocale.LESS : TasksLocale.MORE, language)}
        </Button>
      </>
      )}
      {edit && (
      <form>
        <SimpleGrid columns={5} spacing={6} mb={7}>
          <GridItem colSpan={5}>
            <FormControl isInvalid={!!errors.text}>
              <Textarea
                placeholder={translate(TasksLocale.NOTE_TEXT_PLACEHOLDER, language)}
                id="text"
                {...register('text', {
                  required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
                })}
              />
            </FormControl>
          </GridItem>
          <GridItem colStart={4} colSpan={2}>
            <Box>
              <FormControl
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderRadius="md"
                backgroundColor="gray.100"
                p="6px"
                pl={3}
              >
                <label htmlFor="visibility">{translate(TasksLocale.PUBLIC, language)}</label>
                <Controller
                  control={control}
                  name="visibility"
                  render={({ field }) => (
                    <Switch
                      onChange={(e) => { field.onChange(e.target.checked); }}
                      isChecked={field.value}
                      size="md"
                      colorScheme="red"
                    />
                  )}
                />
              </FormControl>
            </Box>
          </GridItem>
        </SimpleGrid>
      </form>
      )}
      <HStack justify="right">
        {edit ? (
          <>
            <Text
              color="primaryBase"
              cursor="pointer"
              onClick={() => {
                if (handleNoteSubmit) {
                  handleNoteSubmit(getValues());
                  setEdit(false);
                }
              }}
            >
              <strong><Translate label={TasksLocale.SAVE} /></strong>
            </Text>
            <Text color="primaryBase" cursor="pointer" onClick={onDeleteOpen}>
              <strong><Translate label={TasksLocale.DELETE} /></strong>
            </Text>
            <Text color="primaryBase" cursor="pointer" onClick={handleCancelEdit}>
              <strong><Translate label={TasksLocale.CANCEL} /></strong>
            </Text>
            <ConfirmationModal
              message={translate(TasksLocale.ARE_YOU_SURE, language)}
              yes={translate(FormLocale.YES, language)}
              no={translate(FormLocale.NO, language)}
              name={`${note?.text?.split(' ').slice(0, 5).join(' ')}...?`}
              modalFunction={deleteNote}
              isOpen={isDeleteOpen}
              onClose={onDeleteClose}
              onOpen={onDeleteOpen}
            />
          </>
        ) : (
          <Text color="primaryBase" cursor="pointer" onClick={handleEdit}>
            <strong><Translate label={TasksLocale.EDIT_NOTE_TITLE} /></strong>
          </Text>
        )}
      </HStack>
    </Box>
  );
};

export default Note;
