import React from 'react';
import {
  Badge,
  FormControl, FormLabel,
  GridItem, Input,
  SimpleGrid,
} from '@chakra-ui/react';
import { translate } from '../../../../core/localization/LocaleUtils';
import { useGetUser } from '../../../../core/store/reducers/UserSettingsReducer';
import { ClientInvoiceDetailProps } from './ClientInvoices.model';
import ClientInvoicesLocale from './ClientInvoices.locale';
import Translate from '../../../../components/Translate/Translate';
import ShowDate from '../../../../components/ShowDate/ShowDate';
import FormLocale from '../../../../core/localization/translations/Form.locale';

const ClientInvoiceDetailForm: React.FunctionComponent<ClientInvoiceDetailProps> = (props) => {
  const {
    invoice,
  } = props;
  const { language } = useGetUser();

  return (
    <>
      <SimpleGrid columns={[1, 1, 1, 3, 3, 3]} spacing={6} mb={7}>
        <GridItem colSpan={[1, 1, 1, 2, 2, 2]}>
          <FormControl>
            <FormLabel htmlFor="issueDate">
              <Translate label={ClientInvoicesLocale.ISSUE_DATE} />
            </FormLabel>
            <Input
              placeholder={translate(ClientInvoicesLocale.ISSUE_DATE, language)}
              isDisabled
              value={invoice.invoice?.issueDate}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="dueDate">
              <Translate label={ClientInvoicesLocale.DUE_DATE} />
            </FormLabel>
            <Input
              placeholder={translate(ClientInvoicesLocale.DUE_DATE, language)}
              isDisabled
              value={invoice.invoice?.dueDate}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="price">
              <Translate label={ClientInvoicesLocale.PRICE} />
            </FormLabel>
            <Input
              placeholder={translate(ClientInvoicesLocale.PRICE, language)}
              isDisabled
              value={invoice.invoice?.price}
            />
          </FormControl>
        </GridItem>
        <SimpleGrid columns={[1, 1, 1, 3, 3, 3]} spacing={6} mb={7}>
          <GridItem colSpan={[1, 1, 1, 2, 2, 2]}>
            <FormControl>
              <FormLabel htmlFor="issueDate">
                <Translate label={ClientInvoicesLocale.EMAIL_NOTIFICATION_SENT_AT} />
              </FormLabel>
            </FormControl>
            {invoice?.emailSent ? (
              <>
                <Badge colorScheme="green"><Translate label={FormLocale.YES} /></Badge>
                <ShowDate date={invoice.emailSentAt} time />
              </>
            ) : (
              <Badge colorScheme="red"><Translate label={FormLocale.NO} /></Badge>
            )}
          </GridItem>
        </SimpleGrid>
      </SimpleGrid>
    </>
  );
};
export default ClientInvoiceDetailForm;
