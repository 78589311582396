import React, { useEffect, useMemo } from 'react';
import {
  FormControl, FormErrorMessage, FormLabel, Input,
} from '@chakra-ui/react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Toast, Translate, translate } from '@scaut-sro/meepo';
import { Select } from 'chakra-react-select';
import { useAddPrepayment } from '../../../../build/generated-sources/service/MutationService';
import ClientsLocale from '../../Clients.locale';
import { useGetUser } from '../../../../core/store/reducers/UserSettingsReducer';
import FormLocale from '../../../../core/localization/translations/Form.locale';
import { PaymentType } from '../../../../build/generated-sources/enum/PaymentType';
import { ClientAddPrepaymentAttr, ClientAddPrepaymentProps } from '../ClientDetail/ClientDetail.model';

const ClientAddPrepaymentForm: React.FunctionComponent<ClientAddPrepaymentProps> = (props) => {
  const {
    loadingSetter, formRef, clientId, refreshData,
  } = props;
  const { language } = useGetUser();

  const {
    handleSubmit, formState: { errors }, register, control,
  } = useForm<Partial<ClientAddPrepaymentAttr>>();

  const paymentTypeOptionsList = useMemo(() => ([
    { value: 'CASH', label: translate(ClientsLocale.CASH, language) },
    { value: 'CHECK', label: translate(ClientsLocale.CHECK, language) },
    { value: 'OTHER', label: translate(ClientsLocale.OTHER, language) },
  ]), [language]);

  const [mutateAddPrepayment,
    mutateAddPrepaymentOptions] = useAddPrepayment({
    success: true,
  }, {
    onCompleted: () => {
      Toast({
        title: translate(FormLocale.SUCCESSFULLY_SAVED, language),
        status: 'success',
      });
      refreshData();
    },
    onError: () => {
      Toast({
        title: translate(ClientsLocale.ERROR_SAVING_DATA, language),
        status: 'error',
      });
    },
  });

  useEffect(() => {
    if (loadingSetter) loadingSetter(mutateAddPrepaymentOptions.loading);
  }, [loadingSetter, mutateAddPrepaymentOptions]);

  const handleAddPrepaymentFormSubmit: SubmitHandler<ClientAddPrepaymentAttr> = (values) => {
    mutateAddPrepayment({
      variables: {
        clientId: clientId || -1,
        balance: values.balance,
        paymentType: values.paymentType?.value as PaymentType,
        note: values.note,
      },
    });
  };

  return (
    <>
      <form
        name="clientAddPrepaymentForm"
        onSubmit={handleSubmit(handleAddPrepaymentFormSubmit)}
        ref={formRef}
      >
        <FormControl>
          <FormLabel><Translate label={ClientsLocale.PAYMENT_TYPE} /></FormLabel>
          <Controller
            name="paymentType"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={paymentTypeOptionsList}
                hideSelectedOptions={false}
                selectedOptionStyle="check"
              />
            )}
          />
        </FormControl>
        <FormControl isInvalid={!!errors.balance}>
          <FormLabel htmlFor="balance">
            <Translate label={ClientsLocale.AMOUNT} />
          </FormLabel>
          <Input
            placeholder={translate(ClientsLocale.AMOUNT, language)}
            type="number"
            {...register('balance', {
              required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
            })}
          />
          <FormErrorMessage>
            {
              // TODO:
              // @ts-ignore
              errors.balance && errors.balance.message
            }
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.note}>
          <FormLabel htmlFor="note">
            <Translate label={ClientsLocale.NOTE} />
          </FormLabel>
          <Input
            placeholder={translate(ClientsLocale.NOTE, language)}
            {...register('note', {
              required: translate(FormLocale.ERROR_MANDATORY_FIELD, language),
            })}
          />
          <FormErrorMessage>
            {errors.note && errors.note.message}
          </FormErrorMessage>
        </FormControl>
      </form>
    </>
  );
};
export default ClientAddPrepaymentForm;
