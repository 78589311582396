import React, { PropsWithChildren } from 'react';
import { Badge } from '@chakra-ui/react';
import Translate from '../Translate/Translate';
import { PAOStateBadgeLocale, PAOStateBadgeProps } from './PAOStateBadge.model';
import { POAStatus } from '../../build/generated-sources/enum/POAStatus';

export const PAOStateBadge = (
  props: PropsWithChildren<PAOStateBadgeProps>,
) => {
  const { poaStatus } = props;

  switch (poaStatus) {
    case POAStatus.UNDECIDED:
      return (
        <Badge
          bg="#FF7800"
          variant="solid"
          fontSize="xs"
          p={1}
          textAlign="center"
          borderRadius="md"
        >
          <Translate label={PAOStateBadgeLocale.UNDECIDED} />
        </Badge>
      );
    case POAStatus.WONT_GENERATE:
      return (
        <Badge
          bg="#E53E3E"
          variant="solid"
          fontSize="xs"
          p={1}
          textAlign="center"
          borderRadius="md"
        >
          <Translate label={PAOStateBadgeLocale.WONT_GENERATE} />
        </Badge>
      );
    case POAStatus.GENERATED:
      return (
        <Badge
          bg="#0d78b0"
          variant="solid"
          fontSize="xs"
          p={1}
          textAlign="center"
          borderRadius="md"
        >
          <Translate label={PAOStateBadgeLocale.GENERATED} />
        </Badge>
      );
    case POAStatus.READY_TO_GENERATE:
      return (
        <Badge
          bg="#0d78b0"
          variant="solid"
          fontSize="xs"
          p={1}
          textAlign="center"
          borderRadius="md"
        >
          <Translate label={PAOStateBadgeLocale.READY_TO_GENERATE} />
        </Badge>
      );
    case POAStatus.RECEIVED:
      return (
        <Badge
          bg="#00D274"
          variant="solid"
          fontSize="xs"
          p={1}
          textAlign="center"
          borderRadius="md"
        >
          <Translate label={PAOStateBadgeLocale.RECEIVED} />
        </Badge>
      );
    default:
      return (<></>);
  }
};

export default PAOStateBadge;
